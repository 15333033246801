import React, {useState} from "react";
import styles from "../deviceStatuses.module.scss";
import EditSVG from "../../../../../assets/svg/edit.svg";
import {renameChannel} from "../../../../../api/openApi";
import {IChannelsProps} from "../interfaces";

const RenderChannels = (props : IChannelsProps): JSX.Element =>{

    return(
        <ul>
            { props.channels.map(channel => <Channel key={channel.channel_id} channel={channel} {...props}/>) }
        </ul>
    )
}

const Channel = ({setPrevIdChannels, setSelectedChannels, serial, channels, setChannels, channel}): JSX.Element => {
    const [nameChannel, setNameChannel] = useState<string>(channel?.name || `Channel ${channel.channel_id}`);
    const [editName, setEditName] = useState<string>('');

    const selectChannel = (): void => {
        setPrevIdChannels(channel.channel_id)
        setSelectedChannels([channel])
    };
    const handleChangeName = ({target: { value }}): void => setEditName(value);
    const handleFocus = ({target}): string => target.value = nameChannel;
    const handleBlur = ({target}): string => target.value = '';

    const editChannel = async (): Promise<void> =>{
        const body = {
            device_serial: serial[0],
            channel_id: channel?.channel_id,
            name: editName
        }

        const result = await renameChannel(body);

        if(result){
            setNameChannel(result.name);
            const renamedChannelsList = channels.map(item=>{
                if(+item.channel_id === result.channel_id) item.name = result.name;
                return item;
            })
            setChannels(renamedChannelsList);
            setEditName('');
        }
    }

    return (
        <li className={styles.channelItem} key={+channel.channel_id}>
            <div className={styles.nameChannel}>
                <input
                    type="radio"
                    name={'channel'}
                    onChange={selectChannel}
                />
            </div>

            <div className={styles.editChannel}>
                <input
                    type="text"
                    onChange={handleChangeName}
                    placeholder={nameChannel}
                    value={editName}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className="form-control-plaintext"
                />

                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={editChannel}
                    disabled={!editName}>
                    <img src={EditSVG} alt="edit"/>
                </button>
            </div>
        </li>
    )
}

export default RenderChannels;
