import React from 'react';
import Switch from 'react-switch';
import {PropsInterface} from './interface';

const SwitchComponent = ({onChange, checked}: PropsInterface): JSX.Element => {

    return (
        <Switch
            onChange={onChange}
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor={'#B9B9B9'}
            onColor={'#FF7C02'}
            handleDiameter={16}
            height={12}
            width={28}
            boxShadow={'0px -2px 12px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.1)'}
            activeBoxShadow={'none'}
        />
    );
};

export default SwitchComponent;