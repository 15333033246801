import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useRedux} from '../../../../states/redux-state';
import moment from 'moment';
import {equals} from 'ramda';
import {intersection} from 'lodash';
import Checkbox from '../../../elements/Checkbox';
import TuneIcon from '../../../SVG-components/tune';
import Dialog, {DialogConfigSetter} from '../../../Dialog';
import {updateFilter} from '../../../../shared/db/filters-db';
import {tagShowToggle} from '../../../../stores/reducers/tagsDevicesMap/AC';
import {iFullStoreState, UserAuth} from '../../../../shared/interfaces';
import {isMobile} from "../../../../shared/helpers";
import './Filters.scss';

const HALF_DAY = 43_200_000; // 12 hours in milliseconds

const Filters = (): JSX.Element => {
    const authUser = useSelector<iFullStoreState, UserAuth>((s) => s.auth.user, (l, r) => equals(l, r));
    const filters = useSelector<iFullStoreState, object>((state) => state.general.filters);
    // console.log("filters", filters);
    const devicesLastPing = useRedux((s) => s.devicesData.devicesLastPing).valueSeq().toArray();
    const showedTagsIds = useRedux((s) => s.tagsDevicesMap.showedTagsIds).valueSeq().toArray();

    const [selectedDateFilter, setSelectedDateFilter] = useState(null);
    const [selectedSpeedFilter, setSelectedSpeedFilter] = useState(null);
    const [selectedMessageFilters, setSelectedMessageFilters] = useState(null);
    const [selectedAddressFilters, setSelectedAddressFilters] = useState(null);

    const [selectAllFilters, setSelectAllFilters] = useState(false);

    const getInitFiltersState = () => {
        const initState = {
            dateProperty: null,
            speedProperty: null,
            messageProperty: null,
            addressProperty: null
        };

        Object.keys(filters || {}).filter(key => filters[key].selected).forEach((key) => {
            const filter = filters[key];
            if (filter.dateProperty) {
                if (filter.hours) {
                    const milliseconds = filter.hours*60*60*1000;

                    const filteredShowDevices = devicesLastPing.filter((item) => item !== null && moment()
                      .diff(item.time) < milliseconds).map((item) => item.device);
                    const filteredHideDevices = devicesLastPing.filter((item) => item !== null && moment()
                      .diff(item.time) > milliseconds).map((item) => item.device);

                    const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                    initState.dateProperty = {...filter, id: key, category: 'date', devices};
                } else {
                    const filteredShowDevices = devicesLastPing.filter((item) => item !== null)
                      .filter((item) => moment(item.time).valueOf() >= getStartOfDay(filter) && moment(item.time).valueOf() < getEndOfDay(filter))
                      .map((item) => item.device);

                    const filteredHideDevices = devicesLastPing.filter((item) => item !== null)
                      .filter((item) => moment(item.time).valueOf() < getStartOfDay(filter) || moment(item.time).valueOf() >= getEndOfDay(filter))
                      .map((item) => item.device);

                    const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                    initState.dateProperty = {...filter, id: key, category: 'date', devices};
                }
            } else if (filter.speedProperty) {
                if(filter.speedProperty.value === 'equalMore') {
                    const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed >= filter.speed)
                      .map((item) => item.device);
                    const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed < filter.speed)
                      .map((item) => item.device);

                    const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                    initState.speedProperty = {...filter, id: key, category: 'speed', devices};
                }

                if(filter.speedProperty.value === 'equalLess') {
                    const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed <= filter.speed)
                      .map((item) => item.device);
                    const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed > filter.speed)
                      .map((item) => item.device);

                    const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                    initState.speedProperty = {...filter, id: key, category: 'speed', devices};
                }

                if(filter.speedProperty.value === 'more') {
                    const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed > filter.speed)
                      .map((item) => item.device);
                    const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed < filter.speed)
                      .map((item) => item.device);

                    const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                    initState.speedProperty = {...filter, id: key, category: 'speed', devices};
                }

                if(filter.speedProperty.value === 'less') {
                    const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed < filter.speed)
                      .map((item) => item.device);
                    const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed > filter.speed)
                      .map((item) => item.device);

                    const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                    initState.speedProperty = {...filter, id: key, category: 'speed', devices};
                }
            } else if (filter.messageProperty) {
                const filteredShowDevices = devicesLastPing.filter((item) => item !== null)
                  .filter((item) => item.msg.trim() === filter.messageProperty.label)
                  .map((item) => item.device);

                const filteredHideDevices = devicesLastPing.filter((item) => item !== null)
                  .filter((item) => item.msg.trim() !== filter.messageProperty.label)
                  .map((item) => item.device);

                const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                initState.messageProperty = {...filter, id: key, category: 'message', devices};
            } else if (filter.property.value === 'address') {
                const filteredShowDevices = devicesLastPing
                  .filter((item) => item !== null )
                  .filter((item) => item.address.city.trim() === filter.city)
                  .filter((item) => item.address.state.trim() === filter.state)
                  .filter((item) => item.address.street.trim() === filter.street)
                  .filter((item) => item.address.zip.trim() === filter.zip)
                  .map((item) => item.device);
                const filteredHideDevices = devicesLastPing
                  .filter((item) => item !== null)
                  .filter((item) => item.address.city.trim() !== filter.city || item.address.state.trim() !== filter.state ||
                    item.address.street.trim() !== filter.street || item.address.zip.trim() !== filter.zip)
                  .map((item) => item.device);

                const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

                initState.addressProperty = {...filter, id: key, category: 'address', devices};
            } else {
                const setStateByProperty = (property) => {
                    const filteredShowDevices = devicesLastPing
                      .filter((item) => item !== null)
                      .filter((item) => item.address[property].trim() === filter[property])
                      .map((item) => item.device);
                    const filteredHideDevices = devicesLastPing
                      .filter((item) => item !== null)
                      .filter((item) => item.address[property].trim() !== filter[property])
                      .map((item) => item.device);

                    const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;
                    initState.addressProperty = {...filter, id: key, category: 'address', devices};
                }
                if (filter.zip) setStateByProperty('zip');
                if (filter.city) setStateByProperty('city');
                if (filter.state) setStateByProperty('state');
                if (filter.street) setStateByProperty('street');
            }
        });
        // console.log("initState: ", initState);
        return initState;
    }

    const [selectedFilters, setSelectedFilters] = useState({
        dateProperty: null,
        speedProperty: null,
        messageProperty: null,
        addressProperty: null
    });

    const setInitState = () => {
        const initState = getInitFiltersState();
        setSelectedFilters(initState);
    }

    useEffect(setInitState, []);

    const dispatch = useDispatch();

    const dialogRef = React.useRef<DialogConfigSetter>();
    const dialog = dialogRef.current;

    const setupDialog = (callBack: () => DialogConfigSetter): void => {
        dialogRef.current = callBack();
    };

    const devicesIds = devicesLastPing.filter((item) => item !== null).map((item) => item.device);

    const filtersWithIds = Object.keys(filters || {}).reduce((acc, rec) => {
        if (filters[rec].dateProperty) {
            return [...acc, {...filters[rec], id: rec, category: 'date'}];
        } else if (filters[rec].speedProperty) {
            return [...acc, {...filters[rec], id: rec, category: 'speed'}];
        } else if (filters[rec].messageProperty) {
            return [...acc, {...filters[rec], id: rec, category: 'message'}];
        }

        return [...acc, {...filters[rec], id: rec, category: 'address'}];
    }, []);

    const getStartOfDay = (filter): number => {
        if(filter.date) {
            return moment(filter.date).valueOf() - HALF_DAY;
        } else if(filter.dateFrom && filter.dateTo) {
            return moment(filter.dateFrom).valueOf() - HALF_DAY;
        }
    };

    const getEndOfDay = (filter): number => {
        if(filter.date) {
            return moment(filter.date).valueOf() + HALF_DAY;
        } else if(filter.dateFrom && filter.dateTo) {
            return moment(filter.dateTo).valueOf() + HALF_DAY;
        }
    };

    const filterByDate = async (filter, selectAll): Promise<any> => {
        if(filter.hours) {
            const milliseconds = filter.hours*60*60*1000;

            const filteredShowDevices = devicesLastPing.filter((item) => item !== null && moment()
                .diff(item.time) < milliseconds).map((item) => item.device);
            const filteredHideDevices = devicesLastPing.filter((item) => item !== null && moment()
                .diff(item.time) > milliseconds).map((item) => item.device);

            const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

            if(selectAll){
                setSelectedDateFilter({...filter, devices});
            } else {
                if(!selectedFilters.dateProperty || selectedFilters.dateProperty.id === filter.id) {
                    setSelectedFilters({...selectedFilters, dateProperty: selectedFilters.dateProperty ? null : {...filter, devices}});
                }
            }

        } else {
            const filteredShowDevices = devicesLastPing.filter((item) => item !== null)
                .filter((item) => moment(item.time).valueOf() >= getStartOfDay(filter) && moment(item.time).valueOf() < getEndOfDay(filter))
                .map((item) => item.device);

            const filteredHideDevices = devicesLastPing.filter((item) => item !== null)
                .filter((item) => moment(item.time).valueOf() < getStartOfDay(filter) || moment(item.time).valueOf() >= getEndOfDay(filter))
                .map((item) => item.device);

            const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

            if(selectAll) {
                setSelectedDateFilter({...filter, devices});
            } else {
                if(!selectedFilters.dateProperty || selectedFilters.dateProperty.id === filter.id) {
                    setSelectedFilters({...selectedFilters, dateProperty: selectedFilters.dateProperty ? null : {...filter, devices}});
                }
            }
        }

        if(selectAll) {
            await updateFilter(authUser)( filter.id, {...filters[filter.id], selected: !selectAllFilters});
        } else {
            if(!selectedFilters.dateProperty || selectedFilters.dateProperty.id === filter.id) {
                await updateFilter(authUser)( filter.id, {...filters[filter.id], selected: !filters[filter.id].selected});
            } else {
                await dialog?.({
                    type: 'NOTIFICATION',
                    title: 'Filter error',
                    body: 'You can\'t chose more then one filter from one category!',
                });

                return;
            }
        }
    };

    const filterBySpeed = async (filter, selectAll): Promise<any> => {
        if(filter.speedProperty.value === 'equalMore') {
            const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed >= filter.speed)
                .map((item) => item.device);
            const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed < filter.speed)
                .map((item) => item.device);

            const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

            if(selectAll) {
                setSelectedSpeedFilter({...filter, devices});
            } else {
                if(!selectedFilters.speedProperty || selectedFilters.speedProperty.id === filter.id) {
                    setSelectedFilters({...selectedFilters, speedProperty: selectedFilters.speedProperty ? null : {...filter, devices}});
                }
            }
        }

        if(filter.speedProperty.value === 'equalLess') {
            const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed <= filter.speed)
                .map((item) => item.device);
            const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed > filter.speed)
                .map((item) => item.device);

            const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

            if(selectAll) {
                setSelectedSpeedFilter({...filter, devices});
            } else {
                if(!selectedFilters.speedProperty || selectedFilters.speedProperty.id === filter.id) {
                    setSelectedFilters({...selectedFilters, speedProperty: selectedFilters.speedProperty ? null : {...filter, devices}});
                }
            }
        }

        if(filter.speedProperty.value === 'more') {
            const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed > filter.speed)
                .map((item) => item.device);
            const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed < filter.speed)
                .map((item) => item.device);

            const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

            if(selectAll) {
                setSelectedSpeedFilter({...filter, devices});
            } else {
                if(!selectedFilters.speedProperty || selectedFilters.speedProperty.id === filter.id) {
                    setSelectedFilters({...selectedFilters, speedProperty: selectedFilters.speedProperty ? null : {...filter, devices}});
                }
            }
        }

        if(filter.speedProperty.value === 'less') {
            const filteredShowDevices = devicesLastPing.filter((item) => item !== null && item.speed < filter.speed)
                .map((item) => item.device);
            const filteredHideDevices = devicesLastPing.filter((item) => item !== null && item.speed > filter.speed)
                .map((item) => item.device);

            const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

            if(selectAll) {
                setSelectedSpeedFilter({...filter, devices});
            } else {
                if(!selectedFilters.speedProperty || selectedFilters.speedProperty.id === filter.id) {
                    setSelectedFilters({...selectedFilters, speedProperty: selectedFilters.speedProperty ? null : {...filter, devices}});
                }
            }
        }

        if(selectAll) {
            await updateFilter(authUser)(filter.id, {...filters[filter.id], selected: !selectAllFilters});
        } else {
            if(!selectedFilters.speedProperty || selectedFilters.speedProperty.id === filter.id) {
                await updateFilter(authUser)(filter.id, {...filters[filter.id], selected: !filters[filter.id].selected});
            } else {
                await dialog?.({
                    type: 'NOTIFICATION',
                    title: 'Filter error',
                    body: 'You can\'t chose more then one filter from one category!',
                });

                return;
            }
        }
    };

    const filterByMessage = async (filter, selectAll): Promise<any> => {
        const filteredShowDevices = devicesLastPing.filter((item) => item !== null)
            .filter((item) => item.msg.trim() === filter.messageProperty.label)
            .map((item) => item.device);

        const filteredHideDevices = devicesLastPing.filter((item) => item !== null)
            .filter((item) => item.msg.trim() !== filter.messageProperty.label)
            .map((item) => item.device);

        const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

        if(selectAll) {
            setSelectedMessageFilters({...filter, devices});
            await updateFilter(authUser)( filter.id, {...filters[filter.id], selected: !selectAllFilters});
        } else {
            if(!selectedFilters.messageProperty || selectedFilters.messageProperty.id === filter.id) {
                setSelectedFilters({...selectedFilters, messageProperty: selectedFilters.messageProperty ? null : {...filter, devices}});
                await updateFilter(authUser)( filter.id, {...filters[filter.id], selected: !filters[filter.id].selected});
            } else {
                await dialog?.({
                    type: 'NOTIFICATION',
                    title: 'Filter error',
                    body: 'You can\'t chose more then one filter from one category!',
                });

                return;
            }
        }
    };

    const filterByAddress = async (filter, selectAll): Promise<any> => {
        const filteredShowDevices = devicesLastPing
            .filter((item) => item !== null )
            .filter((item) => item.address.city.trim() === filter.city)
            .filter((item) => item.address.state.trim() === filter.state)
            .filter((item) => item.address.street.trim() === filter.street)
            .filter((item) => item.address.zip.trim() === filter.zip)
            .map((item) => item.device);
        const filteredHideDevices = devicesLastPing
            .filter((item) => item !== null)
            .filter((item) => item.address.city.trim() !== filter.city || item.address.state.trim() !== filter.state ||
                item.address.street.trim() !== filter.street || item.address.zip.trim() !== filter.zip)
            .map((item) => item.device);

        const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

        if(selectAll) {
            setSelectedAddressFilters({...filter, devices});
            await updateFilter(authUser)( filter.id, {...filters[filter.id], selected: !selectAllFilters});
        } else {
            if(!selectedFilters.addressProperty || selectedFilters.addressProperty.id === filter.id) {
                setSelectedFilters({...selectedFilters, addressProperty: selectedFilters.addressProperty ? null : {...filter, devices}});
                await updateFilter(authUser)( filter.id, {...filters[filter.id], selected: !filters[filter.id].selected});
            } else {
                await dialog?.({
                    type: 'NOTIFICATION',
                    title: 'Filter error',
                    body: 'You can\'t chose more then one filter from one category!',
                });

                return;
            }
        }
    };

    const filterByProperty = async (filter, property, selectAll): Promise<any> => {
        const filteredShowDevices = devicesLastPing
            .filter((item) => item !== null)
            .filter((item) => item.address[property].trim() === filter[property])
            .map((item) => item.device);
        const filteredHideDevices = devicesLastPing
            .filter((item) => item !== null)
            .filter((item) => item.address[property].trim() !== filter[property])
            .map((item) => item.device);

        const devices = filter.action.value ? filteredShowDevices : filteredHideDevices;

        if(selectAll) {
            setSelectedAddressFilters({...filter, devices});
            await updateFilter(authUser)(filter.id, {...filters[filter.id], selected: !selectAllFilters});
        } else {
            if(!selectedFilters.addressProperty || selectedFilters.addressProperty.id === filter.id) {
                setSelectedFilters({...selectedFilters, addressProperty: selectedFilters.addressProperty ? null : {...filter, devices}});
                await updateFilter(authUser)(filter.id, {...filters[filter.id], selected: !filters[filter.id].selected});
            } else {
                await dialog?.({
                    type: 'NOTIFICATION',
                    title: 'Filter error',
                    body: 'You can\'t chose more then one filter from one category!',
                });

                return;
            }
        }
    };

    const selectToggle =  (filter, selectAll= false): void  => {
        if (filter.dateProperty) {
            filterByDate(filter, selectAll);
        } else if (filter.speedProperty) {
            filterBySpeed(filter, selectAll);
        } else if (filter.messageProperty) {
            filterByMessage(filter, selectAll);
        } else if (filter.property.value === 'address') {
            filterByAddress(filter, selectAll);
        } else {
            if (filter.zip) filterByProperty(filter, 'zip', selectAll);

            if (filter.city) filterByProperty(filter, 'city', selectAll);

            if (filter.state) filterByProperty(filter, 'state', selectAll);

            if (filter.street) filterByProperty(filter, 'street', selectAll);
        }
    };

    /*const selectAllToggle =  async (): Promise<any> => {
        const categoriesCounter = {date: 0, speed: 0, message: 0, address: 0};

        filtersWithIds.forEach((filter) => {
            if (filter.category === 'date') categoriesCounter.date += 1;

            if (filter.category === 'speed') categoriesCounter.speed += 1;

            if (filter.category === 'message') categoriesCounter.message += 1;

            if (filter.category === 'address') categoriesCounter.address += 1;
        });

        if(categoriesCounter.date > 1 || categoriesCounter.speed > 1 || categoriesCounter.message > 1 || categoriesCounter.address > 1) {
            const dialog = dialogRef.current;
            await dialog?.({
                type: 'NOTIFICATION',
                title: 'Filter error',
                body: 'You can\'t chose more then one filter from one category!',
            });

            return;
        } else {
            filtersWithIds.forEach((filter) => {
                selectToggle(filter, true);
            });
            setSelectAllFilters(!selectAllFilters);
        }
    };*/

    useEffect(() => {
        const filters = Object.values(selectedFilters).filter((item) => item !== null);

        if (filters.length === 0) {
            showedTagsIds.forEach((tagId) => dispatch(tagShowToggle(tagId, devicesIds, true)));
        } else if (filters.length === 1) {
            filters.forEach((filter) => {
                showedTagsIds.forEach((tagId) => dispatch(tagShowToggle(tagId, filter.devices, true)));
            });
        } else {
            const filterDevices = filters.reduce((acc, rec) => {
                return [...acc, rec.devices];
            }, []);
            showedTagsIds.forEach((tagId) => dispatch(tagShowToggle(tagId, intersection(...filterDevices), true)));
        }
    }, [selectedFilters]);

    /*useEffect(() => {
        console.log("selectAllFilters", selectAllFilters);
        setSelectedFilters({
            dateProperty:  selectAllFilters ? selectedDateFilter : null,
            speedProperty: selectAllFilters ? selectedSpeedFilter : null,
            messageProperty: selectAllFilters ? selectedMessageFilters : null,
            addressProperty: selectAllFilters ? selectedAddressFilters : null
        });
    }, [selectAllFilters]);*/

    return (
        <div className={`Filters ${isMobile ? 'Filters-Mobile' : ''}`}>
            <div className='FiltersBox'>
                {filtersWithIds.map((filter) => {
                    return (
                        <div key={filter.id}>
                            <div>
                                <Checkbox inputId={filter.id}  checked={filter.selected} onChange={(): void => selectToggle(filter)}/>
                                <span>{filter.label}</span>
                            </div>
                            <Link to={`/filters/${filter.id}/edit`}>
                                <TuneIcon/>
                            </Link>
                        </div>
                    );
                })}
            </div>
            <div className='ButtonsBlock'>
                {/*<button onClick={(): Promise<any> => selectAllToggle()} className='WhiteButton'>{selectAllFilters ? 'Clear All' : 'Select All'}</button>*/}
                <Link className='ButtonsBlock-link' to={'/filters/new-filter/edit'}>
                    <button className='BlackButton'>Add Filter</button>
                </Link>
            </div>
            <Dialog setupConfig={setupDialog} />
        </div>
    );
};

export default Filters;
