import React, {HTMLAttributes, ReactChild, ReactElement, useEffect} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';

import TabContent from './TabContent';
import Tab from './Tab';

import './Tabs.scss';

export const cnTabs = cn('Tabs');

type IProps = IClassNameProps & HTMLAttributes<HTMLOListElement> & {
    children: Array<ReactElement<{ 'data-label': string; children: ReactChild }>>;
    activeTab?: number;
}

const Tabs = ({activeTab, children, className, ...props}: IProps) => {
    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    const changeTab = (tabIndex: number) => () => {
        if (activeTabIndex !== tabIndex && tabIndex < children.length) {
            setActiveTabIndex(tabIndex);
        }
    };
    useEffect(() => {
        if(activeTab) setActiveTabIndex(activeTab);
    }, []);

    const content = children[activeTabIndex].props.children;

    return <>
        <ol className={cnTabs({}, [className])} {...props}>
            {children.map(({props: {'data-label': label}}, index) => {
                return label ? <Tab key={index} label={label} onClick={changeTab(index)} active={index === activeTabIndex} /> : null;
            }).reverse()}
        </ol>
        <TabContent>
            {content}
        </TabContent>
    </>;
};

export default Tabs;
