import React from 'react';
import raven from 'raven-js';
import { faExclamation } from '@fortawesome/fontawesome-free-solid';

import { Fa } from '../components/elements/fa';
import { devSettings } from './dev-helpers';
import { theClient, fbConfig } from './firebase';


export abstract class BaseComponent<P = {}, S = {}, FullProps = {}> extends React.Component<P, S> {

    get fullProps(): (FullProps & {dispatch: any} & P) { return this.props as any };

    protected stateSetter = newState => (...any) => this.setState(newState);

    abstract debugRender: () => JSX.Element | null | false;

    render() {
        return (
            <ErrorCatcher compName={(this as any).constructor.name} renderer={this.debugRender} />
        )
    }
}

class ErrorCatcher extends React.Component<{compName, renderer}, { error?: string|false }> {
    componentDidCatch(error:Error, info) {
        if (!devSettings.disableSentryBool) {
            raven.captureException(error, {stacktrace: info.componentStack, extra: { client: theClient(), who: fbConfig } })
        }

        console.groupCollapsed(error.message)
        console.log(error);
        console.log(info.componentStack)
        console.groupEnd();

        this.setState({ error: info.componentStack.split('\n')[2].split(' (')[0] } as any)
    }

    render() {
        if (this.state && this.state.error) return <Fa icon={faExclamation} title={this.state.error} />;

        return <>{this.props.renderer()}</>
    }
}
