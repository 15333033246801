import React from 'react';
import C from '../../../../shared/constants';
import {fenceHistory} from '../../../../stores/store';
import {BaseComponent} from "../../../../shared/BaseComponent";
import {Fa} from '../../../elements/fa';
import {faArrowLeft} from '@fortawesome/fontawesome-free-solid';
import {FaAction, ButtonLink, ButtonAction, Props} from './ActionRow.interfaces'
import ActionLink from './ActionLink'
import styles from './ActionRow.module.scss';

class ActionRow extends BaseComponent<Props, any> {
    shouldComponentUpdate(nextProps: Props): boolean {
        const {title, actions, canBack, style} = this.props;

        return nextProps.title !== title || nextProps.actions !== actions || nextProps.canBack !== canBack || nextProps.style !== style || nextProps.itemType != this.props.itemType || nextProps.itemId != this.props.itemId;
    }

    debugRender = (): JSX.Element => {
        const {title, actions, canBack=false, style={}, itemId, itemType} = this.props;

        return (
            <div className={styles.actionRow} style={{...style}}>
                {!canBack ? null : <span className={`btn-link ${styles.actionBackBtn}`} onClick={(_) => fenceHistory.goBack()}><Fa icon={faArrowLeft} /></span>}
                <span className={styles.actionRowTitle} title={`${title}`}>{title}</span>

                <span className={styles.actionsList}>
                    {Array.isArray(actions)
                        ? actions.map((x, index) => <ActionLink itemId={itemId} itemType={itemType} key={index} to={x.link} title={x.title}><Fa icon={x.fa} containerClassName={styles.actionIcon} /></ActionLink>)
                        : (actions as any).to
                            ? <ActionLink itemId={itemId} itemType={itemType} to={(actions as ButtonLink).to} className="btn btn-primary btn-xs">{actions.text}</ActionLink>
                            : <button className="btn btn-primary btn-xs" onClick={(actions as ButtonAction).action}>{actions.text}</button>
                    }
                </span>
            </div>
        );
    }
}


export default ActionRow;