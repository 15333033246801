import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ACL } from "../shared/constants";
import { iFullStoreState, ItemType, UserAuth } from "../shared/interfaces";

export const useIsReadOnly = (itemId: string, itemType: ItemType): boolean => {
    const [isReadOnly, setIsReadOnly] = useState(true);
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);

    useEffect(() => {
        ACL.checkPermissionAndEditResource({
            user,
            itemType: itemType,
            itemId,
        }).then(hasPermissionToEdit => {
            setIsReadOnly(!hasPermissionToEdit);
        });
    }, [user, itemId, itemType]);

    return isReadOnly
}