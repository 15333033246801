import React, { CSSProperties as css } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { GithubPicker } from 'react-color';
import { faTrashAlt, faDotCircle } from '@fortawesome/fontawesome-free-regular';
import { faStopCircle } from '@fortawesome/fontawesome-free-solid';
import ActionRow from '../ActionRow/ActionRow';
import C from '../../../../shared/constants';
import { fenceHistory } from '../../../../stores/store';
import { FenceBodyLayout, FenceBodyContainer } from '../BodyLayout';
import { FenceType, FenceGroup, iFullStoreState } from '../../../../shared/interfaces';
import { Actions as PathActions } from '../../../../stores/reducers/map-path-reducers';
import { Actions as RegionActions } from '../../../../stores/reducers/map-region-reducers';
import {
    getMapCenter as getMapZoomFromStore,
    Actions as GmapActions,
} from '../../../../stores/reducers/gmap-reducers';
import * as fencedb from '../../../../shared/db/fences-db';
import { BaseComponent } from "../../../../shared/BaseComponent";
import { Fa } from "../../../elements/fa";
import { noSubmit } from "../../../../shared/helpers";
import {IFullProps, iState} from "./EditPath.interfaces";
import styles from './EditPath.module.scss';

const defaultRegionColor = '#333';


const mapStateToProps = (s: iFullStoreState, o) => {
    const getMapCenter = () => getMapZoomFromStore(s.gmap);
    const {itemType, itemId, fenceId: pathId} = o.match.params;

    return {
        itemId,
        itemType,
        pathId,
        getMapCenter,
        authUser: s.auth.user!,

        recording: s.paths.vis?.[pathId]?.recording ?? false,
        points: s.paths.vis?.[pathId]?.points ?? [],
        pointCount: s.paths.vis[pathId] ? s.paths.vis[pathId].points.length : 1,
        ffPointCount: s.paths.vis[pathId] ? (s.paths.vis[pathId].ffPoints || []).length : 0,
    };
};

class EditPath extends BaseComponent<IFullProps, iState> {
    state = {
        deetsToUpdate: {},
    } as iState

    initializePath = async id => {
        const { pathId, dispatch, initialDetails } = this.props;

        let points = (await fencedb.getPath(pathId)) || [this.props.getMapCenter()]

        // initialize a path to edit in the store
        dispatch(PathActions.ADD_PATH(
            pathId, points, (initialDetails || {}).color || defaultRegionColor, true
        ))
    }

    componentWillUnmount() {
        const { pathId, dispatch, points } = this.props;
        const { initialPoints = false } = this.state;

        if (initialPoints && initialPoints.length != points.length) {
            dispatch(PathActions.SWAP_POINTS(pathId, initialPoints));
        }
        
        dispatch(PathActions.STASH_PATHS());
        dispatch(RegionActions.STASH_REGIONS());
    }

    componentWillReceiveProps (props: Readonly<IFullProps>): void {
        if (props.points.length && !this.state.initialPoints) {
            this.setState({initialPoints: props.points})
        }
    }

    setColor = ({hex}) => {
        const { dispatch, pathId } = this.props;

        dispatch(PathActions.UPDATE_COLOR(pathId, hex));

        this.setState(s => update(s, ({
            deetsToUpdate: {color: {$set: hex}}
        })));
    }

    setDeet = ({target}) => {
        const { name, value } = target;
        const { initialDetails = {} } = this.props;

        const initVal = initialDetails[name] || "";

        if (!name.length) return;

        if (initVal == value)
            this.setState(s => update(s, ({
                deetsToUpdate: {$unset: [name]}
            })));
        else 
            this.setState(s => update(s, {
                deetsToUpdate: { [name]: {$set: value}}
            }))
    }

    save = async () => {
        const { pathId, points, itemType, itemId } = this.props;
        
        // saving is a combination of the data from the form (deetstoupdate) and the region
        let fence = {
            id: pathId,
            ...this.state.deetsToUpdate,
            shape: FenceType.PATH,
        } as any;

        fence.path = points;
        fence.fenceType = FenceType.PATH;
        fence.fenceGroup = FenceGroup.PATH;

        await fencedb.updateFence(this.props.authUser)(itemType, itemId, fence);

        this.setState({initialPoints: fence.path});

        fenceHistory.goBack();
    }

    private find = () => {
        const {points, dispatch} = this.props;

        if (!points.length) {
            this.recenter();
        } else {
            dispatch(GmapActions.RECENTER_MAP(points[points.length - 1]));
        }
    };

    private recenter = () => {
        const { pathId, dispatch, getMapCenter } = this.props;
        dispatch(PathActions.SWAP_POINTS(pathId, [getMapCenter()]));
        dispatch(PathActions.CHANGE_RECORD(pathId, false));
    };

    timeTravel = (oldVal, newVal) => {
        if (oldVal == newVal) return;

        if (newVal < oldVal) return this.props.dispatch(PathActions.REWIND_BY(this.props.pathId, oldVal - newVal))

        if (newVal > oldVal) return this.props.dispatch(PathActions.FAST_FORWARD_BY(this.props.pathId, newVal - oldVal));
    }

    componentDidMount () {
        const {pathId, dispatch, points} = this.props;

        this.initializePath(pathId);

        dispatch(PathActions.UNSTASH_PATHS());
        dispatch(RegionActions.UNSTASH_REGIONS());

        if (points.length) {
            dispatch(GmapActions.RECENTER_MAP(points[points.length - 1]));
        }
    }

    debugRender = () => {
        const { initialDetails, isVisible, toggleVisible, dispatch, pathId, pointCount, ffPointCount, recording, itemId, itemType } = this.props;
        const { deetsToUpdate } = this.state;

        const deets = initialDetails || {};

        const saveBtn = Object.keys(deetsToUpdate).length || this.props.points.length != (this.state.initialPoints || []).length
            ? {action: this.save, text: 'Save'}
            : [];

        return (
            <FenceBodyLayout isVisible={isVisible} toggleVisible={toggleVisible} needFencesTab={!itemId || !['device', 'person'].includes(itemType)}>
                <ActionRow title={deets.name || "New Path"} canBack actions={saveBtn} />
                <FenceBodyContainer>
                    <form onSubmit={noSubmit} onChange={this.setDeet}>
                        <div className={styles.editPathTop}>
                            <span className={`btn-link ${styles.fenceClear}`}
                                onClick={this.recenter}
                            >
                                <Fa icon={faTrashAlt} />
                                <span>Clear</span>
                            </span>
                            {/* Center */}

                            <span className={`btn-link ${styles.fenceFind}`} onClick={this.find}>Find</span>

                            <span className={`btn-link ${styles.fenceRecord}`}
                                onClick={_ => dispatch(PathActions.CHANGE_RECORD(pathId, !recording))}
                            >
                                <Fa icon={recording ? faStopCircle : faDotCircle} className={`${recording ? styles.fenceRecordRed : styles.fenceRecord }`} />
                                <span>{recording ? 'Stop' : 'Record'}</span>
                            </span>
                        </div>

                        {/* Label / name */}
                        <div className={styles.editRow}>
                            <span className={styles.fenceTitle}>Label</span>
                            <input defaultValue={deets.name} name="name" className={`mas-input ${styles.inputCSS} ${styles.inputFlex}`} />
                        </div>

                        {/* Description */}
                        <div className={`${styles.editRow } ${styles.editRowWrap}`}>
                            <span className={styles.fenceTitle}>Description</span>
                            <textarea defaultValue={deets.description} name="description" className={`mas-input ${styles.inputCSS} ${styles.inputFullWidth}`} />
                        </div>

                        {/* Color */}
                        <div className={`${styles.editRow } ${styles.editRowWrap}`}>
                            <span className={styles.fenceTitle}>Color</span>
                            <GithubPicker  onChange={this.setColor} colors={C.chooserColors} triangle="hide" />
                        </div>

                        <div>
                            Rewind
                            <input className="fancy-slider" type="range" 
                                min={1} max={pointCount + ffPointCount} 
                                step={1} 
                                onChange={({target}) => this.timeTravel(pointCount, target.value)} 
                                value={pointCount.toString()} />
                        </div>
                    </form>
                </FenceBodyContainer>
            </FenceBodyLayout>
        )
    }
}    

export default connect(mapStateToProps)(EditPath);
