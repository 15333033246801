import React from 'react';
import moment from 'moment';

import { friendlyDiff, minutesToFriendly, userCurrentOffset, utcOffset } from '../../../../../shared/helpers';
import { iDeviceDetails, iFullStoreState, iTrip } from '../../../../../shared/interfaces';
import { TripTravelRow } from '../trip-travel-row';
import { LabelOrPersonRow } from './person-label-row';
import { useLocalStorage } from '../../../../../stores/reducers/general-reducers';
import { iHiddenReportData } from '../../vis-report-data';
import { useSelector } from 'react-redux';


interface iProps { row: iTrip }

export const TripCell = ({row}: iProps) => {
    const [hiddenFields] = useLocalStorage<iHiddenReportData>(`hidden-report-data:travel`, {});
    let counter = 0;

    return <div style={{display: 'flex', flexDirection: 'column', }}>
        <TripTravelRow
            visible={!hiddenFields['duration']}
            label="Duration"
            val={friendlyDiff(moment(row.endDate), moment(row.startDate))}
            odd={(hiddenFields['duration'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['idle']}
            label="Idle"
            val={minutesToFriendly(row.idleMinutes)}
            odd={(hiddenFields['idle'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['stopped']}
            label="Stopped"
            val={minutesToFriendly(row.stopMinutes)}
            odd={(hiddenFields['stopped'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['safety']}
            label="Safety"
            val={row.safetyPercent ? row.safetyPercent + '%' : 'NA'}
            odd={(hiddenFields['safety'] ? 0 : counter++) % 2 === 0}
        />
        <TripTravelRow
            visible={!hiddenFields['person']}
            label="Person"
            val={<LabelOrPersonRow row={row} type="trip" field="personId" />}
            odd={(hiddenFields['person'] ? 0 : counter++) % 2 === 0}
        />
    </div>
}
