import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { iFullStoreState } from "../shared/interfaces";
import { DashboardSize } from '../stores/reducers/dashboardInfo';
import { openDashboard } from "../stores/reducers/dashboardInfo/AC";

const useAutoOpenDashboardIfClosed = (): Function => {
    const dispatch = useDispatch();
    const size = useSelector<iFullStoreState, DashboardSize>(s => s.dashboardInfo.size);

    const autoOpenDashboardIfClosed = () => {
        if (DashboardSize.CLOSED === size ) {
            setTimeout(() => {
                dispatch(openDashboard());
            })
        }
    }

    return autoOpenDashboardIfClosed
}

export default useAutoOpenDashboardIfClosed;