import update from 'immutability-helper';
import moment from 'moment';

import { TimelineRangeType } from '../../shared/interfaces';
import { SET_DEVICE_ROUTE } from './devicesTripsPoints/AC';
import {RangeModifier} from "react-day-picker";


class ActionsClass {
    public UPDATE_RANGE = (startDate: moment.Moment, endDate: moment.Moment) => ({ type: 'TIMELINE_UPDATE_RANGE', startDate, endDate });
    public APPLY_RANGE = (startDate: moment.Moment, endDate: moment.Moment) => ({ type: 'TIMELINE_APPLY_RANGE', startDate, endDate })
    public GO_LIVE = () => ({ type: 'TIMELINE_GO_LIVE' })
    public TOGGLE_DATES_SELECTOR = () => ({ type: 'TIMELINE_TOGGLE_DATE_SELECTOR' })
    public CHANGE_TIMELINE_TYPE = (rangeType: TimelineRangeType) => ({ type: 'TIMELINE_CHANGE_TYPE', rangeType })
    public TIMELINE_START_SEARCHING = () => ({ type: 'TIMELINE_START_SEARCHING' });
}

export const Actions = new ActionsClass;

const today = moment().startOf('day');
const isLiveUpdateWrapper = (s: iState) => update(s, {
    isLive: { $set: !s.range || today.isSameOrBefore(s.range.endDate) }
})

export const reducers1 = (state:iState = initialState, action) => {

    switch (action.type) {
        case 'TIMELINE_GO_LIVE':
            return update(state, {
                showSelectors: { $set: false }
            });
        case 'TIMELINE_START_SEARCHING':
            return update(state, {
                searching: { $set: true }
            });

        case SET_DEVICE_ROUTE:
            return update(state, {
                searching: { $set: false }
            });
        case 'TIMELINE_UPDATE_RANGE':
            return update(state, {
                tempRange: { $set: { startDate: action.startDate, endDate: action.endDate }} });
        case 'TIMELINE_APPLY_RANGE':
            return update(state, {
                range: { $set: { startDate: action.startDate, endDate: action.endDate } },
                $unset: ['showSelectors'],
            });
        case 'TIMELINE_TOGGLE_DATE_SELECTOR':
            return update(state, {
                showSelectors: { $set: !state.showSelectors }
            });

        case 'TIMELINE_CHANGE_TYPE':
            return update(state, {
                rangeType: {$set: action.rangeType}
            });
        default:
            return state;
    }
}

export const reducers = (state: iState = initialState, action) => isLiveUpdateWrapper(
    reducers1(state, action)
);

export interface iState {
    isLive: boolean,
    rangeType: TimelineRangeType,
    range?: { startDate: moment.Moment, endDate: moment.Moment },
    tempRange?: { startDate: moment.Moment, endDate: moment.Moment },
    showSelectors: boolean,
    searching: boolean,
}
const initialState: iState = {
    isLive: true,
    showSelectors: false,
    searching: false,
    rangeType: TimelineRangeType.DAY,
}
