import React from 'react';
import { useSelector } from 'react-redux';

import { friendlySpeed, friendlyDist, friendlyMilesPer } from '../../../../../shared/helpers';
import { iDeviceDetails, iFullStoreState, iTrip } from '../../../../../shared/interfaces';
import { TripTravelRow } from '../trip-travel-row';
import { LabelOrPersonRow } from './person-label-row';
import { useLocalStorage } from '../../../../../stores/reducers/general-reducers';
import { iHiddenReportData } from '../../vis-report-data';

interface iProps { row: iTrip }

export const TravelCell = ({ row }: iProps) => {
    const device = useSelector<iFullStoreState, iDeviceDetails | undefined>(state => state.devicesData.devicesDetails.get(row.device));

    const [hiddenFields] = useLocalStorage<iHiddenReportData>('hidden-report-data:travel', {});
    let counter = 0;

    return (
        <div style={{display: 'flex', flexDirection: 'column', }}>
            <TripTravelRow visible={!hiddenFields['maxSpeed']} label="Max Speed" val={friendlySpeed(row.maxSpeed, device?.units)} odd={(hiddenFields['maxSpeed'] ? 0 : counter++) % 2 === 0}/>
            <TripTravelRow visible={!hiddenFields['avgSpeed']} label="Avg. Speed" val={`${row.averageSpeed ? friendlySpeed(row.averageSpeed, device?.units) : 'NA'}`} odd={(hiddenFields['avgSpeed'] ? 0 : counter++) % 2 === 0}/>
            <TripTravelRow visible={!hiddenFields['distance']} label="Distance" val={friendlyDist(row.miles, device?.units)} odd={(hiddenFields['distance'] ? 0 : counter++) % 2 === 0}/>
            <TripTravelRow visible={!hiddenFields['consumption']} label="Consumption" val={friendlyMilesPer(row.mpg, device?.units)} odd={(hiddenFields['consumption'] ? 0 : counter++) % 2 === 0}/>
            <TripTravelRow visible={!hiddenFields['label']} label="Label" val={<LabelOrPersonRow row={row} type="trip" field="label" />} odd={(hiddenFields['label'] ? 0 : counter++) % 2 === 0}/>
        </div>
    )
}
