import React, { CSSProperties as css } from 'react';
import { Link } from 'react-router-dom';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import { faPlusSquare } from '@fortawesome/fontawesome-free-regular';

import C from '../../shared/constants';
import { vals, range } from '../../shared/helpers';
import { iList, iNote } from '../../shared/interfaces';
import { keyGen } from '../../shared/firebase';
import { Fa } from "../elements/fa";

type IProps = {
    ownerTitle?:string;
    notes: iList<iNote>;
    deviceOrFence: 'device' | 'fence';
    deviceOrFenceId: string;
    style?: css;
    disabled?: boolean;
}
export default ({notes, deviceOrFence, deviceOrFenceId, style={}, ownerTitle, disabled = false}: IProps) => {
    return (
        <div style={{...style, width: '100%'}}>
            <div style={{position: 'relative', ...notePadCss}}>
                <div style={{position: 'absolute', top: 7, right: 0}}>
                    <Link style={disabled? {color: "gray", pointerEvents: "none"} : {}} to={`/${deviceOrFence}/${deviceOrFenceId}/notes/add/${keyGen()}`}>
                        <Fa icon={faPlusSquare} style={{fontSize: 19, color: C.primaryColor, paddingTop: 10, paddingRight: 10, ...(disabled? {color: "gray", pointerEvents: "none"} : {})}}/>
                    </Link>
                </div>
                {vals(notes).map(note => (
                    <div key={note.id}>
                        <div style={noteLineCss}>
                            <span style={{fontSize: 'larger', marginRight: 5}}>{note.date.format(C.simpleDateFormat)}</span>
                            <span>
                                {note.note} &nbsp;&nbsp;
                                <Link to={{
                                    pathname: `/${deviceOrFence}/${deviceOrFenceId}/notes/edit/${note.id}`,
                                    state: {ownerTitle}
                                }} style={disabled? {color: "gray", pointerEvents: "none"} : {}}>
                                    <Fa style={disabled? {color: "gray", pointerEvents: "none"} : {}} icon={faPencilAlt} />
                                </Link>
                            </span>
                        </div>
                    </div>
                ))}

                {/*<div style={{display: 'flex', position: 'absolute', bottom: 0, left: 0, right: 0}}>*/}
                {/*    {range(0, 30).map(num => <div key={num} style={triangle}> </div>)}*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

const noteLineCss: css = {
    marginBottom: 8,
    height: 30,
    borderBottom: '1px solid black'
}

const triangle: css = {
    width: 0,
    height: 0,
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',

    borderBottom: '15px solid white',
}
const notePadCss: css = {
    backgroundColor: '#FCF5AD',
    overflow: 'hidden',
    minHeight: 200,
    padding: 10,
    paddingTop: 25,
    lineHeight: '23px',
    borderRadius: '5px',
    // backgroundImage: `url(images/notepad.jpg)`,
    // backgroundPositionX: 'right',
    // backgroundRepeatX: 'no-repeat',
    // backgroundRepeatY: 'repeat'
} as any
