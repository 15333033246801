import update from 'immutability-helper';
import {fromPairs} from 'ramda';
import {FenceType, iLocation, iFenceDetails, iIcon, iList, iDisplayRegion} from '../../shared/interfaces';
import {useDispatch} from 'react-redux';
import {useRedux} from '../../states/redux-state';

class ActionsClass {
    public ADD_REGION = (
        id: string,
        itemId: string,
        shape: FenceType,
        editable: boolean,
        center: iLocation,
        height: number,
        width: number,
        color: string,
        ico: false|iIcon,
        name?: string,
        hideName?: boolean,
        hideShadows?: boolean,
        hideIcon?: boolean) => ({type: 'MAP_REGION_ADD_NEW', id, shape, editable, center, height, width, color, ico, itemId, name, hideName, hideShadows, hideIcon});
    public UPDATE_REGION_HEIGHT = (id: string, height: number) => ({type: 'MAP_REGION_UPDATE_REGION', id, dim: height, loc: 'height'})
    public UPDATE_REGION_WIDTH = (id: string, width: number) => ({type: 'MAP_REGION_UPDATE_REGION', id, dim: width, loc: 'width'})
    public UPDATE_REGION_CENTER = (id: string, coord: iLocation) => ({type: 'MAP_REGION_UPDATE_CENTER', id, coord})
    public UPDATE_REGION_COLOR = (id: string, color: string) => ({type: 'MAP_REGION_UPDATE_COLOR', id, color})
    public UPDATE_REGION_NAME = (id: string, name: string) => ({type: 'MAP_REGION_UPDATE_NAME', id, name})
    public REMOVE_REGION = (id: string) => ({type: 'MAP_REGION_REMOVE', id})

    public SET_REGION_ICON = (id: string, icon: iIcon) => ({type: 'MAP_REGION_UPDATE_ICON', id, icon});

    public STASH_REGIONS = () => ({type: 'MAP_STASH_REGIONS'});
    public UNSTASH_REGIONS = () => ({type: 'MAP_UNSTASH_REGIONS'});

    public PLACE_FENCE = (fence: iFenceDetails, editable) => this.ADD_REGION(
        fence.id,
        fence.itemId,
        fence.region.shape,
        editable,
        fence.region.center,
        fence.region.height,
        fence.region.width,
        fence.color,
        fence.icon,
        fence.name,
        fence.region.hideName,
        fence.region.hideShadows,
        fence.region.hideIcon);
}

export const Actions = new ActionsClass;

export const reducers = (state: iState = {vis: {}, stashed: {}}, action) => {
    if (action.type == 'MAP_REGION_ADD_NEW') {
        const {id, shape, editable, center, height, width, color, ico, itemId, name, hideIcon, hideName, hideShadows} = action as ReturnType<ActionsClass["ADD_REGION"]>;

        if (!itemId) throw Error('missing item id');

        const region: iDisplayRegion = {
            center, height, width, shape, editable, color, ico: ico, itemId, name, hideIcon, hideName, hideShadows
        };

        return update(state, {
            vis: {
                [id]: {$set: region}
            }
        });
    }

    if (action.type == 'MAP_REGION_UPDATE_ICON') {
        let key: false|string = false;

        if (state.vis[action.id]) key = 'vis';

        if (state.stashed[action.id]) key = 'stashed';

        if (!key) return state;

        return update(state, {
            [key]: {
                [action.id]: {
                    ico: {$set: action.icon}
                }
            }
        });
    }

    if (action.type == 'MAP_STASH_REGIONS') {
        const newState = update(state, {
            vis: {$set: {}},
            stashed: {$set: fromPairs(Object.keys(state.vis).map((k) => [
                k, update(state.vis[k], {editable: {$set: false}})
            ] as any))}
        });

        return newState;
    }

    if (action.type == 'MAP_UNSTASH_REGIONS') {
        if (!Object.keys(state.stashed).length) return state;

        const newState = update(state, {
            vis: {$set: {...state.stashed}},
            stashed: {$set: {}}
        });

        return newState;
    }

    if (action.type == 'MAP_REGION_UPDATE_REGION') return update(state, {vis: {
        [action.id]: {[action.loc]: {$set: action.dim}}
    }});

    if (action.type == 'MAP_REGION_UPDATE_CENTER') return update(state, {vis: {
        [action.id]: {center: {$set: action.coord}}
    }});

    if (action.type == 'MAP_REGION_REMOVE') return update(state, {vis: {
        $unset: [action.id]
    }});

    if (action.type == 'MAP_REGION_UPDATE_COLOR') return update(state, {vis: {
        [action.id]: {color: {$set: action.color}}
    }});

    if (action.type == 'MAP_REGION_UPDATE_NAME') return update(state, {vis: {
        [action.id]: {name: {$set: action.name}}
    }});

    return state;
};

export interface iState {
    vis: { [key: string]: iDisplayRegion };
    stashed: { [key: string]: iDisplayRegion };
}

interface iUseVisRegionsActions {
    remove: (id: string) => any;
    add: (fence: iFenceDetails, editable?: boolean) => any;
    toggleRegion: (fence: iFenceDetails, editable?: boolean) => any;
    stashRegions: () => any;
    unstashRegions: () => any;
}
export const useVisRegions = (): [iList<iDisplayRegion>, iUseVisRegionsActions] => {
    const dispatcher = useDispatch();
    const regions = useRedux((s) => s.regions.vis);

    return [
        regions,
        {
            remove: (id) => dispatcher(Actions.REMOVE_REGION(id)),
            add: (fence: iFenceDetails, editable = false) => dispatcher(Actions.PLACE_FENCE(fence, editable)),
            toggleRegion: (fence: iFenceDetails, editable = false) => regions[fence.id] ?
                dispatcher(Actions.REMOVE_REGION(fence.id)) :
                dispatcher(Actions.PLACE_FENCE(fence, editable)),
            stashRegions: () => dispatcher(Actions.STASH_REGIONS()),
            unstashRegions: () => dispatcher(Actions.UNSTASH_REGIONS()),
        }
    ];

};
