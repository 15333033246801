import moment from 'moment';
import { clientDb } from '../firebase';
import { vals, makeAudit } from '../helpers';
import { iList, iNote } from '../interfaces';
import { ItemType, UserAuth } from '../interfaces';


const convertNote = (raw: any) => ({
    id: raw.id,
    date: moment(raw.date),
    note: raw.note,
});

export const updateNote = (user: UserAuth) => async (itemId: string, noteId: string, type: 'device' | 'fence', note: string) => {
    await clientDb().update(makeAudit(user, {
        [`notes/${type}/${itemId}/${noteId}`]: {date: new Date(), note, id: noteId}
    }))
}

export const deleteNote = (user: UserAuth) => async (itemId: string, noteId: string, type: 'device' | 'fence') => {
    await clientDb().update(makeAudit(user, {[`notes/${type}/${itemId}/${noteId}`]: null}))
}

export const getNote = async (itemId: string, type: 'device' | 'fence', noteId) => (await clientDb().child('notes').child(type.toString()).child(itemId).child(noteId).once("value")).val() as iNote | null;

export const getNotes = async (itemId: string, type: ItemType) => {
    let raw = (await clientDb().child('notes').child(type.toString()).child(itemId).once("value")).val();

    if (!raw) return {} as iList<iNote>;

    let ret = {};

    vals(raw).map(convertNote).forEach(note => ret[note.id] = note);

    return ret as iList<iNote>;
}
