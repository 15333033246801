import React from 'react';
import {is, Map, Set} from 'immutable';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useParams} from 'react-router-dom';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import {faCrosshairs} from '@fortawesome/fontawesome-free-solid';
import moment from 'moment';

import {useRedux} from '../../../states/redux-state';
import DeviceMarker from './DeviceMarker';
import {iFullStoreState} from '../../../shared/interfaces';
import {icoSize, isMobile} from '../../../shared/helpers';
import {usePrevious} from '../../../shared/hooks/usePrevius';
import MapControl from '../MapControl';
import {Fa} from '../../elements/fa';
import {Actions as GmapActions} from '../../../stores/reducers/gmap-reducers';
import InfoSelectedPoint from '../InfoSelectedPoint';
import MapSettingsButtons from '../MapSettingsButtons';

export default (): JSX.Element => {
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>((state) => state.gmap.mapRef());
    const isDriver = useRedux((s) => s.auth.isDriver);

    if (!mapRef || !mapRef.current) return null;

    const size = icoSize(mapRef.current.getZoom());

    return <>
        <Route path="/dashboard">
            <RTagsDevicesMarker size={size} />
            <InfoSelectedPoint />
        </Route>

        <Route path="/device" exact>
            <RTagsDevicesMarker size={size} />
        </Route>

        <Route path={['/reports', '/device/:deviceId']}>
            {!isDriver && <MapSettingsButtons />}
        </Route>

        <Route path="/device/:deviceId">
            <RDeviceMarker size={size} />
        </Route>

        <Route path="/tags">
            <RTagsDevicesMarker size={size} />
            <InfoSelectedPoint />
            <MapSettingsButtons />
        </Route>

        <Route path="/tag/:tagId">
            <RTagsDevicesMarker size={size} />
            <InfoSelectedPoint />
        </Route>
    </>;
};

const RDeviceMarker = React.memo(({size}: { size: number }) => {
    const {deviceId} = useParams<{ deviceId: string }>();

    const dispatch = useDispatch();

    const rangeEnd = useSelector<iFullStoreState, moment.Moment | undefined>(
        (s) => s.timeline.range?.endDate,
        (l, r) => l?.unix() === r?.unix(),
    );

    if (rangeEnd?.isBefore(moment())) {
        return null;
    }

    const recenter = () => {
        dispatch(GmapActions.RECENTER_MAP_TO_DEVICE(deviceId));
    };

    return <>
        <DeviceMarker deviceId={deviceId} size={size} doPan />

        {!isMobile  && <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
            <div className="btn btn-default"
                onClick={recenter}
                style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    width: 40,
                    height: 40,
                    borderWidth: 0,
                }}>
                <Fa icon={faCrosshairs}/>
            </div>
        </MapControl>}
    </>;
});

const RTagsDevicesMarker = React.memo(({size}: { size: number}) => {
    const showedDevices = useSelector<iFullStoreState, Map<string, { selected: boolean; tagsIds: Set<string> }>>(
        (state) => state.tagsDevicesMap.showedDevices,
        (l, r) => is(l, r),
    );

    const showedDevicesIds = showedDevices.filter(({selected}) => selected).keySeq().toSet();

    const prevShowedIds = usePrevious<Set<string>>(showedDevicesIds);

    if (showedDevicesIds.size === 0) return null;

    let deviceIdDoPan: string | undefined;

    if (!!prevShowedIds && !is(prevShowedIds, showedDevicesIds)) {
        for (const deviceId of showedDevicesIds) {
            if (!prevShowedIds.has(deviceId)) {
                deviceIdDoPan = deviceId;

                break;
            }
        }
    }

    return (
        <>
            {showedDevicesIds.map((deviceId) =>
                <DeviceMarker key={deviceId} deviceId={deviceId} size={size} doPan={deviceIdDoPan === deviceId} />,
            )}
        </>
    );
});