import React, { CSSProperties as css } from 'react';

import Constants from '../../shared/constants';
import { BaseComponent } from "../../shared/BaseComponent";

const styles: React.CSSProperties = {
    display: 'flex',
    // borderBottom: `1px solid ${Constants.primaryColor}`,
}

const elStyle = (condensed: boolean): React.CSSProperties => ({
    padding: `5px ${condensed ? 8 : 10}px`,
    cursor: 'pointer',
})

const activeElStyle = (condensed: boolean): React.CSSProperties => ({
    ...elStyle(condensed),
    borderBottom: `2px solid ${Constants.primaryColor}`,
    fontWeight: 'bold',
})

export default class Toggler extends BaseComponent<{onToggle: (idx: number) => any, activeIdx?: number, condensed?: boolean, style?: css, className?: string }, { activeIdx }> {

    constructor(props) {
        super(props);
        this.state = {activeIdx: this.props.activeIdx || 0}
    }

    toggle = (e,activeIdx) => {
        e.preventDefault();
        this.setState({activeIdx});
        this.props.onToggle(activeIdx);
    }

    debugRender = () => {
        const { condensed, activeIdx, style = {}, className = "" } = this.props;

        if (activeIdx === undefined) {
            // What is it? //compile error
            // activeIdx == this.state.activeIdx;
        }

        return (
            <div className={className} style={{ ...styles, ...style }}>
                {
                React.Children.map(this.props.children, (el, idx) => (
                    <div className="unique-thing"  style={idx == activeIdx ? activeElStyle(condensed) : elStyle(condensed)} onClick={e => this.toggle(e, idx)}>
                        { React.cloneElement(el as any, {style: {color: activeIdx == idx ? '' : Constants.primaryText, ...(el as any).props.style}}) }
                    </div>
                ))
                }
            </div>
        );
    }

}
