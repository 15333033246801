import React, { FC, useContext, useEffect } from 'react';
import {iAddLabelProps} from "./AddLabels.interfaces";
import { objectFieldDiff } from './AddLabels.helpers';
import { useSelector } from 'react-redux';
import { iExtraInfo, iFullStoreState, iList } from '../../../../../../shared/interfaces';
import {ScrollContext} from "../../../../../../contexts/ScrollProvider";
import { ReactComponent as CloseIcon } from '../../../../../../assets/svg/close.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AddLabels: FC<iAddLabelProps> = ({chosedLabel, onSelect, onClose}) => {
    const labels = useSelector<iFullStoreState, iList<iExtraInfo>>((s) => s.general.extraInfo);
    const notChosenLabels = objectFieldDiff(labels, chosedLabel);
    const isLabelsToSelect = notChosenLabels.length > 0;
    const scrollContext = useContext(ScrollContext);
    const handleLabelClick = (value) => () => {
        onSelect(value);
    }

    useEffect(() => {
        scrollContext.scrollToBottom();
    }, [chosedLabel]);

    return (
        <div className="add-labels">
            <div className="add-labels__content">
                <div className="add-labels__header">
                    <button className="add-labels__close-btn" onClick={onClose} >
                        <CloseIcon className="add-labels__close-icon"/>
                    </button>
                </div>
            </div>
            {
                isLabelsToSelect && (
                    <PerfectScrollbar>
                        <div className="add-labels__content">
                            <ul className="add-labels__list">
                                {
                                    notChosenLabels.map((value, indx) => {
                                        return (<li className="add-labels__item" key={indx} onClick={handleLabelClick(value)}>{value}</li>)
                                    })
                                }
                            </ul>
                        </div>
                    </PerfectScrollbar>
                )
            }
            {
                !isLabelsToSelect && (
                    <div className ="add-labels__blank">
                        <p>No labels to select</p>
                    </div>
                )}
        </div>
    )
}

export default AddLabels;