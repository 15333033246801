import React from 'react';
import './index.scss';

type IReplyProps = {
    startPlayHandler: () => void,
    resetPathHandler: () => void,
    isDisabledStartBtn: boolean,
    isDisabledResetBtn: boolean,
    pausePlayHandler: () => void,
    shouldMoveDevice: boolean
}

const ReplyComponent = (props: IReplyProps) => {
    return <>
        <div className='playback-container'>
            <section className='playback-control-panel'>
                <button className='path-start-play' type='button' onClick={props.startPlayHandler} disabled={props.isDisabledStartBtn}>Play</button>
                <button className='path-pause' type='button' onClick={props.pausePlayHandler}>Pause</button>
                <button className='path-reset' type='button' onClick={props.resetPathHandler} disabled={props.isDisabledResetBtn}>Reset</button>
            </section>
        </div>
    </>

}


export default ReplyComponent;