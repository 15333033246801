import {CSSProperties as css} from "react";
import {isMobile} from "../../../../../shared/helpers";

export const overlayContainerCss: css = {
    zIndex: 102,
    bottom: 0,
    backgroundColor: '#fff',
    position: 'absolute',
    boxShadow: '0 -5px 5px -5px #333',
    top: 0,
    width: '100%',
};

export const boxStyle: css = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isMobile ? '' : 228,
    backgroundColor: isMobile ? 'transparent' : '#fff',
    borderRadius: 4,
    maxHeight: '100%',
};

export const boxStyle2: css = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isMobile ? '' : 228,
    backgroundColor:'transparent',
    borderRadius: 4,
    maxHeight: '100%',
};

export const modalStyle1: css = {
    position: 'absolute',
    zIndex: 90,
    width: '100%',
    backgroundColor: '#fff',
    height: '100%',
};