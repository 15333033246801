import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import CalendarIcon from '../../SVG-components/calendar';
import WifiTetheringIcon from '../../SVG-components/wifi_tethering';
import ArrowForwardIcon from '../../SVG-components/arrow_forward';
import TimelineDialog from './TimelineDialog';
import TimelineRange from "./TimeLineRange/TimelineRange";
import Drawer, {DrawerManager} from '../../../BemComponents/Drawer';
import {iFullStoreState} from '../../../shared/interfaces';
import {Actions as TimelineActions} from '../../../stores/reducers/timeline-reducers';
import './styles.scss';
import {useParams} from 'react-router-dom';

enum TimelineTabs {
    timeline, live
}

function Timeline (props) {
    const dispatch = useDispatch();
    const {deviceId} = useParams<{ deviceId: string }>();
    const isLive = useSelector<iFullStoreState, boolean>((s) => s.timeline.isLive);
    // const rangeEndDate = useSelector<iFullStoreState, moment.Moment | undefined>(s => s.timeline.range?.endDate);

    const [timelineTab, updateTimelineTab] = React.useState<TimelineTabs>(TimelineTabs.live);
    const [openedSettings, setOpenedSettings] = React.useState(false);

    const startDate = useSelector<iFullStoreState, moment.Moment | undefined>(
        (s) => s.timeline.range?.startDate,
        (l, r) => l?.unix() === r?.unix(),
    );
    const endDate = useSelector<iFullStoreState, moment.Moment | undefined>(
        (s) => s.timeline.range?.endDate,
        (l, r) => l?.unix() === r?.unix(),
    );
    const drawerRef = React.useRef<DrawerManager>();
    const setupDrawerConfig = (callBack: () => DrawerManager) => drawerRef.current = callBack();

    const handleClickOutside = () => {
        if (openedSettings) {
            const drawer = drawerRef.current;

            drawer?.({close: true});
            setOpenedSettings(false);
            isLive && updateTimelineTab(TimelineTabs.live);
        }
    };

    const hideCalendar = () => {
        const drawer = drawerRef.current;

        drawer?.({close: true});
        setOpenedSettings(false);
        updateTimelineTab(TimelineTabs.live);
    };

    const setLiveTab = () => {
        openDrawer();

        if (timelineTab === TimelineTabs.live) return;

        updateTimelineTab(TimelineTabs.live);
        setOpenedSettings(false);

        const drawer = drawerRef.current;

        drawer?.({close: true});
        dispatch(TimelineActions.GO_LIVE());
    };

    const setTimelineTab = () => {
        if (timelineTab === TimelineTabs.timeline) return;

        updateTimelineTab(TimelineTabs.timeline);

        if (!startDate || !endDate) {
            openDrawer();
        }
    };

    const openDrawer = () => {
        const drawer = drawerRef.current;

        drawer?.();
        setOpenedSettings(true);
    };

    return <>
        <div id="timeline-overlay"
             className={`timeline-overlay${timelineTab === TimelineTabs.live ? ' live' : ''}${openedSettings ? ' opened-settings' : ''}`}>
            <div className="timeline-container">
                <div className={`timeline-item${timelineTab === TimelineTabs.timeline ? ' active' : ''}`} onClick={setTimelineTab}>
                    <CalendarIcon />
                    <span>Timeline</span>
                </div>
                <div className={`live-indicator${timelineTab === TimelineTabs.timeline ? ' timeline' : ''}`} />
                <div className="divider" />
                <div className={`timeline-item${timelineTab === TimelineTabs.live ? ' active' : ''}`} onClick={setLiveTab}>
                    <WifiTetheringIcon />
                    <span>Live</span>
                </div>
                <div className={`live-indicator${timelineTab === TimelineTabs.live ? ' live' : ''}`} />
            </div>
            {
                (startDate && endDate) &&
                    <div className="date-range">
                        <TimelineRange start={startDate} end={endDate} />
                        <span className="change-period-toggle" onClick={openDrawer}>
                            <ArrowForwardIcon />
                        </span>
                    </div>
            }
            <Drawer containerId="timeline-overlay" setupConfig={setupDrawerConfig} anchor="top" clickOutside={handleClickOutside}>
                <TimelineDialog drawerRef={drawerRef}
                                deviceId={deviceId}
                                live={!!timelineTab}
                                hideCalendarCallback={hideCalendar}
                                setOpenedSettings={setOpenedSettings} />
            </Drawer>
        </div>
    </>;
}

export default Timeline;