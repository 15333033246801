import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import moment from 'moment';
import { faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';
import DayPicker from 'react-day-picker';
// import 'react-day-picker/lib/style.css';

import { Actions as TimelineActions } from '../../../stores/reducers/timeline-reducers';
import { TimelineRangeType, iFullStoreState } from '../../../shared/interfaces';
import { range as numRange, isMobile } from '../../../shared/helpers';
import { BaseComponent } from '../../../shared/BaseComponent';
import { Fa } from '../../elements/fa';


const mapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
    rangeType: state.timeline.rangeType,
    range: state.timeline.tempRange,
});

type IPropsFromStore = {
    rangeType: TimelineRangeType,
    range?: { startDate: moment.Moment, endDate: moment.Moment }
}

type IProps = IPropsFromStore & DispatchProp;

export const ChooseRange = ({rangeType}: { rangeType: TimelineRangeType; }) => {
    switch (rangeType) {
        case TimelineRangeType.MONTH :
            return <RangeTypeMonth />;
        case TimelineRangeType.WEEK :
            return <RangeTypeDayWeek />;
        case TimelineRangeType.DAY :
            return <RangeTypeDayWeek />;
        case TimelineRangeType.CUSTOM :
        default :
            return <RangeTypeCustom />;
    }
};

@connect(mapStateToProps)
class RangeTypeMonth extends BaseComponent<any, any> {
    componentDidMount() {
        const { range, dispatch } = this.props as IProps;
        // initialize a default date range or fix existing range
        if (!range) return dispatch(TimelineActions.UPDATE_RANGE(moment().startOf('month'), moment()));

        // range exists - just make sure it is the whole month
        const month = range.startDate.clone();

        const format = 'YYYYMMDDHHMM';

        if (range.startDate.format(format) != month.clone().startOf('month').format('format')) {
            return dispatch(TimelineActions.UPDATE_RANGE(month.clone().startOf('day').startOf('month'), month.clone().endOf('month').endOf('day')));
        } else if (range.endDate.format(format) != month.clone().endOf('month').endOf('day').format('format')) {
            return dispatch(TimelineActions.UPDATE_RANGE(month.clone().startOf('month').startOf('day'), month.clone().endOf('month').endOf('day')));
        }
    }

    changeMonth = (isBack) => () => {
        const { dispatch, range } = this.props as IProps;

        const fn = isBack ? 'subtract' : 'add';

        // don't allow for future dates
        if (!isBack && range.startDate.clone().add(1, 'month').isAfter(moment())) return;

        dispatch(TimelineActions.UPDATE_RANGE(
            range.startDate.clone()[fn](1, 'month'),
            range.endDate.clone()[fn](1, 'month')
        ));
    }

    debugRender = () => {
        const { dispatch, range } = this.props as IProps;

        if (!range) return null;

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', fontSize: 18, fontWeight: 100, marginTop: 15}}>
                <div><Fa icon={faChevronLeft} style={{paddingRight: 15, cursor: 'pointer'}} onClick={this.changeMonth(true)} /></div>
                <div style={{textAlign: 'center', width: 135}}>{range.startDate.format('MMMM YYYY')}</div>
                <div><Fa icon={faChevronRight} style={{paddingLeft: 15, cursor: 'pointer'}} onClick={this.changeMonth(false)} /></div>
            </div>
        )
    }
}

@connect(mapStateToProps)
class RangeTypeDayWeek extends BaseComponent<any, any> {
    componentDidMount() {
        const { range, dispatch, rangeType } = this.props as IProps;

        const unit = rangeType == TimelineRangeType.DAY ? 'day' : 'week';

        // initialize a default date range or fix existing range
        if (!range) return dispatch(TimelineActions.UPDATE_RANGE(moment().startOf(unit), moment()));

        // range exists - just make sure it is the whole week / day
        const inRange = range.startDate.clone();

        const format = 'YYYYMMDDHHMM';

        if (range.startDate.format(format) != inRange.clone().startOf(unit).format('format')) {
            return dispatch(TimelineActions.UPDATE_RANGE(inRange.clone().startOf(unit).startOf('day'), inRange.clone().endOf(unit).endOf('day')));
        } else if (range.endDate.format(format) != inRange.clone().endOf(unit).endOf('day').format('format')) {
            return dispatch(TimelineActions.UPDATE_RANGE(inRange.clone().startOf(unit).startOf('day'), inRange.clone().endOf(unit).endOf('day')));
        }
    }

    __change = (date: Date) => {
        const { dispatch, rangeType } = this.props as IProps;

        const asMoment = moment(date);

        const unit = rangeType == TimelineRangeType.DAY ? 'day' : 'week';

        dispatch(TimelineActions.UPDATE_RANGE(
            asMoment.clone().startOf(unit).startOf('day'),
            asMoment.clone().endOf(unit).endOf('day'),
        ))
    }

    debugRender = () => {
        const { range, rangeType } = this.props as IProps;

        if (!range) return null;

        return (
            <div>
                <DayPicker
                    initialMonth={range.startDate ? range.startDate.toDate() : new Date()}
                    onDayClick={this.__change}
                    selectedDays={numRange(0, rangeType == TimelineRangeType.DAY ? 0 : 6).map(x => range.startDate.clone().add(x, 'days').toDate())}

                    showOutsideDays
                />
            </div>
        );
    }
}

const RangeTypeCustom = connect(mapStateToProps)(
class RangeTypeCustom_ extends BaseComponent<IProps, any> {

    componentDidMount() {
        const { range, dispatch } = this.props;

        if (!range) {
            dispatch(TimelineActions.UPDATE_RANGE(
                moment().startOf('day').subtract(1, 'day'),
                moment().endOf('day'),
            ));
        }
    }

    __dayChange = (isStart) => (date: Date) => {
        const { dispatch, range } = this.props;

        dispatch(TimelineActions.UPDATE_RANGE(
            isStart ? moment(date).startOf('day') : range.startDate,
            !isStart ? moment(date).endOf('day') : moment(date).endOf('day')
        ))

        this.setState({
            endMonth: !isStart
                ? null
                : date
        })
    }

    debugRender = () => {
        const { range } = this.props;
        const { endMonth } = this.state || {} as any;

        if (!range) return null;

        return (
            <div style={{display: 'flex', flexWrap: 'nowrap', flexDirection: isMobile ? 'column' : 'row'}}>
                <DayPicker
                    initialMonth={range.startDate.clone().toDate()}
                    onDayClick={this.__dayChange(true)}
                    selectedDays={[range.startDate.clone().toDate()]}
                />
                <DayPicker
                    month={endMonth}
                    initialMonth={range.endDate.clone().toDate()}
                    onDayClick={this.__dayChange(false)}
                    selectedDays={[range.endDate.clone().toDate()]}
                />
            </div>
        );
    }
});
