import React from 'react';
import { connect } from 'react-redux';
import { values, prop } from 'ramda';

import ActionRow from '../ActionRow/ActionRow';
import { FenceBodyLayout, FenceBodyContainer } from '../BodyLayout';
import { FenceGroup, iFenceDetails, iList, iNote, iFullStoreState } from '../../../../shared/interfaces';
import * as tagSelectors from '../../../../shared/db/tags-labels-selectors';
import * as alertsdb from '../../../../shared/db/alerts-db';
import * as notesdb from '../../../../shared/db/notes-db';
import * as fencedb from '../../../../shared/db/fences-db';
import { ItemType } from '../../../../shared/interfaces';
import { Actions as PathActions } from '../../../../stores/reducers/map-path-reducers';
import { Actions as RegionActions } from '../../../../stores/reducers/map-region-reducers';
import { AlertToggles } from '../../../general/alert-toggle';
import { BaseComponent } from "../../../../shared/BaseComponent";
import {ExtraInfo} from "../../../elements/extra-info";
import NotepadWrapper from "./NotepadWrapper";
import {iConProps} from "./RegionOverview.interfaces";
import styles from './RegionOverview.module.scss';

const mapStateToProps = (s:iFullStoreState, o) => {
    return {
        ...o.match.params,
        initialDetails: o.initialDetails,
        // tags: tagSelectors.getItemTagsSelector(s, {itemType: ItemType.fence, itemId: o.match.params.fenceId}),
        currentLabels: tagSelectors.getItemLabelNameValMap(s, {type: ItemType.fence, itemId: o.match.params.fenceId}),
        fenceEventKeys: values(s.general.deviceEvents)
            .filter(e => e.showForFence)
            .map(prop('key'))
    };
}

class RegionOverview extends BaseComponent<any, { notes: iList<iNote>, eventValues: any|'initializing' }, iConProps> {
    state = {
        notes: {} as iList<iNote>,
        eventValues: 'initializing'
    }

    initNotes = async () => {
        const { fenceId } = this.props;

        const notes = await notesdb.getNotes(fenceId, ItemType.fence);

        if (notes) this.setState(s => ({
            notes: notes
        }))
    }

    initExistingRegion(dispatch, fenceId,  itemId, { fenceType, region }: iFenceDetails, color: string, icon: any, name: string) {
        dispatch(RegionActions.ADD_REGION(fenceId, itemId, region.shape, false, region.center, region.height, region.width, color, icon, name));
    }

    initializePath = async id => {
        const {dispatch, initialDetails} = this.props;
        // initialize a path to edit in the store
        dispatch(PathActions.PLACE_FENCE(initialDetails, await fencedb.getPath(id)))
    }

    watchCloser;

    componentDidMount() {
        const { fenceId, initialDetails, dispatch, fenceGroup, itemType, itemId } = this.props;

        this.initNotes();

        this.watchCloser = alertsdb.watchEventValues(
            {itemType: ItemType.fence, alertType: itemType, itemId: fenceId},
            eventValues => this.setState({ eventValues })
        )

        // lets show the paths which were visible
        dispatch(RegionActions.UNSTASH_REGIONS());
        dispatch(PathActions.UNSTASH_PATHS());

        const details = initialDetails || {};
        fenceGroup == FenceGroup.FENCE
            ? this.initExistingRegion(dispatch, fenceId, itemId, details, details.color, details.icon, details.name)
            : this.initializePath(fenceId);
    }

    componentWillUnmount() {
        const { fenceId, dispatch } = this.props;

        this.watchCloser();

        dispatch(RegionActions.STASH_REGIONS());
        dispatch(PathActions.STASH_PATHS());
    }

    debugRender = () => {
        const { fenceId, fenceEventKeys, initialDetails: fence, currentLabels, fenceGroup, itemType, itemId } = this.fullProps;
        const { notes, eventValues } = this.state;

        if (!fence) return null;

        return (
            <FenceBodyLayout needFencesTab={!itemId || !['device', 'person'].includes(itemType)}>
                <ActionRow itemType={itemType} itemId={itemId} title={fence.name} canBack actions={{to: `/${itemType}/${itemId}/${fenceGroup}/${fenceId}/edit`, text: "edit"}} />
                <FenceBodyContainer>
                    <div className={styles.regionOverviewDescription}>{fence.description}</div>

                    <ExtraInfo editUrl={`/label-fence/isFence/fence/${fence.id}`} labels={currentLabels} itemType={itemType} itemId={itemId} />

                    <AlertToggles
                        style={{marginTop: 8}}
                        itemIdProps={itemId}
                        itemTypeProps={itemType}
                        itemId={fenceId}
                        itemType={ItemType.fence}
                        subLayer={itemType}
                        eventKeys={fenceEventKeys}
                        values={eventValues == 'initializing' ? {} : eventValues as any}
                    />

                    <NotepadWrapper notes={notes}
                                    deviceOrFence="fence"
                                    deviceOrFenceId={fenceId}
                                    style={{marginTop: 15}}
                                    ownerTitle={fence.name}
                                    itemId={itemId}
                                    itemType={itemType}
                    />

                </FenceBodyContainer>
            </FenceBodyLayout>
        )
    }
}
export default connect(mapStateToProps)(RegionOverview)