import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes, renderRoutes} from 'react-router-config';
import {Route, useLocation} from 'react-router-dom';
import {pathOr} from 'ramda';
import {faBell, faCrosshairs, faDotCircle, faStreetView} from '@fortawesome/fontawesome-free-solid';
import MobileMenu from './components/menus/MobileMenu';
import MainMobileMenu from './components/menus/MainMobileMenu';
import FenceContainer from './components/fence/FencesContainer/FencesContainer';
import {getRoutes} from './routes';
import {useRedux, useReduxPath} from './states/redux-state';
import {Col, Row} from './components/elements/flex';
import {Actions as GmapActions} from './stores/reducers/gmap-reducers';
import GMaps from './components/gmaps';
import {MOBILE_VIEW} from './stores/reducers/general-reducers-fn';
import {ToggleBtn} from './components/elements/Buttons';
import C, {ACL, UserCan} from './shared/constants';
import {iFullStoreState, ItemType} from './shared/interfaces';
import {fenceHistory, history} from './stores/store';
import Dialog, {DialogConfigSetter} from './components/Dialog';
import DashboardBlock from './components/DashboardBlock';
import './standard-mobile-layout.scss';
import {DashboardSize} from './stores/reducers/dashboardInfo';
import useAutoOpenDashboardIfClosed from './hooks/auto-open-dashboard-if-closed';
import {GMapModal} from './components/GMapModal';

const mobileMenuCss = (active): React.CSSProperties => ({
    flex: 1,
    textAlign: 'center',
    padding: '8px 0'
});
const txtCss = (active): React.CSSProperties => !active ? ({}) : ({
    color: C.primaryColor.darken(.1),
    fontWeight: 'bold',
    borderBottom: `5px solid ${C.primaryColor.darken(.1)}`,
    paddingBottom: 2
});

const MobileNav = () => {
    const {pathname} = useLocation();
    const [choosen, setChoosen] = useReduxPath((s) => s.general.currentMobileView, ['general', 'currentMobileView']);

    const {itemType, itemId} = pathOr({}, [0, 'match', 'params'], matchRoutes([{path: '/:itemType/:itemId'}], pathname));

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter): void => {dialogRef.current = callBack();};
    const autoOpenDashboardIfClosed = useAutoOpenDashboardIfClosed();

    const chooser = (menu: MOBILE_VIEW) => async () => {
        let error: false|string = false;

        if (menu === MOBILE_VIEW.TIMELINE && itemType !== ItemType.device) {
            error = 'You must choose a device to view timeline.';
        }

        if (menu === MOBILE_VIEW.FENCE && !itemId) {
            error = 'Please select a device or tag to view fences.';
        }

        if (error) {
            const dialog = dialogRef.current;
            await dialog?.({
                title: 'Invalid selection',
                body: error,
                type: 'NOTIFICATION',
            });

            return;
        }

        autoOpenDashboardIfClosed();
        setChoosen(menu);
    };

    React.useEffect(() => {
        if (choosen === MOBILE_VIEW.FENCE) {
            fenceHistory.push('/fences');
        }

        // timeline needed to nest inside device so when timeline is selected we
        // need to alter the url
        if (choosen === MOBILE_VIEW.TIMELINE) {
            if (itemType === ItemType.device) {
                history.push(`/device/${itemId}/timeline`);
            }
        }

        // timeline can stay selected until we go back to main
        if (choosen === MOBILE_VIEW.MENU && (pathname || '').includes('timeline')) {
            history.goBack();
        }
    }, [choosen]);

    return <>
        <Row justify="space-evenly" style={{borderBottom: '1px solid gray', backgroundColor: C.primaryColor.fade(.8), fontWeight: 'bold'}}>
            <div style={mobileMenuCss(choosen == MOBILE_VIEW.MENU)} onClick={chooser(MOBILE_VIEW.MENU)}>
                <span style={txtCss(choosen == MOBILE_VIEW.MENU)}>MENU</span>
            </div>
            <div style={mobileMenuCss(choosen == MOBILE_VIEW.SETTINGS)} onClick={chooser(MOBILE_VIEW.SETTINGS)}>
                <span style={txtCss(choosen == MOBILE_VIEW.SETTINGS)}>SETTINGS</span>
            </div>
            <div style={mobileMenuCss(choosen == MOBILE_VIEW.FENCE)} onClick={chooser(MOBILE_VIEW.FENCE)}>
                <span style={txtCss(choosen == MOBILE_VIEW.FENCE)}>FENCE</span>
            </div>
            <div style={{...mobileMenuCss (choosen == MOBILE_VIEW.TIMELINE), paddingRight: 80}} onClick={chooser(MOBILE_VIEW.TIMELINE)}>
                <span style={txtCss(choosen == MOBILE_VIEW.TIMELINE)}>TIMELINE</span>
            </div>
        </Row>
        <Dialog setupConfig={setupDialog} />
    </>;
};

const MobileViewSwitcher = ({view}: { view: MOBILE_VIEW }) => {
    switch (view) {
    case MOBILE_VIEW.FENCE: {
        return (
            <Route path={['/tags', '/:itemType/:itemId'/* , '/reports/:reportType(static|travel)'*/]}>
                <DashboardBlock>
                    <FenceContainer
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            zIndex: 3,
                        }} />
                </DashboardBlock>
            </Route>
        );
    }
    case MOBILE_VIEW.TIMELINE:
    case MOBILE_VIEW.MENU:
        return <MainModal />;
    case MOBILE_VIEW.SETTINGS:
        return <DashboardBlock><MobileSettings /></DashboardBlock>;
    case MOBILE_VIEW.MAP:
    default:
        return null;
    }
};

export const StandardMobileLayout2 = () => {
    const selectedMobileView = useRedux((s) => s.general.currentMobileView);
    const size = useSelector<iFullStoreState, DashboardSize>((s) => s.dashboardInfo.size);
    const closed = size === DashboardSize.OPEN_FULL ? ' closed' : '';

    const [showMainMenu, setShowingMainMenu] = useState(false);

    const [showMainContent , setShowingMainContent] = useState(true);

    const mainContentToggle = () => {
        setShowingMainContent(!showMainContent);
    };

    const mainMenuToggle = () => {
        setShowingMainMenu(!showMainMenu);
    };

    return <div className="mobile-layout">
        {/* <Header />*/}

        <div
            className="main-content"
            onClick={() => showMainMenu ? mainMenuToggle() : null}
        >
            <div className={`google-map-block${closed}`}>
                <GMaps />
            </div>
            {/* <MobileNav />*/}
            { showMainContent && <>
                {/* <MobileNav />*/}
                <MobileViewSwitcher view={selectedMobileView} />
            </>
            }
			<GMapModal />
        </div>
        <div>
            <MobileMenu mainMenuToggle={mainMenuToggle} mainContentToggle={mainContentToggle}/>
        </div>
        <div className={`modal-menu-overlay-${showMainMenu ? 'open' : 'close'}`} onClick={() => mainMenuToggle()}>

        </div>
        <div className={`modal-menu-styles-${showMainMenu ? 'open' : 'close'}`}>
            <MainMobileMenu mainMenuToggle={mainMenuToggle}/>
        </div>

    </div>;
};


const MainModal = ({}) => {
    const userCanDo = useSelector<iFullStoreState, Array<string>>(
        s => s.auth.user?.acl?.can
    );
    const showTags = ACL.check(UserCan.SEE_TAGS, userCanDo, true);
    const seeAllReports = ACL.check(UserCan.SEE_ALL_REPORTS, userCanDo, true);
    const routes = getRoutes({[UserCan.SEE_ALL_REPORTS]: seeAllReports, [UserCan.SEE_TAGS]: showTags});

    return <>
        {renderRoutes(routes)}
    </>;
}

const SettingBtn = ({title, action, icon, isActive}) => {
    const dispatch = useDispatch();
    const dispatcher = (action) => () => dispatch(action);

    return <ToggleBtn
        tabIndex={-1}
        style={{margin: '20px 8px'}}
        onClick={dispatcher(action)}
        active={isActive}
        title={title}
        icon={icon}
    />;
};
const MobileSettings = ({}) => {
    const mapType = useRedux((s) => s.gmap.mapType);
    const isSat = mapType == google.maps.MapTypeId.SATELLITE;
    const isStreetView = useRedux((s) => s.gmap.streetViewLocation);
    const isShowingAlerts = useRedux((s) => s.gmap.showPOI);
    const isShowingDots = useRedux((s) => s.gmap.showDots);

    return <Col className="mobile-settings" style={{flex: 1, zIndex: 4, background: 'white',}}>
        <SettingBtn action={GmapActions.TOGGLE_STREET_VIEW()} isActive={isStreetView} title="Street View" icon={faStreetView} />
        <SettingBtn action={GmapActions.TOGGLE_MAP_TYPE()} isActive={isSat} title="Sattelite" icon={faCrosshairs} />
        <SettingBtn action={GmapActions.TOGGLE_POI()} isActive={isShowingAlerts} title="Show Alert Icons" icon={faBell} />
        <SettingBtn action={GmapActions.TOGGLE_DOTS()} isActive={isShowingDots} title="Show All Points" icon={faDotCircle} />
    </Col>;
};
