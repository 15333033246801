import * as favoritesdb from "../../shared/db/favorites-db";
import { ItemType } from "../../shared/interfaces";

const toggleFavorite = ({favoriteType, uid, favoriteId, isFavorite} : {
    favoriteType: ItemType;
    uid: string;
    favoriteId: string;
    isFavorite: boolean;
}): void => {
    favoritesdb.toggleFavorite(favoriteType, uid, favoriteId, isFavorite).then((res) => console.log(res)).catch(e => console.log(e))
}

const GeneralActions = {
    toggleFavorite
}

export default GeneralActions;