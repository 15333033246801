import React from 'react';
import {Route, useParams} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';

// import Main from './components/menus/main';
// import Header from './components/menus/header';
// import SearchModal from "./components/menus/search";
import MainMenu from './components/menus/MainMenu';
import FencesContainer from './components/fence/FencesContainer/FencesContainer';
import {LocatingModal} from './components/modals/locating-modal';
import TimeLine from './components/menus/Timeline2';
import {FlashMessage, PremiumFlash} from './components/elements/flash-message';
import {getRoutes} from './routes';
import {useRedux} from './states/redux-state';
import {useIsLocating, LOCATION_STATUS} from './stores/reducers/general-reducers';
import GMaps from './components/gmaps';
import FabMenu from './components/elements/FabMenu';
import {CloseButtonStreetView} from './components/elements/CloseButtonSteetView';

import './standard-desktop-layout.scss';
import {GMapModal} from './components/GMapModal';
import {useSelector} from "react-redux";
import {iFullStoreState} from "./shared/interfaces";
import {ACL, UserCan} from "./shared/constants";

/*
export const StandardDesktopLayout = () => {
    return <div style={{display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'stretch', position: 'relative'}}>
        <SearchModal />
        <div className="header-wrapper" style={{zIndex: 3, flexGrow: 0}}><Header /></div>
        <div style={{flexGrow: 1, display: 'flex', overflow: 'hidden'}}>
            <div style={{zIndex: 3, flexGrow: 0}}>
                <Main />
            </div>
            <MainContent />
        </div>
    </div>;
};
*/

export const StandardDesktopLayout = (): JSX.Element => (
    <div className="desktop-layout">
        <MainMenu />
        <MainContent />
    </div>
);

const MainContent = (): JSX.Element=> {
    const isDriver = useRedux((s) => s.auth.isDriver);
    const userCanDo = useSelector<iFullStoreState, Array<string>>(
        s => s.auth.user?.acl?.can
    );
    const showTags = ACL.check(UserCan.SEE_TAGS, userCanDo, true);
    const seeAllReports = ACL.check(UserCan.SEE_ALL_REPORTS, userCanDo, true);
    const routes = getRoutes({[UserCan.SEE_ALL_REPORTS]: seeAllReports, [UserCan.SEE_TAGS]: showTags});
    return (
        <div className="main-content">
            {renderRoutes(routes, {isDriver})}
            <div className="google-map-block">
                {!isDriver && <NonModalPagePortion />}
                <GMaps />
                <CloseButtonStreetView />
                <GMapModal />
            </div>
        </div>
    );
};
const NonModalDevicePortion = (): JSX.Element => {
    const {deviceId} = useParams<{ deviceId: string }>();
    const [locateStatus] = useIsLocating(deviceId);

    const isStreetView = useRedux((s) => !!s.gmap.streetViewLocation);

    return <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {locateStatus !== LOCATION_STATUS.ACTIVE && (
                <TimeLine deviceId={deviceId}/>
            )}
            <div style={{zIndex: 1, maxHeight: 0}}>
                {
                    isStreetView ?
                        <div /> :
                        locateStatus === LOCATION_STATUS.ACTIVE ?
                            <LocatingModal /> :
                            <FlashMessage />
                }
                <PremiumFlash />
            </div>
        </div>
    </>;
};

const NonModalPagePortion = React.memo(() => {
    const isStreetView = useRedux((s) => !!s.gmap.streetViewLocation);

    return <>
        <FabMenu />
        <Route path="/device/:deviceId">
            <NonModalDevicePortion />
        </Route>
        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', maxHeight: 'calc(100% - 150px)'}}>
            {
                !isStreetView &&
                <Route path={['/tags', '/:itemType/:itemId'/* , '/reports/:reportType(static|travel)'*/]}>
                    <FencesContainer style={{zIndex: 1, margin: '10px 20px 50px'}} />
                </Route>
            }
        </div>
    </>;
});
