import React, {CSSProperties as css} from 'react';
import {connect, DispatchProp} from 'react-redux';
import {NavLink as Link, RouteComponentProps} from 'react-router-dom';
import {pathOr, pipe, values, uniq, join, filter} from 'ramda';
import {faPencilAlt, faSyncAlt, faPhone, faEnvelope} from '@fortawesome/fontawesome-free-solid';

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {BaseComponent} from '../../../shared/BaseComponent';
import * as tagSelectors from '../../../shared/db/tags-labels-selectors';
import {iDevicePing, iDeviceDetails, iTag, ItemType, iList, iPerson, iConfig} from '../../../shared/interfaces';
import * as structure from '../../../shared/interfaces';
import C, {ACL, UserCan} from '../../../shared/constants';
import {Tile, MinimalTile} from '../../general';
import {clientDefaultSettings, extractDevice, namingHelper} from '../../../shared/helpers';
import {Fa, FaLink} from '../../elements/fa';
import {formatWhenLast} from '../devices/device-page';
import {FormatAddress} from '../devices/device-list';
import {Row} from '../../elements/flex';
import {TableSimple} from '../../elements/plain-table';
import {getPersonAllowedSee} from '../../../shared/db/general-db';
import {ExtraInfo} from '../../elements/extra-info';
import {AllUsersFeatures} from '../../elements/all-users-features';
import {DevicesDetailsContainer} from '../../../stores/reducers/devicesData';
import {history} from '../../../stores/store';
import {fbapp} from '../../../shared/firebase';
import {localStorage} from '../../../shared/storage';
import Logout from '../../SVG-components/logout';
import {get} from 'lodash';
import { logout as logoutAPI } from '../../../api';
import styles from './people-page.module.scss';


const mapStateToProps = (state: structure.iFullStoreState, {match: {params: {id: personId}}}: IProps): IPropsFromStore => ({
    config: state.general.config!,
    assignedDevice: state.general.people?.[personId]?.hasDevice ? extractDevice(state.devicesData.devicesDetails, state.general.people[personId]?.hasDevice?.deviceId) : false,
    assignedDeviceLastPing: state.general.people?.[personId]?.hasDevice ? state.devicesData.devicesLastPing.get(state.general.people[personId]?.hasDevice?.deviceId) : undefined,
    devicesDetails: state.devicesData.devicesDetails,
    person: state.general.people[personId],
    userCanDo: state.auth.user?.acl?.can ?? [],
    userId: state.auth.user?.uid,
    itemTags: tagSelectors.getItemTagsSelector(state, {itemId: personId, itemType: ItemType.person}),
    tags: state.general.tags,
    currentLabels: tagSelectors.getItemLabelNameValMap(state, {type: ItemType.person, itemId: personId}),
});

type IProps = RouteComponentProps<{id: string}>;

type IPropsFromStore = {
    currentLabels: [string, string][];
    userCanDo: string[];
    userId: string;
    devicesDetails: DevicesDetailsContainer;
    assignedDeviceLastPing?: iDevicePing;
    config: iConfig;
    person: iPerson;
    tags: iList<iTag>;
    itemTags: iList<iTag>;
    assignedDevice: iDeviceDetails | false;
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;

export default connect(mapStateToProps)
(class PersonTabPage extends BaseComponent<IFullProps> {
    debugRender = () => {
        const {
            assignedDevice,
            assignedDeviceLastPing,
            person,
            currentLabels,
            userCanDo,
            userId,
            itemTags,
            devicesDetails,
            tags,
            config} = this.props;
        const {params: {id: personId}} = this.props.match;
        if (!person) return null;

        const hasTags = !!Object.keys(itemTags).length;

        const ago = formatWhenLast(assignedDevice ? assignedDeviceLastPing?.time : undefined);

        const isAdmin = ACL.check(UserCan.DO_ANYTHING, userCanDo) && (personId === userId);

        const isDriver = ACL.checkDriver(UserCan.ASSIGN_DRIVER, userCanDo);

        const logout = (e) => {
            e.preventDefault();
            fbapp.auth().signOut();
            localStorage.clear();
            logoutAPI().finally(() => {
                document.location.href = '/';
            });
        }

        return <DashboardBlock>
            <ModalHeader title={person ? person.displayName : ''}
                action = {!isDriver && {
                    fa: faPencilAlt,
                    title: 'Edit Person Details',
                    click: () => history.push(`/person/${personId}/edit`)}
                }
                role={isDriver}
            />

            <div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <div style={{width: 70, height: 70, borderRadius: 70, backgroundImage: `url(${person.photoURL})`, backgroundSize: 'cover', border: `2px solid ${C.mediumGray}`}} />

                    <MinimalTile style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: 16, lineHeight: '15px', position: 'relative', marginLeft: 5, paddingBottom: 21}}>
                        <div>
                            {assignedDeviceLastPing &&
                            <FormatAddress
                                style={{alignSelf: 'stretch', paddingTop: 5, textAlign: 'center', lineHeight: '18px', fontWeight: 'lighter'}}
                                address={assignedDeviceLastPing.address}
                            />
                            }
                        </div>
                        <span style={{position: 'absolute', left: 3, bottom: 3, fontSize: 10}}>
                            <Fa icon={faSyncAlt} style={{color: C.primaryColor}}/> Last Seen: {ago || 'N/A'}
                        </span>
                    </MinimalTile>
                </div>
                <MinimalTile>
                    <Row align="center" justify="space-between"><span><Fa icon={faPhone} style={{fontSize: 11}} /> &nbsp; Main</span>{person.mainNumber || 'none'}</Row>
                    <Row align="center" justify="space-between"><span><Fa icon={faEnvelope} style={{fontSize: 11}} /> &nbsp; Email</span>{person.email || 'none'}</Row>
                </MinimalTile>

                <div style={{borderTop: '2px solid ' + C.primaryText, marginTop: 4, marginBottom: 6}}/>

                <VisibleTagsTile personId={personId} userCanDo={userCanDo} devicesDetails={devicesDetails} tags={tags} />

                {!config.strictAccess ? null :
                    <VisibleDevicesTile personId={personId} userCanDo={userCanDo} devicesDetails={devicesDetails} tags={tags} />
                }

                <ExtraInfo
                    editUrl={`/${ItemType.person}/${personId}/add/extra-info`}
                    labels={currentLabels}
                    role={isDriver}
                />

                {isAdmin && <DefaultSettingsTile personId={personId} userCanDo={userCanDo}/>}

                {isAdmin && <AllUsersFeatures />}

                {personId === userId && (
                    <Link onClick={logout} to="/login" style={{display: 'flex', alignItems: 'center', textDecoration: 'none', marginTop:10}}>
                        <Logout style={{height:30, width: 30, fill: `${C.primaryText}`, marginRight:10}}/>
                        <h6 style={{marginBottom: 0, color: `${C.primaryText}`}}>Logout</h6>
                    </Link>
                )}

            </div>
        </DashboardBlock>;
    }
});


class VisibleDevicesTile extends BaseComponent<{userCanDo; personId; tags: structure.iList<iTag>; devicesDetails: DevicesDetailsContainer}, { allowed: structure.iItemsAllowed; devices: iDeviceDetails[] }> {
    async componentDidMount() {
        const {personId} = this.fullProps;
        this.setState({allowed: await getPersonAllowedSee(personId)});
    }

    _source = (deviceId) => pipe(
        pathOr({}, ['device', deviceId]),
        values,
        uniq,
        filter((item) => typeof item !== 'boolean'),
        join(' & ')
    )(this.state.allowed)

    debugRender = () => {
        const {allowed = {} as any} = this.state || {};
        const {userCanDo, personId, devicesDetails} = this.props;

        const hideTable = allowed?.device && Object.keys(allowed.device).length === 0;
        return (
            <LocalTile
                collapsible
                initialCollapsed
                title="Visible Devices"
                loading={!this.state || !this.state.allowed}
                action={!ACL.check(UserCan.DO_ANYTHING, userCanDo) ?
                    undefined :
                    <FaLink to={`/person/${personId}/visible-devices`} icon={faPencilAlt} style={{color: '#fff'}} />}
            >
                {!this.state || !this.state.allowed || hideTable ? null :
                    <TableSimple sm striped headers={['Device', 'Source']} style={{fontSize: 11}}>
                        {Object.keys(allowed.device || {})
                            .filter((id) => devicesDetails.has(id))
                            .map((id) => devicesDetails.get(id))
                            .map((device) => <tr key={device!.id}>
                                <td className={styles.tableItem}>{device!.name}</td>
                                <td>{this._source(device!.id)}</td>
                            </tr>)
                        }
                    </TableSimple>
                }

            </LocalTile>
        );
    }
}

class VisibleTagsTile extends BaseComponent<{userCanDo; personId; tags: structure.iList<iTag>; devicesDetails: DevicesDetailsContainer}, { allowed: structure.iItemsAllowed; devices: iDeviceDetails[] }> {
    async componentDidMount() {
        const {personId} = this.fullProps;

        this.setState({allowed: await getPersonAllowedSee(personId)});
    }

    debugRender = () => {
        const {userCanDo, personId, tags} = this.props;

        const filteredTags = Object.keys(tags).reduce((acc, rec) => {
            if(get(tags[rec], `instances.allowed-see.person.${personId}`)) {
                return {...acc, [rec]: tags[rec]};
            }

            return acc;
        }, {});

        const hideTable = Object.keys(filteredTags).length === 0;

        return (
            <LocalTile
                collapsible
                initialCollapsed
                title="Visible Tags"
                loading={!this.state || !this.state.allowed}
                action={!ACL.check(UserCan.DO_ANYTHING, userCanDo) ?
                    undefined :
                    <FaLink to={`/person/${personId}/visible-tags`} icon={faPencilAlt} style={{color: '#fff'}} />}
            >
                {!this.state || !this.state.allowed || hideTable ? null :
                    <TableSimple sm striped headers={['Tag label', 'Read only']} style={{fontSize: 11}}>
                        {Object.keys(filteredTags || {}).map((filterId) =>
                            <tr key={filterId}>
                                <td style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 170}}>{filteredTags[filterId].details.name}</td>
                                <td>{get(filteredTags[filterId], `instances.allowed-see.readOnly.${personId}`, false).toString()}</td>
                            </tr>)
                        }
                    </TableSimple>
                }

            </LocalTile>
        );
    }
}

class DefaultSettingsTile extends BaseComponent<{userCanDo; personId}, { allowed: structure.iItemsAllowed, defaultSettings: structure.iDefaultSettings}> {
    async componentDidMount() {
        const {personId} = this.fullProps;

        this.setState({allowed: await getPersonAllowedSee(personId)});
    }

    debugRender = () => {
        const {userCanDo, personId} = this.props;

        const isSetAllowed = !this.state||!this.state.allowed
        const isSetDefaultSettings = !this.state||!this.state.defaultSettings

        isSetDefaultSettings&&clientDefaultSettings((value) => this.setState({defaultSettings: value}))

        return (
            <LocalTile
                collapsible
                initialCollapsed
                title="Default Settings"
                loading={isSetAllowed}
                action={!ACL.check(UserCan.DO_ANYTHING, userCanDo) ?
                    undefined :
                    <FaLink
                        to={`/person/${personId}/default-settings`}
                        icon={faPencilAlt}
                        className={styles.tableLink}
                    />
                }
            >
                {isSetAllowed&&isSetDefaultSettings ? null :
                    <TableSimple sm striped headers={['Name', 'Value']} style={{fontSize: 11}}>
                        {Object.entries(this.state.defaultSettings || {}).map((entrie) =>
                            <tr key={entrie[0]}>
                                <td className={styles.tableItem} >{namingHelper(entrie[0])}</td>
                                <td className={styles.tableItem}>{entrie[1]}</td>
                            </tr>)
                        }
                    </TableSimple>
                }

            </LocalTile>
        );
    }
}

const localTileStyle: css = {
    margin: 3,
    marginBottom: 6,
};
const LocalTile = ({style = {}, title= '', children, action, collapsible = false, initialCollapsed = false, loading = false}) => (
    <Tile style={{...style, ...localTileStyle}} title={title} action={action} collapsible={collapsible} initialCollapse={initialCollapsed} loading={loading}>
        {children}
    </Tile>
);
