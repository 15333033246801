import React from 'react';
import { faCircleNotch } from '@fortawesome/fontawesome-free-solid';

import { Col } from './flex';
import { Fa } from './fa';

interface iProps {
    size?: number,
    subText?: string|false|JSX.Element,
}
export const LoadingModal = ({ size = 40, subText = false }: iProps) => (
    <Col justify="center" align="center" style={{ height: '100%', flex: 1 }}>
        <Fa spin icon={faCircleNotch} style={{fontSize: size}} />
        {!subText ? null : <div>
            {subText}
        </div>}
    </Col>
)
