import React, {CSSProperties as css} from 'react';
import {connect, DispatchProp} from 'react-redux';
import {NavLink as Link} from 'react-router-dom';
import {iFullStoreState} from '../../../shared/interfaces';
import {Actions as LayoutActions} from '../../../stores/reducers/layout-reducers';
import {Actions as GeneralActions} from '../../../stores/reducers/general-reducers';
import {fullCloseDashboard, fullOpenDashboard} from '../../../stores/reducers/dashboardInfo/AC';
import {BaseComponent} from '../../../shared/BaseComponent';
import C from '../../../shared/constants';
import {DashboardSize} from '../../../stores/reducers/dashboardInfo';
import MenuSvg from '../../../components/SVG-components/menu_mobile_icon';
import DashboardSvg from '../../../components/SVG-components/dashboard_mobile_icon';
import FavoritesSvg from '../../../components/SVG-components/favorites_mobile_icon';
import SearchSvg from '../../../components/SVG-components/search_mobile_icon';
import MapSvg from '../../../components/SVG-components/map_mobile_icon';

import './style.scss';

type IProps = {
    row?: boolean;
    mainMenuToggle?: Function;
    mainContentToggle?: Function;
}

type IPropsFromStore = {
    fenceEditMode: boolean;
    size: DashboardSize;
    showSearch: boolean;
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;

class MobileMenu extends BaseComponent<IFullProps> {
    __navHelper = () => {
        const {dispatch} = this.props;
        dispatch(LayoutActions.EXPAND_IF_CLOSED());
        dispatch(GeneralActions.TOGGLE_NAV_MENU());
        dispatch(fullOpenDashboard());
    }

    __showMap = () => {
        const {dispatch, showSearch} = this.props;

        if (showSearch) {
			dispatch(GeneralActions.HIDE_SEARCH);
			dispatch(fullOpenDashboard());
		} else {
			dispatch(GeneralActions.SHOW_SEARCH);
			dispatch(fullCloseDashboard());
		}
    }

    debugRender = () => {
        const {fenceEditMode, mainMenuToggle, dispatch} = this.props;

        return (
            <div className="main-nav-men">
                {!fenceEditMode ? null :
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: C.editFenceOverlayBgColor,
                        zIndex: 103
                    }} />
                }

                <div style={this.innerRowStyle}>
                    <div className='MenuSection'>
                        <div onClick={() => mainMenuToggle()} className="main-nav-link">
                            <MenuSvg/>
                        </div>

                        <Link
                            onClick={this.__navHelper}
                            to="/dashboard"
                            className="main-nav-link"
                            activeClassName={'active-row'}
                        >
                            <DashboardSvg/>
                        </Link>
                    </div>

                    <div className='MapButton'>
                        <MapSvg onClick={this.__showMap}/>
                    </div>

                    <div className='MenuSection'>
                        <Link
                            onClick={this.__navHelper}
                            to="/favorites"
                            className="main-nav-link"
                            activeClassName={'active-row'}
                        >
                            <FavoritesSvg/>
                        </Link>

                        <Link
                            onClick={() => {
                                this.__navHelper();
                                dispatch(GeneralActions.SHOW_SEARCH);
                            }}
                            to="/search-mobile"
                            className="main-nav-link"
                            activeClassName={'active-row'}
                        >
                            <SearchSvg/>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    outerStyle: css = {
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
        backgroundColor: `rgba(255,255,255, ${C.opacity}`,
        zIndex: 101,
    }

    innerRowStyle: css = {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 30px',
        position: 'relative'
    };

    innerStyle: css = {
        height: '100%',
        paddingBottom: 400,
        overflowY: 'scroll',
        marginRight: -17,
        color: C.primaryText,
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${C.primaryColor}`,
    };
}

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
    fenceEditMode: state.fences.isEditing,
    size: state.dashboardInfo.size,
    showSearch: state.general.showSearch,
});

export default connect(mapStateToProps)(MobileMenu);
