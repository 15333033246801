import React from 'react';
import { Marker } from 'react-google-maps';
import * as color from 'color';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { useDispatch } from 'react-redux';

import { iLocation } from '../../../shared/interfaces';
import { Actions as GmapActions } from '../../../stores/reducers/gmap-reducers';

interface iProps {
    location: iLocation,
    title: string,
    id: string,
    dispatch?: any,
    color,
    label: string,
}

export const SearchMarker = React.memo((props: iProps) => {
    const dispatch = useDispatch();
    const {location, label, title, id, color: c} = props;

    return <>
        <Marker
            position={location}
            animation={google.maps.Animation.DROP}
            title={title}
            onClick={_ => dispatch(GmapActions.DEL_SEARCH_MARKER(id))}
        />
        {label && <InfoBox
            options={{
                disableAutoPan: true,
                zIndex: 4,
                boxStyle: {
                    zIndex: 4,
                    overflow: 'hidden',
                    'background-color': '#000',
                    width: '80px',
                    border: `1px solid ${color(c).darken(.5)}`,
                    padding: '2px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: c,
                    color: (color(c).light() ? '#000' : '#fff'),
                    opacity: '.9',
                },
                alignBottom: true,
                closeBoxURL: '',
                pixelOffset: new google.maps.Size(-30, 29),
            }}
            defaultPosition={new google.maps.LatLng(location.lat, location.lng)}>
            <div>{label}</div>
        </InfoBox>}
    </>;
});
