export class GoogleSearchService {
    private static service;
    private static COORDINATES_QUANTITY = 2;

    static convertCoordinatesFormat = (array) => {
        const result = array.map ((item, index) => {
            if(item < 0) {
                const DD = Math.trunc(Math.abs(item))
                const MM = Math.trunc((Math.abs(item) - DD) * 60)
                const SS = Math.floor((((Math.abs(item) - DD) * 60 - MM) * 60) * 100) / 100
                const CRD = index === 0 ? 'S' : 'W'
                return `${DD}°${MM}'${SS}"${CRD}`
            }
            const DD = Math.trunc(item)
            const MM = Math.trunc((item - DD) * 60)
            const SS = Math.floor((((item - DD) * 60 - MM) * 60) * 100) / 100
            const CRD = index === 0 ? 'N' : 'E'
            return `${DD}°${MM}'${SS}"${CRD}`
        })
        return result.join(' ')
    }

    static checkKeyword = (keyword) => {
        const getSeparator = () => {
            if(keyword.includes('\t')) return '\t'
            if(keyword.includes(',')) return ','
            return ' '
        }
        const splitKeyword = keyword.split(getSeparator())

        if(splitKeyword.length === GoogleSearchService.COORDINATES_QUANTITY ) {
            const onlyNumbers = splitKeyword.map(item => +item)
            if(onlyNumbers.filter( item => !isNaN(item)).length === splitKeyword.length){
                return GoogleSearchService.convertCoordinatesFormat(onlyNumbers)
            }
        }
        return keyword
    }

    static get = (keyword, center) => {
        if (!GoogleSearchService.service) {
            const googleMapContainer = document.getElementById('google-map')
            const fakeElement = document.createElement('div');

            GoogleSearchService.service = new google.maps.places.PlacesService(
                new google.maps.Map(fakeElement),
            );
        }

        return new Promise<google.maps.places.PlaceResult[]>((resolve, rej) => {
            GoogleSearchService.service.textSearch({
                query: GoogleSearchService.checkKeyword(keyword),
                locationBias: center,
                fields: ['id', 'place_id', 'formatted_address', 'icon', 'name', 'geometry'],
            }, resolve);
        });
    };
}
