import React from 'react';
import { withBemMod } from '@bem-react/core';

import { cnTabs } from '../';
import './Tabs_theme_dark.scss';

export interface ITabsThemeLightProps {
    theme?: 'dark';
}

export const withTabsThemeDark = withBemMod<ITabsThemeLightProps>(
    cnTabs(),
    {theme: 'dark'},
    (Component) => ({theme, ...props}) => <Component {...props} />,
);
