import React from 'react';

import Header from '../menus/header';
import { ItemType } from '../../shared/interfaces';
import { BaseComponent } from '../../shared/BaseComponent';


interface iProps {
    showSideBars?: boolean;
    itemType: ItemType | null,
    itemId: string | null,
}
export default class HeaderOnlyLayoutComponent extends BaseComponent<iProps> {
    debugRender = () => {
        const { children } = this.props;

        return (
            <div className="layout-component" style={{display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'stretch', position: 'relative'}}>
                {/*<div className="header-wrapper" style={{zIndex: 3, flexGrow: 0}}><Header /></div>*/}
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row'}}>
                    <div style={{flexGrow: 2, display: 'flex'}}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}


