import {DevicesDetailsContainer} from "../../stores/reducers/devicesData";
import {iReportDetails} from "../../stores/reducers/report-reducers";
import {AlertTypeTitle, iFullStoreState, iList, iPerson, iScheduledReport, iTag} from "../../shared/interfaces";
import { pipe, keys, map, pathOr, path, always, when, empty } from 'ramda';
import {connect} from "react-redux";
import {BaseComponent} from "../../shared/BaseComponent";
import React, {useReducer} from "react";
import {useRedux} from "../../states/redux-state";
import {IReportFiltersLabelsProps, IReportFiltersLabelsPropsFromStore, IReportFiltersLabelsType} from "./types";

export const ReportFiltersLabels = ({type, defaultLabel, render}: IReportFiltersLabelsProps) => {
    const {
        devicesDetails,
        reportDetails,
        people,
        tags
    } = useRedux((s: iFullStoreState): IReportFiltersLabelsPropsFromStore => ({
        devicesDetails: s.devicesData.devicesDetails,
        reportDetails: s.report.details,
        people: s.general.people,
        tags: s.general.tags
    }))
    const report: iScheduledReport = {...reportDetails, type: reportDetails.reportType} as any;
    let items = [];

    switch (type) {
        case IReportFiltersLabelsType.ALERTS:
            items = pipe(
                pathOr({}, ['filters', 'alerts']),
                keys,
                map(AlertTypeTitle),
                when(empty, always([defaultLabel])),
            )(report);
            break;
        case IReportFiltersLabelsType.LABELS:
            items = pipe(
                pathOr({}, ['filters', 'labels']),
                keys,
                when(empty, always([defaultLabel])),
            )(report);
            break;
        case IReportFiltersLabelsType.TAGS:
            items = report.filters.tag
                ? pipe(
                    pathOr({}, ['filters', 'tag']),
                    keys,
                    map(k => (path([k, 'details', 'name'])(tags)))
                )(report)
                : [defaultLabel];
            break;
        case IReportFiltersLabelsType.DEVICES:
            items = report.filters.device
                ? pipe(
                    pathOr({}, ['filters', 'device']),
                    keys,
                    map(k => devicesDetails.getIn([k, 'name']))
                )(report)
                : [defaultLabel]
            break;
        case IReportFiltersLabelsType.PERSONS:
            items = report.filters.person
                ? pipe(
                    pathOr({}, ['filters', 'person']),
                    keys,
                    map(k => (path([k, 'displayName'])(people)))
                )(report)
                : [defaultLabel];
            break;
    }

    return (
        <>
            {items.map(item => render({item}))}
        </>
    )
}

