import { clientDb } from '../firebase';
import { vals, idValArr, makeAudit } from '../../shared/helpers';
import { ItemType, UserAuth } from '../../shared/interfaces';

const favoritesDb = () => clientDb().child('favorites');


export const allWatch = async (uid, callback) => {
    let uidRef = favoritesDb().child(uid);

    await uidRef.on("value", res => 
        callback(res.val() || {})
    , err => console.log(err));

    return () => uidRef.off("value", callback);
}

export const changeFavSort = (user: UserAuth) => async (sorts: { type: ItemType, uid: string, itemId: string, order: number }[]) => {
    const update = {};

    sorts.forEach(({type, uid, itemId, order}) => update[`favorites/${uid}/${type}/${itemId}`] = order)

    return await clientDb().update(makeAudit(user, update));
}

export const toggleFavorite = async (type: ItemType, uid: string, itemId: string, on) => {   
    return !on
        ? await favoritesDb().child(`${uid}/${type}/${itemId}`).remove()
        : await favoritesDb().child(`${uid}/${type}/${itemId}`).set(999999);
}