import moment from "moment";

export const COLUMNS = [
  {
    Header: "Invoice/Credit Number",
    accessor: "id",
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "state",
    disableSortBy: true,
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ cell: { value } }) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },

  {
    Header: "Amount",
    accessor: "total",
    disableSortBy: true,
    Cell: ({ value }) => `$${value}`,
  },
];
