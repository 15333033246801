import React, { CSSProperties as css } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderedMap, OrderedSet } from 'immutable';
import { pipe } from 'ramda';
import moment from 'moment';
import { faCaretDown, faBars } from '@fortawesome/fontawesome-free-solid';
import { faTimesCircle } from '@fortawesome/fontawesome-free-regular';

import { Actions as TimelineActions  } from '../../stores/reducers/timeline-reducers';
import { Actions as GmapActions, } from '../../stores/reducers/gmap-reducers';
import { Toggler } from '../../components/elements';
import { ChooseRange } from './timeline/range-pickers';
import C from '../../shared/constants';
import { ramdaLog, isMobile } from '../../shared/helpers';
import { Fa } from "../elements/fa";
import { useRedux } from "../../states/redux-state";
import DashboardBlock from '../DashboardBlock';


const TimeLine = () => {
    const dispatch = useDispatch();

    const {deviceId} = useParams<{ deviceId: string; }>();

    const isLive = useRedux(s => s.timeline.isLive);
    const showSelectors = useRedux(s => s.timeline.showSelectors);
    const rangeType = useRedux(s => s.timeline.rangeType);
    const tempRange = useRedux(s => s.timeline.tempRange);
    const range = useRedux(s => s.timeline.range) || {} as any;
    const searching = useRedux(s => s.timeline.searching);

    const rangeHasPoints = useRedux(s => {
        const liveTripsIds = s.devicesTripsPoints.getIn([deviceId, 'liveTripsIds'], OrderedSet());
        const allTripsIds = s.devicesTripsPoints.getIn([deviceId, 'tripsPoints'], OrderedMap()).keySeq();

        return allTripsIds.size > liveTripsIds.size;
    })
    const [percent, setPercent] = React.useState({start: 0, end: 0, width: 0});
    const canApplyRange = tempRange && (!tempRange.startDate.isSame(range.startDate) || !tempRange.endDate.isSame(range.endDate));

    const confirmEmpty = false;

    React.useEffect(() => {
        if (tempRange && tempRange.startDate && tempRange.endDate) setPercent({
            start: (tempRange.startDate.diff(moment().subtract(1, 'year'), 'days')) / 365,
            end: (tempRange.endDate.diff(moment().subtract(1, 'year'), 'days')) / 365,
            width: percent.end - percent.start,
        })
    }, [tempRange])

    const confirmEmptyClick = () => {}

    const recenter = () => {
        dispatch(GmapActions.RECENTER_MAP_TO_DEVICE(deviceId));
    };

    const handleClickLive = pipe(
        TimelineActions.GO_LIVE,
        dispatch,
        ramdaLog,
        recenter,
    );

    return <DashboardBlock>
        <div style={{display: 'flex', justifyContent: 'center', padding: 10, marginLeft: 'auto', marginRight: 'auto', width: 400, maxWidth: '100%', zIndex: 1}}>
            <div style={{ width: 500 }}>
                <div style={topStyle}>
                    <span>Timeline</span>
                    <span>{isLive || !tempRange
                        ? <span className="btn-link" onClick={handleClickLive}>Live</span>
                        : `${tempRange.startDate.format('MM/DD/YY')} - ${tempRange.endDate.format('MM/DD/YY')}`}</span>

                    <span style={{cursor: 'pointer'}} onClick={pipe(TimelineActions.TOGGLE_DATES_SELECTOR, dispatch)}>Choose Range <Fa icon={faCaretDown} /></span>
                </div>

                <div style={{display: 'flex'}} className="progress">
                    <div
                        title={!rangeHasPoints ? 'No points found for given range' : ''}
                        className={`fade-color ${searching || isLive ? 'progress-bar progress-bar-striped progress-bar-animated' : ''}`}
                        style={bottomStyle(isLive, showSelectors, !rangeHasPoints && !searching)}>
                        {isLive ? null :
                            <div style={dragBarCss(percent)} onClick={pipe(TimelineActions.TOGGLE_DATES_SELECTOR, dispatch)} >
                                <div style={dotCss} />
                                <div><Fa icon={faBars} rotation={90} style={{fontSize: 10, fontWeight: 100, color: C.primaryColor}} /></div>
                                <div style={dotCss} />
                            </div>
                        }
                    </div>
                </div>

                {confirmEmpty &&
                    <div style={selectorsContainer}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
                            <p>The requested range period resulted in no points.</p>
                            <button className="btn btn-warning" onClick={confirmEmptyClick}>Ok</button>
                        </div>
                    </div>
                }
                {(showSelectors && !confirmEmpty) && <>
                    <div style={selectorsContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                            <Toggler onToggle={pipe(TimelineActions.CHANGE_TIMELINE_TYPE, dispatch)} activeIdx={rangeType} style={{fontSize: 12, justifyContent: 'space-around'}}>
                                <span>Custom</span>
                                <span>Month</span>
                                <span>Week</span>
                                <span>Day</span>
                            </Toggler>

                            <div style={{display: 'flex', fontSize: 11, justifyContent: 'center'}}>
                                <ChooseRange rangeType={rangeType}/>
                            </div>
                        </div>
                        {!isMobile &&
                            <span
                                style={{padding: 10, position: 'absolute', right: 1, bottom: -5, cursor: 'pointer', color: C.primaryColor}}
                                onClick={pipe(TimelineActions.TOGGLE_DATES_SELECTOR, dispatch)}
                            >
                                <Fa icon={faTimesCircle} style={{color: 'red', fontSize: 22}} />
                            </span>
                        }
                    </div>
                    <button
                        disabled={!canApplyRange}
                        className="btn btn-primary"
                        style={{cursor: 'pointer'}}
                        //onClick={() => dispatch(TimelineActions.APPLY_RANGE())}
                    >Update</button>
                </>}
            </div>
        </div>
    </DashboardBlock>;
};

export default React.memo(TimeLine);




const barpad = 4;
const bordRad = 5;

const dragBarCss = ({start, end, width}): css => ({
    backgroundColor: '#fff',
    left: `${Math.max(.055, start)*100}%`,
    width: `${width*100}%`,
    minWidth: 38,
    marginLeft: -38,
    // right: `${100 - end*100}%`,
    borderRadius: 1000,
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 10,
    cursor: 'pointer',
    boxShadow: '2px 2px 3px -2px rgba(0,0,0,0.75)'
})

const dotCss: css = {
    backgroundColor: C.primaryColor,
    width: 6, height: 6,
    borderRadius: 6, marginLeft: 4, marginRight: 4
}

const topStyle: css = ({
    backgroundColor: `rgba(255, 255, 255, ${C.opacity}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: barpad,
    borderTopLeftRadius: bordRad,
    borderTopRightRadius: bordRad,
    fontSize: 'smaller',

})

const selectorsContainer: css = {
    position: 'relative',
    backgroundColor: `rgba(255, 255, 255, ${C.opacity}`,
    borderBottomLeftRadius: bordRad,
    borderBottomRightRadius: bordRad,
    padding: 10,
    paddingBottom: 20,
    display: 'flex',

}

const bottomStyle = (isLive, showingSelectors, error): css => ({
    height: 18,
    flex: 1,
    backgroundColor: !error || isLive ? C.primaryColor.fade(C.fade) : '#b80000',
    padding: barpad,
    borderBottomLeftRadius: showingSelectors ? 0 : bordRad,
    borderBottomRightRadius: showingSelectors ? 0 : bordRad,
    overflow: 'hidden',
    fontSize: 'smaller',
    display: 'flex',
    position: 'relative',
    justifyContent: isLive ? 'flex-end' : 'flex-start',

})
