import React, {CSSProperties as css, useEffect} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {is, Seq} from 'immutable';
import {Link} from 'react-router-dom';
import {addIndex, equals, map, pathOr, pipe, splitEvery} from 'ramda';

import C from '../../../shared/constants';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import { Icon } from '../../elements/icon';
import { FormatAddress } from './device-list';
import { iDeviceDetails, iDevicePing, iFullStoreState, ItemType } from '../../../shared/interfaces';
import { FaFav, FaPer } from '../../elements/fa';
import { PersonBox } from '../../general/person-box';
import { fitText } from '../../../shared/fit-text';
import { useFirebase } from '../../../states/firebase-state';
import { useRedux } from '../../../states/redux-state';
import { withSentry } from '../../../shared/hoc/withSentry';
import { setShowedDevices } from '../../../shared/helpers';
import GoogleMap from "react-google-maps/lib/components/GoogleMap";

const DevicesPage = () => {
    const devicesIds = useSelector<iFullStoreState, Seq.Indexed<string>>(
        (s) => s.devicesData.devicesDetails.keySeq(),
        ((l, r) => is(l, r)),
    );
    console.log('devicesIds', ...devicesIds);
    const dispatch = useDispatch();

    const devicesLastPings = useRedux((s) => s.devicesData.devicesLastPing).valueSeq().toArray();

          const mapRef = useSelector<
            iFullStoreState,
            React.RefObject<GoogleMap> | null
          >((state) => state.gmap.mapRef());

          const devicesMarker = useRedux((s) => s.devicesData.devicesLastPing)
            .valueSeq()
            .toArray()
            .filter((it) => it !== null);

    useEffect(() => {
                    let markerBounds = new google.maps.LatLngBounds();

                    devicesMarker.forEach((marker) => {
                      markerBounds.extend(
                        new google.maps.LatLng(
                          marker.coordinates.location.lat,
                          marker.coordinates.location.lng
                        )
                      );
                    });

                    mapRef?.current?.fitBounds(markerBounds);
        setShowedDevices(devicesLastPings, dispatch);
    }, [devicesLastPings]);

    return (
        <DashboardBlock>
            <ModalHeader title="Devices" />

            <div style={{display: 'flex', flexWrap: 'wrap', alignContent: 'space-between', flexDirection: 'row'}}>
                {pipe(
                    map((deviceId) => <DeviceTile key={deviceId} deviceId={deviceId} />),
                    splitEvery(2),
                    addIndex(map)((g, idx) => <div key={idx} style={{display: 'flex', flexWrap: 'wrap'}}>{g}</div>)
                )(devicesIds.toArray())}
            </div>
        </DashboardBlock>
    );
};

export default withSentry(DevicesPage);

interface iTileProps {
    deviceId: string;
    style?: React.CSSProperties;
}

// todo: move this to shared path
export const formatWhenLast = (time?: moment.Moment) => {
    if (!time) return false;

    const now = moment();

    const lastActiveDays = now.diff(time, 'days');
    const lastActiveHours = Math.floor(now.diff(time, 'hours'));
    const lastActiveMinutes = now.diff(time, 'minutes');

    let ago = '';

    if (lastActiveDays) ago = `${lastActiveDays} Day${lastActiveDays === 1 ? '' : 's'} ago`;
    else if (lastActiveHours) ago = `${lastActiveHours} Hour${lastActiveHours === 1 ? '' : 's'} ago`;
    else if (lastActiveMinutes) ago = `${lastActiveMinutes} Minute${lastActiveMinutes === 1 ? '' : 's'}`;

    return ago;
};
const getPersonFromState = (personId, state) => pathOr(false, ['general', 'people', personId], state);

const DeviceTile = ({deviceId, style={}}: iTileProps) => {

    const uid = useRedux((s) => s.auth.user.uid);
    const [isFavorite, setFavorite] = useFirebase(`favorites/${uid}/${ItemType.device}/${deviceId}`);
    const device = useSelector<iFullStoreState, iDeviceDetails>(
        (state) => state.devicesData.devicesDetails.get(deviceId)!,
        (left, right) => equals(left, right),
    );
    const lastPing = useSelector<iFullStoreState, iDevicePing | undefined>(
        (state) => state.devicesData.devicesLastPing.get(deviceId),
        (left, right) => left?.pointId === right?.pointId,
    );

    const pingMsg = lastPing?.msg ?? 'N/A';

    const assignedPersonId = device?.assignedTo?.personId;
    const assignedTo = useRedux((s) => getPersonFromState(assignedPersonId, s));

    const [ago, setAgo] = React.useState(formatWhenLast(lastPing?.time));

    const toggleFav = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFavorite(isFavorite ? null : 999999);
    };

    // when ago changes or last ping changes queue up another ago
    React.useEffect(() => {
        const timerId = setTimeout(() => setAgo(formatWhenLast(lastPing?.time)), 1000*60);

        return () => clearTimeout(timerId);
    }, [lastPing?.time, ago]);

    const deviceEl = React.createRef();
    const personEl = React.createRef();

    React.useEffect(() => {
        fitText([
            [deviceEl.current, `device-tile-${deviceId}`],
        ]);
    }, [assignedTo]);

    return (
        <Link key={deviceId} to={`/device/${deviceId}`} style={{...tileCss, ...style}}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${C.darkGray}`,
                paddingBottom: 8,
                marginBottom: 3,
                width: '100%'
            }}>
                <FaPer active={assignedTo} title={assignedTo} />
                <Icon style={{flex: 1, textAlign: 'center'}} color={device.color} icon={device.icon} size="sm"/>
                <FaFav isFav={!!isFavorite} style={{color: C.yellow}} onClick={toggleFav}/>
            </div>

            <div ref={deviceEl as any} style={{
                fontSize: 18, textAlign: 'center', marginBottom: 8,
                maxWidth: '100%', maxHeight: '52px', overflow: 'hidden', textOverflow: 'ellipsis'
            }}>
                {device.name}
            </div>

            {/* Last Active */}
            {ago &&
            <span
                style={{
                    fontSize: 12,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center'
                }}
                title={ago.toString()}
            >{ago}</span>
            }

            {/* Status */}
            {pingMsg && <div style={{
                borderRadius: 3,
                backgroundColor: C.lightGray,
                fontSize: 12,
                fontWeight: 'bold',
                textAlign: 'center',
                padding: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%'
            }}>
                {pingMsg}
            </div>}

            {/* Location */}
            <FormatAddress style={{alignSelf: 'stretch', fontSize: 11, paddingTop: 5, textAlign: 'center'}}
                           address={lastPing?.address} noWrap/>

            {/* Assigned to */}
            {assignedTo &&
            <div style={{width: '100%'}} ref={personEl as any}>
                <PersonBox
                    tiny
                    compressed
                    active={false}
                    person={assignedTo}
                    style={{alignSelf: 'flex-start', marginLeft: -5, marginBottom: -5}}
                />
            </div>
            }
        </Link>
    );
};

const tileCss: css = {
    margin: 5,
    userSelect: 'none',
    textDecoration: 'none',
    color: C.primaryText,
    border: `1px solid ${C.mediumGray}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    borderRadius: 3,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: 125,
    minHeight: 220,
};
