import React, { useState, FC } from 'react';
import style from '../SummaryReport.module.scss';
import { SummaryItemComponent } from './interfaces';
import { friendlyDist, friendlySpeed } from '../../../../../shared/helpers';
import { useRedux } from '../../../../../states/redux-state';
import { Button, ButtonSize, ButtonStyles } from '../../../../Button';
import { ReactComponent as NotificationSvg } from '../../../../../assets/svg/notification.svg';
import { ReactComponent as MapPointerSvg } from '../../../../../assets/svg/map_pointer.svg';
import { getTimeFormated, useOneDeviceReportUnits } from '../../ReportPage';

const SummaryItem: FC<SummaryItemComponent> = ({
	showReportTrip,
	hideReportTrip,
	filteredRecords,
	results,
}) => {
	const deviceUnits = useOneDeviceReportUnits();

	// show routes block
	const [showAllRoute, setShowAllRoute] = useState(false);
	const tripPoints = useRedux(s => s.devicesTripsPoints);

	const showAllTrips = records => {
		records.forEach(item => {
			setShowAllRoute(!showAllRoute);
			showReportTrip(item, tripPoints);
		});
	};

	const hideAllTrips = records => {
		setShowAllRoute(!showAllRoute);
		records.forEach(item => hideReportTrip(item));
	};
	// end show routes block
	return (
		<div className={style['summary-table']}>
			<div className={style['summary-table__header']}>
				<h3 className={style['summary-table__title']}>{results.device}</h3>
				<div className={style['summary-table__buttons']}>
					<Button
						className={`${style['but']} but but-toggle but-24px`}
						styleType={ButtonStyles.GRAY_INACTIVE}
						size={ButtonSize.XSM}
					>
						<NotificationSvg className={'icon-sm'} />
					</Button>
					<Button
						className={`${style['but']} but but-toggle but-24px ${showAllRoute ? 'active' : ''}`}
						styleType={showAllRoute ? ButtonStyles.BLACK_WHITE : ButtonStyles.GRAY_INACTIVE}
						size={ButtonSize.XSM}
						onClick={
							showAllRoute
								? () => hideAllTrips(filteredRecords)
								: () => showAllTrips(filteredRecords)
						}
					>
						<MapPointerSvg className={'icon-sm'} />
					</Button>
				</div>
			</div>
			<div className={style['summary-table__content']}>
				<div className={style['summary-table__col']}>
					<div className={style['summary-table__item']}>
						<p className={style['summary-table__item__title']}>Average Speed</p>
						<p className={style['summary-table__item__value']}>
							{isNaN(results.averageSpeed) ? 0 : friendlySpeed(results.averageSpeed, deviceUnits)}
						</p>
					</div>
					<div className={style['summary-table__item']}>
						<p className={style['summary-table__item__title']}>Safety</p>
						<p className={style['summary-table__item__value']}>{results.safetyPercent}</p>
					</div>
					<div className={style['summary-table__item']}>
						<p className={style['summary-table__item__title']}>Drive Time</p>
						<p className={style['summary-table__item__value']}>
							{getTimeFormated(results.driveTime)}
						</p>
					</div>
				</div>
				<div className={style['summary-table__col']}>
					<div className={style['summary-table__item']}>
						<p className={style['summary-table__item__title']}>Stopped Time</p>
						<p className={style['summary-table__item__value']}>
							{getTimeFormated(results.stoppedTime / 60)}
						</p>
					</div>
					<div className={style['summary-table__item']}>
						<p className={style['summary-table__item__title']}>Total Distance</p>
						<p className={style['summary-table__item__value']}>
							{results.distanceTraveled > 0
								? friendlyDist(results.distanceTraveled, deviceUnits)
								: 0}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SummaryItem;