import React, { CSSProperties as css } from 'react';

import { BaseComponent } from '../../shared/BaseComponent';

export class Checkbox extends BaseComponent<{id: string, [k: string]: any, style?: css}> {
    debugRender = () => {
        const { style={} } = this.props;
        return (
            <span style={style}>
                <input type="checkbox" className="css-checkbox" {...this.props}/>
                <label htmlFor={this.props.id} className="css-label lite-blue-check" />
            </span>
        )
    }
}

export class TriStateCheckbox extends BaseComponent<{id: string, state: 'on'|'off'|'both', [k: string]: any}> {
    debugRender = () => {
        const { id, state, ...safeProps } = this.props;

        return (
            <span>
                <input type="checkbox" id={id} checked={state === 'on'} className={`css-checkbox ${state}`} {...safeProps}/>
                <label htmlFor={id} className="css-label lite-blue-check" />
            </span>
        )
    }
}
