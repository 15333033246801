import { createSelector } from 'reselect';
import { iDeviceDetails, iPerson, iList, iTag, iFullStoreState } from '../../shared/interfaces';
import { createKeyMatchSelector } from './general-selectors';
import { toLower, test, pathOr, fromPairs } from 'ramda';

const getTerm = (state: iFullStoreState) => state.general.searchTerm;
const getLowerTerm = createSelector(getTerm, term => term.toLowerCase());
const devicesDetails = (state: iFullStoreState) => state.devicesData.devicesDetails;
const allPeople = (state: iFullStoreState) => state.general.people || {};
const allTags = (state: iFullStoreState) => state.general.tags || {} as iList<iTag>;

const smush = (...groups) => {
    let ret = {};

    groups.forEach(g => Object.keys(g).forEach(id => ret[id] = g[id]));

    return ret;
}

const matchesSearch = (obj, term, path) => {
    const getObjProp = pathOr('', path);

    const res = Object.entries(obj).filter(([key, value]) => {
        const propValue = getObjProp(value);
        const getLoweredProp = toLower(propValue);
        return test(new RegExp(term), getLoweredProp);
    });

    return fromPairs(res);
}

// device group
export const devicesMatchSelector = (function() {
    const deviceNameMatchSelector = createSelector(getLowerTerm, devicesDetails,
        (term, devicesDetails) => matchesSearch(devicesDetails.toObject(), term, ['name'])
    );

    // If these are uncommented they are using lodash pickby so will need update
    // const deviceTagMatchSelector = createSelector(getLowerTerm, allDevices, matchingTags,
    //     (term, devices, tags) => pickBy(devices, device => vals(tags).some((tag: iTag) => !!tag.instances.device[device.id]))
    // )
    //
    // const deviceExternalMatches = createSelector(getLowerTerm, allDevices,
    //     (term, devices) => pickBy(devices, (device: iDeviceDetails) => !term.length || JSON.stringify(device.externalData).toLowerCase().indexOf(term) != -1)
    // );
    //
    // const deviceAssignedTomatches = createSelector(getLowerTerm, allDevices, allPeople,
    //     (term, devices, people) => pickBy(devices, (device: iDeviceDetails) => !term.length || device.assignedTo && people[device.assignedTo.personId] && people[device.assignedTo.personId].displayName.toLowerCase().indexOf(term) != -1)
    // );

    const smushDevices = createSelector(deviceNameMatchSelector,
        (...devices) => smush(...devices) as iList<iDeviceDetails>
    )

    return createKeyMatchSelector(smushDevices, devices => devices)
})();

export const peopleMatchSelector = (function() {
    const personNameMatchSelector = createSelector(getLowerTerm, allPeople,
        (term, people) => matchesSearch(people, term, ['displayName'])
    )

    const smushPeople = createSelector(personNameMatchSelector,
        (...people) => smush(...people) as iList<iPerson>
    )

    return createKeyMatchSelector(smushPeople, people => people);
})();

export const tagsMatchSelector = (function() {
    const tagNameMatcheSelector = createSelector(getLowerTerm, allTags,
        (term, tags) => matchesSearch(tags, term, ['details', 'name'])
    )

    const smushTags = createSelector(tagNameMatcheSelector, (...tags) => smush(...tags) as iList<iTag>)

    return createKeyMatchSelector(smushTags, tags => tags);
})()
