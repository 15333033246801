import React from 'react';
import { CSSProperties as css } from 'react';

import { default as C } from '../../shared/constants';
import * as structure from '../../shared/interfaces';
import { BaseComponent } from '../../shared/BaseComponent';

export default class UserImage extends BaseComponent<{size?: number, person: structure.iPerson, style?: css}, any> {
    debugRender = () => {
        const { person, size = 90, children = null, style: extraStyle = {} } = this.props;

        return (
            <div style={{...style(size, person.photoURL), ...extraStyle}}>
                {children}
            </div>
        )
    }
}

const style = (size, url): css => ({
    width: size, height: size, borderRadius: size, backgroundImage: `url(${url})`, backgroundSize: 'cover', border: `2px solid ${C.mediumGray}`, position: 'relative'
})
