import * as alertsdb from '../../../shared/db/alerts-db';

import {Col, Row} from '../../elements/flex';
import Dialog, {DialogConfigSetter} from '../../Dialog';
import {ItemType, UserAuth, iFenceDetails, iFullStoreState} from '../../../shared/interfaces';
import React, {CSSProperties as css} from 'react';
import {faMinusCircle, faPlusCircle, faToggleOff, faToggleOn} from '@fortawesome/fontawesome-free-solid';
import {iDeviceEvent, iEventInstance, iList, iPerson} from '../../../shared/interfaces';
import {idValArr, kilos, knots, lEmpty, range, targetVal, vals} from '../../../shared/helpers';
import {mapObjIndexed, path, pipe, values} from 'ramda';

import ActionRow from '../../fence/tab-bodies/ActionRow/ActionRow';
import {BaseComponent} from '../../../shared/BaseComponent';
import C from '../../../shared/constants';
import DashboardBlock from '../../DashboardBlock';
import DeleteIcon from '../../SVG-components/delete_outline';
import {Fa} from '../../elements/fa';
import {FenceBodyLayout} from '../../fence/tab-bodies/BodyLayout/FenceBodyLayout/FenceBodyLayout';
import Knob from '../../elements/Knob';
import { MinimalTile } from '../../general/index';
import ModalHeader from '../../menus/modal/modal-header';
import {PersonBox} from '../../general/person-box';
import {RouteComponentProps} from 'react-router-dom';
import SearchGridOld from "../../SearchGridOld/SearchGridOld";
import SwitchComponent from "../../elements/SwitchComponent";
import {bounded} from '../../../shared/ramda';
import {connect} from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';

// #endregion
// /alerts/11/fence/-KxiyBQK44rtLjimKF9S
// /alerts/4/device/-KvHiVE6-tPWTOuCcPyr
//                <Fa icon={on ? faToggleOn : faToggleOff} onClick={() => alertsdb.toggleNotificationSchedule(this.fullProps.authUser)(alertKey, !on)}/>
// key/itemType/itemId
const fenceItemSelector = (state, ownProps) => state.general.fences[ownProps.match.params.fenceType]?.[ownProps.match.params.deviceId]?.[ownProps.match.params?.itemId];
const isFence = (ownProps) => ownProps.match.params.itemId !== undefined && ownProps.match.params.itemType === 'fence';

const mapStateToProps = (state: iFullStoreState, ownProps): IPropsFromStore => ({
    authUser: state.auth.user!,
    event: state.general.deviceEvents[ownProps.match.params.eventKey],
    fenceItem: isFence(ownProps) ? fenceItemSelector(state, ownProps) : null,
});

type IPropsFromFence = {
    isVisible?:boolean;
    toggleVisible?: (visible:boolean) => void;
}

type IProps = RouteComponentProps<{ eventKey: string; itemType: ItemType; itemId: string, }>;

type IPropsFromStore = {
    event: iDeviceEvent;
    authUser: UserAuth;
    fenceItem?: iFenceDetails;
}

type IFullProps = IProps & IPropsFromFence & IPropsFromStore;

interface iState {
    tab: 'notify'|'settings'; alertInst: iEventInstance|'initializing';
}

class AlertSettingsPage extends BaseComponent<IFullProps, iState> {
    state = {
        tab: 'notify',
        alertInst: 'initializing'
    } as iState

    watchCloser;
    componentDidMount() {
        const {event: {key}} = this.props;
        const {itemType, itemId} = this.props.match.params;
        const {setState} = this;

        this.watchCloser = alertsdb.watchEventValues({alertType: key, itemType, itemId}, pipe((alertInst) => ({alertInst}), setState.bind(this)));
    }

    componentWillUnmount() {
        if (this.watchCloser) this.watchCloser();
    }

    tabSwitcher = (tab) => () => this.setState((s) => ({tab}))

    debugRender = () => {
        const {event, fenceItem} = this.props;
        const {itemType, itemId} = this.props.match.params;
        const {tabSwitcher} = this;
        const {tab, alertInst} = this.state;
        const isFence = itemType === ItemType.fence;

        if (alertInst === 'initializing') return null;

        const people = alertInst && alertInst.people ? alertInst.people : {};
        const customRecipients = alertInst && alertInst.custom ? alertInst.custom : {} as iList<string>;

        const DynamicLayout: any = isFence ? FenceBodyLayout : DashboardBlock;
        const DynamicHeader = isFence ?
            null :
            <ModalHeader title={this.props.event.name} />;

        {console.log('here', alertsdb.getGeofences(itemId));}

        return (
            <DynamicLayout style={!isFence ? {} : {maxWidth: 300}} itemType={null} itemId={null} isVisible={this.props.isVisible} toggleVisible={this.props.toggleVisible} header={isFence ? <ActionRow title={fenceItem?.name || 'New Item'} canBack actions={[]} /> : false}>
                {DynamicHeader}

                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <span onClick={tabSwitcher('notify')} style={headingStyle(tab == 'notify')}>Notify</span>
                    <span onClick={tabSwitcher('settings')} style={headingStyle(tab == 'settings')}>Settings</span>
                </div>

                {
                    tab == 'notify' ?
                        <div style={{paddingLeft: 10}}>
                            <AlertSettingsPageNotify
                                togglePerson={(personId) => alertsdb.toggleAlertNotifyPerson(this.props.authUser)([{itemType, itemId, alertType: event.key}], personId, !people[personId])}
                                notifyPeople={people}
                            />
                            <MinimalTile style={{marginTop: 8, marginBottom: 8}}>
                                <NonUserAlerts
                                    recipients={customRecipients}
                                    toggleRecipient={(recipient, on) => on ?
                                        alertsdb.addAlertNofityCustom(this.props.authUser)([{itemType, itemId, alertType: event.key}], recipient) :
                                        alertsdb.removeAlertNotifyCustom(this.props.authUser)({itemType, itemId, alertType: event.key}, recipient)
                                    }
                                />
                            </MinimalTile>

                            <MinimalTile style={{marginTop: 8, marginBottom: 8}}>
                                <DND alertInst={alertInst} eventKey={event.key} itemType={itemType} itemId={itemId} />
                            </MinimalTile>

                        </div> :
                        <AlertSettingsPageSettings
                            initialValue={(alertInst || {} as any).settings}
                            onChange={(newVal) => alertsdb.alertSettingVal(this.props.authUser)(itemType, itemId, event.key, newVal)}
                            event={event}
                        />
                }

            </DynamicLayout>
        );
    }
}
export default connect(mapStateToProps)(AlertSettingsPage);

const mapNotifyToProps = (state: iFullStoreState, ownProps) => ({
    people: state.general.people,
    customRecipients: ownProps.customRecipients,

});

interface iPageNotifyProps {
    notifyPeople: iList<true>;
    togglePerson: (personId: string) => any;
}
interface iPageNotifyPropsFull extends iPageNotifyProps {
    people: iList<iPerson>;
}
@connect(mapNotifyToProps)
class AlertSettingsPageNotify extends BaseComponent<iPageNotifyProps, { tagFilter }> {
    state = {tagFilter: ''}

    debugRender = () => {
        const {people, togglePerson, notifyPeople = {}} = this.props as iPageNotifyPropsFull;

        const {tagFilter} = this.state;

        const filteredPeople = vals(people)
            .filter((person) => (person.displayName || '').toLowerCase().indexOf(tagFilter) != -1)
            .map((person) => <PersonBox key={person.id} personId={person.id} person={person} active={!!notifyPeople[person.id]} />);

        return (
                <SearchGridOld
                    list={filteredPeople}
                    filterStr={tagFilter}
                    placeholder="Search People"
                    perPage={12}
                    keyClicked={togglePerson}
                    filterChange={tagFilter => this.setState(s => ({ tagFilter }))}
                    style={{maxWidth: 390}}
                />
        )
    }
}

@connect((state: iFullStoreState) => ({
    authUser: state.auth.user
}))
class DND extends BaseComponent<{alertInst: iEventInstance; eventKey; itemType; itemId}, {activeDay: string }, { authUser }> {
    days = [
        {day: 'sun', disp: 'S'},
        {day: 'mon', disp: 'M'},
        {day: 'tue', disp: 'T'},
        {day: 'wed', disp: 'W'},
        {day: 'thr', disp: 'T'},
        {day: 'fri', disp: 'F'},
        {day: 'sat', disp: 'S'},
    ]

    initNewRange = () => {
        const {eventKey, itemType, itemId} = this.props as {alertInst: iEventInstance; [rest: string]: any};

        alertsdb.upsertDnd(this.fullProps.authUser)({alertType: eventKey, itemType, itemId}, this.state.activeDay, {from: 13, to: 23});
    }

    debugRender = () => {
        const {eventKey, itemType, itemId, authUser, alertInst} = this.fullProps;

        const {on = false, days = {}} = path(['alertInst', 'dnd'])(this.props) || {};
        const {activeDay = ''} = this.state || {};

        const alertKey = {alertType: eventKey, itemType, itemId};

        return <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', outline: 'none'}}>
                <span style={{margin: 0, fontSize: 14, padding: 5}}>Do Not Disturb</span>
                <SwitchComponent onChange={() => alertsdb.toggleNotificationSchedule(this.fullProps.authUser)(alertKey, !on)} checked={on}/>
            </div>
            <p style={{margin: 0, fontSize: 12, padding: 5}}>Choose the times you DO NOT want to receive this alert</p>

            <div style={{display: 'flex', justifyContent: 'space-evenly', paddingBottom: 5}}>
                {this.days.map(({day, disp}) => (
                    <button key={day} style={{flex: 1, borderRadius: 0, borderColor: 'rgb(135, 136, 137)', width:20, padding: 5}} onClick={() => this.setState({activeDay: day})} className={`btn btn${activeDay == day ? '' : '-outline'}-dark`}>
                        <span style={{fontSize: 14, margin: 'auto', padding:0}}>{disp}{days[day] ? <sup>*</sup> : ''}</span>
                    </button>
                ))}
            </div>

            {!activeDay ? null :
                <table className="table table-sm table-striped">
                    <tbody>
                        {(idValArr(days[activeDay]) || []).map(({id, val: {from, to}}: any, idx) =>
                            <>
                                <tr key={`${from}-${to}-${idx}`}>
                                    <td style={{width:200}}>
                                        <Fa icon={faMinusCircle} onClick={() => alertsdb.deleteDnd(this.fullProps.authUser)(alertKey, activeDay, id)} />
                                        <button onClick={() => alertsdb.upsertDnd(authUser)(alertKey, activeDay, {from: 0, to: 23}, id)} style={{width: '90%'}} className={`btn btn-${isAllDay({from,to}) ? 'primary' : ''} btn-xs`}>All Day</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-right"><FromTo from={from} to={to} change={(newVal) => alertsdb.upsertDnd(authUser)(alertKey, activeDay, newVal, id)} /></td>
                                </tr>
                            </>

                        )}
                        <tr>
                            <td style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Fa icon={faPlusCircle} onClick={this.initNewRange} />
                                <span className="btn-link" style={{color: 'rgb(135, 136, 137)', textDecoration: 'none'}} onClick={() => alertsdb.clearNotificationSchedules(this.fullProps.authUser)(alertKey, activeDay)}>Clear</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>;
    }
}

const isAllDay = ({from, to}) => from == 0 && to == 23;

const options = range(0, 23).map((hour) => ({
    hour, name: moment().hour(hour).format('hA')
}));

class FromTo extends BaseComponent<{from: number; to: number; change: ({from, to}: {from: number; to: number}) => any}, any> {

    __changeFrom = (newVal: number) => this.props.change({
        from: newVal,
        to: this.props.to,
    })

    __changeTo = (newVal: number) => this.props.change({
        to: newVal,
        from: this.props.from,
    })

    debugRender = () => {
        const {from, to} = this.props;

        return <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <select className="mas-input" style={{...C.selectCss, marginRight: 8}} defaultValue={from as any} onChange={({target: {value}}) => this.__changeFrom(parseInt(value))}>
                {options.map(({hour, name}) => <option key={hour} value={hour}>{name}</option>)}
            </select>
            <span>To</span>
            <select className="mas-input" style={{...C.selectCss, marginLeft: 8}} defaultValue={to as any} onChange={({target: {value}}) => this.__changeTo(parseInt(value))}>
                {options.map(({hour, name}) => <option key={hour} value={hour}>{name}</option>)}
            </select>
        </div>;
    }
}

class NonUserAlerts extends BaseComponent< {style?: css; recipients: iList<string>; toggleRecipient: (recipient: string, onOff: boolean) => any}, {newRecipient?: string}> {
    private dialog: DialogConfigSetter;

    __remove = (recipient) => () => this.props.toggleRecipient(recipient, false);

    __change = ({target: {value}}) => this.setState({newRecipient: value})

    __keyDown = ({keyCode}) => {
        if (keyCode == '13') this.__submit();
    }

    __validEmail = (str) => !/(\.{2}|-{2}|_{2})/.test(str) && /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(str);

    __validPhoneNumber = (str) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(str);

    __setupDialog = (callBack: () => DialogConfigSetter): void => {
        this.dialog = callBack();
    }

    __submit = () => {
        const {newRecipient = undefined} = this.state || {};

        if (typeof(newRecipient) === 'undefined' || newRecipient === '') {
            this.dialog?.({
                title: 'Input is empty!',
                body: 'Please, enter your email or phone number.',
                type: 'NOTIFICATION',
            });

            return;
        } else if (this.__validEmail(this.state.newRecipient) || this.__validPhoneNumber(this.state.newRecipient)) {
            this.props.toggleRecipient(this.state.newRecipient, true);
            this.setState({newRecipient: ''});
        } else {
            this.dialog?.({
                title: 'Incorrect input!',
                body: 'This should be a valid email or phone number.',
                type: 'NOTIFICATION',
            });
        }
    }

    debugRender = () => {
        const {toggleRecipient, recipients, style={}} = this.props;
        const {newRecipient = ''} = this.state || {};

        return <div style={{display: 'flex', flexDirection: 'column', padding: 5, ...style}}>
            <p style={{margin: 0, fontSize: 14}}>Add custom recipients phone or email</p>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 8}}>
                <input
                    value={newRecipient}
                    className="mas-input"
                    style={{border: 'none', backgroundColor: '#F7F7F7', width: 125, flex: '0.55 0.55 0%', borderRadius: 2, fontSize:12, padding: 10, outline: 'none'}}
                    onChange={this.__change}
                    onKeyDown={this.__keyDown}
                />
                <button className="btn btn-sm btn-outline-primary"
                        style={{border: '1px solid black', flex: '0.3 0.3 0%', padding: '5px 10px', fontSize: 12, color: 'white', backgroundColor: 'black', borderRadius: 4}}
                        onClick={this.__submit}>Add</button>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, padding: '10px 5px 5px', backgroundColor: 'rgb(247, 247, 247)'}}>
                <p style={{fontWeight: 500, fontSize: 14, marginBottom: 5}}>Email/Phone</p>
                <div>
                    {pipe(
                        mapObjIndexed((r,k) => {
                            const isEmail = r.includes('@')
                            const customRecipient = isEmail ? r : r.replace(/\D/g,'')
                                return (
                                    <div
                                        style={{display: 'flex', justifyContent: "space-between", marginTop: 5}}
                                        key={k}>
                                            <p title={r} style={{overflow: "hidden", fontSize: 13, textOverflow: "ellipsis", whiteSpace: "nowrap", margin: 0}}>{customRecipient}</p>
                                            <p style={{textAlign: 'right', marginLeft: 5, marginBottom: 0}}>
                                                <DeleteIcon className='SvgElement trash' onClick={this.__remove(k)} />
                                            </p>
                                    </div>
                                )
                            }
                        ),
                        values,
                    )(recipients)}
                </div>
            </div>
            {/* <div style={{display: lEmpty(recipients) ? 'none' : 'inherit'}}>*/}
            {/*    <table className="table table-striped table-bordered table-sm">*/}
            {/*        <thead>*/}
            {/*            <tr><td>Email/Phone</td><td></td></tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*            {pipe(*/}
            {/*                mapObjIndexed((r,k) => <tr key={k}><td>{r}</td><td style={{textAlign: 'right'}}><button onClick={this.__remove(k)} className="btn btn-outline-danger btn-sm">Remove</button></td></tr>),*/}
            {/*                values,*/}
            {/*            )(recipients)}*/}
            {/*        </tbody>*/}
            {/*    </table>*/}
            {/* </div>*/}
            <Dialog setupConfig={this.__setupDialog} />
        </div>;
    }
}

class AlertSettingsPageSettings extends BaseComponent<{event: iDeviceEvent; onChange; initialValue?}, {value}> {
    state = {
        value: this.props.initialValue || this.props.event.min || 0
    }

    __change = pipe(
        bounded(this.props.event.min || 0, this.props.event.max || Number.MAX_SAFE_INTEGER),
        (value) => {
            this.setState({value});
            this.props.onChange(value);
        }
    )

    debugRender = () => {
        const {event} = this.props;
        const {value} = this.state;

        if (event.type == 'speed') {
            return (
                <div style={{padding: 10, display: 'flex', alignItems: 'center', backgroundColor: '#333', flexDirection: 'column', borderRadius: 3}}>
                    <div style={{color: '#fff', flex: 1, display: 'flex', justifyContent: 'center', marginBottom: 10, fontSize: 18}}>Max Speed</div>
                    <div style={{marginBottom: -33}}>
                        <Knob value={value} onChange={this.__change} min={event.min || 0} max={event.max ||100} angleOffset={-125} angleArc={250} fgColor="#b20000" inputColor="#b20000" step={5} thickness={.1} />
                    </div>
                    <div style={{display: 'flex', color: '#ffec03', justifyContent: 'space-around', alignItems: 'stretch', width: 200, zIndex: 2}}>
                        <div style={{lineHeight: '15px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}><div>{value}</div><div>mph</div></div>
                        <div style={{fontSize: 30, lineHeight: '15px', textAlign: 'center', display: 'flex', alignItems: 'center'}}>/</div>
                        <div style={{lineHeight: '15px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}><div>{kilos(value)}</div><div>kph</div></div>
                        <div style={{fontSize: 30, lineHeight: '15px', textAlign: 'center', display: 'flex', alignItems: 'center'}}>/</div>
                        <div style={{lineHeight: '15px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}><div>{knots(value)}</div><div>knots</div></div>
                    </div>
                </div>);
        }

        if (event.type == 'range') {

            return <Col>
                <span>{event.name}</span>
                <span style={{fontSize: 'smaller'}}>{event.description}</span>
                <Row justify="space-between" style={{fontSize: 'smaller'}}><span>{event.min || 0}</span><span>{event.max || 100}</span></Row>
                {/* Range supports ints so multiple by precision for selection and divide on setting value */}
                <input
                    type="range"
                    min={event.min}
                    max={event.max}
                    step={(event.step || 1)}
                    value={value}
                    className="fancy-slider"
                    style={{width: '100%'}}
                    onChange={pipe(targetVal, this.__change)}
                />
                <span>value: {value } {event.unitString || ''}</span>
                {!event.src ? null : <div style={{textAlign: 'center', marginTop: 20}}><img src={event.src} /></div>}
            </Col>;
        }

        return <div style={{display: 'flex', justifyContent: 'center'}}>No settings for this type</div>;
    }
}

const headingStyle = (active): css => ({
    cursor: 'pointer',
    borderBottomStyle: 'solid',
    borderBottomColor: C.primaryColor,
    borderBottomWidth: active ? 3 : 1,
    padding: '0 20px',
    margin: '10px 0',
});
