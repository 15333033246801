import React from 'react';
import { identity } from 'ramda';

import { globaldb, theClient } from '../shared/firebase';


type iFirebaseTransactor<T> = (data: T) => T

const safeFromTo = (fn) => (...stuff) => {
    try {
        return fn(...stuff);
    } catch (e) {
        return undefined;
    }
};

// todo: change to transactor as optional 3rd param?
function useGlobalFirebase<T>(
    path: string,
    fromDb: (...stuff: any[]) => T = identity,
    toDb: (data:T) => any = identity
): [T, (newVal: iFirebaseTransactor<T>|T) => any] {
    const [val, stateSetter] = React.useState(fromDb(null)) as any

    fromDb = safeFromTo(fromDb);
    toDb = safeFromTo(toDb);

    React.useEffect(() => {
        const listener = (v: any) => stateSetter(fromDb(v.val()));
        globaldb.child(path).on('value', listener);

        return () => console.log('disconnect fb ' + path) as any || globaldb.child(path).off('value', listener);
    }, [path])

    const setter = (transactorOrData?: iFirebaseTransactor<T>|T) => {
        if (typeof transactorOrData === 'function') {
            globaldb.child(path).transaction(data => toDb((transactorOrData as any)(fromDb(data))));
        } else {
            if (transactorOrData === undefined) {
                globaldb.child(path).remove();
            } else {
                globaldb.child(path).set(toDb(transactorOrData));
            }
        }
    }

    return [val, setter];
}

export const useFirebase = <T>(
    path: string,
    fromDb: (...stuff: any[]) => T = identity,
    toDb: (data:T) => any = identity
) => useGlobalFirebase(`clients/${theClient()}/${path}`, fromDb, toDb);
