import React, { HTMLAttributes } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

import Portal from '../../components/Portal';
import './Drawer.scss';


type IProps = IClassNameProps & HTMLAttributes<HTMLDivElement> & {
    anchor?: 'top' | 'right' | 'bottom' | 'left';
    containerId?: string;
    setupConfig: (callBack: () => DrawerManager) => void;
    clickOutside?: () => void;
}
type AnimationState = 'CLOSED' | 'OPENING' | 'OPENED' | 'CLOSING';

export const cnDrawer = cn('Drawer');

export type DrawerManager = (params?: { close?: boolean }) => void;

const Drawer: React.FC<IProps> = ({children, className, containerId, anchor = 'top', setupConfig, clickOutside}) => {
    const [animationState, setAnimationState] = React.useState<AnimationState>('CLOSED');
    const ref = useOnclickOutside(() => animationState === 'OPENED' && clickOutside?.());

    React.useEffect(() => {
        setupConfig(() => ({close} = {}) => {
            setAnimationState(close ? 'CLOSING' : 'OPENING');
        });
    }, []);

    const notClosed = animationState !== 'CLOSED';

    React.useEffect(() => {
        let timerId;

        if (animationState === 'CLOSING') {
            timerId = setTimeout(() => setAnimationState('CLOSED'), 300);
        } else if (animationState === 'OPENING') {
            setAnimationState('OPENED');
        }

        return () => timerId && clearTimeout(timerId);
    }, [animationState]);

    return <>
        {notClosed && (
            <Portal containerId={containerId}>
                <div ref={ref} className={cnDrawer({anchor}, [className, `${animationState === 'OPENED' ? 'open' : ''}`])}>
                    {children}
                </div>
            </Portal>
        )}
    </>;
};

export default Drawer;
