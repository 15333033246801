import { createSelector } from 'reselect';
import { getDistance, orderByDistance } from 'geolib';
import { reduce, values, path } from 'ramda';

import { createKeyMatchSelector } from '../../shared/db/general-selectors';
import { getElipsisPoints } from '../../shared/helpers';
import { iFullStoreState } from '../../shared/interfaces';

const getRegions = (state: iFullStoreState) => state.regions.vis;
const getPaths = (state: iFullStoreState) => state.paths.vis;
const getSearchMarkers = (state: iFullStoreState) => state.gmap.searchMarkers;

export const getPathIdsSelector = createSelector(getPaths, paths => Object.keys(paths));

export const getSearchMarkersSelector = createKeyMatchSelector(getSearchMarkers, m => values(m));

const getCenterLat = (state:iFullStoreState, { id }) => path(['regions', 'vis', id, 'center', 'lat'], state);
const getCenterLng = (state:iFullStoreState, { id }) => path(['regions', 'vis', id, 'center', 'lng'], state);
const getWidth = (state:iFullStoreState, { id }) => path(['regions', 'vis', id, 'width'], state);
const getHeight = (state:iFullStoreState, { id }) => path(['regions', 'vis', id, 'height'], state);

export const getPathsSelector = createSelector(
    getCenterLat, getCenterLng, getWidth, getHeight,
    (lat, lng, width, height) => getElipsisPoints({lat, lng}, width, height)
)

export const getMinMax = createSelector(
    getPathsSelector, getCenterLat, getCenterLng,
    (path, latitude, longitude) => {
        let sorted;
        try {
            sorted = orderByDistance({ latitude, longitude }, path.map(p => ({
                latitude: p.lat(),
                longitude: p.lng(),
            })))
        } catch (e) {
            return []
        }

        const minDistance = getDistance({latitude, longitude}, sorted[0]);
        const maxDistance = getDistance({latitude, longitude}, sorted[sorted.length - 1]);

        return [minDistance, maxDistance];
    }
);

export const getMinMaxLengthsAcrossFt = createSelector(
    getMinMax,
    ([minDistance, maxDistance]) => [minDistance * 3.280839895 * 2, maxDistance * 3.280839895 * 2],
);


export const getNorthEast = createSelector(
    getPathsSelector,getCenterLat, getCenterLng,
    (path, centerLat, centerLng) => {
        const leftRight = reduce((acc, p) => !acc || Math.abs(p.lat() - centerLat) < Math.abs(acc.lat() - centerLat) && p.lng() > centerLng ? p : acc, false as any, path)
        const topBottom = reduce((acc, p) => !acc || Math.abs(p.lng() - centerLng) < Math.abs(acc.lng() - centerLng) && p.lat() > centerLat ? p : acc, false as any, path)

        return {
            north: {
                longitude: centerLng,
                latitude: topBottom.lat()
            },
            east: {
                longitude: leftRight.lng(),
                latitude: centerLat
            }
        }
    }

)
export const topMarkerSelector = createSelector(
    getNorthEast,
    r => !r ? false : r.north
)

export const rightMarkerSelector = createSelector(
    getNorthEast,
    r => !r ? false : r.east
)
