import React from "react";
import {MinimalTile} from "../../../../general";
import styles from './LocalTile.module.scss';

const LocalTile = ({ title, children }) => (
    <MinimalTile title={title} className={styles.tile}>
        {children}
    </MinimalTile>
)

export default LocalTile;