import { Map, Record } from 'immutable';
import { AnyAction } from 'redux';

import {
    SET_ALL_DEVICES_INFO, setAllDevicesInfo,
    SET_DEVICE_INFO, setDeviceInfo,
    UPDATE_DEVICE_LOCATION, updateDeviceLocation,
} from './AC';
import { iDeviceDetails, iDevicePing } from '../../../shared/interfaces';

export type DevicesDetailsContainer = Map<string, iDeviceDetails>;
export type DevicesLastPingContainer = Map<string, iDevicePing>;

const defaultDevicesData = {
    devicesDetails: Map<string, iDeviceDetails>(),
    // null indicates a device has been activated but has not reported a message
    devicesLastPing: Map<string, iDevicePing | null>(),
};
const DevicesDataRecordFactory = Record<typeof defaultDevicesData>(defaultDevicesData, 'DevicesDataRecord');

const DevicesDataRecord = DevicesDataRecordFactory();

export type IReducerDevicesData = typeof DevicesDataRecord;

const initialState: IReducerDevicesData = DevicesDataRecord;

export default (state = initialState, action: AnyAction): IReducerDevicesData => {
    switch (action.type) {
        case SET_ALL_DEVICES_INFO: {
            const {devicesDetails} = (action as ReturnType<typeof setAllDevicesInfo>).payload;
            return state.set('devicesDetails', Map(devicesDetails));
        }

        case SET_DEVICE_INFO: {
            const {deviceDetails} = (action as ReturnType<typeof setDeviceInfo>).payload;
            return state.setIn(['devicesDetails', deviceDetails.id], deviceDetails);
        }

        case UPDATE_DEVICE_LOCATION: {
            const {deviceId, ping} = (action as ReturnType<typeof updateDeviceLocation>).payload;
            return state.setIn(['devicesLastPing', deviceId], ping);
        }

        default:
            return state;
    }
}
