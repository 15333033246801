import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    Fragment,
} from 'react';
import moment from 'moment';
import {localStorage} from "../../shared/storage";
import { logout as logoutAPI } from "../../api";
import {history, store} from "../../stores/store";
import {fbapp} from "../../shared/firebase";

const SessionTimeout = () => {
    const [events, setEvents] = useState(['click', 'load', 'scroll', 'keydown']);
    const isAuthenticated = localStorage.get('active-client');

    let timeStamp;
    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();

    useEffect(() => {
        let timeString =  localStorage.get('lastTimeStamp');
        activityChecker(timeString);
    }, []);

    let logout = () => {
        fbapp.auth().signOut();
        localStorage.clear();
        logoutAPI().finally(() => {
            document.location.href = '/';
        });
    }

    let activityChecker = (timesString) => {
        if(!timesString) return;
        const maxTime = 1;

        const diff = moment.duration(moment().diff(moment(timesString)));
        const minPast = diff.hours();

        if (minPast >= maxTime) {
            clearInterval(warningInactiveInterval.current);
            localStorage.forget('lastTimeStamp');
            logout();
        }
    }

    let timeChecker = () => {
        // @ts-ignore
        startTimerInterval.current = setTimeout(() => {
            let storedTimeStamp = localStorage.get('lastTimeStamp');
            warningInactive(storedTimeStamp);
        }, 60000);
    };

    let warningInactive = (timeString) => {
        clearTimeout(startTimerInterval.current);

        // @ts-ignore
        warningInactiveInterval.current = setInterval(() => {
            activityChecker(timeString);
        }, 1000);
    }

    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        if(isAuthenticated) {
            timeStamp = moment();
            localStorage.set('lastTimeStamp', timeStamp);
        } else {
            clearInterval(warningInactiveInterval.current);
            localStorage.forget('lastTimeStamp');
        }
        timeChecker();
    }, [isAuthenticated]);

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });

        timeChecker();

        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, resetTimer);
            });

            clearTimeout(startTimerInterval.current);
        };
    }, [resetTimer, events, timeChecker]);

    return <Fragment />;
}

export default SessionTimeout;