export const readTextFile = (file: File): Promise<string> => {
  const fileReader = new FileReader();
  fileReader.readAsText(file);

  return new Promise((resolve, reject) => {
    fileReader.onloadend = (e) => {
      const result = e.target.result as string;
      resolve(result)
    }
    fileReader.onerror = (e) => {
      reject(e);
    }
  })
}
