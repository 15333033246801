import React from "react";
import HeaderPlanList from "./HeaderPlanList";
import { IModalHeader } from "./interfaces";
import "./ModalHeader.scss";

const ModalHeader = ({
  row,
  closeWindow,
  activationDeviceSerial,
  cancel,
}: IModalHeader) => {

  return (
    <>
      <div className="modal__header">
        <div className="modal__header-row">
          <h2>
            {row?.device_status == 'active'
              ? "CHANGE DEVICE ACTIVATION"
              : "NEW DEVICE ACTIVATION"}
          </h2>
          <button onClick={() => closeWindow()}>X</button>
        </div>
      </div>
      {(row || activationDeviceSerial) && (
        <div className="modal__device">
          <p className="modal__device-title">
            DEVICE {row?.serial || activationDeviceSerial}
          </p>
          {row && <HeaderPlanList currentPlans={row.subscriptions.devicePlans} deviceStatus={row.subscriptionStatus} />}
          {cancel.length > 0 && <HeaderPlanList currentPlans={cancel} deviceStatus={row.subscriptionStatus} />}
        </div>
      )}
    </>
  );
};

export default ModalHeader;
