import { iDevicePing } from '../../../shared/interfaces';

export const SET_DEVICE_ROUTE = 'SET_DEVICE_ROUTE';
export const SET_REPORT_TRIP = 'SET_REPORT_TRIP';
export const TOGGLE_SHOW_REPORT_TRIP = 'TOGGLE_SHOW_REPORT_TRIP';
export const HIDE_REPORT_TRIPS = 'HIDE_REPORT_TRIPS';
export const REMOVE_REPORT_TRIPS = 'REMOVE_REPORT_TRIPS';
export const HIDE_POINT = 'HIDE_POINT';
export const SHOW_POINT = 'SHOW_POINT';
export const CLEAR_DEVICE_ROUTE = 'CLEAR_DEVICE_ROUTE'
export const SET_ACTUAL_TRIPS = 'SET_ACTUAL_TRIPS';

export const setDeviceRoute = (deviceId: string, points: Array<iDevicePing>) => ({
    type: SET_DEVICE_ROUTE,
    payload: {
        deviceId,
        points,
    },
});

export const clearDeviceRoute = (deviceId: string) => ({
    type: CLEAR_DEVICE_ROUTE,
    payload: {
        deviceId,
    },
});

export const setReportTrip = (deviceId: string, tripId: string, points: Array<iDevicePing>, show: boolean = true) => ({
    type: SET_REPORT_TRIP,
    payload: {
        deviceId,
        tripId,
        points,
        show,
    },
});

export const toggleShowReportTrip = (deviceId: string, tripId: string, show: boolean | string) => ({
    type: TOGGLE_SHOW_REPORT_TRIP,
    payload: {
        deviceId,
        tripId,
        show,
    },
});

export const hidePoint = (deviceId: string, tripId: string, pointId: string) => ({
    type: HIDE_POINT,
    payload: {
        deviceId,
        tripId,
        pointId
    },
});

export const showPoint = (deviceId: string, tripId: string, pointId: string) => ({
    type: SHOW_POINT,
    payload: {
        deviceId,
        tripId,
        pointId
    },
});

export const hideReportTrips = () => ({
    type: HIDE_REPORT_TRIPS,
});

export const removeReportTrips = () => ({
    type: REMOVE_REPORT_TRIPS,
});

