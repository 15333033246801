import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import Marker from 'react-google-maps/lib/components/Marker';
import Rectangle from 'react-google-maps/lib/components/Rectangle';

import { default as C } from '../../shared/constants';
import { icoSize, getTopLat, getRightLng, getRadiusHeight, getRadiusWidth, shortLocation } from '../../shared/helpers';
import { iLocation, iDisplayRegion, iFullStoreState, iLongLocation } from '../../shared/interfaces';
import { Actions as RegionActions } from '../../stores/reducers/map-region-reducers';
import { BaseComponent } from '../../shared/BaseComponent';
import { FaVector } from '../elements/fa';
import { topMarkerSelector, rightMarkerSelector } from '../../stores/reducers/gmap-selectors';
import { getMapZoom as getMapZoomFromStore } from '../../stores/reducers/gmap-reducers';
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { MouseTooltip } from '../MouseTooltip';


interface iProps {
    id: string;
}

type iPropsFromStore = {
    region?: iDisplayRegion;
    getMapZoom: () => number;
    topHandle?: iLocation;
    rightHandle?: iLocation;
}

type iFullProps = iProps & iPropsFromStore & DispatchProp;

const mapStateToProps = (state: iFullStoreState, ownProps: iProps): iPropsFromStore => {
    const getMapZoom = (): number => getMapZoomFromStore(state.gmap);
    const region = state.regions.vis[ownProps.id];
    let ret: iPropsFromStore = {
        region,
        getMapZoom,
    };

    if (region?.editable) {
        ret = {
            ...ret,
            topHandle: shortLocation(topMarkerSelector(state, ownProps) as iLongLocation),
            rightHandle: shortLocation(rightMarkerSelector(state, ownProps) as iLongLocation),
        }
    }

    return ret;
};

type iFullState = {
    isTooltipVisible: boolean;
}

export default connect(mapStateToProps)
(class extends BaseComponent<iFullProps, iFullState> {

    state = {
        isTooltipVisible: false
    }

    showTooltip = () => {
        this.setState({isTooltipVisible: true});
    };
    hideTooltip = () => {
        this.setState({isTooltipVisible: false});
    };

    getNameBlock = (lat, lng, title) => {
        return (
            <InfoBox
                options={{
                    disableAutoPan: true,
                    zIndex: 4,
                    boxStyle: {
                        zIndex: 4,
                        padding: '4px 6px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        backgroundColor: '#800',
                        color: '#fff',
                        opacity: '.9',
                        fontSize: '16px',
                    },
                    alignBottom: true,
                    closeBoxURL: '',
                    pixelOffset: new google.maps.Size(45, -12),
                }}
                defaultPosition={new google.maps.LatLng(lat, lng)}
            >
                <div>{title}</div>
            </InfoBox>
        )
    }

    debugRender = () => {
        const {id, topHandle, rightHandle, dispatch, region, getMapZoom} = this.props;

        if (!region) return null;

        const latDiff = getTopLat(region.center, region.height) - region.center.lat;
        const lngDiff = getRightLng(region.center, region.width) - region.center.lng;

        const bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(region.center.lat - latDiff, region.center.lng - lngDiff),
            new google.maps.LatLng(region.center.lat + latDiff, region.center.lng + lngDiff)
        );

        const size = icoSize(getMapZoom());

        const {hideName = false, hideIcon = false, hideShadows = false, editable, name} = region;

        const showPolygon = editable || !hideShadows;
        const showIcon = !editable && !hideIcon;
        const showName = name && (editable || !hideName);

        const showTooltip = this.props.region.hideName && !this.props.region.editable;
        const poligonHandlers = showTooltip? {
            onMouseOver: this.showTooltip,
            onMouseOut: this.hideTooltip
        }: {};
        const iconHandlers = showTooltip && hideShadows? {
            onMouseOver: this.showTooltip,
            onMouseOut: this.hideTooltip
        }: {};
        
        return (

            <div>
                {
                    showTooltip && (
                        <MouseTooltip
                            title={name} 
                            isVisible={this.state.isTooltipVisible}
                        />
                    )
                }
                {
                    showPolygon && (
                        <Rectangle
                            {...poligonHandlers}
                            bounds={bounds}
                            options={{strokeWeight: 1.5, strokeColor: region.color, fillColor: region.color, fillOpacity: C.fenceFillOpacity}}
                        />
                    )
                }

                {/* Top Handle */}
                {!region.editable ? null :
                    <>
                        <Marker
                            position={topHandle}
                            icon={{
                                url: "images/h-bars.png",
                                size: new google.maps.Size(70, 70),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(size/2, size/2),
                                scaledSize: new google.maps.Size(size, size)
                            }}
                            cursor="crosshair"
                            draggable
                            onDragEnd={function(this: google.maps.Marker, x) {
                                // lock it on axis
                                this.setPosition(new google.maps.LatLng(
                                    Math.max(x.latLng.lat(), region.center.lat),
                                    region.center.lng
                                ))
                            }}
                            onDrag={function(this: google.maps.Marker, x) {
                                // lock it on axis
                                this.setPosition(new google.maps.LatLng(
                                    Math.max(x.latLng.lat(), region.center.lat),
                                    region.center.lng
                                ))
                                dispatch(RegionActions.UPDATE_REGION_HEIGHT(
                                    id,
                                    getRadiusHeight(region.center, Math.max(x.latLng.lat(), region.center.lat))
                                ))
                            }}
                        />
                        {this.getNameBlock(region.center.lat, region.center.lng, region.name)}
                    </>
                }
                {
                    showIcon && region.ico && (
                        <Marker
                            {...iconHandlers}
                            position={region.center}
                            icon={{
                                [region.ico.fa ? 'path' : 'url']: region.ico.url || FaVector(region.ico.fa),

                                size: new google.maps.Size(size, size),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(size/2, size/2),

                                ...(region.ico.url ? {} : {
                                    strokeColor: '#000',
                                    strokeOpacity: 1,
                                    strokeWeight: .25,
                                    fillColor: region.color,
                                    fillOpacity: .95,
                                    anchor: new google.maps.Point(215, 300),
                                    scale: .05 - ((26 - size) / 1000),
                                })
                                } as any}
                        />
                    )
                }

                {   
                    showName && this.getNameBlock(region.center.lat, region.center.lng, region.name)
                }

                {/* Move Handle */}
                {!region.editable ? null :
                    <>
                        <Marker
                            position={region.center}
                            draggable
                            onDrag={({latLng}) => { dispatch(RegionActions.UPDATE_REGION_CENTER(id, {lat: latLng.lat(), lng: latLng.lng()}))
                                {/* return this.setState(s => update(s, {
                                    oval: {
                                        center: {$set: {lat: latLng.lat(), lng: latLng.lng()}}
                                    }
                                })) */}
                            }}
                            cursor="crosshair"
                            icon={{
                                url: "images/circled_dot.png",
                                size: new google.maps.Size(70, 70),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(size/2, size/2),
                                    scaledSize: new google.maps.Size(size, size)
                            }}
                        />
                        {this.getNameBlock(region.center.lat, region.center.lng, region.name)}
                    </>
                }

                {/* Right Handle */}
                {!region.editable ? null :
                    <Marker
                        position={rightHandle}
                        icon={{
                            url: "images/v-bars.png",
                            size: new google.maps.Size(70, 70),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(size/2, size/2),
                            scaledSize: new google.maps.Size(size, size)
                        }}
                        cursor="crosshair"
                        draggable
                        onDragEnd={function(this: google.maps.Marker, x) {
                            // lock it on axis
                            this.setPosition(new google.maps.LatLng(
                                rightHandle!.lat,
                                Math.max(x.latLng.lng(), region.center.lng)
                            ))
                        }}
                        onDrag={function(this: google.maps.Marker, x) {
                            // lock it on axis and prevent inverting
                            this.setPosition(new google.maps.LatLng(
                                rightHandle!.lat,
                                Math.max(x.latLng.lng(), region.center.lng)
                            ))
                            dispatch(RegionActions.UPDATE_REGION_WIDTH(
                                id,
                                getRadiusWidth(region.center, Math.max(x.latLng.lng(), region.center.lng))
                            ))
                        }}
                    />
                }
            </div>
        )
    }
})
