import React from "react";
import InvoicesTable from "./InvoicesTable";
import { billigTablesApiHOC } from "../BillingApi/BillingTablesApiHOC";

const BillingInvoices = () => {
  const Invoices = billigTablesApiHOC(InvoicesTable);
  return <Invoices />;
};

export default BillingInvoices;
