import React from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import {connect, DispatchProp} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {always, cond, equals, pipe, range, T} from 'ramda';

import {history} from '../../../stores/store';
import C from '../../../shared/constants';
import {Checkbox} from '../../elements';
import {isMobile, targetChecked, targetVal} from '../../../shared/helpers';
import {iReportDetails} from '../../../stores/reducers/report-reducers';
import {iFullStoreState, iList, iPerson, ItemType, UserAuth} from '../../../shared/interfaces';
import {BaseComponent} from '../../../shared/BaseComponent';
import {addScheduledReport} from '../../../shared/db/report-db';
import Dialog, {DialogConfigSetter} from '../../Dialog';
import "./create-report.scss";
import {DashboardSize, IReducerDashboardInfo} from "../../../stores/reducers/dashboardInfo";
import {ReportFiltersLabels} from "../../ReportFiltersLabels";
import {IReportFiltersLabelsType} from "../../ReportFiltersLabels/types";
import {OptionElement, SelectElement} from "../../SelectElement";
import {Button, ButtonSize, ButtonStyles} from "../../Button";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { GMapModalAC, GMapModalContent } from '../../../stores/reducers/gMapModal/AC';
import { selectAllEmailsToSendReport, selectCustomEmailsToSendReport, selectPeopleToSendEmail, selectPeopleToSendReportKeys } from '../../../stores/reducers/report-selectors';

const modalWidth = 467;

interface iState {
    details: {
        frequency: 'weekly' | 'monthly' | 'daily' | 'anually',
        delivery?: 'weekly' | 'monthly' | 'daily' | 'anually',
        frequencyDays: { [day: number]: boolean },
        frequencyMonths?: { month: number, day: number }
        rangeDays: number,
        format: 'pdf'|'csv',
    },
}

const WeekDay = ({ title, on, onChange }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>{title}</span>
        <input type="checkbox" checked={on} onChange={onChange} />
    </div>
)

const WEEK_DAYS = ['S', 'M', 'T', 'W', 'TH', 'F', 'S'];

const WeekDays = ({selected, onChange}: { selected: number[]; onChange: (dayIdx) => void; }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {WEEK_DAYS.map((day, index) =>
                <WeekDay key={index} title={day} on={selected.some(equals(index))} onChange={() => onChange(index)} />
            )}
        </div>
    )
}

const now = moment();
const months = range(0, 11).map(k => now.clone().month(k).format('MMMM'));
const getMonthDayMoments = monthId => range(0, now.month(monthId).daysInMonth()).map(d => now.clone().month(monthId).startOf('month').add(d, 'days'));

const sMarg = { marginTop: 10, marginBottom: 10 }

const MonthDay = ({ month, day, onChange, hideMonth = false }) => {
    const monthsOptions = months.map((m, idx) => ({value: idx, text: m}));
    const daysOptions = [...getMonthDayMoments(month)
        .map((d, idx) =>
            ({
                value: Number(d.startOf("month").add(idx, "days").format("DD")),
                text: d.format("Do")
            })
        )];
    const handleMonthSelectClick = ({ target: { value } }) => onChange({ month: Number(value), day: 1 });
    const handleDaySelectClick = ({ target: { value } }) => onChange({ month, day: Number(value) });
    return (
        <div>
            <SelectElement
                options={monthsOptions}
                value={month}
                Component={OptionElement}
                onChange={handleMonthSelectClick}
            />
            <div style={{height: "10px"}}></div>
            <SelectElement
                options={daysOptions}
                value={day}
                Component={OptionElement}
                onChange={handleDaySelectClick}
            />
        </div>
    )
}

type iPropsFromState = {
    peopleToSendReport: iList<iPerson>;
    customEmailsToSendReport: string[];
    emailsToSendReport: string[];
    details: iReportDetails;
    authUser: UserAuth;
    dashboardInfo: IReducerDashboardInfo
}

type iProps = iPropsFromState & DispatchProp;

export default connect((state: iFullStoreState): iPropsFromState => ({
    peopleToSendReport: selectPeopleToSendEmail(state),
    customEmailsToSendReport: selectCustomEmailsToSendReport(state),
    emailsToSendReport: selectAllEmailsToSendReport(state),
    details: state.report.details,
    authUser: state.auth.user!,
    dashboardInfo: state.dashboardInfo,
}))(
class CreateReport extends BaseComponent<iProps, iState> {
    private dialog: DialogConfigSetter;
    state = {
        ...this.state,
        details: {
            delivery: 'monthly',
            frequency: 'daily',
            rangeDays: 1,
            frequencyDays: {},
            frequencyMonths: { month: 0, day: 1 },
            format: 'pdf',
        },
    } as iState;
    setupDialog = (callBack: () => DialogConfigSetter): void => {
        this.dialog = callBack();
    }

    addFieldEdit = field => ({ target: { value } }) => this.setState(s => update(s, {
        details:
            {
                [field]: { $set: value }
            }
    }))

    __saveReport = async () => {
        const { details: reportDeets } = this.props;
        const { details } = this.state as iState;

        addScheduledReport(this.props.authUser)({
            type: reportDeets.reportType,
            deliverySchedule: {
                schedule: details.delivery,
                frequencyDays: details.frequencyDays,
                frequencyMonths: details.frequencyMonths,
            },
            filters: this.props.details.filters,
            format: details.format,
            reportRangeDays: details.rangeDays,
            sendTo: {
                people: Object.values(this.props.peopleToSendReport).map(p => p.id),
                emails: this.props.customEmailsToSendReport
            }
        })

        history.push('/reports/scheduled');
    }

    debugRender = () => {
        const { details: reportDetails, dashboardInfo, dispatch, peopleToSendReport, customEmailsToSendReport } = this.props;
        const { details } = this.state as iState;

        const hasDeliveryOptinos = ['anually', 'monthly', 'weekly'].some(equals(details.delivery));

        const hideMonth = details.delivery == 'monthly';
        return (
            <>
                <div className={`create-report-container ${dashboardInfo.size === DashboardSize.OPEN? "dashboard-open": ""}`}>
                    <PerfectScrollbar>
                        <div className={`create-report ${dashboardInfo.size === DashboardSize.OPEN? "dashboard-open": ""}`}>
							{(isMobile || window["cordova"]) && <h5>Create report</h5>}
                            <div className={"create-report-col create-report-details"}>
                                { !(isMobile || window["cordova"]) && dashboardInfo.size === DashboardSize.OPEN_FULL && (
                                    <div className="create-report-row">
                                        <div className="create-report-header">
                                            <h5>Create report:</h5>
                                        </div>
                                    </div>
                                )}
                                <div className={"create-report-row"}>
                                    <div className="create-report-row-title">Type</div>
                                    <div className={"create-report-row-value"}>
                                        <Button
                                            styleType={
                                                details.format === "pdf"
                                                ? ButtonStyles.BLACK_WHITE
                                                : ButtonStyles.GRAY_INACTIVE
                                            }
                                            size={ButtonSize.MD}
                                            title={"PDF"}
                                            onClick={this.stateSetter({details: {...details, format: 'pdf'}})}
                                        ><b>PDF</b>
                                        </Button>
                                        &nbsp;
                                        <Button
                                            styleType={
                                                details.format === "csv"
                                                ? ButtonStyles.BLACK_WHITE
                                                : ButtonStyles.GRAY_INACTIVE
                                            }
                                            size={ButtonSize.MD}
                                            title={"PDF"}
                                            onClick={this.stateSetter({details: {...details, format: 'csv'}})}
                                        ><b>CSV</b>
                                        </Button>
                                    </div>
                                </div>
                                <div className={"create-report-row"}>
                                    <div className={"create-report-row-title"}>Device / Person</div>
                                    <div className={"create-report-row-value"}>{reportDetails.devicePerson == ItemType.device ? 'Device' : 'Person'}</div>
                                </div>
                                <div className={"create-report-row"}>
                                    <div className={"create-report-row-title"}>Travel / Static</div>
                                    <div className={"create-report-row-value"}>{reportDetails.reportType == 'static' ? 'Satic' : 'Travel'}</div>
                                </div>


                                <div className="create-report-row">
                                    <div className="create-report-row-title">Filters</div>
                                    <div className="create-report-row-value">
                                        <ReportFiltersLabels
                                            type={IReportFiltersLabelsType.ALERTS}
                                            render={({item}) => <span>{item}</span>}
                                            defaultLabel={"All filters"}
                                        />
                                    </div>
                                </div>

                                <div className="create-report-row">
                                    <div className="create-report-row-title">Labels</div>
                                    <div className="create-report-row-value">
                                        <ReportFiltersLabels
                                            type={IReportFiltersLabelsType.LABELS}
                                            render={({item}) => <span>{item}</span>}
                                            defaultLabel={"All labels"}
                                        />
                                    </div>
                                </div>

                                <div className="create-report-row">
                                    <div className="create-report-row-title">Tags</div>
                                    <div className="create-report-row-value">
                                        <ReportFiltersLabels
                                            type={IReportFiltersLabelsType.TAGS}
                                            render={({item}) => <span>{item}</span>}
                                            defaultLabel={"All tags"}
                                        />
                                    </div>
                                </div>

                                <div className="create-report-row">
                                    <div className="create-report-row-title">Devices</div>
                                    <div className="create-report-row-value">
                                        <ReportFiltersLabels
                                            type={IReportFiltersLabelsType.DEVICES}
                                            render={({item}) => <span>{item}</span>}
                                            defaultLabel={"All devices"}
                                        />
                                    </div>
                                </div>

                                <div className="create-report-row">
                                    <div className="create-report-row-title">People</div>
                                    <div className="create-report-row-value">
                                        <ReportFiltersLabels
                                            type={IReportFiltersLabelsType.PERSONS}
                                            render={({item}) => <span>{item}</span>}
                                            defaultLabel={"All persons"}
                                        />
                                    </div>
                                </div>

                                <div className="create-report-row">
                                    <div className="create-report-row-title">Send to</div>
                                    <div className="create-report-row-value">
                                        <div className="create-report-row-label">
                                            {[...Object.values(peopleToSendReport).map(p => p.displayName), ...customEmailsToSendReport].join(", ")}
                                        </div>
                                        <Button className="create-repport-add-send-to-btn" styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} onClick={() =>{
                                            dispatch(GMapModalAC.showModal({contentType: GMapModalContent.SELECT_SEND_TO}));
                                        }}>Add</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="create-report-col create-report-select">
                                { dashboardInfo.size === DashboardSize.OPEN && (
                                    <div className="create-report-row">
                                        <div className="create-report-header">
                                            <h5>Create report:</h5>
                                        </div>
                                    </div>
                                )}
                                {!hideMonth ? null : (
                                    <div className={"create-report-note"}>
                                        <b>Note:</b> Picking a day which a month doesn't contain, will cause that month's report to not be delivered. (eg. Feb 31st)
                                    </div>
                                )}
                                <div>
                                    <div className={"create-report-select-section-title"}>Delivery</div>
                                    <SelectElement
                                        options={[
                                            { value: "weekly", text: "Weekly"},
                                            { value: "monthly", text: "Monthly" },
                                            { value: "daily", text: "Daily" },
                                            { value: "anually", text: "Anually"}
                                        ]}
                                        value={details.delivery}
                                        Component={OptionElement}
                                        onChange={this.addFieldEdit('delivery')}
                                    />
                                </div>



                                {!hasDeliveryOptinos ? null :
                                    <div className="create-report-select-section" >
                                        <div className={"create-report-select-section-title"}>Excluded day</div>
                                        {details.delivery != 'weekly' ? null :
                                            <WeekDays
                                                selected={Object.keys(details.frequencyDays).filter(k => details.frequencyDays[k]).map(Number)}
                                                onChange={idx => this.setState(s => update(s, { details: { frequencyDays: { $toggle: [idx] } } }))}
                                            />
                                        }

                                        {details.delivery != 'anually' && details.delivery != 'monthly' ? null :
                                            <MonthDay
                                                day={details.frequencyMonths.day}
                                                month={details.frequencyMonths.month}
                                                hideMonth={details.delivery == 'monthly'}
                                                onChange={$set => this.setState(s => update(s, { details: { frequencyMonths: { $set } } }))}
                                            />
                                        }
                                    </div>
                                }
                                <div title="Range" className="create-report-select-section">
                                    <div className={"create-report-select-section-title"}>Range</div>
                                    <SelectElement
                                        options={[
                                            { value: "weekly", text: "Weekly" },
                                            { value: "monthly", text: "Monthly" },
                                            { value: "daily", text: "Daily" },
                                            { value: "anually", text: "Anually" }
                                        ]}
                                        value={details.frequency}
                                        Component={OptionElement}
                                        onChange={this.addFieldEdit("frequency")}
                                        />
                                </div>
                                <div>
                                    <Calendar
                                        type={details.frequency}
                                        dayCount={details.rangeDays}
                                        onChange={$set => this.setState(s => update(s, {
                                            details: {
                                                rangeDays: { $set }
                                            }
                                        }))} />
                                </div>
                            </div>

                        </div>

                    </PerfectScrollbar>
                </div>
                <Dialog setupConfig={this.setupDialog} />
                <CreateReportActions saveReport={this.__saveReport}/>
            </>
        )
    }
})

const CreateReportActions = ({saveReport}) => {
    const history = useHistory();
    const handleBackClick = () => history.goBack();
    return (
        <div className="create-report-actions">
			{!(isMobile || window["cordova"]) && 
			<div className="create-report-actions-left">
				<Button
					styleType={ButtonStyles.BLACK_WHITE}
					size={ButtonSize.MD}
					onClick={handleBackClick}
				>
					Back
				</Button>
			</div>
			}
            <div className="create-report-actions-right">
                <Link to={"/reports/scheduled"}>
                    <Button
                        className="but white but-28px"
						styleType={(isMobile || window["cordova"]) ? ButtonStyles.WHITE_GRAY : ButtonStyles.GRAY_INACTIVE}
                        size={(isMobile || window["cordova"]) ? ButtonSize.LG : ButtonSize.MD}
                    >
                        Cancel
                    </Button>
                </Link>
                <Button
                    styleType={ButtonStyles.ORANGE_WHITE}
                    size={(isMobile || window["cordova"]) ? ButtonSize.LG : ButtonSize.MD}
                    onClick={saveReport}
                >
                    Apply
                </Button>
            </div>
        </div>
    )
}

interface iCalProps { onChange: ((dayCount: number) => any), dayCount: number, type: 'weekly' | 'monthly' | 'daily' | 'anually' }
const Calendar = ({ type, dayCount, onChange }: iCalProps) => {
    if (type == 'daily') {
        return <div><span className={"color-inactive"}>Report will include previous day's data</span></div>;
    }

    const maxDays = {
        weekly: 7,
        monthly: 28,
        yearly: 365
    }[type];

    const typeTxt = cond([
        [equals('weekly'), always('week')],
        [equals('monthly'), always('month')],
        [equals('anually'), always('year')],
        [T, always('')],
    ])(type)

    return <div>
        <div> Include previous
            <input
                type="number"
                className="mas-input"
                style={{ ...C.inputCss, width: 55, lineHeight: '11px', marginLeft: 5, marginRight: 5 }}
                value={dayCount < 1 ? '' : dayCount}
                disabled={dayCount < 1}
                max={7}
                min={1}
                onChange={pipe(targetVal, onChange)}
            /> days in report.
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
                id={'start'}
                checked={dayCount == 0}
                onChange={pipe(targetChecked, checked => onChange(checked ? 0 : 1))}
            />
            From start of {typeTxt} in report
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
                id={'entire'}
                checked={dayCount == -1}
                onChange={pipe(targetChecked, checked => onChange(checked ? -1 : 1))}
            />
            Include one full {typeTxt} in report
        </div>
    </div>
}
