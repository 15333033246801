import React from 'react';
import { IconSelect } from '../../components/modals/icon-chooser';

export interface SelectIconContext {
    selectIcon: IconSelect | undefined;
    setSelectIcon: (selectIcon: () => IconSelect) => void;
}

const DEFAULT_VALUE = {
    selectIcon: undefined,
    setSelectIcon: (selectIcon: () => IconSelect) => {},
};

export const selectIconContext = React.createContext<SelectIconContext>(DEFAULT_VALUE);
