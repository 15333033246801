import React, {useState} from 'react';

import LoginForm from '../../forms/LoginForm';
import RequestPasswordReset from '../../forms/RequestPasswordReset';
import MasTrackLogoIcon from '../../SVG-components/mastrack_logo_new';

import './styles.scss';
import NewPassword from '../NewPassword';
import BackIcon from '../../SVG-components/left_chevron';
import SignUpForm from "../../forms/SignUpForm";

const LoginMobile = () => {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [signUp, setSignUp] = useState(false);
    const [response, setResponse] = useState(null);
    const showResetPasswordPage = window.location.href.indexOf('reset-password') !== -1;

    const getForm = () => {
        if (forgotPassword) {
            return <>
                    <div className="description">Request password reset</div>
                    <RequestPasswordReset goBack={() => setForgotPassword(!forgotPassword)} setResponse={(res) => setResponse(res)} />
                   </>
        } else if (signUp) {
            return <>
                    <div className="description">Please sign up to view tracking information</div>
                    <SignUpForm goBack={() => setSignUp(!signUp)} setResponse={(res) => setResponse(res)}/>
                   </>
        } else {
            return <>
                    <div className="description">Please sign in to view tracking information</div>
                    <LoginForm setForgotPassword={() => setForgotPassword(!forgotPassword)} setSignUp={() => setSignUp(!signUp)}/>
                   </>
        }
    };
    const getPage = () => {
        if(showResetPasswordPage){
            return (
                <>
                    <div className="description">Well done!</div>
                    <div style={{fontSize: '12px',lineHeight: '18px'}}>Please enter your new password:</div>
                    <NewPassword/>
                </>);
        } else if (response) {
            return (
                <>
                    <div className="name">Done:</div>
                    <div className="description">{response}</div>
                    <div
                        style={{fontSize: '11px', cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '20px'}}
                        onClick={() => {
                            setForgotPassword(false);
                            setSignUp(false);
                            setResponse(null);
                        }}
                    >
                        <BackIcon/>
                        Go back
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {getForm()}
                </>
            );
        }
    };

    return (
        <div className="LoginPage">
            <div className="Login" style={{
                backgroundImage: `url(${process.env.REACT_APP_LOGIN_MOBILE_BACKGROUND})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                height: '100%',
                padding: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div className="log-box" style={{
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    padding: '15px',
                    width: '100%'
                }}>
                    {/* <MasTrackLogoIcon style={{width: '100%', height: '120px'}}/> */}
					<img src={process.env.REACT_APP_LOGIN_LOGO_MOBILE} alt="logo-mobile" width="100%" />

                    {getPage()}
                </div>
            </div>
        </div>
    );
};

export default LoginMobile;
