import update from 'immutability-helper';
import { fromPairs } from 'ramda';

import { iPath, iFenceDetails, iLocation } from '../../shared/interfaces';

class ActionsClass {
    public ADD_PATH = (id: string, points: iLocation[], color: string, editable: boolean) => ({type: 'MAP_PATH_ADD', id, points, color, editable});

    public REMOVE_PATH = (id: string) => ({type: 'MAP_PATH_REMOVE', id})
    public CHANGE_RECORD = (id: string, on) => ({type: 'MAP_PATH_RECORD_CHANGE', id, on});
    public PUSH_POINTS = (id: string, points: iLocation[]) => ({type: 'MAP_PATH_PUSH_POINTS', id, points})
    public CLEAR_POINTS = (id: string) => ({type: 'MAP_PATH_CLEAR_POINTS', id})
    public REWIND_BY = (id: string, by: number) => ({type: 'MAP_PATH_REWIND_BY', id, by})
    public FAST_FORWARD_BY = (id: string, by: number) => ({type: 'MAP_PATH_FF_BY', id, by})
    public SWAP_POINTS = (id: string, points: iLocation[]) => ({type: 'MAP_PATH_SWAP_POINTS', id, points});
    public UPDATE_COLOR = (id: string, color: string) => ({type: 'MAP_PATH_COLOR_CHANGE', id, color});

    public STASH_PATHS = () => ({type: 'MAP_STASH_PATHS'})
    public UNSTASH_PATHS = () => ({type: 'MAP_UNSTASH_PATHS'})

    public PLACE_FENCE = (fence: iFenceDetails, path, editable = false) => this.ADD_PATH(fence.id, path, fence.color, editable);
}

export const Actions = new ActionsClass;

export const reducers = (state: iState = { vis: {}, stashed: {} }, action) => {
    if (action.type == 'MAP_PATH_ADD') return update(state, { vis: {
        [action.id]: {$set: {
            ffPoints: [],
            ico: action.ico,
            points: [...action.points],
            width: 1,
            editable: action.editable,
            color: action.color
        } as iPath}
    }});

    if (action.type == 'MAP_STASH_PATHS') return update(state, {
        vis: { $set: {} },
        stashed: { $set: fromPairs(Object.keys(state.vis).map(k => [
            k, update(state.vis[k], { editable: { $set: false } } )
        ] as any)) }
    })

    if (action.type == 'MAP_UNSTASH_PATHS') {
        return update(state, {
            vis: { $set: state.stashed },
            stashed: { $set: {} }
        })
    }

    if (action.type == 'MAP_PATH_RECORD_CHANGE') return update(state, { vis: {
        [action.id]: { recording: { $set: action.on } }
    }})

    if (action.type == 'MAP_PATH_REMOVE') return update(state, { vis: {
        $unset: [action.id]
    }})

    if (action.type == 'MAP_PATH_COLOR_CHANGE') return update(state, { vis: {
        [action.id]: {
            color: {$set: action.color}
        }
    }});

    if (action.type == 'MAP_PATH_PUSH_POINTS') return update(state, { vis: {
        [action.id]: {points: {$push: action.points}, ffPoints: {$set: []}}
    }})

    if (action.type == 'MAP_PATH_CLEAR_POINTS') {
        return update(state, { vis: {
            [action.id]: {
                points: {$set: [state.vis[action.id].points[state.vis[action.id].points.length - 1]]},
                ffPoints: {$unshift: state.vis[action.id].points}
            }
        }});
    }

    if (action.type == 'MAP_PATH_SWAP_POINTS') return update(state, { vis: {
        [action.id]: {
            points: { $set: action.points },
            ffPoints: { $set: [] }
        }
    }})

    if (action.type == 'MAP_PATH_REWIND_BY') {
        return update(state, { vis: {
            [action.id]: {
                points: {
                    $splice: [[state.vis[action.id].points.length - action.by]]
                },
                ffPoints: {
                    $unshift: state.vis[action.id].points.slice(state.vis[action.id].points.length - 1 - action.by)
                }
            }
        }})
    }

    if (action.type == 'MAP_PATH_FF_BY') {
        return update(state, { vis: {
            [action.id]: {
                ffPoints: {
                    $splice: [[0, action.by]]
                },
                points: {
                    $push: state.vis[action.id].ffPoints.slice(0, action.by)
                }
            }
        }})
    }
    return state;
}

export interface iState {
    vis: { [key: string]: iPath },
    stashed: { [key: string]: iPath },
}


