import React from 'react';

import { SelectIconContext } from '../contexts/selectIcon.context';
import { IconSelect } from '../../components/modals/icon-chooser';

export const useSelectIcon = (): SelectIconContext => {
    const [selectIcon, setSelectIcon_] = React.useState<IconSelect | undefined>();

    const setSelectIcon = React.useCallback((selectIcon_: () => IconSelect): void => {
        setSelectIcon_(selectIcon_);
    }, []);

    return {
        selectIcon,
        setSelectIcon,
    };
};
