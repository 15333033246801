import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import instance from "../../../../../api/instance";
import {
  contractCancel,
  contractCancelInfo,
  cancelPlan
} from "../../BillingApi/billingApiRequests";
import BillingButton from "../../Other/BillingButton";
import Loader from "../../Other/Loader";
import PlansCard from "../PlansCard";
import { IServicePlans } from "./interfaces";
import ConfirmModal from "../../Other/ConfirmModal";
import CancelServiceModal from "./CancelServiceModal";
import "./ServicePlans.scss";

const ServicePlans = ({
  row,
  choose,
  allPlans,
  selectedPlan,
  activationDevice,
  activationDeviceId,
  loading,
  setCancel,
  closeWindow,
}: IServicePlans) => {
  const [open, setOpen] = useState(false);
  const [showSwitcher, setShowSwitcher] = useState(false);
  const [cancelLoading,setCancelLoading] = useState(false);
  
  const history = useHistory();

  const planCancel = () => {
    setCancelLoading(true);
    cancelPlan({
      // plan_id,
      device_id: activationDeviceId || row.id,
    }).finally(() => {
      history.push('/billing')
      setCancelLoading(false);
      closeWindow(true);
    });
  };

  return (
    <div className="modal__service">
      {loading ? (
        <Loader size={20} />
      ) : (
        <>
          <div className="modal__header">
            <div className="modal__header-row">
              <h2>
                {row?.isActivated || activationDeviceId
                  ? "SERVICE PLAN OPTIONS"
                  : `THIS DEVICE COMES PRE-PAID WITH THE ${row?.plan_description} SERVICE FOR ONE BILLING TERM.`}
              </h2>
            </div>
            <div>
              {row?.isActivated || activationDeviceId
                ? `This indicates how often the Device will routinely real time information while the vehileis moving. 
                                  i.e with 1 minute interval reporting the tracker will send updated information
                                  every one (1) minute.`
                : `Please enter your credit card details below for future service billing ($0.00 every 1 month).
                                  Note: You can cancel service any time before your first rebill date`}
            </div>
          </div>

          <div className="modal__main">
            {allPlans?.map((variant) => {
              return (
                <PlansCard
                  key={variant.planType}
                  plan={variant}
                  isSelected={variant.planType === selectedPlan.planType}
                  choose={choose}
                />
              );
            })}
          </div>
          {row?.alreadySubscribed && (
            <div className="cancellation-btn">
              <BillingButton
                text="Cancel all service"
                // loading={cancelLoading}
                color="red"
                action={() => setOpen(true)}
              />
            </div>
          )}

          {open && (
            <ConfirmModal
              cancel={setOpen}
              isEOL={row.isEOL}
              planCancel={planCancel}
              cancelLoading={cancelLoading}
              setShowSwitcher={setShowSwitcher}
            />
          )}
          {showSwitcher && (
              <CancelServiceModal cancel={setShowSwitcher} row={row} activationDevice={activationDevice} />
          )}
        </>
      )}
    </div>
  );
};

export default ServicePlans;
