import React from 'react';
import { WeekdayElementProps } from 'react-day-picker';

const DayPickerWeekday = ({weekday, className, localeUtils, locale}: WeekdayElementProps) => {
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);

    return (
        <div className={className} title={weekdayName}>
            {weekdayName.slice(0, 3)}
        </div>
    );
}

export default DayPickerWeekday;
