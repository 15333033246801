import {FingerprintHelper} from "./FingerPrintHelper";

const fingerprintHelper = new FingerprintHelper();

const saveEncryptedToken = (token:string):void => {
    fetch(`${process.env.REACT_APP_API_URL}/api-fingerprint/register-store-token`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({"device_id": window['device'].uuid, "fingerprint_token": token})
    }).then(res => res.json())
        .then(res => {
            if (res.status === "OK") {
            } else {
                console.log("saveEncryptedToken() Error: " + JSON.stringify(res));
            }
        })
}

const doFingerprintAuthentication = (encrData:any):void => {
    const options:any = {
        secret: encrData.secret,
        username: encrData.username
    };
    if (window["device"].platform === "iOS") {
        options.ios = {
            message: "scanning"
        };
    }
    fingerprintHelper.authenticate(options, (error, result) => {
        if (error) {
            console.log("authentication error: " + JSON.stringify(error));
        } else {
            if (window["device"].platform === "iOS") {
                result.token = encrData.secret;
            }
            saveEncryptedToken(result.token);
        }
    });
}

const getFingerprintCredentialsToEncrypt = ():void => {
    fetch(`${process.env.REACT_APP_API_URL}/api-fingerprint/register-get-secret`,{
        method: 'POST',
            headers: {
            Accept: 'application/json',
                'Content-Type': 'application/json',
        },
        body: JSON.stringify({"device_id": window['device'].uuid})
    }).then(res => res.json())
        .then(res => {
        if (res.status === "OK") {
            doFingerprintAuthentication({secret: res.fingerprint_secret, username: res.username});
        }
    })
        .catch(error =>  console.log("error: " + JSON.stringify(error)))
}

export const startFingerprintAuthFlow = ():void => {
    fingerprintHelper.isFingerprintAvailable((error, result) => {
        if (error) {
            console.log("isFingerpringAvailable error: " + error);
        } else {
            console.log("getFingerprintCredentialsToEncrypt");
            getFingerprintCredentialsToEncrypt();
        }
    })
}
