import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { when } from 'ramda';
import moment from 'moment';
import { faTimes } from '@fortawesome/fontawesome-free-solid';

import { Row } from './flex';
import { useReduxPath } from '../../states/redux-state';
import { Fa } from './fa';
import { deferredCall } from '../../shared/helpers';
import { iFullStoreState } from '../../shared/interfaces';

const displayLengthMs = 5000;
const fadeTimeMs = 1000;

export const PremiumFlash = () => {
    const {deviceId} = useParams<{ deviceId: string }>();
    const premiumUntil = useSelector<iFullStoreState, moment.Moment>(state => state.devicesData.devicesDetails.getIn([deviceId, 'premiumUntil']));
    const [hide, setHide] = useState(false)

   const interval = moment(premiumUntil).diff(moment(),'milliseconds')

    const hideAlert = () =>{
        setHide(true)
    }

    if(interval > 0) {
        deferredCall(interval, hideAlert)
    }
  
    if (hide||!premiumUntil || premiumUntil.isBefore(moment())) return null

    return (
        <Row className="flash-modal" style={{...modalCss, ...modalFailCss, padding: 8}}>
            {`Theft Recovery Enabled.  Theft Recovery ends at ${moment(premiumUntil).format('MM/DD/YY h:mm')}`}
        </Row>
    );
}

export const FlashMessage = () => {
    const [flash, setFlash, unsetFlash] = useReduxPath(s => s.general.flashMessage, ['general', 'flashMessage']);
    const [isFading, doFade] = React.useState(false);

    const scheduleFade = () => setTimeout(() => doFade(true), displayLengthMs);
    const scheduleRemoval = () => setTimeout(unsetFlash, fadeTimeMs);


    // schedule the fade if the flash becomes visible
    React.useEffect(() => {
        when(Boolean, scheduleFade, flash)
    }, [flash]);

    // schedule removal if fade is turned onn
    React.useEffect(() => {
        when(Boolean, scheduleRemoval, isFading)
    }, [isFading]);

    const css = {
        ...modalCss,
        ...(!flash ? {} : 'success' in flash ? modalSuccessCss : modalFailCss),
        ...(isFading ? modalFadeCss : {})
    };

    return !flash ? null : (
        <Row className="flash-modal" style={css}>
            <button style={closeBtnStyle} onClick={() => doFade(true)}><Fa icon={faTimes} /></button>
            {flash.message}
        </Row>
    );
}

const modalCss: React.CSSProperties = {
    position: 'relative',
    backgroundColor: '#33333340',
    margin: '0 10%',
    padding: 40,
    opacity: 1
}

const modalFailCss: React.CSSProperties = {
    border: '1px solid #f00',
    backgroundColor: '#ffa0a080',
    display: 'inherit',
    fontWeight: 'bold',
    textAlign: 'center'
}

const modalSuccessCss: React.CSSProperties = {
    border: '1px solid #0f0',
    borderColor: 'green',
    backgroundColor: '#00ff0080',
}

const modalFadeCss: React.CSSProperties = {
    transition: `opacity ${fadeTimeMs}ms`,
    opacity: 0
}

const closeBtnStyle: React.CSSProperties = {
    position: 'absolute',
    top: 5,
    right: 5,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
}
