import { toast } from "react-toastify";
import instance from "./instance";
import moment from "moment";

interface IStatusCamera {
  serial: string,
}

export const checkStatusCamera = async ({ serial }: IStatusCamera) => {
    try{
        const response = await instance.get(`/api/device/cameras-info?uniqueIds=${serial}`);
        if(response.status === 200){
            const {data: { data }} = response;
            return {onlineStatus: !!data[0].onlineState, channels: data[0]?.channels, cameraTime: data[1]}
        }else {
            return {onlineStatus: false, channels: []}
        }
    }catch ({ message }){
        return {onlineStatus: false, channels: []}
        console.error(`ERROR: online status ${ message }`);
    }
};

export const renameChannel = async (body) => {
    const URL = `/api/device/rename-channel`;

    try{
        const { data } = await instance.post(URL, {...body});
        return data;
    }catch ({ message }){
        console.error(message);
    }
};

export const getChannels = async ({serial}) => {
    // Media type. 1-flv; 2-hls. The default value is flv
    // Stream type. 1-main; 2-sub stream
    const URL = `/api/device/camera-stream-info?uniqueId=${serial}&channels=1,2`;

    try{
        const { data } = await instance.get(URL);
        return data
    }catch ({ message }){
        toast.error(message)
        return Promise.reject(message)
    }
};

export const getAvailableTime = async (serial) => {
    const URL = `/api/device/get-time-info?uniqueId=${serial}`;

    try{
        const { data } = await instance.get(URL);
        return data;
    }catch ({message}){
        console.error(message)
    }
}

export const updateAvailableTime = async (serial, playedSeconds) => {
    const URL = `/api/device/time-update`;

    const body = {
        uniqueId: serial,
        used_time_in_seconds: playedSeconds,
    }

    try{
        const { data } = await instance.post(URL, {...body});
        return data;
    }catch ({message}){
        console.error(message)
    }
}

export const buyTime = async (serial, tariff, cardId) => {
    const URL = `/api/device/buy-time`;
    const body = { uniqueId: serial, tariff, payment_method_id: cardId }

    try{
        const { data } = await instance.post(URL, {...body});
        return data;
    }catch ({message}){
        toast.error(message)
        console.error(message)
    }
}

export const existingHistoryDate = async ({serial, currentMonth}) => {

    try {
        const { data } = await instance.get(`api/device/calendar-month?uniqueId=${serial}&month=${currentMonth}`);
        return data
    } catch({message}) {
        console.error("ERR => takeExistingHistoryDate", message);
    }
}

export const dayChooseByTimestamp = async ({ serial, convertDate, alertTime }) => {
    const selectedDate = `${convertDate}, ${alertTime}`;
    const alertTimestamp = new Date(selectedDate).getTime();

    const timeZone = new Date(convertDate).getTimezoneOffset() * 60000;
    const timestampDate = new Date(convertDate).getTime();
    
    try {
        const response = await instance.get(`api/device/calendar-day-by-timestamp?uniqueId=${serial}&startOfDay=${timestampDate - +timeZone.toString().slice(1)}${alertTime && `&startEvent=${alertTimestamp}`}`);
        const {  data, code, message } = response.data;

        if((code && code == 200) || data.length) {
            return data
        } else {
            toast.error(message || 'Something went wrong', {position: toast.POSITION.TOP_RIGHT});
        }
    } catch ({message}) {
        console.error("ERR => dayChooseByTimestamp", message);
    }
};

// export const dayChoose = async ({ serial, convertDate, alertTime }) => {
//     const selectedDate = `${convertDate}, ${alertTime}`;
//     const timestampDate = new Date(selectedDate).getTime();

//     try {
//         const { data } = await instance.get(`api/device/calendar-day?uniqueId=${serial}&day=${convertDate}${alertTime && `&timestamp=${timestampDate}`}`);
//         return data
//     } catch ({message}) {
//         console.error("ERR => dayChoose", message);
//     }
// };

export const getHistoryVideoApi = async ({ selectedHistoryChannel, uniqueId, playedSeconds, alertTimestamp }) => {
    const {
        beginTime,
        endTime,
        storageType,
        streamType,
        timeZoneOffset,
        vodFileType,
        channel,
    } = selectedHistoryChannel;
    const compareTimestamps = beginTime == alertTimestamp;

    const settingBeginTime = () => {
        let time;
        
        if(playedSeconds === 0 && compareTimestamps) {
            time = moment(beginTime).unix() * 1000;
        } else {
            const startTime = moment(beginTime).add(playedSeconds, 'seconds');
            const continueTime = moment(startTime).unix() * 1000;
            // const alertStartEventTimestamp = (alertTimestamp - beginTime) + beginTime;
            const timestamp = alertTimestamp > 0 ? alertTimestamp : continueTime;
            time = timestamp;
        }
        
        return time;
    }
    const setStartTime = settingBeginTime();

    try {
        const { data } = await instance.get(
          `api/device/video-record?uniqueId=${uniqueId}&channels=${channel}&startTime=${setStartTime}&endTime=${endTime}&streamType=${streamType}&storeType=${storageType}&mediaProtocol=FLV`
        );
        return data
    } catch ({message}) {
        console.error("ERR => getHistoryVideo", message);
        toast.error('No files are available. null response', { position: toast.POSITION.TOP_RIGHT });
    }
};

export const closeHistorySession = async ({ session }) => {
    try {
        const { data } = await instance.post(`api/device/close-video-record`, {
          opSession: session,
        });
        return data
    } catch ({message}) {
        console.log("ERR => closeHistorySession", message);
        toast.error(message, { position: toast.POSITION.TOP_RIGHT });
    }
}

export const getBillingCards = () => {
        return instance
        .get(`api/billing/cards`)
        .then((res) => res.data)
        .catch((err) => {
            console.log("ERROR: plaid/braintree token or cards =>", err);
            const { message } = err;
            return Promise.reject(message);
        });
}
