import React, {useState} from "react";
import {useRedux} from '../../../../states/redux-state';
import {exportReport, exportReportPdf, exportSummary} from '../report-helper';
import "./index.scss";
import {useDispatch} from "react-redux";
import {GMapModalAC} from "../../../../stores/reducers/gMapModal/AC";
import {ReactComponent as PdfSvg} from "../../../../assets/svg/pdf.svg";
import {ReactComponent as XlsSvg} from "../../../../assets/svg/xls.svg";
import {Checkbox} from "../../../Checkbox";
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import { iDevicePingWithGeofences, iList } from "../../../../shared/interfaces";
import { selectDeviceOrPerson, selectReportsGrouped } from "../../../../stores/reducers/report-selectors";
import { exportGeofenceReportCsv, exportGeofenceReportPdf } from "../GeofenceReport/helpers";
import { useOneDeviceReportUnits } from "../ReportPage";

export const ExportReport = () => {
    const {
        reportType,
        reportDetails,
        devicesDetails,
        people,
        displayRecordsGrouped,
        displayRecords,
        deviceOrPerson,
        geofenceReportsGrouped,
		reportDates
    } = useRedux(s => ({
        reportType: s.report.details.reportType,
        reportDetails: s.report.details,
        devicesDetails: s.devicesData.devicesDetails,
        people: s.general.people,
        displayRecordsGrouped: selectReportsGrouped(s),
        displayRecords: s.report.displayRecords,
        deviceOrPerson: selectDeviceOrPerson(s),
        geofenceReportsGrouped: selectReportsGrouped(s),
		reportDates: s.report.details.dates
    }));

	const deviceUnits = useOneDeviceReportUnits();

    const handleExport = () => {
        if (exportCsv) {
            if (reportType === "travel" || reportType === "static") exportReport({displayRecords: displayRecords, reportType, devicesDetails, people})()
            if (reportType === "geofence") exportGeofenceReportCsv({devices: devicesDetails, people, deviceOrPerson, geofenceReportsGrouped: (geofenceReportsGrouped  as iList<iDevicePingWithGeofences[]>)})
            if (reportType === "summary") exportSummary(displayRecordsGrouped, deviceUnits, devicesDetails, reportDates, exportCsv)
        }
        if (exportPdf) {
            if (reportType === "travel" || reportType === "static") exportReportPdf({records: displayRecordsGrouped, report: reportDetails, reportData: devicesDetails, people})
            if (reportType === "geofence") exportGeofenceReportPdf({devices: devicesDetails, people, deviceOrPerson, geofenceReportsGrouped: (geofenceReportsGrouped  as iList<iDevicePingWithGeofences[]>)})
            if (reportType === "summary") exportSummary(displayRecordsGrouped, deviceUnits, devicesDetails, reportDates)
        }
    }

    const dispatch = useDispatch();
    const handleCancel = () => dispatch(GMapModalAC.hideModal());

    const [ exportPdf, setExportPdf ] = useState(false);
    const toggleExportPdf = () => setExportPdf(b => !b);
    const [ exportCsv, setExportCsv ] = useState(false);
    const toggleExportCsv = () => {
        setExportCsv(b => !b);
    };

    return (
        <div className="export-report-container">
            <div className="export-report-header"><h3>Export report</h3></div>
            <div className="export-report-content">
                <div className="export-report-content-row" onClick={toggleExportPdf}>
                    <label
                        className={`export-report-label ${exportPdf? "active": ""}`}>
                            <PdfSvg />&nbsp;PDF
                    </label>
                    <Checkbox checked={exportPdf}/>
                </div>
                <div className="export-report-content-row" onClick={toggleExportCsv}>
                    <label
                        className={`export-report-label ${exportCsv? "active": ""}`}>
                            <XlsSvg />&nbsp;XLS
                    </label>
                    <Checkbox checked={exportCsv} />
                </div>
            </div>
            <div className="export-report-actions">
                <Button
                  styleType={ButtonStyles.WHITE_GRAY}
                  size={ButtonSize.LG}
                  onClick={handleCancel}>Cancel</Button>
                <Button
                  styleType={ButtonStyles.BLACK_WHITE}
                  size={ButtonSize.LG}
                  onClick={handleExport}>Export</Button>
            </div>
        </div>
    )
}