import React from 'react';
import {withBemMod} from '@bem-react/core';

import {cnTabs} from '../index';
import './Tabs_styled_underlined_new.scss';

export interface TabsStyledUnderlinedProps {
    styled?: 'underlined_new';
}

export const withTabsStyledUnderlinedNew = withBemMod<TabsStyledUnderlinedProps>(
    cnTabs(),
    {styled: 'underlined_new'},
    (Component) => ({styled, ...props}) => <Component {...props} />,
);
