import {
	pipe,
	pathOr,
	prop,
	filter,
	keys,
	map,
	join,
	startsWith,
	values,
	always,
} from 'ramda';

import {
	AlertTypeTitle,
	iTrip,
	iDevicePing,
	iList,
	iPerson,
	iReportTravelData,
	iReportStaticData,
	iFullTravelData,
	iFullStaticData,
	iSummaryItemResults,
	iExportReportPdfProps,
	iExportReportProps,
	DateTimeFormatOptions,
} from '../../../shared/interfaces';
import { csvExporter } from '../../../shared/csv';
import { DevicesDetailsContainer } from '../../../stores/reducers/devicesData';
import {
	friendlyDiff,
	minutesToFriendly,
	friendlySpeed,
	friendlyMilesPer,
	friendlyDist,
	countDateWithUserAndTimezoneOffset,
	exportPDF,
} from '../../../shared/helpers';
import moment from 'moment';
import { UserOptions } from 'jspdf-autotable';
import {
	mapFromDeviceResultToTableBody,
	checkFileResolution,
	generateSummaryResults,
} from './SummaryReport/helper';

// we can't really use device units preference
// because we are combining devices.
const _tripRecordToRow =
	(devicesDetails: DevicesDetailsContainer, people: iList<iPerson>) => (record: iTrip) => {
		const timezone = devicesDetails.getIn([record.device, 'timezone']);
		const start = countDateWithUserAndTimezoneOffset(record.startDate, timezone);
		const end = countDateWithUserAndTimezoneOffset(record.endDate, timezone);
		return {
			'Device Name': devicesDetails.getIn([record.device, 'name']) || 'device not found',
			'Trip Minutes': record.endDate.diff(record.startDate, 'minutes'),
			'Start Time': start.format('M/D/YY h:mmA'),
			'Start Address': record.startAddress,
			'End Time': end.format('M/D/YY h:mmA'),
			'End Address': record.endAddress,
			'Idle Minutes': record.idleMinutes,
			'Stopped Minutes': record.stopMinutes,
			'Max Speed': record.maxSpeed,
			'Average Speed': record.averageSpeed,
			'Miles Traveled': record.miles,
			MPG: record.mpg,
			'Safty Percent': `${record.safetyPercent}%`,
			Alerts: pipe(
				prop('alertActivity'),
				filter(Boolean),
				keys,
				filter(startsWith('has')),
				map(AlertTypeTitle),
				join(', ')
			)(record),
		};
	};

const _pointToRow =
	(devicesDetails: DevicesDetailsContainer, people: iList<iPerson>) => (record: iDevicePing) => {
		const timezone = devicesDetails.getIn([record.device, 'timezone']);
		const dateWithCorrection = countDateWithUserAndTimezoneOffset(record.time, timezone);
		return {
			Person: pathOr('Not assigned', [record.personId, 'displayName'], people),
			Device: devicesDetails.getIn([record.device, 'name']) || '',
			Time: dateWithCorrection.format('M/D/YY h:mmA'),
			Street: record.address.street,
			City: record.address.city,
			State: record.address.state,
			Zip: record.address.zip,
			Latitude: record.coordinates.location.lat,
			Longitude: record.coordinates.location.lng,
			Label: record.label,
			Fuel: record.fuel,
			MPG: record.mpg,
			Miles: record.miles,
			Message: record.msg,
			'Posted Speed': record.posted_speed,
			MPH: record.speed,
			Voltage: record.volt,
		};
	};

export const exportReport = ({
	displayRecords,
	reportType,
	devicesDetails,
	people,
}: iExportReportProps) =>
	pipe(
		always(displayRecords),
		values,
		map(
			reportType === 'travel'
				? _tripRecordToRow(devicesDetails, people)
				: _pointToRow(devicesDetails, people)
		),
		// turn into header row + values of rest
		records => [keys(records[0]), ...records.map(values)],
		csvExporter('report.csv')
	) as () => any;

const getReportTravelData = ({ group, device, people }: iReportTravelData): UserOptions[] => {
	const { timezone, name, units } = device;
	return Object.values(group).map(row => {
		const start = countDateWithUserAndTimezoneOffset(row.startDate, timezone);
		const end = countDateWithUserAndTimezoneOffset(row.endDate, timezone);
		const personKey = row.personId;
		const person = people[personKey] || { displayName: '' };
		return {
			head: [[name, start.format('M/D/YY'), '', `${start.format('h:mmA')} - ${row.startAddress}`]],
			body: [
				['', 'Duration:', friendlyDiff(moment(row.endDate), moment(row.startDate))],
				['', 'Idle:', minutesToFriendly(row.idleMinutes)],
				['', 'Stopped:', minutesToFriendly(row.stopMinutes)],
				['', 'Safety:', row.safetyPercent ? row.safetyPercent + '%' : 'NA'],
				['', 'Person', person.displayName],
				['', 'Avg. Speed:', `${row.averageSpeed ? friendlySpeed(row.averageSpeed, units) : 'NA'}`],
				['', 'Max Speed', `${row.maxSpeed ? friendlySpeed(row.maxSpeed, units) : 'NA'}`],
				['', 'Distance', friendlyDist(row.miles, units)],
				['', 'Consumption:', friendlyMilesPer(row.mpg, units)],
				['', 'Label', row.label || ''],
			],
			foot: [['', '', '', `${end.format('h:mmA')} - ${row.endAddress}`]],
		};
	});
};

const getReportStaticData = ({ group, device }: iReportStaticData): UserOptions[] => {
	const { timezone, name } = device;
	const bodyData = Object.values(group).map(row => {
		const dateWithCorrection = countDateWithUserAndTimezoneOffset(row.time, timezone);

		return [
			dateWithCorrection.format('MMM-D'),
			dateWithCorrection.format('h:mmA'),
			row.fuel,
			row.speed,
			row.posted_speed,
			row.mpg,
			row.msg,
			`${row.address.street} ${row.address.city} ${row.address.state} ${row.address.zip}`,
			`${row.coordinates.location['lat']}, ${row.coordinates.location['lng']}`,
		];
	});

	return [
		{
			theme: 'grid',
			headStyles: { fillColor: '#2E80BA' },
			head: [[name, 'Data', 'Fuel', 'mph', 'Posted', 'mpg', 'Message', 'Location', 'Coordinates']],
			body: bodyData,
		},
	];
};

const getFullTravelData = ({ records, reportData, people }: iFullTravelData) => {
	const fullTravelData = [];
	Object.entries(records).forEach(([deviceId, group], index) => {
		const device = reportData.get(deviceId);
		if (!device) return;
		const travelTableData = getReportTravelData({ group, device, people });

		// travelTableData is an array of UserOptions
		// we need to save an one array instead array of UserOptions arrays
		fullTravelData.push(...travelTableData);
	});

	return fullTravelData;
};

const getFullStaticData = ({ records, reportData }: iFullStaticData) => {
	const fullStaticData = [];
	Object.entries(records).forEach(([deviceId, group], index) => {
		const device = reportData.get(deviceId);
		if (!device) return;
		const staticTableData = getReportStaticData({ group, device });

		// travelTableData is an array of UserOptions
		// we need to save an one array instead array of UserOptions arrays
		fullStaticData.push(...staticTableData);
	});

	return fullStaticData;
};

export const exportReportPdf: (params: iExportReportPdfProps) => void = ({
	records,
	reportData,
	report,
	people,
}) => {
	const allTables =
		report.reportType === 'travel'
			? getFullTravelData({ records, reportData, people })
			: getFullStaticData({ records, reportData }); // report.reportType === 'static'

	exportPDF({
		fileName: 'Report',
		data: allTables,
	});
};

export const exportSummary = (data, deviceUnits, devicesDetails, reportDates, exportCsv = false) => {
	const results = {};

	Object.values(data).forEach((deviceTrips: any) => {
		const deviceName = devicesDetails.get(deviceTrips[0].device)?.name || 'Unknown Device';
		results[deviceTrips[0].device] = generateSummaryResults(deviceTrips, deviceName, 'summary');
	});

	const outputPDF = Object.values(results).map((device: iSummaryItemResults) => {
		return {
			head: [[device.device, '']],
			body: mapFromDeviceResultToTableBody(device, deviceUnits),
		};
	});

	const outputCSV = [
		['Device', 'Average Speed', 'Safety', 'Drive Time', 'Stopped Time', 'Total Distance'],
	];
	Object.values(results).forEach(
		(device: iSummaryItemResults, index: number, array: iSummaryItemResults[]) => {
			const deviceInfo = [...mapFromDeviceResultToTableBody(device, deviceUnits, true)];
			outputCSV.push(...deviceInfo);
		}
	);

	const dateOptions: DateTimeFormatOptions = {
		day: '2-digit',
		month: '2-digit'
	}
	const fileName = reportDates.length > 1 ? reportDates.map(date => new Intl.DateTimeFormat('en-US', dateOptions).format(date.startDate)).join(', ') : `${new Intl.DateTimeFormat('en-US', dateOptions).format(reportDates[0].startDate)} - ${new Intl.DateTimeFormat('en-US', dateOptions).format(reportDates[0].endDate)}`;

	if (exportCsv) {
		const newFileName = checkFileResolution({ typeWithoutDot: 'csv', fileName });
		csvExporter(newFileName)(outputCSV);
	} else {
		const newFileName = checkFileResolution({ typeWithoutDot: 'pdf', fileName });
		exportPDF({
			fileName: newFileName,
			data: outputPDF,
		});
	}
};
