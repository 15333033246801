import React, { MouseEventHandler } from 'react'
import Recaptcha from 'react-recaptcha'
import { Subject } from 'rxjs'
import './Captcha.scss'
import {captchaKeyV2} from '../../shared/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isDev } from '../../shared/helpers'
import {DEV_CAPTCHA_KEY} from '../../shared/constants'

type CaptchaProps = {
    onVerify: Function,
    closeCaptcha: Function,
}

const Captcha: React.FunctionComponent<CaptchaProps> = ({onVerify, closeCaptcha}) =>{

    const captchaSubject = React.useRef(new Subject<boolean>()) 

    const verifyCallback = () =>{
        onVerify()
        setTimeout(closeCaptcha, 1000)
    }

    return <div className="captcha-wrapper" onClick={closeCaptcha as MouseEventHandler<HTMLDivElement>}>
                <div className="captcha">
                    <div 
                        onClick={closeCaptcha as MouseEventHandler<HTMLDivElement>} 
                        className="close"><FontAwesomeIcon icon="times"/></div>
                        <span className="title">Prove you are not a robot</span>
                        <Recaptcha 
                            sitekey={!isDev ? captchaKeyV2 : DEV_CAPTCHA_KEY}
                            verifyCallback={verifyCallback} />
                </div>
        </div>
    
}

export default Captcha