import React from 'react';

import "./trip-travel-row.scss";

export const TripTravelRow = ({label, val, odd, visible = true}) => !visible ? null : (
    <div className="trip-travel-row">
        <div className="trip-travel-row-label">{label}: </div>
        <div className="trip-travel-row-value">{val}</div>
    </div>
)
