import { ElementType } from 'react';
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';


export interface IButtonProps extends IClassNameProps {
    as?: ElementType;
}

export const cnButton = cn('Button');
