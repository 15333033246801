import React, { FC } from "react";
import "./index.scss";
import { NavbarElementProps } from 'react-day-picker';
import ChevronRightIcon from '../../SVG-components/chevron_right';

const DayPickerNavbar: FC<NavbarElementProps> = ({onPreviousClick, onNextClick, className, localeUtils, month, showNextButton, showPreviousButton}) => {
    const months = localeUtils.getMonths();
    const currentMonthIndex = month.getMonth();

    const year = month.getFullYear();
    return (
        <div className="day-picker-navbar">
            <ChevronRightIcon 
                onClick={() => onPreviousClick()}
                className="day-picker-navbar__chevron day-picker-navbar__chevron--turn-left" 
            />            
            <div className="day-picker-navbar__header">{months[currentMonthIndex]} {year}</div>
            <ChevronRightIcon 
                className="day-picker-navbar__chevron" 
                onClick={() => onNextClick()}
            />
        </div>
    )
}

export default DayPickerNavbar;