import { CSVArray } from "../interfaces";
import { parseCSV } from "./parse-csv";

export enum FileFormat {
  JSON = 'json',
  CSV = 'csv'
}

export function createParser(format: FileFormat): (string: string) => any | CSVArray  {
  switch(format) {
    case FileFormat.JSON:
      return JSON.parse;
    case FileFormat.CSV:
      return parseCSV;
  }
}