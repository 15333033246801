import React from 'react';
import { connect, DispatchProp, useSelector } from 'react-redux';

import { iDeviceDetails, iFullStoreState, iList, iPerson, ItemType } from '../../../shared/interfaces';
import { idValArr, extractDevice } from '../../../shared/helpers';
import { default as C } from '../../../shared/constants';
import LauncherTile from '../../../shared/item-launch';
import UserImage from '../../general/user-image';
import { BaseComponent } from "../../../shared/BaseComponent";
import { DevicesDetailsContainer } from '../../../stores/reducers/devicesData';


type IPropsFromStore = {
    people: iList<iPerson>;
    devicesDetails: DevicesDetailsContainer;
}
type IProps = IPropsFromStore & DispatchProp;

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => {
    const devicesDetails = state.devicesData.devicesDetails;
    const people = (state.general.people || {});

    return ({
        people,
        devicesDetails,
    });
};

export default connect(mapStateToProps)
(class People extends BaseComponent<IProps> {
    debugRender = () => {
        const { people, devicesDetails } = this.props;

        const lastIdx = Object.keys(people).length - 1;

        return <div>
            {idValArr(people).map(({ id, val }, idx) => {
                const assignedDevice = val.hasDevice ? extractDevice(devicesDetails, val.hasDevice.deviceId) : false;

                return (
                    <LauncherTile
                        key={id}
                        id={id}
                        link={`/person/${id}`}
                        strikeBottom={idx != lastIdx}
                        type={ItemType.person}
                    >
                        {/* Image and Name */}
                        <div style={{display: 'flex', fontSize: 18}}>
                            <UserImage person={val} size={35} style={{marginRight: 8}} />
                            <span>{val.displayName}</span>
                        </div>

                        {assignedDevice && <PersonRow device={assignedDevice} />}

                    </LauncherTile>
            )})}
        </div>
    }
});

const PersonRow = ({device}: { device: iDeviceDetails }) => {
    const lastPingMsg = useSelector<iFullStoreState, string>(
        state => state.devicesData.devicesLastPing.getIn([device.id, 'msg']) ?? '',
    );
    return <div style={{display: 'flex', marginTop: 10, fontSize: 11}}>
        {device.name && (<div style={{backgroundColor: C.lightGray, borderRadius: 5, padding: '2px 5px', marginRight: 10}}>
            {device.name}
        </div>)}
        {lastPingMsg && (<div style={{backgroundColor: C.lightGray, borderRadius: 5, padding: '2px 5px'}}>
            {lastPingMsg}
        </div>)}
    </div>;
};

