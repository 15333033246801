import { iExtraInfo } from './../interfaces';
import {clientDb} from '../firebase';
import {idValArr, vals, emptyObjConst, makeAudit} from '../helpers';
import * as structure from '../../shared/interfaces';
import {pipe, map, mapObjIndexed, assoc, assocPath, cond, equals, always, T, fromPairs} from 'ramda';
import {ItemType, UserAuth} from '../interfaces';
import {thrower} from '../ramda';
import {AUDIT_LOG} from '../constants';

const labelDb = () => clientDb().child('extra-info');

// export const all = async (): Promise<structure.iList<structure.iExtraInfo>> => (await labelDb().once("value")).val();

export const allWatch = (callback) => labelDb().on('value', pipe(
    (res) => {
        const values: structure.iList<iExtraInfo> = res.val();
        const uniqueValues = [];

        Object.entries(values).forEach(([key, item]) => {
            const filtered = uniqueValues.filter(([key, label]) => item && label.details.name === item?.details.name);
            filtered.length === 0 && uniqueValues.push([key, item]);
        });
        
        return fromPairs(uniqueValues) || emptyObjConst
    },
    mapObjIndexed((obj, idx) => assocPath(['details', 'id'], idx, obj)),
    callback
), (err) => console.log(err));

export const update = (user: UserAuth) => async (key, info: {[key: string]: any}) => {
    await clientDb().update(makeAudit(user, {
        [`extra-info/${key}`]: {details: info},
    }));
};

export const deleteLabels = (user: UserAuth) => async (ids) => {
    const update = {};
    
    ids.forEach((id) => {
        update[`extra-info/${id}`] = null;
    });

    await clientDb().update(makeAudit(user, update));
};

/**
 * Update a itemId - val null for removal
 */
export const updateItemInfoValue = (user: UserAuth) => async (type: ItemType, itemId: string, labels: structure.iList<string>) => {
    const path = cond([
        [equals(ItemType.device), always(`devices/device-details/${itemId}/extra-info/`)],
        [equals(ItemType.fence), always(`fences/fence-instances/${itemId}/extra-info`)],
        [equals(ItemType.person), always(`people/people-details/${itemId}/extra-info`)],
        [equals(ItemType.tag), always(`tags-extra-info-values/${itemId}`)],
        [T, thrower('missing item type handler')]
    ])(type);

    clientDb().update(makeAudit(user, {
        [path]: labels,
    }));
};