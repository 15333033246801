import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import { equals } from 'ramda';

import { iTrip, iDevicePing, iFullStoreState  } from '../../../../shared/interfaces';
import { Actions as ReportActions } from '../../../../stores/reducers/report-reducers';
import { setTripCol, setPointCol } from '../../../../shared/db/report-db';
import C from '../../../../shared/constants';
import { Fa } from '../../../elements/fa';
import { withSentry } from '../../../../shared/hoc/withSentry';
import Dialog, { DialogConfigSetter } from '../../../Dialog';


interface iSmartPencilInterface {
    id: string,
    updateType: 'trip' | 'point',
    pickType: 'personId' | 'label',
    currentValue: string,
    style?: React.CSSProperties,
    expandingEdit?: boolean,
    showIcon?: boolean
}

function SmartPencil (props: iSmartPencilInterface) {
    const dispatch = useDispatch();
    const {updateType, expandingEdit, pickType, id, currentValue, showIcon = true} = props;

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    const record = useSelector<iFullStoreState, iTrip | iDevicePing | undefined>(
        s => s.report.displayRecords?.[id],
        (l, r) => equals(l, r),
    );

    const update = async () => {
        if (expandingEdit) {
            const dialog = dialogRef.current;
            const confirmed = await dialog?.({
                title: 'Change entire trip',
                body: 'This point is in a trip and your change will update the entire trip. Confirm to proceed.',
            })
            if (!confirmed) return;
        }

        // could be affecting multiple rows. if we are editing a trip for a visible point we need to match all points for trip
        let affectedRowCriteria = updateType == 'point' ? {pointId: id} : {tripId: id};
        if (expandingEdit) affectedRowCriteria = {tripId: id};

        // get the right updater / prime the updater
        const updater = updateType == 'trip' || expandingEdit
                        ? await setTripCol(pickType, id)
                        : await setPointCol(pickType, (record as iDevicePing).pointId);

        dispatch(
            ReportActions.PICK_NEW_CELLS_VALUE({
                id,
                pickType,
                oldVal: currentValue,
                updateType,
                affectedRowCriteria,
                updater
            })
        )
    }

    return <>
        <div style={props.style || {}}>
            {
            showIcon
                ? <Fa icon={faPencilAlt} style={{color: C.primaryColor.toString()}} onClick={update} />
                : <span className="cursor-pointer" onClick={update}>Edit</span>
            }
        </div>
        <Dialog setupConfig={setupDialog} />
    </>;
}

export default withSentry(SmartPencil);
