import React, {FC, useEffect, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { selectDevices } from "../../../../stores/reducers/devicesData/selector";
import { iDeviceDetails, iDevicePingWithGeofences, iFullStoreState, iList, iPerson, ItemType } from "../../../../shared/interfaces";
import { selectPeople } from "../../../../stores/reducers/general-selectors";
import { selectDeviceOrPerson, selectReportsGrouped } from "../../../../stores/reducers/report-selectors";
import "./index.scss";
import { Map } from "immutable";
import { countDateWithUserAndTimezoneOffset, minutesToFriendly } from "../../../../shared/helpers";
import {VisFencesData} from "../vis-fences-data";
import {useRedux} from "../../../../states/redux-state";
import {useLocalStorage} from "../../../../stores/reducers/general-reducers";
import {iHiddenReportData} from "../../reports-mobile/vis-report-data";
import {Button, ButtonSize, ButtonStyles} from "../../../Button";
import moment from "moment";
import {reduceBy} from 'ramda';

const GeofenceReport: FC<{showVisibleFencesData: false | true }> = ({showVisibleFencesData}: {showVisibleFencesData: false | true}) => {
    const records = useSelector<iFullStoreState, iList<iDevicePingWithGeofences[]>>(s => selectReportsGrouped(s) as iList<iDevicePingWithGeofences[]>);
    const people = useSelector<iFullStoreState, iList<iPerson>>(selectPeople);
    const devices = useSelector<iFullStoreState, Map<string, iDeviceDetails>>(selectDevices);
    const deviceOrPerson = useSelector<iFullStoreState, ItemType.device | ItemType.person>(selectDeviceOrPerson);
    const reportType = useRedux(s => s.report.details.reportType);
    const recordsJsx = useMemo(() => {
        return Object.entries(records).map(([key, records]) => {
            const name = deviceOrPerson === ItemType.device? devices.getIn([key, "name"]): people[key]?.displayName;
            return <GeofenceDeviceRecords device={devices.get(key)} key={key} name={name || "Not assigned"} records={records} />
        })
    }, [records, people, devices, deviceOrPerson]);


    return (
        <div className="geofence-report-page">
                <VisFencesData type={reportType as any} showVisibleFencesData={showVisibleFencesData} records={records}/>
                {recordsJsx}
        </div>
    )
}

export interface iDeviceGeofenceReport {
    device: iDeviceDetails;
    name: string;
    records: iDevicePingWithGeofences[];
}

const GeofenceDeviceRecords: FC<iDeviceGeofenceReport> = ({records, name, device}) => {
    const [toggleGeofenceReport, setToggleGeofenceReport] = useState(true);
    const [toggleGeofenceSummaryReport, setToggleGeofenceSummaryReport] = useState(true);
    const [hiddenFields] = useLocalStorage<iHiddenReportData>('hidden-report-data:geofence', {});
    const IGNITION_ON = 'Ignition On';
    const IGNITION_OFF = 'Ignition off';

    const geofenceRecords = records.reduce((acc, record) =>
        [...acc, ...Object.entries(record.geofences)
            .map(([name, status]) => {
                const time = record.time;

                return {name, time, status}
            })
        ], []);

    const recordsJSX = geofenceRecords
        .map((record, index) => {
            const timezone = device.timezone;
            const time = countDateWithUserAndTimezoneOffset(record.time, timezone);

            return !hiddenFields[record.name] &&
                <GeofenceRecord
                    key={index}
                    name={record.name}
                    status={record.status}
                    timestamp={time.format("MM-DD-YYYY hh:mmA")}
                />
    })

    const geofenceStopsRecords = geofenceRecords
        .reduce((res, cur, i, arr) => {
            let curPlace;
            let nextPlace;
            let curIndex

            if (!cur.status && i === 0) {
                const firstIgnitionOn = records.find(record => record.msg === IGNITION_ON)
                res.push({name: cur.name, time: firstIgnitionOn.time, status: 1});
                res.push(cur);
            }

            if (cur.status) {
                if (i === arr.length - 1) {
                    const LastIgnitionOff = records.slice().reverse().find(record => record.msg === IGNITION_OFF)
                    res.push(cur);
                    res.push({name: cur.name, time: LastIgnitionOff.time, status: 0});

                } else {
                    curPlace = cur.name;
                    curIndex = i
                    res.push(cur);
                    nextPlace = arr.find((p, index) => p.name === curPlace && p.status === 0 && index > curIndex)

                    if (nextPlace) {
                        res.push(nextPlace);
                    }
                }
            }

            return res;
        }, [])
        .reduce((res, curr, i, array) => {
            let timeDiff;
            let stopPlace;

            if (array[i + 1] && array[i + 1].status === 0) {
                let timeNext;
                let timePrev;

                if (toggleGeofenceSummaryReport) {
                    timeNext = new Date(array[i + 1].time);
                    timePrev = new Date(array[i].time);
                } else {
                    timePrev = i === 0 ?
                        new Date((moment(array[i].time).hours(0).minutes(0).seconds(0).toDate()))
                        :
                        new Date(array[i].time);

                    timeNext = i + 1 === array.length - 1 ?
                        new Date((moment(array[i + 1].time).hours(23).minutes(59).seconds(59).toDate()))
                        :
                        new Date(array[i + 1].time);
                }

                if (i === array.length - 1 && array[array.length - 1].status === 0) {
                    stopPlace = array[i - 1].name;
                } else {
                    stopPlace = array[i + 1].name;
                }

                if (timeNext > timePrev) {
                    timeDiff = Math.ceil((timeNext.getTime() - timePrev.getTime())/60000 ) ;
                    res.push({stopPlace, timeDiff});
                }
            }

            return res;
        }, [])

   const geofenceGroupedRecords = reduceBy((acc, next) => acc + next.timeDiff, 0, (x) => x.stopPlace, geofenceStopsRecords)

    const geofenceStopsJSX = Object.keys(geofenceGroupedRecords)
        .map( (key, index) => {
            return !hiddenFields[key] &&
                    <GeofenceRecordStops
                        key={index}
                        name={key}
                        timestamp={minutesToFriendly(geofenceGroupedRecords[key])}
                    />
    })

    const showEvents = () => setToggleGeofenceReport(true);
    const showSummary = () => setToggleGeofenceReport(false);
    const showByIgnition = () => setToggleGeofenceSummaryReport(true);
    const showByDay = () => setToggleGeofenceSummaryReport(false);

    return (
        <>
            {  recordsJSX.length > 0 && geofenceStopsJSX.length > 0 &&
                <div className="geofence-report">
                    <div className="geofence-report-tabs">
                        <Button
                            styleType={toggleGeofenceReport ? ButtonStyles.BLACK_WHITE : ButtonStyles.GRAY_INACTIVE}
                            size={ButtonSize.SM}
                            onClick={showEvents}
                        >
                            Events
                        </Button>
                        <Button
                            styleType={toggleGeofenceReport ? ButtonStyles.GRAY_INACTIVE : ButtonStyles.BLACK_WHITE}
                            size={ButtonSize.SM}
                            onClick={showSummary}
                        >
                            Summary
                        </Button>
                    </div>
                    <div className="geofence-report__header">
                        {name}
                        {!toggleGeofenceReport ?
                            <div className="geofence-report__header-tabs">
                                <Button
                                    styleType={toggleGeofenceSummaryReport ? ButtonStyles.BLACK_WHITE : ButtonStyles.GRAY_INACTIVE}
                                    size={ButtonSize.SM}
                                    onClick={showByIgnition}
                                >
                                    By ignition
                                </Button>
                                <Button
                                    styleType={toggleGeofenceSummaryReport ? ButtonStyles.GRAY_INACTIVE : ButtonStyles.BLACK_WHITE}
                                    size={ButtonSize.SM}
                                    onClick={showByDay}
                                >
                                    By day
                                </Button>
                            </div>
                            :
                            ''
                        }
                    </div>
                    <div className='geofence-report__body'>
                        { toggleGeofenceReport ? recordsJSX : geofenceStopsJSX }
                    </div>
                </div>
            }
        </>
    )
}

export interface iGeofenceReportRecord {
    name: string;
    timestamp: string;
    status: 0 | 1;
}

const GeofenceRecord: FC<iGeofenceReportRecord> = ({name, timestamp, status}) => {
    return (
        <div className="geofence-report__record geofence-by-day">
            <div className="geofence-report__record-item geofence-report__record-name">{name}</div>
            <div className="geofence-report__record-item">{timestamp}</div>
            <div className={`geofence-report__record-item geofence-report__record-item--${status === 1 ? "entered" : "exited"}`}>
                {status === 1? "Entered": "Exited"}
            </div>
        </div>
    )
}

export interface iGeofenceReportRecordStops {
    name: string;
    timestamp: string;
}

const GeofenceRecordStops: FC<iGeofenceReportRecordStops> = ({name, timestamp}) => {
    return (
        <div className="geofence-report__record geofence-report__record--stops geofence-by-ignition">
            <div className="geofence-report__record-item geofence-report__record-name">{name}</div>
            <div className="geofence-report__record-item geofence-report__record-item--time-diff">{timestamp}</div>
        </div>
    )
}

export default GeofenceReport;

