import React, { FC, HTMLProps } from "react";
import "./index.scss";

enum InputStyle {
    PLAINE = "input--plaine"
}

interface InputProps extends HTMLProps<HTMLInputElement> {
    styleType?: InputStyle
}

const Input: FC<InputProps> = ({styleType, className, ...props}) => {
    return <input className={`input ${styleType} ${className}`} {...props}/>
}

export default Input;