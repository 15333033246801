import React, {useEffect, useState} from 'react';
import base64 from 'base-64';
import ReactDOM from 'react-dom';
import {connect, Provider, useSelector} from 'react-redux';
import {Link, withRouter,/* HashRouter as */Router, RouteComponentProps} from 'react-router-dom';
import * as raven from 'raven-js';
import {propOr} from 'ramda';
import * as color from 'color';
import {faClock} from '@fortawesome/fontawesome-free-regular';
import {faHome} from '@fortawesome/fontawesome-free-solid';

import {history, runWatchers, store} from './stores/store';
import ModalHeader from './components/menus/modal/modal-header';
import GMaps from './components/gmaps';
import {fbapp, fbConfig, theClient} from './shared/firebase';
import {getAcl} from './shared/db/people-db';
import {IconChooser} from './components/modals/icon-chooser';
import {localStorage} from './shared/storage';
import Layout from './components/pages/header-only-layout';
import DashboardBlock from './components/DashboardBlock';
import {Fa} from './components/elements/fa';
import {devSettings} from './shared/dev-helpers';
import {MinimalTile} from './components/general';
import {clientDefaultSettings, idValArr, isMobile} from './shared/helpers';
import {Row} from './components/elements/flex';
import {useConnected} from './stores/reducers/general-reducers';
import {useRedux} from './states/redux-state';
import {StandardDesktopLayout} from './standard-desktop-layout';
import {StandardMobileLayout2} from './standard-mobile-layout';
import LoginMobile from './components/pages/Login/login-mobile';
import {useSelectIcon} from './shared/hooks/useSelectIcon';
import {selectIconContext} from './shared/contexts/selectIcon.context';
import Portal from './components/Portal';

import './assets/styles/fonts.scss';
import Login from './components/pages/Login/login';
import {logout as logoutAPI} from './api';
import './index.scss';
import {ACL, defaultSettingsTemplate, UserCan} from './shared/constants';
import {AclFb, DefaultAclFb, iDefaultSettings, iFullStoreState, UserAuth} from './shared/interfaces';
import { getDefaultAcl } from './shared/db/users-access';
import DefaultSettingsModal from './components/DefaultSetingsModal/DefaultSettingsModal'
import {presetDefaultDeviceSettings} from "./shared/db/devices-db";
import SessionTimeout from "./BemComponents/SessionTimeout";
import {authenticateWithCustomToken} from './index.helpers';
import { ToastContainer } from 'react-toastify';
 import "react-toastify/dist/ReactToastify.css";

let loggedTimeToRnder = false;
const App = () => {
    const [isConnected] = useConnected();
    const auth = useRedux((s) => s.auth);
    const fullyInitialized = useRedux((s) => s.general.initComplete);

    if (!loggedTimeToRnder)
        console.log('time to render:', (Date.now() - window['startTime']));

    loggedTimeToRnder = true;

    useEffect(() => {
        if (auth.fbToken) {
            fbapp.auth().signInWithCustomToken(auth.fbToken);
        }
    }, [auth.fbToken]);

    useEffect(() => {
        authenticateWithCustomToken();
    }, [])

    if (isMobile) {
        if (!auth.user && !auth.fbToken) return <LoginMobile />;
    } else if (!isMobile) {
        if (!auth.user && !auth.fbToken) return <Login />;
    } else {
        if (!auth.user) return <NotLoggedInModal />;
    }

    if (!fullyInitialized || !isConnected || !auth.user) return (
        <div className="height-desktop-hundred">
            <Router history={history}>
                <Layout itemId={null} itemType={null}>
                    {/* <PanHelper />*/}
                    <GMaps />
                    <div className="height-desktop-hundred" style={{flex: 1, display: 'flex', flexDirection: 'column', paddingTop: '10%', backgroundColor: color('black').fade(.3), zIndex: 100}}>
                        <div className="height-desktop-hundred" style={{display: 'flex', justifyContent: 'center', color: '#fff', fontSize: 40,}}>
                            <div className="height-desktop-hundred" style={{textAlign: 'center'}}>
                                <div><Fa icon={faClock} style={{fontSize: 70, fontWeight: 100}} /></div>
                                <div>Connecting</div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </Router>
        </div>
    );

    return <AuthLayout />;
};

const AuthLayout = () => {
    const selectIconFunctional = useSelectIcon();
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const can = user.acl.can;
    const superAdmin = ACL.check(UserCan.DO_ANYTHING, can);

    return (
        <Router history={history}>
            <selectIconContext.Provider value={selectIconFunctional}>
                {superAdmin && <DefaultSettings />}
                <IconChooser setSelectIcon={selectIconFunctional.setSelectIcon} />
                <GeneralErrorPage />
            </selectIconContext.Provider>
            <SessionTimeout />
        </Router>
    );
};

const DefaultSettings = () => {
    const [isNeedDefSettings, setIsNeedDefSettings] = useState(false)
    const [isCheckedDefSettings, setIsCheckedDefSettings] = useState(false)
    const [loadedSettings, setLoadedSettings] = useState<iDefaultSettings>()


    const checkDefaultSettings = ( settings ) => {
        const settingsCount  = Object.values(settings).length
        const neededSettingsCount = Object.values(defaultSettingsTemplate).length
        if(settingsCount < neededSettingsCount) {
            setLoadedSettings(settings)
            setIsNeedDefSettings(true)
        }

        setIsCheckedDefSettings(true)
    }

    useEffect(() => {
        !isCheckedDefSettings && clientDefaultSettings(checkDefaultSettings)
    }, [])

    useEffect(() => {
        async function presetDefault() {
            await presetDefaultDeviceSettings();
        }
        isCheckedDefSettings && presetDefault();
    }, [isCheckedDefSettings])

    return (
        isNeedDefSettings&&<DefaultSettingsModal
            isOpenModal={() => setIsNeedDefSettings(false)}
            loadedSettings={loadedSettings}
        />
    );


}

const GeneralErrorPage = withRouter(connect((s, {location: {key: locationKey}}: RouteComponentProps) => ({locationKey}))
(class extends React.Component<{ locationKey? }, any> {
  state = {error: false}

  static getDerivedStateFromProps(props, state) {
      return {
          error: propOr(false, 'location', props) === propOr(false, 'locationKey', state)
      };
  }

  componentDidCatch(error: Error, info) {
      if (!devSettings.disableSentryBool) {
          raven.captureException(error, {stacktrace: info.componentStack, extra: {client: theClient(), who: fbConfig}});
      }

      console.groupCollapsed(error.message);
      console.log(error);
      console.log(info.componentStack);
      console.groupEnd();

      this.setState({error: info.componentStack.split('\n')[2].split(' (')[0]} as any);
  }

  _renderError = () => (
      <DashboardBlock>
          <ModalHeader title="Problem encountered" />
          <MinimalTile style={{maxWidth: 350}}>
              <div>
          Unfortunately we have encountered an error. If you continue to have trouble please contact support.
              </div>
              <Row justify="center">
                  <Link className="btn btn-primary" to="/dashboard"><Fa icon={faHome} /> Home</Link>
              </Row>
          </MinimalTile>
      </DashboardBlock>
  )

  render() {
      if (this.state && this.state.error) return this._renderError();

      return isMobile ? <StandardMobileLayout2 /> : <StandardDesktopLayout />;
  }
}));

export const NotLoggedInModal = () => <>
    <Router history={history}>
        <GMaps />
    </Router>
    <Portal>
        <div className="modal-overlay">
            <div className="modal-window" tabIndex={-1}>
                <div className="modal-header">
                    <div className="modal-title">Not Authenticated</div>
                </div>
                <div className="modal-body">You are current not logged in</div>
            </div>
        </div>
    </Portal>
</>;

const extracBe = (t: string) => JSON.parse(base64.decode(t.split('.')[1]));

function deserializeAcl(uid: string, acl: AclFb, defaultAcl: DefaultAclFb): UserCan[] {
    const allowed = [];
    Object.entries(acl).forEach(([permission, users]) => {
        const isAllowed = users[uid] === true;
        const isDenied = users[uid] === false;
        const isDefaultPermission = defaultAcl[permission];
        if (isDefaultPermission && !isDenied) {
            allowed.push(permission);
        } else if (isAllowed) {
            allowed.push(permission);
        }
    });
    return allowed;
}

// update the user after official auth
fbapp.auth().onAuthStateChanged(async (user) => {
    // this is some loginc related to logut
    if (!user) {
        store.dispatch({type: 'USER_LOGOUT'});
        localStorage.forget('login-init-be-token');
        localStorage.forget('login-init-user');
        localStorage.forget('cached-user');
        localStorage.forget('active-client');
        localStorage.forget('lastTimeStamp');

        return;
    } else {
        runWatchers();
    }

    localStorage.set('login-init-user', {
        uid: user.uid,
        photoURL: user.photoURL,
        displayName: user.displayName,
        email: user.email,
    });

    if (user && !localStorage.get('login-init-be-token')) {
        const beToken = await fbapp.auth().currentUser.getIdToken(true).then(extracBe);
        localStorage.set('login-init-be-token', beToken);
    }

    const cached = {
        beToken: localStorage.get('login-init-be-token'),
        user: localStorage.get('login-init-user'),
    };

    localStorage.set('active-client', cached.beToken.clientId);



    const acl = await getAcl(cached.beToken.clientId);
    const defaultAcl = await getDefaultAcl();
    const desAcl = deserializeAcl(user.uid, acl, defaultAcl);
    cached.user.acl = {
        can: desAcl
    };

    store.dispatch({type: 'USER_SET', details: cached.user});
    store.dispatch({type: 'SET_BE_TOKEN', token: cached.beToken});
    store.dispatch({type: 'SET_IS_DRIVER'});
});

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        theme="colored"
      />
      <App />
    </Provider>,
    document.getElementById("app")
  );

const intId = setInterval(async () => {
    if (!document.getElementById('app')) return;
    clearInterval(intId);

    if (window['cordova']) {
        try {
            await fbapp.auth().signOut()
            localStorage.forget('login-init-be-token');
            localStorage.forget('login-init-user');
            localStorage.forget('cached-user');
            localStorage.forget('active-client');
            await logoutAPI();
        } catch(e) {
            console.log('logout error');
        }
        render();
    } else {
        render();
    }
}, 10);