import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { faMinusCircle } from '@fortawesome/fontawesome-free-solid';

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../../components/menus/modal/modal-header';
import { Toggler } from '../../elements';
import { watchGeneratedReports, getReportSchedules, removeGeneratedReport } from '../../../shared/db/report-db';
import { iGeneratedReport, iList, iScheduledReport, iFullStoreState, UserAuth } from '../../../shared/interfaces';
import { Table } from '../../general/table';
import Constants from '../../../shared/constants';
import { idValArr } from '../../../shared/helpers';
import { BaseComponent } from '../../../shared/BaseComponent';
import { Fa } from '../../elements/fa';
import { MinimalTile } from '../../general';
type IPropsFromStore = {
    authUser: UserAuth;
}

type IFullProps = IPropsFromStore;

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
    authUser: state.auth.user!,
});

interface iState {schedules: iList<iScheduledReport>, reports: iList<iGeneratedReport>}

class ReportQueue extends BaseComponent<IFullProps, iState> {
    state = {
        schedules: {},
        reports: {},
    };
    watcher;
    __startWatching() {
        this.watcher = watchGeneratedReports(reports => this.setState({reports}));
    }

    __stopWatching() {
        if (this.watcher) this.watcher();
    }

    async componentDidMount() {

        const schedules = await getReportSchedules();

        this.__startWatching();

        this.setState({ schedules })
    }

    componentWillUnmount() {
        this.__stopWatching();
    }

    debugRender = () => {
        const {reports, schedules} = this.state;

        // reports = reports.filter(r => schedules.hasOwnProperty(k))

        return (
            <DashboardBlock>
                {/* <ModalHeader title="Queued Reports" /> */}
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                        {/* Report Search */}
                        {/* JP didn't know what this was for */}
                        {/* <input style={{...C.inputCss, marginBottom: 15}} placeholder="Search Reports" /> */}

                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            {/* Header toggle */}
                            <Toggler onToggle={() => {}} activeIdx={3} >
                                <Link style={{textDecoration: 'none'}} to="/reports/static">Static</Link>
                                <Link style={{textDecoration: 'none'}} to="/reports/travel">Travel</Link>
                                <Link style={{textDecoration: 'none'}} to="/reports/scheduled">Scheduled</Link>
                                <Link style={{textDecoration: 'none'}} to="/reports/queue">Queue</Link>
                            </Toggler>                           
                        </div>
                    </div>
                </div>

                <MinimalTile className="minimal-tile">
                    <Table
                        noMeta
                        style={{fontSize: 11}}
                        perPage={5}
                        columns={[
                            { name: 'Date', prop: 'date', cellMapper: ({startDate}) => startDate.format(Constants.simpleDateFormat)},
                            // { name: 'Format', prop: 'format', cellMapper: ({scheduleId}: iGeneratedReport) => formatToString(schedules[scheduleId].format) },
                            { name: 'File', prop: 'file', cellMapper: ({url}) => <div style={{display: 'flex', width: '100%', justifyContent: 'center', fontSize: 17,}}>
                                <i className={`fa ${!url ? 'fa-spin fa-spinner' : 'fa-download'}`} style={{cursor: url ? 'pointer' : 'not-allowed', }} onClick={() => { if(url) window.location.href = url; }} />
                            </div>},
                            { name: 'Remove', prop: 'remove', cellMapper: ({id}) => (
                                <div
                                    onClick={this.__delete(id)}
                                    style={{cursor: 'pointer', display: 'flex', width: '100%', justifyContent: 'center', fontSize: 17, color: 'darkred'}}
                                >
                                    <Fa icon={faMinusCircle} />
                                </div>
                            )},
                        ]}
                        data={idValArr<iGeneratedReport>(reports).filter(({id}) => schedules.hasOwnProperty(id)).map(({id, val}) => ({id, ...val}))}
                    />
                </MinimalTile>
            </DashboardBlock>
        )
    }

    genReportRemover = removeGeneratedReport(this.props.authUser);

    __delete = id => async () => {
        await this.genReportRemover(id);
    }
}

export default connect(mapStateToProps)(ReportQueue);
