type IsUserHaveFingerPrintgResponse = Promise<{
    has_fingerprint: boolean;
}>

export class FingerprintHelper {
    private callback:Function;
    private secret:string;
    private username:string;
    private cipherMode:string = 'ENCRYPT';
    private dialogMessage:string;

    constructor() {
    }
    public isFingerprintAvailable(callback:Function):void {
        this.callback = callback;
        if (window["device"].platform === "Android") {
            window['FingerprintAuth'].isAvailable(
                this.fpAuthIsAvailableSuccess.bind(this),
                this.fpAuthIsAvailableError.bind(this)
            );
        } else if (window["device"].platform == "iOS") {
            window["plugins"].touchid.isAvailable(
                this.touchIdIsAvailableSuccess.bind(this),
                this.touchIdIsAvailableError.bind(this)
            );
        }
    }

    private fpAuthIsAvailableSuccess(result):void {
        this.callback(null, result);
    }

    private fpAuthIsAvailableError(message):void {
        console.log("fpAuthIsAvailableError(): " + message);
        this.callback(message);
    }
    private touchIdIsAvailableSuccess(result):void {
        result = {isAvailable: true};
        this.callback(null, result);
    }

    private touchIdIsAvailableError(error):void {
        console.log("TouchId error: " + JSON.stringify(error));
        this.callback(error.localizedDescription);
    }

    public authenticate(options:{
        secret:string,
        username:string
        mode?:string,
        ios?: {
            message?:string
        }
    }, callback:Function):void {
        this.callback = callback;
        this.secret = options.secret;
        this.username = options.username;
        if (options.ios) {
            this.dialogMessage = options.ios.message;
        }

        if (options.mode) {
            this.cipherMode = options.mode;
        }
        if (window["device"].platform === "Android") {
            this.androidAuthentication();
        } else if (window["device"].platform == "iOS") {
            this.iosAuthentication();
        }
    }

    private androidAuthentication():void {
        window['FingerprintAuth'].isAvailable(this.androidAuthIsAvailableSuccess.bind(this),
            this.androidAuthIsAvailableError.bind(this));
    }

    private iosAuthentication():void {
        window["plugins"].touchid.isAvailable(
            this.iosAuthIsAvailableSuccess.bind(this),
            this.iosAuthIsAvailableError.bind(this)
        );
    }

    private androidAuthIsAvailableSuccess(result):void {
        if (result.isAvailable) {
            const config = {
                clientId: "com.mastrack.Mobilemap",
                username:  this.username,
                password: this.secret,
                token: this.secret
            };
            if (this.cipherMode === 'ENCRYPT') {
                window['FingerprintAuth'].encrypt(config,
                    this.fpAuthEncryptSuccess.bind(this),
                    this.fpAuthEncryptError.bind(this)
                );
            } else if (this.cipherMode === 'DECRYPT') {
                window['FingerprintAuth'].decrypt(config,
                    this.fpAuthDecryptSuccess.bind(this),
                    this.fpAuthDecryptError.bind(this)
                );
            }

        } else {
            this.callback(null, result);
        }
    }

    private androidAuthIsAvailableError(message):void {
        this.callback(message);
    }

    private iosAuthIsAvailableSuccess(result):void {
        window["plugins"].touchid.verifyFingerprint(
            this.dialogMessage,
            this.touchIdVerifySuccess.bind(this),
            this.touchIdVerifyError.bind(this)
        );
    }

    private iosAuthIsAvailableError(error):void {
        this.callback(error.localizedDescription);
    }

    private fpAuthEncryptSuccess(result):void {
        this.callback(null, result);
    }

    private fpAuthEncryptError(message):void {
        this.callback(message);
    }

    private fpAuthDecryptSuccess(result):void {
        this.callback(null, result);
    }

    private fpAuthDecryptError(message):void {
        this.callback(message);
    }

    private touchIdVerifySuccess(result):void {
        result = {success: true};
        this.callback(null, result);
    }

    private touchIdVerifyError(error):void {
        this.callback(error.localizedDescription);
    }

    public deleteFingerPrintToken() {
        return fetch(`${process.env.REACT_APP_API_URL}/api-fingerprint/delete`,{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"device_id": window['device'].uuid})
        })
    }

    public isUserHaveFingerPrint() {
        return fetch(`${process.env.REACT_APP_API_URL}/api-fingerprint/has`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"device_id": window['device'].uuid})
        }).then(res => res.json() as IsUserHaveFingerPrintgResponse)
    }
}
