import React, { CSSProperties as css, FC } from 'react';
import update from 'immutability-helper';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import C from '../../../shared/constants';
import { range, isMobile } from '../../../shared/helpers';
import { BaseComponent } from '../../../shared/BaseComponent';
import "./DateChoosers.scss";
import RangeDayPicker from '../../elements/RangeDayPicker';
import DayPickerNavbar from '../../elements/DayPickerNavbar';
import DayPickerWeekday from "../../elements/DayPickerWeekday";

interface iDaysChooserProps {
    dates: { startDate: moment.Moment, endDate: moment.Moment }[],
    broadcast: ({ startDate, endDate }: {startDate: moment.Moment, endDate: moment.Moment}) => any
    closeable?: boolean,
    calBottom?: boolean,
    style?: css,
    flat?: boolean,
    remove: (date: moment.Moment) => any
}
export class DaysChooser extends BaseComponent<iDaysChooserProps, {startTime: {hour, minute, ampm}, endTime: {hour, minute, ampm}, open}> {
    __firstOrDefault = (key, defaultMoment) => {
        if (this.props.dates[0] && this.props.dates[0][key]) defaultMoment = this.props.dates[0][key];


        return defaultMoment
    }

    state = {
        open: false,
        startTime: {
            hour: this.__firstOrDefault('startDate', moment().startOf('day')).format('h'),
            minute: this.__firstOrDefault('startDate', moment().startOf('day')).minute(),
            ampm: this.__firstOrDefault('startDate', moment().startOf('day')).format('a'),
        },
        endTime: {
            hour: this.__firstOrDefault('endDate', moment()).endOf('day').format('h'),
            minute: this.__firstOrDefault('endDate', moment()).endOf('day').minute(),
            ampm: this.__firstOrDefault('endDate', moment()).endOf('day').format('a'),
        },
    }

    __dayChange = (date: Date) => {
        const { broadcast, dates, remove } = this.props;
        const { startTime ,endTime } = this.state;
        const startTimeParsed = moment(`${startTime.hour}:${startTime.minute} ${startTime.ampm}`, ["hh:mm A"]);
        const endTimeParsed = moment(`${endTime.hour}:${endTime.minute} ${endTime.ampm}`, ["hh:mm A"]);
        // each date has a start and and end date that differ only in time
        const startDate = moment(date).hour(startTimeParsed.hours()).minute(this.state.startTime.minute);
        const endDate = moment(date).hour(endTimeParsed.hours()).minute(this.state.endTime.minute);

        const isAlreadyActive = dates.find(d => d.startDate.format('YYYYMMDD') == startDate.format('YYYYMMDD'));

        isAlreadyActive ? remove(startDate) : broadcast({ startDate, endDate });
    }

    __handleStartTimeChange = ({hours, minutes, ampm}) => {
        const { dates, broadcast } = this.props;
        const time = moment(`${hours}:${minutes} ${ampm}`, [`hh:mm A`]);

        dates
            .map(date => ({
                startDate: date.startDate.clone().set({
                    hours: time.hours(),
                    minutes: time.get("minutes")
                }),
                endDate: date.endDate
            }))
            .forEach(broadcast);

        this.setState({startTime: {hour: hours, minute: minutes, ampm}});
    }

    __handleEndTimeChange = ({hours, minutes, ampm}) => {
        const { dates, broadcast } = this.props;
        const time = moment(`${hours}:${minutes} ${ampm}`, [`hh:mm A`]);
        dates
            .map(date => ({
                startDate: date.startDate.clone().set({
                    hours: time.hours(),
                    minutes: time.get("minutes")
                }),
                endDate: date.endDate
            }))
            .forEach(broadcast);

        this.setState({endTime: {hour: hours, minute: minutes, ampm}});
    }

    debugRender = () => {
        // start date and end date y-m-d will always match
        const { flat=false, calBottom = false, closeable = false, style={}, dates = [] } = this.props;
        const { startTime, endTime, open } = this.state;

        return (
            <div className="date-choosers">
                <div style={{display: 'flex', flexDirection: flat && !isMobile ? 'row' : 'column'}}>
                    {!open && closeable ? null :
                        <DayPicker
                            // @ts-ignore
                            style={{order: calBottom ? 2 : 1}}
                            className="day-picker"
                            initialMonth={dates[0] ? dates[0].startDate.toDate() : new Date()}
                            onDayClick={this.__dayChange}
                            selectedDays={dates.map(date => date.startDate.toDate())}
                            navbarElement={DayPickerNavbar}
                            weekdayElement={DayPickerWeekday}
                            captionElement={() => null}
                            disabledDays={{after: new Date()}}
                            showOutsideDays
                        />
                    }
                    <div className="time-options-container">
                        <div>
                            <strong className="time-options-header">Start Date:</strong>
                            <TimeOptions
                                hours={startTime.hour}
                                minutes={startTime.minute}
                                ampm={startTime.ampm}
                                onTimeChange={this.__handleStartTimeChange}
                                // hourChange={this.__changeHour(true)}
                                // minuteChange={this.__changeMinute(true)}
                                // ampmChange={this.__changeAmPm(true)}
                            />
                        </div>
                        <div>
                            <strong className="time-options-header">End Date:</strong>
                            <TimeOptions
                                hours={endTime.hour}
                                minutes={endTime.minute}
                                ampm={endTime.ampm}
                                onTimeChange={this.__handleEndTimeChange}
                                // hourChange={this.__changeHour(false)}
                                // minuteChange={this.__changeMinute(false)}
                                // ampmChange={this.__changeAmPm(false)}
                            />
                        </div>
                    </div>
                </div>
                {/* {
                    dates.length > 0
                    ? <div style={{flex: 1, display: 'flex', flexDirection: flat ? 'row' : 'column', flexWrap: 'wrap', maxHeight: 80, overflow: 'auto', marginTop: isMobile ? 15 : 0, width: isMobile ? 216 : 'auto'}} >
                          {dates.map(x => x.startDate).sort(momentCmp).map(date =>
                              <StandardItem key={date.valueOf()} style={{margin: 5, height: 20}} displayName={date.format('ll')} itemId={date.format('YYYY-MM-DD')} remove={_ => this.props.remove(date)} />
                          )}
                      </div>
                    : null
                } */}
            </div>
        )

    }
}


interface iProps {
    initialStart:moment.Moment,
    initialEnd: moment.Moment,
    calendarHideable: boolean;
    calendarTop: boolean;
    style?: css,
    startDateChange: (date: moment.Moment) => any;
    endDateChange: (date: moment.Moment) => any;
}

export class RangeDateChooser extends BaseComponent<iProps, any> {
    // __changer = isStart => newDate => {
    //     const { startDateChange, endDateChange } = this.props;

    //     isStart ? startDateChange(newDate) && endDateChange(moment(newDate).add(1, 'day')) : endDateChange(newDate);
    // }

    state = {
        startTime: {
            hour: moment().startOf('day').format('h'),
            minute: moment().startOf('day').minute(),
            ampm: moment().startOf('day').format('a'),
        },
        endTime: {
            hour: moment().endOf('day').format('h'),
            minute: moment().endOf('day').minute(),
            ampm: moment().endOf('day').format('a'),
        },
        range: null,
    }

    __change = (newRange: {from: Date, to: Date}) => {
        const { startDateChange, endDateChange } = this.props;
        const { startTime, endTime } = this.state;
        const startTimeParsed = moment(`${startTime.hour}:${startTime.minute} ${startTime.ampm}`, [`hh:mm A`]);
        const endTimeParsed = moment(`${endTime.hour}:${endTime.minute} ${endTime.ampm}`, [`hh:mm A`]);

        if (newRange.from && newRange.to) {
            startDateChange(moment(newRange.from).set({hours: startTimeParsed.hours(), minutes: startTimeParsed.minutes()}));
            endDateChange(moment(newRange.to).set({hours: endTimeParsed.hours(), minutes: endTimeParsed.minutes()}));
            this.setState({range: newRange});
        }
    }

    __handleStartTimeChange = ({hours, minutes, ampm}) => {
        this.setState({
            startTime: {hour: hours, minute: minutes, ampm}},
            () => this.state.range && this.__change(this.state.range)
        );
    }
    __handleEndTimeChange = ({hours, minutes, ampm}) => {
        this.setState(
            {endTime: {hour: hours, minute: minutes, ampm}},
            () => this.state.range && this.__change(this.state.range)
        );
    }

    debugRender = () => {
        const { initialStart: startDate, initialEnd: endDate, calendarTop, calendarHideable, style={} } = this.props;
        const { startTime, endTime } = this.state;

        return (
            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', flex: 1, flexWrap: 'wrap', ...style}}>
                <RangeDayPicker defaultRange={{ startDate: startDate?.toDate(), endDate: endDate?.toDate()}} onRangeChange={this.__change} />
                <div className="time-options-container">
                    <div>
                        <strong className="time-options-header">Start Date:</strong>
                        <TimeOptions
                            hours={+startTime.hour}
                            minutes={startTime.minute}
                            ampm={startTime.ampm}
                            onTimeChange={this.__handleStartTimeChange}
                            // hourChange={this.__changeHour(true)}
                            // minuteChange={this.__changeMinute(true)}
                            // ampmChange={this.__changeAmPm(true)}
                        />
                    </div>
                    <div>
                        <strong className="time-options-header">End Date:</strong>
                        <TimeOptions
                            hours={+endTime.hour}
                            minutes={endTime.minute}
                            ampm={endTime.ampm}
                            onTimeChange={this.__handleEndTimeChange}
                            // hourChange={this.__changeHour(false)}
                            // minuteChange={this.__changeMinute(false)}
                            // ampmChange={this.__changeAmPm(false)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

type TimeOptionsProps = {
    hours: number,
    minutes: number,
    ampm: string,
    onTimeChange: (time: {hours: number, minutes: number, ampm: string}) => void
}

const TimeOptions: FC<TimeOptionsProps> = ({hours, minutes, ampm, onTimeChange}) => {
    const handleTimeChange = ({target: {value, name}}) => {
        onTimeChange({
            hours, minutes, ampm,
            [name]: value
        });
    }

    return (
        <div className="time-options">
            <select className="time-options__mas-input" name="hours" onChange={handleTimeChange} value={hours}>
                { simpleHours }
            </select>

            <select className="time-options__mas-input" name="minutes" onChange={handleTimeChange} value={minutes}>
                { minuteOptions }
            </select>
            <select className="time-options__mas-input" name="ampm" onChange={handleTimeChange} value={ampm}>
                <option value="am">AM</option>
                <option value="pm">PM</option>
            </select>
        </div>
    );
}

const minuteOptions = range(0, 59).map(m => <option key={m} value={m}>{m < 10 ? `0${m}` : m}</option>);
const simpleHours = range(1,12).map(h => <option key={h} value={h}>{h}</option>);

const hourOptions = range(0, 23).map(hour => {
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const humanHour = (hour > 12 ? hour - 12 : hour) || 12;

    const dispHour = `${humanHour}`.length > 1 ? `${humanHour}` : ` ${humanHour}`

    return <option key={hour} value={hour}>{dispHour}:00 {ampm}</option>
})

class DateTime extends BaseComponent<{ title, date, format, change, calendarTop, alwaysShow }, { showCal: boolean, tempstore?: any, calendarTop: boolean, alwaysShow: boolean, date: moment.Moment }> {
    state = {
        showCal: false || this.props.alwaysShow,
    } as any;

    private toggleCal = () => this.setState(s => ({ showCal: !s.showCal || this.props.alwaysShow }));

    private handleTimeChange = (type, isTwentyfour: boolean) => ({ target: { value }}) => {
        const { date, change } = this.props;

        const initial = date.clone();

        if (!date) return this.setState(s => update(s, {
            tempstore: { [type]: { $set: value }}
        }))

        this.setState(s => ({
            tempstore: null
        }))

        if (type == 'minute') return change(date.clone().minute(value));

        if (type == 'hour') return change(date.clone().hour(
            // if the user is passing a 12 hour we need to add 12 if we are workign in the pm
            parseInt(value) + (!isTwentyfour && date.format('a') == 'pm' ? 12 : 0)

        ))

        if (type == 'ampm') {
            if (value == 'am' && date.format('a') == 'pm') {
                value = date.hour() - 12;
            } else if (value == 'pm' && date.format('a') == 'am') {
                value = date.hour() + 12;
            } else {
                value = date.hour();
            }

            return change(date.clone().hour(value));
        }
    }

    private changeDate = hour => date => {
        const asMoment = moment(date);

        if (this.state.tempHourStore) {
            asMoment.hour(this.state.tempHourStore);
            this.setState(s => ({
                tempHourStore: null
            } as any))
        } else if (hour) {
            asMoment.hour(hour);
        } else {
            asMoment.hour(0);
        }

        this.props.change(asMoment);

        this.toggleCal()
    }

    debugRender = () => {
        const { title, date, format, calendarTop, alwaysShow } = this.props;
        const { showCal } = this.state;

        return (
            <DayPicker
                className="report-selectable"
                month={date ? date.toDate() : new Date()}
                onDayClick={this.changeDate(date ? date.hour() : 0)}
                selectedDays={[date.toDate()]}
            />
        )
    }



}

const rightAddonStyle: css = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${C.primaryColor}`,
    borderLeft: 'none',
    marginLeft: -1,
    padding: '0 5px',
    cursor: 'pointer',
}

