import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { iFullStoreState } from '../shared/interfaces';

type fnReturn<T> = (val: T) => any;

export const useReduxPath = <T>(fn: ((s: iFullStoreState) => T), path?: string[]): [T, fnReturn<T>, (() => void)] => {
    const dispatch = useDispatch();
    const state = useRedux(fn);

    const dispatcher: any = (val: T) => { dispatch({ type: 'GEN_SET', path, val }); }
    const unsetter: any = () => { dispatch({ type: 'GEN_SET', path, val: undefined }); }

    return [
        state as T,
        dispatcher,
        unsetter
    ];
}

export const useRedux = <T>(fn: ((s: iFullStoreState) => T), equalityFn: ((t: T) => boolean) = undefined): T => useSelector(fn, equalityFn);