import React, { CSSProperties as css } from 'react';
import classNames from "classnames";
import { faSearch, faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';

import { BaseComponent } from '../../../shared/BaseComponent';
import C from '../../../shared/constants';
import { Fa } from '../../elements/fa';
import {iProps} from "./types";
import "./index.scss";
import { Button, ButtonSize, ButtonStyles } from '../../Button';
import { Link } from 'react-router-dom';

export default class SearchGrid extends BaseComponent<iProps, {page: number}> {

    state = {
        page: 1
    }

    displayItems = () => {
        const { perPage, list, RedirectToStatic, selectedCount, countOfSelectedFilters, currentFilterObject, filterType } = this.props;
        const { page } = this.state;
        const someFiltersMoreThanTwo = Object.entries(countOfSelectedFilters).some(([key,value]) => value >= 2)

        const checkingAccessForMultipleFilters = ({item}) => {
            const condition = (someFiltersMoreThanTwo) && (selectedCount == 1) && (Object.keys(currentFilterObject[filterType])[0] !== item)
            const disableOtherFilter = condition ? true : false;
            return disableOtherFilter
        }
        
            return list
            .filter((i, idx) => idx >= perPage * (page - 1) && idx < perPage * (page -1) + perPage )
            .map(element => {
                const disableFilters = checkingAccessForMultipleFilters({item: element.key});
                return RedirectToStatic ?
                    <Link key={element.key} to={RedirectToStatic && 'static'} className={classNames({ "search-item": true, 'disable': disableFilters ? true : false})} onClick={_ => this.props.keyClicked(element.key.toString())}>{element}</Link>
                    :
                    <div key={element.key} className={classNames({ "search-item": true, 'disable': disableFilters ? true : false})} onClick={_ => this.props.keyClicked(element.key.toString())}>{element}</div>
            })
    }

    private get pageCount() {
        return Math.ceil(this.props.list.length / this.props.perPage );
    }

    filterChange = ({ target }) => {
        this.props.filterChange(target.value);
    }

    changePage = (amount) => this.setState(state => {
        let newPage = this.state.page + amount;
        if (newPage < 1) newPage = 1;
        if (newPage > this.pageCount) newPage = this.pageCount;

        return { page: newPage };
    })


    debugRender = () => {
        const { placeholder, style = {} } = this.props;
        const { page } = this.state;

        return (
            <div className="search-grid" style={style}>
                <div className="search-grid-search">
                    <div className="search">
                        <input type="text" placeholder={placeholder} value={this.props.filterStr} onChange={this.filterChange} />                    
                    </div>
                    <div className="reset" onClick={this.props.onReset}>
                        <Button 
                            styleType={ButtonStyles.BLACK_WHITE}
                            size={ButtonSize.MD}
                        >
                            Reset to all
                        </Button>
                    </div>
                </div>
                <div className="search-items">{this.displayItems()}</div>
                <Pager page={page} pages={this.pageCount} change={this.changePage}/>
            </div>
        )
    }
}

const chev: css = {
    color: C.primaryColor,
    cursor: 'pointer',
    padding: 10
}
const disabled: css = {
    color: C.mediumGray,
    cursor: 'not-allowed'
}
const pageDot: css = {
    width: 12, height: 12, borderRadius: 12,
    borderWidth: 1,
    borderColor: C.mediumGray,
    borderStyle: 'solid',
    margin: '0 5px',
    display: 'inline-block',
    cursor: 'pointer',
}
const pageDotCur: css = {
    backgroundColor: C.primaryColor,
    borderColor: C.primaryColor,
    cursor: 'default',
}

const Pager = ({ page, pages, change }) => {
    if (pages < 2) return null;

    let pageNums = Array(pages).fill(1).map((_, p) => p + 1);

    return <div className="pager-container">
        <Fa icon={faChevronLeft} onClick={_ => change(-1)} style={{...chev, ...(page == 1 ? disabled : {})}}/>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {pageNums.map(p => (
                <span 
                    key={p}
                    style={{...pageDot, ...(page == p ? pageDotCur : {})}}
                    onClick={_ => change(p - page)}
                />
            ))}
        </div>
        <Fa icon={faChevronRight} onClick={_ => change(1)} style={{...chev, ...(page == pages ? disabled : {})}}/>
    </div>
}

const itemStyle: css = {
    minWidth: 80,
    flex: 1,
    minHeight: 35,
    marginLeft: -1,
    marginBottom: -1,
    borderBottom: '1px solid ' + C.lightGray,
    borderLeft: '1px solid ' + C.lightGray,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    cursor: 'pointer',
}

const containerCss: css = {
    border: '1px solid '+C.lightGray,
    display: 'flex',
    flexWrap: 'wrap',
}