import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { iReducersState } from "../../../../../../stores/reducers";

const Player = ({url, isPlaying, setCurrentTime, setPlayedSeconds, updateTime, isControls }): JSX.Element => {
    const { modals } = useSelector((state: iReducersState) => state.videoCameras);

    const playerRef = useRef(null);

    const handleProgress = state => {
        if(!modals.openContinueLiveStreamingModal) {
            setCurrentTime(state.playedSeconds);
            setPlayedSeconds(Math.round(state.playedSeconds));
        } else {
            setCurrentTime(0)
        }
    }

    const handlePlay = (): void => console.log('Play');
    // const handleBuffer = async (): Promise<void> => await updateTime();
    const onError = (): void => console.error('Error');

    // const handlePause = async (): Promise<void> => {
        // await updateTime();
    // }

    const flv = {
        file: {
            flvVersion: "1.6.2",
            dashVersion: "4.4.0",
        },
    };

    const hls = {
        file: {
            flvVersion: "1.6.2",
            dashVersion: "4.4.0",
            hlsVersion: "1.1.5",
            forceHLS: true,
            hlsOptions: {
                autoStartLoad: true,
                enableWorker: true,
            },
        },
    };

    const checkFlvFormat = url.includes(".flv");
    const configSettings = checkFlvFormat ? flv : hls;

    return (
        <>
            <ReactPlayer
                ref={playerRef}
                className='react-player'
                width='100%'
                height='100%'
                url={url}
                pip={false}
                playing={isPlaying}
                controls={isControls}
                onProgress={handleProgress}
                onPlay={handlePlay}
                // onPause={handlePause}
                // onBuffer={handleBuffer}
                onError={onError}
                config={configSettings}
            />
        </>
    )
}

export default Player;
