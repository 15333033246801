import React, { CSSProperties } from 'react';
import ReactFileReader from 'react-file-reader';
import { values } from 'ramda';
import { Subject, Subscription } from 'rxjs';
import { faPlus, IconDefinition, default as freeIcons, faFontAwesomeLogoFull } from '@fortawesome/fontawesome-free-solid';

import C from '../../shared/constants';
import { Fa } from '../elements/fa';
import Dialog, { DialogConfigSetter } from '../Dialog';

const excludedIcons = ['arrow','up','down','left','right','user','align',
    'circle','battery','money','calendar','card','chart','comment', 'dice',
    'alt','list','chart','file','hand','end','alt','list', 'chart','file',
    'mars','thermometer','square','bar','check','board','divide','ellipsis']

const iconOmitted = (ico: IconDefinition) => {
    return [faFontAwesomeLogoFull.iconName].indexOf(ico.iconName) === -1 &&
        !(excludedIcons.some( item => ico.iconName.includes(item)))
}

export type IconSelect = () => Promise<IconDefinition | File | undefined>

type IconChooserProps = {
    setSelectIcon: (selectIcon: () => IconSelect) => void;
}

export const IconChooser = ({setSelectIcon}: IconChooserProps) => {
    const selectIcon$ = React.useRef(new Subject<IconDefinition | File | undefined>());

    React.useEffect(() => {
        let subscription: Subscription | undefined;

        setSelectIcon(() => () => {
            const dialog = dialogRef.current;

            dialog?.({type: 'NOTIFICATION'}).then(confirmed => {
                selectIcon$.current.next(undefined);
            });

            return new Promise<IconDefinition | File | undefined>(resolve => {
                subscription = selectIcon$.current.subscribe(icon => {
                    resolve(icon);
                });
            });
        });
        selectIcon$.current.next(undefined);

        return () => subscription?.unsubscribe();
    }, []);

    const icons: IconDefinition[] = values(freeIcons).filter(iconOmitted);

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter): void => {
        dialogRef.current = callBack();
    };
    const selectIcon = (icon: IconDefinition | File) => {
        selectIcon$.current.next(icon);

        const dialog = dialogRef.current;

        dialog?.({close: true});
    };
    const handleFiles = (files: Array<File>) => {
        const [icon] = files;

        selectIcon(icon);
    };
    return (
        <Dialog setupConfig={setupDialog} title="Select Icon">
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                <div style={itemStyle}>
                    <ReactFileReader handleFiles={handleFiles}>
                        <div className="mas-primary-btn" style={imgStyle}>
                            <Fa icon={faPlus} style={{ fontSize: 22 }} />
                            <div style={{ fontSize: 20, textAlign: 'center' }}>Upload Image</div>
                        </div>
                    </ReactFileReader>
                </div>

                {icons.map(fa =>
                    <div style={itemStyle} key={fa.iconName}>
                        <div
                            key={fa.iconName}
                            className="mas-primary-btn"
                            style={imgStyle}
                            onClick={_ => selectIcon(fa)}
                        >
                            <i style={{ fontSize: '5em' }} />
                            <Fa icon={fa} />

                        </div>
                        <p title={fa.iconName} style={iconLabelStyle}>{fa.iconName}</p>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

const itemStyle: CSSProperties = {
    width: 90,
    margin: 10
}

const imgStyle: CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${C.lightGray}`,
    borderRadius: 3,
    fontSize: 57,
    cursor: 'pointer',
}

const iconLabelStyle: CSSProperties = {
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 0
}