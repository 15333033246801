import React from "react";
import Modal from "../../../../Modal";
import BillingButton from "../BillingButton";
import { IConfirmModalProps } from "./interfaces";
import "./ConfirmModal.scss";

const ConfirmModal = ({ cancel, planCancel, cancelLoading, isEOL, setShowSwitcher }: IConfirmModalProps) => {
  return (
    <Modal>
      <div className="modal__content" style={{ width: "40vw" }}>
        <div className="modal__header">
          <div className="modal__header-row">
            <h2>Notice for Reactivation</h2>
            <button onClick={() => cancel(false)}>X</button>
          </div>
        </div>
        <hr />
        <div className="modal__confirm">
          <div className="modal__confirm-content">
            {isEOL ? (
              <>
                <p>
                  Due to recent changes in technology, the wireless service
                  provider has placed restrictions to any re-activation of
                  service to this device. Service to this device must be
                  reactivated within 6 months of the cancel date.
                </p>
                <h5>
                  After 6 months re-activating service to this device will not
                  be possible.
                </h5>
                <p>
                  You may contact{" "}
                  <a className="link" href={"mailto:" + "maail"}>
                    support@mastrack.com{" "}
                  </a>{" "}
                  OR call{" "}
                  <a className="link" href={"tel:" + "phone"}>
                    800-559-8991
                  </a>{" "}
                  to inquire about alternative options.
                </p>
              </>
            ) : (
              <p>Confirm cancel</p>
            )}
          </div>
          <div className="modal__confirm-btn">
            <BillingButton
              text="Continue and cancel Service"
              loading={cancelLoading}
              color="red"
              action={() => planCancel()}
              // action={() => { cancel(false); setShowSwitcher(true) }}
            />
            <BillingButton
              text="Stop and keep service active"
              color="green"
              action={() => cancel(false)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
