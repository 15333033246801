import { ACTIONS } from "./action.cameras";

const initialState = {
  modals: {
    openHistoryModal: false,
    openHistoryPlayer: false,
    openLiveViewModal: false,
    openLiveViewPlayer: false,
    openBuyMoreModal: false,
    openContinueLiveStreamingModal: false,
  },
  cameraOnline: false,
  availableTime: 0,
  deviceInfo: {
    id: '',
    alertDate: '',
    alertTime: ''
  }
};

export const videoCameras = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.key]: action.payload.value,
        },
      };
    case ACTIONS.STATUS_CAMERA_ONLINE:
      return {
        ...state,
        cameraOnline: action.payload,
      };
    case ACTIONS.AVAILABLE_TIME:
      return {
        ...state,
        availableTime: action.payload,
      };
    case ACTIONS.TAKE_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: {
          ...state.deviceInfo,
          id: action.payload.id,
          alertDate: action.payload.alertDate,
          alertTime: action.payload.alertTime,
        },
      };
    case ACTIONS.RESET_ALERT_DATE:
      return {
        ...state,
        deviceInfo: {
          id: '',
          alertDate: '',
          alertTime: ''
        }
      }
      case ACTIONS.RESET_CAMERAS_STATE:
        return initialState;

    default:
      return state;
  }
};

export type VideoCamerasState = ReturnType<typeof videoCameras>;
