import { createSelector } from 'reselect';
import { filter, map, pipe, toPairs, cond, equals, always, prop, T, unnest, values, fromPairs } from 'ramda';

import { extractDevice } from '../helpers';
import { ItemType, iFullStoreState, iList, iTag } from '../interfaces';

const getExtraInfos = (state: iFullStoreState, params) => state.general.extraInfo

const getType = (state ,{ type, itemId }) => type
const getItemId = (state, { type, itemId }) => itemId
const getPeople = ( { general : { people } }) => people
const getDevicesDetails = ( { devicesData }) => devicesData.devicesDetails;
const getTags = ({ general: { tags }}) => tags
const getFences = ( { general: { fences }}) => fences

export const getItemSelector = createSelector(
    getType,
    getItemId,
    getPeople,
    getDevicesDetails,
    getTags,
    getFences,
    (type, itemId, people, devicesDetails, tags, fences) => cond([
        [equals(ItemType.device), always(extractDevice(devicesDetails, itemId))],
        [equals(ItemType.person), always(people[itemId])],
        [equals(ItemType.tag), x => {
            if (!tags[itemId]) {
                console.log('!tags[itemId]',{tags, itemId, itemType: x})
            }
            return tags[itemId].details
        }],
        [equals(ItemType.fence), () => (pipe as any)(
            values,
            map(values),
            unnest,
            map(toPairs),
            unnest,
            fromPairs,
            prop(itemId),
        )(fences)],
        [T, () => {
            throw Error('trying to get item with unknown selector type')
        }]
    ])(type)
)

export const getItemLabelNameValMap = createSelector(
    getItemSelector,
    getExtraInfos,

    (infoInst = {}, infos) => pipe(
        prop('extra-info'),
        toPairs,
        filter(([id, _]) => id in infos),
        map(([id, val]: any) => ([infos[id].details.name, val])),
    )(infoInst)
)

const getItemTags = (state: iFullStoreState, {itemType, itemId}: { itemType: ItemType, itemId: string }): iList<iTag> => {
    const {general: {tags}} = state;

    return Object.entries(tags).reduce(
        (r, [id, tag]) => tag.instances?.[itemType]?.[itemId] ? {...r, [id]: tag} : r,
        {} as iList<iTag>,
    );
};

// using this for react hooks
export const itemTags = (tags, itemType: ItemType, itemId: string) => getItemTags({ general: { tags }} as any, { itemType, itemId });

export const getItemTagsSelector = createSelector(
    getItemTags, itemTags => itemTags
)
