import update from 'immutability-helper';

// todo: Remove this
class ActionsClass {
    SET_EDIT_MODE = isEditing => ({type: 'FENCES_EDIT_MODE', isEditing})
}

export const Actions = new ActionsClass;

export const reducers = (state: iState = initialState, action) => {
    if (action.type == 'FENCES_EDIT_MODE') return update(state, {
        isEditing: { $set: action.isEditing }
    })

    return state;
}

export enum FenceTab {
    MAP = 'map',
    FEN ='fences',
    DEVICES= 'vis-tag-devices',
}
export interface iState {
    currentTab: FenceTab,
    isEditing: boolean,
}

const initialState = {
    currentTab: FenceTab.FEN,
    isEditing: false,
}


