import * as color from "color";
import * as generaldb from "../../../shared/db/general-db";
import * as tagsdb from "../../../shared/db/tags-db";

import { Button, ButtonSize, ButtonStyles } from "../../Button";
import Dialog, { DialogConfig, DialogConfigSetter } from "../../Dialog";
import { DispatchProp, connect, useSelector } from "react-redux";
import {
  ItemType,
  UserAuth,
  iDeviceDetails,
  iFullStoreState,
  iList,
  iTag,
} from "../../../shared/interfaces";
import React, {
  CSSProperties as css,
  useEffect,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  assoc,
  dissoc,
  filter,
  keys,
  mapObjIndexed,
  path,
  pathOr,
  pipe,
  prop,
  test,
  values,
} from "ramda";
import { faBell, faBellSlash } from "@fortawesome/fontawesome-free-regular";
import {
  hideAllTags,
  showAllDevices,
  showTagDevices,
  toggleShowSelectedTagDevice,
} from "../../../stores/reducers/tagsDevicesMap/AC";
import { makeAudit, vals } from "../../../shared/helpers";

import C from "../../../shared/constants";
import DashboardBlock from "../../DashboardBlock";
import { DevicesDetailsContainer } from "../../../stores/reducers/devicesData";
import { ExtraInfo } from "../../elements/extra-info";
import { Fa } from "../../elements/fa";
import { GithubPicker } from "react-color";
import { Icon } from "../../elements/icon";
import { IconBox } from "../../general/person-box";
import { MinimalTile } from "../../general/tile";
import ModalHeader from "../../menus/modal/modal-header";
import SearchGridOld from "../../SearchGridOld/SearchGridOld";
import { UiActions } from "../../../stores/reducers";
import { clientDb } from "../../../shared/firebase";
import { containerWrapper } from "../reports/ReportSelectors";
import { createSelector } from "reselect";
import { faBell as faSolidBell } from "@fortawesome/fontawesome-free-solid";
import { getItemLabelNameValMap } from "../../../shared/db/tags-labels-selectors";
import { getTagReadOnlyStatus } from "../../../shared/db/general-db";
import { selectDevices } from "../../../stores/reducers/devicesData/selector";
import { selectIconContext } from "../../../shared/contexts/selectIcon.context";
import { withSentry } from "../../../shared/hoc/withSentry";
import { useRedux } from "../../../states/redux-state";
import GoogleMap from "react-google-maps/lib/components/GoogleMap";


const taggedDeviceIdsSelector = (
  tagId: string
): ((state: iFullStoreState) => ITaggedDeviceIds) =>
  pathOr({}, ["general", "tags", tagId, "instances", "device"]);
type ITaggedDeviceIds = { [deviceId: string]: true };

const deviceListSelector = (tagId) =>
  createSelector(
    (state: iFullStoreState) => state.devicesData.devicesDetails,
    path(["ui", "tagDeetsDeviceFilter"]),
    taggedDeviceIdsSelector(tagId),
    (devicesDetails, filterStr: string, taggedDeviceIds: ITaggedDeviceIds) =>
      pipe(
        filter(pipe(prop("name"), test(new RegExp(filterStr, "i")))),
        mapObjIndexed(
          (device: iDeviceDetails) =>
            (
              <IconBox
                itemId={device.id}
                active={device.id in taggedDeviceIds}
                title={device.name}
                {...device.icon}
              />
            ) as any
        ),
        mapObjIndexed((el, id) =>
          containerWrapper(id, taggedDeviceIds[id])(el)
        ),
        values
      )((devicesDetails as DevicesDetailsContainer).toObject())
  );

const mapStateToProps = (
  state: iFullStoreState,
  ownProps: IProps
): IPropsFromStore => ({
  authUser: state.auth.user,
  tags: state.general.tags,
  currentLabels: getItemLabelNameValMap(state, {
    type: ItemType.tag,
    itemId: ownProps.match.params.tagId,
  }),
  deviceFilterStr: state.ui.tagDeetsDeviceFilter,
  deviceEls: deviceListSelector(ownProps.match.params.tagId)(state),
});

type IProps = RouteComponentProps<{ tagId: string }>;

type IPropsFromStore = {
  authUser: UserAuth;
  tags: iList<iTag>;
  currentLabels: [string, string][];
  deviceFilterStr: string;
  deviceEls: any;
};
type IFullProps = IProps & IPropsFromStore & DispatchProp;

const confirmModalConfig: DialogConfig = {
  title: "Alert Confirm Modal",
  body: "Only one alert type can be attached to device. Confirm to continue",
  type: "CONFIRM",
};

function TagDetailsPage(props: IFullProps) {
  const {
    tags,
    dispatch,
    currentLabels,
    deviceFilterStr,
    deviceEls,
    authUser,
  } = props;
  const { tagId } = props.match.params;
  const tag = tags[tagId];
  const devices = useSelector(selectDevices);
  const devicesId = [...devices.keys()];
  const taggedDeviceIdsList = useSelector(taggedDeviceIdsSelector(tagId));
  const taggedDeviceIds = Object.keys(taggedDeviceIdsList);
  const [isAllDeviceSelected, setIsAllDeviceSelected] = useState(() => {
    if (taggedDeviceIds.length === devicesId.length) {
      return true;
    }
    if (taggedDeviceIds.length === 0) {
      return false;
    }
  });
  
  const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>((state) => state.gmap.mapRef());

  const devicesMarker = useRedux((s) => s.devicesData.devicesLastPing)
    .valueSeq()
    .toArray()
    .filter((it) => it !== null);
    
  const { selectIcon } = React.useContext(selectIconContext);

  const dialogRef = React.useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());

  const showDevices = () => {
    dispatch(showTagDevices(tagId));
  };

  const removeAllTags = () => {
    dispatch(hideAllTags());
  };

  React.useEffect(() => {
    removeAllTags();
    showDevices();

    return () => {
      removeAllTags();
    };
  }, [tagId]);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const readOnlyTag = await getTagReadOnlyStatus(authUser.uid, tagId);
      if (readOnlyTag) {
        await dialog?.({
          title: "Permission error",
          body: "This tag is read only",
          type: "NOTIFICATION",
          afterClose: () => history.goBack(),
        });
      }
    })();
  });

  const changeImage = async () => {
    try {
      let icon = await selectIcon?.();

      if (!icon) {
        return;
      }

      if (typeof icon == "object" && "icon" in icon) {
        icon = {
          // @ts-ignore
          fa: icon,
        };
      } else {
        icon = {
          // @ts-ignore
          url: await generaldb.saveIcon(icon as File),
        };
      }

      await tagsdb.update(authUser)(tagId, { icon });
    } catch (e) {
      console.log("change image error", e);
      dialog?.({
        title: "Permission error",
        body: "You don't have permission to edit tags",
        type: "NOTIFICATION",
      });
    }
  };
  const setToAlert = (on: boolean) => async (_) => {
    // prevent changing if already tagging something
    // if this changes we will need to handle case where tag is shared on device or is on a person etc

    if (on && vals(tag.instances).length) {
      dialog?.({
        title: "Ineligible",
        body: "This tag is assigned to a device or person and is ineligible for conversion into an alert type tag.",
        type: "NOTIFICATION",
      });
      return;
    }

    if (on && !(await dialog?.(confirmModalConfig))) return;

    try {
      tagsdb.update(authUser)(tagId, {
        isAlertType: on,
        eventValues: null,
      });
    } catch (e) {
      console.log("set to alert error", e);
      dialog?.({
        title: "Permission error",
        body: "You don't have permission to edit tags",
        type: "NOTIFICATION",
      });
    }
  };

  const setColor = async ({ hex: color }) => {
    try {
      await tagsdb.update(authUser)(tagId, { color });
    } catch (e) {
      console.log("e", e);
      dialog?.({
        title: "Permission error",
        body: "You don't have permission to edit tags",
        type: "NOTIFICATION",
      });
    }
  };

  const toggleSelectedDevice = async (deviceId: string) => {
    if (!tag.details.isAlertType) {
      try {
        await toggleDeviceTag(deviceId);
        dispatch(toggleShowSelectedTagDevice(tagId, deviceId));
      } catch (e) {
        dialog?.({
          title: "Permission error",
          body: "You don't have permission to edit tags",
          type: "NOTIFICATION",
        });
      }
    }
  };

    // const toggleDeviceTag = async (deviceId: string) => {
    //     /**TODO in next line there is something wrong
    //      * deviceTags - has function type (string) => any,
    //      * but in code below deviceTags uses as object
    //      */
    //     let deviceTags = pathOr({}, path(['tag', 'instances', 'device', deviceId])(deviceId));
    //
    //     if (path(['instances', 'device', deviceId], tag)) {
    //         deviceTags = dissoc(tagId, deviceTags) as any;
    //     } else {
    //         // if (tag.details.isAlertType) {
    //             // const otherAlertTag = pipe(
    //             //     // alert types
    //             //     filter(pathEq(['details', 'isAlertType'], true)),
    //             //     // that this device has
    //             //     filter(path(['instances', 'device', deviceId])),
    //             //     // that isn't current tag
    //             //     filter(pipe(pathEq(['details', 'id'], tagId), not)),
    //             //
    //             //     // first (only)
    //             //     values,
    //             //     propOr(false, 0)
    //             // )(tags as any) as false|iTag;
    //             // const dialog = dialogRef.current;
    //             //
    //             // if (!otherAlertTag || !(await dialog?.({title: `Remove tag ${otherAlertTag.details.name}`, body: 'test'}))) return false;
    //             //
    //             // deviceTags = dissoc(otherAlertTag.details.id, deviceTags) as any
    //         // }
    //         deviceTags = assoc(tagId, true, deviceTags)
    //     }
    //
    //     try {
    //         await tagsdb.setItemTags(authUser)(ItemType.device, deviceId, keys(deviceTags), tagId);
    //     } catch(e) {
    //         console.log("toggle device tag error", e);
    //         dialog?.({
    //             title: 'Permission error',
    //             body: "You don't have permission to edit tags",
    //             type: 'NOTIFICATION',
    //         });
    //     }
    //     return true;
    // }

  const toggleDeviceTag = async (deviceId: string) => {
    /**TODO in next line there is something wrong
     * deviceTags - has function type (string) => any,
     * but in code below deviceTags uses as object
     */
    let deviceTags = pathOr(
      {},
      path(["tag", "instances", "device", deviceId])(deviceId)
    );
    const update = {};
    const peopleAllowedSee =
      (
        await clientDb()
          .child("tags")
          .child(tagId)
          .child("instances")
          .child("allowed-see")
          .child("person")
          .once("value")
      ).val() || {};

    if (path(["instances", "device", deviceId], tag)) {
      deviceTags = dissoc(tagId, deviceTags) as any;
      Object.keys(peopleAllowedSee).forEach(
        (personId) =>
          (update[
            `/acl/items-allowed/${personId}/device/${deviceId}/${tagId}`
          ] = null)
      );
    } else {
      deviceTags = assoc(tagId, true, deviceTags);
    }

    try {
      await tagsdb.setItemTags(authUser)(
        ItemType.device,
        deviceId,
        keys(deviceTags),
        tagId
      );

      const taggedDevices =
        (
          await clientDb()
            .child("tags")
            .child(tagId)
            .child("instances")
            .child("device")
            .once("value")
        ).val() || {};

      Object.keys(peopleAllowedSee).forEach((personId) =>
        Object.keys(taggedDevices).forEach(
          (deviceId) =>
            (update[
              `/acl/items-allowed/${personId}/device/${deviceId}/${tagId}`
            ] = "tag")
        )
      );
      clientDb().update(makeAudit(authUser, update));
    } catch (e) {
      console.log("toggle device tag error", e);
      dialog?.({
        title: "Permission error",
        body: "You don't have permission to edit tags",
        type: "NOTIFICATION",
      });
    }
    return true;
  };

  const tagDetails = tag.details;

  const handleSelectAllClick = () => {
    setIsAllDeviceSelected((prev) => !prev);

    const toggleValue = !isAllDeviceSelected;
    tagsdb
      .toggleDevicesForTag(devicesId, tagId, toggleValue)
      .then(() => {
        if (isAllDeviceSelected) {
          removeAllTags();
        } else {
        let markerBounds = new google.maps.LatLngBounds();

        devicesMarker.forEach((marker) => {
             markerBounds.extend(new google.maps.LatLng(
              marker.coordinates.location.lat, 
              marker.coordinates.location.lng
              ))});

            mapRef.current.fitBounds(markerBounds);
            dispatch(showAllDevices(devicesId));
        }
      })
      .catch((e) => {
        console.error("toggle device tag error", e);
        dialog?.({
          title: "Permission error",
          body: "You don't have permission to edit tags",
          type: "NOTIFICATION",
        });
      });
  };

  return (
    <>
      <DashboardBlock>
        <ModalHeader title={`Tag: ${tagDetails.name}`} />

        {/* Image */}
        <div style={{ display: "flex" }}>
          <div style={imageBoxCss} onClick={changeImage}>
            {tagDetails.icon ? (
              <Icon size="lg" color={tagDetails.color} icon={tagDetails.icon} />
            ) : (
              "Choose Icon"
            )}
            {!tagDetails.isAlertType ? null : (
              <Fa icon={faSolidBell} style={imageBellCss} />
            )}
          </div>
          <div>
            <div>
              {tagDetails.isAlertType ? (
                <button
                  style={{ outline: "none" }}
                  onClick={setToAlert(false)}
                  className="btn btn-info"
                >
                  <Fa icon={faBell} /> Alerts Enabled
                </button>
              ) : (
                <button
                  style={{ outline: "none" }}
                  onClick={setToAlert(true)}
                  className="btn btn-default"
                >
                  <Fa icon={faBellSlash} /> Alerts Disabled
                </button>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginLeft: 25, marginBottom: 20 }}>
          <GithubPicker onChange={setColor} colors={C.chooserColors} />
        </div>

        <div style={{ marginTop: 8, marginBottom: 8 }}>
          <SearchGridOld
            list={deviceEls}
            filterStr={deviceFilterStr}
            placeholder={`Filter Devices...`}
            perPage={9}
            keyClicked={toggleSelectedDevice}
            filterChange={pipe(UiActions.SET_TAG_DEETS_DEVICE_FILTER, dispatch)}
            actions={
              <Button
                styleType={ButtonStyles.BLACK_WHITE}
                size={ButtonSize.MD}
                onClick={handleSelectAllClick}
              >
                {isAllDeviceSelected ? "Clear all" : "Select all"}
              </Button>
            }
          />
        </div>

        <div style={{ position: "relative" }}>
          <div
            style={{
              ...(tagDetails.isAlertType ? { display: "none" } : noAlertsStyle),
            }}
          >
            <MinimalTile
              style={{
                border: `1px solid ${C.primaryColor}`,
                backgroundColor: "#fff",
                margin: 10,
              }}
            >
              Enable Alerts to Edit
            </MinimalTile>
          </div>

          <ExtraInfo
            editUrl={`/${ItemType.tag}/${tagDetails.id}/add/extra-info`}
            labels={currentLabels}
          />

          <div style={{ marginTop: 10, textAlign: "center" }}>
            {/* <AlertToggles
                            broadcast={deviceInstances.map(({ id }) => ({itemType: ItemType.device, itemId: id}))}
                            style={{marginTop: 10}}
                            itemId={tagDetails.id}
                            itemType={ItemType.tag}
                            values={tagDetails.eventValues}
                            alerts={tagAvailailableAlerts}
                        /> */}
          </div>
        </div>
      </DashboardBlock>
      <Dialog setupConfig={setupDialog} />
    </>
  );
}

export default withSentry(connect(mapStateToProps)(TagDetailsPage));

const imageBoxCss: css = {
  position: "relative",
  width: 100,
  marginRight: 8,
  height: 100,
  cursor: "pointer",
  boxShadow: "5px 5px 16px -2px rgba(194,194,194,1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const noAlertsStyle: css = {
  backgroundColor: color("#333").fade(0.7),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const imageBellCss: css = {
  position: "absolute",
  top: 2,
  right: 2,
  fontSize: 17,
};
