import React, {useEffect, useState} from 'react'
import { closeHistorySession } from '../../../../../api/openApi';
import { isEmpty } from 'ramda'
import Modal from "../../../../Modal";
import styles from "./ContinueStreamModal.module.scss";

const CONTINUE_LIVE_STREAMING: string = 'Do you wish to continue live streaming?';

const ContinueStreamModal = ({prevIdChannels, getVideos, setSelectedChannels, closeContinueLiveStreamingModal, setReminderIntervalTime, setIsPlaying,historyVideo,getHistoryVideo, closeModal }): JSX.Element => {

    const [selectedTime, setSelectedTime] = useState<number>(60000);
    useEffect(() => {
      if (!isEmpty(historyVideo)) {
        closeHistorySession({ session: historyVideo[0].session });
      }
    },[])

    const changeTime = async (): Promise<void>  => {
        setReminderIntervalTime(selectedTime);

        if (!isEmpty(historyVideo)) {
          await getHistoryVideo()
          .then(() => {
            closeContinueLiveStreamingModal();
          })
        } else {
          await getVideos().then((channels) => {
            const channel = channels.find((c) => c.channel_id === prevIdChannels);
            setSelectedChannels([channel]);
            setIsPlaying(true);
            closeContinueLiveStreamingModal();
          })
        }
    }

    const handleSelect = (t): void => setSelectedTime(t.target.value * 60 * 1000)

    return (
        <Modal className={styles.modalContinueLive} onBackdropClick={()=>closeContinueLiveStreamingModal()}>
              <h4>{CONTINUE_LIVE_STREAMING}</h4>
              <div className={styles.selector}>
                  <label htmlFor="time">Remind me in:</label>
                  <select name="time" id="time" onChange={handleSelect}>
                      { Array(10).fill('').map((t, i) => <option key={i+1} value={i + 1}>{`${i + 1} minute`}</option>)}
                  </select>
              </div>
              <div className={styles.actions}>
                <button onClick={() => closeModal()}>No</button>
                <button onClick={changeTime}>Yes</button>
              </div>
        </Modal>
    );
};

export default ContinueStreamModal
