import React from 'react';

import { Toggler } from '../../elements';
import Devices from '../devices/device-list';
import People from '../people/people-list';
import { withSentry } from '../../../shared/hoc/withSentry';


enum DashboardTabs {
    DEVICES,
    PEOPLE,
}

function DevicePeopleToggle () {
    const [tabIdx, setTabIdx] = React.useState<DashboardTabs>(DashboardTabs.DEVICES);

    return <>
        <div style={{paddingBottom: 10}}>
            <Toggler onToggle={tab => setTabIdx(tab)}>
                <span>{tabIdx === DashboardTabs.DEVICES ? <b>Devices</b> : 'Devices'}</span>
                <span>{tabIdx === DashboardTabs.PEOPLE ? <b>People</b> : 'People'}</span>
            </Toggler>
        </div>
        {tabIdx === DashboardTabs.DEVICES ? <Devices /> : <People />}
    </>;
}

export default withSentry(DevicePeopleToggle);
