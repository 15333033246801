import React, {ReactElement} from 'react';
import {Redirect} from 'react-router-dom';
import {RouteConfig} from 'react-router-config';

import Dashboard from './components/pages/Dashboard';
import DeviceTabPage from './components/pages/devices/device-tab';
import DeviceTabEditPage from './components/pages/devices/DeviceTabPageEdit';
import DeviceAssignmentPage from './components/pages/devices/device-assignment';
import AllTagsInfoPage from './components/pages/tags-info/all-tag-info';
import TagDetailsPage from './components/pages/tags-info/tag-details';
import AllFavoritesPage from './components/pages/favorites/all-favs';
import TagItem from './components/pages/tags-info/tag-item';
import LabelItem from './components/pages/tags-info/label-item';
import PersonTab from './components/pages/people/person-tab';
import PersonCanSee from './components/pages/people/person-can-see';
import PersonEdit from './components/pages/people/person-edit';
import AddNote from './components/pages/notes/add-note';
import SettingsPage from './components/pages/settings';
import Devices from './components/pages/devices/device-page';
import People from './components/pages/people/people-page';
import AlertSettingsPage from './components/pages/alerts/alert-settings';
import ReportPage from './components/pages/reports/ReportPage';
import ReportPageFilters from './components/pages/reports/ReportPageFilters';
import ScheduledReports from './components/pages/reports/scheduled-reports';
import CreateReport from './components/pages/reports/create-report';
import ReportQueue from './components/pages/reports/report-queue';
import DeviceDetailsPage from './components/pages/devices/device-details';
import TimeLine from './components/menus/timeline';
import PersonVisibleTags from './components/pages/people/person-visible-tags';
import DefaultSettings from './components/pages/people/people-default-settings';

import ReportPageMobile from './components/pages/reports-mobile/ReportPage';
import ReportPageFiltersMobile from './components/pages/reports-mobile/ReportPageFilters';
import ScheduledReportsMobile from './components/pages/reports-mobile/scheduled-reports';
import CreateReportMobile from './components/pages/reports-mobile/create-report';
import ReportQueueMobile from './components/pages/reports-mobile/report-queue';
import SearchMobile from './components/pages/SearchMobile';
import {exact} from "prop-types";
import {UserCan} from "./shared/constants";
import {array} from "yup";
import { Billing } from './components/pages/Billing/BillingImports';

const getRedirectTo = ({isDriver}) =>
    <Redirect to={isDriver ? '/device' : '/dashboard'} />;

interface RoutesMap {
    [key: string]: Array<RouteConfig>
}

const seeTagsRoutes: Array<RouteConfig>  = [{
    path: '/tags',
    exact: true,
    component: AllTagsInfoPage
}];

const seeAllReports: Array<RouteConfig>  = [{
    path: '/reports/scheduled',
    exact: true,
    component: ScheduledReports
}];

const protectedRoutes: RoutesMap = {
    [UserCan.SEE_TAGS]: seeTagsRoutes,
    [UserCan.SEE_ALL_REPORTS]: seeAllReports
}
export const getRoutes = (userCan) => {
    let routes: Array<RouteConfig>  = [...Routes];
    Object.keys(userCan).forEach(permissionKey => {
        if (userCan[permissionKey]) {
            routes = [...routes, ...(protectedRoutes[permissionKey] || [])]
        }
    })
    return routes;
}

export const Routes: Array<RouteConfig> = [
    {
        path: '/',
        exact: true,
        component: getRedirectTo,
    },
    {
        path: '/dashboard',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/settings',
        exact: true,
        component: SettingsPage,
    },
    {
        path: '/billing',
        component: Billing,
    },
    {
        path: '/reports',
        exact: true,
        component: () => <Redirect to="/reports/new/travel" />,
    },
    {
        path: '/reports/create-report',
        exact: true,
        component: CreateReport,
    },
    {
        path: '/reports/queue',
        exact: true,
        component: ReportQueue,
    },
    {
        path: '/reports/new/:type(travel|static|geofence|summary)?',
        exact: false,
        component: ReportPage,
    },
    {
        path: '/reports/:type(travel|static|geofence|summary)/filters/:filterItem(device|person|tag|alerts|labels)?',
        exact: true,
        component: ReportPageFilters,
    },
    {
        path: '/device',
        exact: true,
        component: Devices,
    },
    {
        path: '/device/:id',
        exact: false,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/alerts/:eventKey',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/add/extra-info',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/add/tags',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/notes/:action/:noteId',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/person',
        exact: true,
        component: People,
    },
    {
        path: '/:itemType/:fenceType/:deviceId/:itemId/alerts/:eventKey',
        exact: true,
        component: AlertSettingsPage,
    },
    {
        path: '/:itemType/:itemId/alerts/:eventKey',
        exact: true,
        component: AlertSettingsPage,
    },
    {
        path: '/tag/:tagId',
        exact: true,
        component: TagDetailsPage,
    },
    {
        path: '/favorites',
        exact: true,
        component: AllFavoritesPage,
    },
    {
        path: '/search-mobile',
        exact: true,
        component: SearchMobile,
    },
    {
        path: '/extra-info',
        exact: true,
        component: AllTagsInfoPage,
    },
    {
        path: '/:type/:itemId/add/extra-info',// :type(device|person|tag)
        exact: true,
        component: LabelItem,
    },
    {
        path: '/person/:personId/default-settings',
        exact:true,
        component: DefaultSettings,
    },
    {
        path: '/:type/:itemId/add/tags',// :type(device|person)
        exact: true,
        component: TagItem,
    },
    {
        path: '/person/:id',
        exact: true,
        component: PersonTab,
    },
    {
        path: '/person/:id/visible-devices',
        exact: true,
        component: PersonCanSee,
    },
    {
        path: '/person/:id/visible-tags',
        exact: true,
        component: PersonVisibleTags,
    },
    {
        path: '/person/:id/edit',
        exact: true,
        component: PersonEdit,
    },
    {
        path: '/:deviceOrFence(device|fence)/:deviceOrFenceId/notes/:action/:noteId',
        exact: true,
        component: AddNote,
    },
];

export const sharedRoutes: Array<RouteConfig> = [
    {
        path: '/',
        exact: true,
        component: getRedirectTo,
    },
    {
        path: '/dashboard',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/settings',
        exact: true,
        component: SettingsPage,
    },
    {
        path: '/device',
        exact: true,
        component: Devices,
    },
    {
        path: '/device/:id',
        exact: false,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/alerts/:eventKey',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/add/extra-info',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/add/tags',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/device/:itemId/notes/:action/:noteId',
        exact: true,
        component: DeviceTabPage,
    },
    {
        path: '/person',
        exact: true,
        component: People,
    },
    {
        path: '/:itemType/:fenceType/:deviceId/:itemId/alerts/:eventKey',
        exact: true,
        component: AlertSettingsPage,
    },
    {
        path: '/:itemType/:itemId/alerts/:eventKey',
        exact: true,
        component: AlertSettingsPage,
    },
    {
        path: '/tag/:tagId',
        exact: true,
        component: TagDetailsPage,
    },
    {
        path: '/favorites',
        exact: true,
        component: AllFavoritesPage,
    },
    {
        path: '/search-mobile',
        exact: true,
        component: SearchMobile,
    },
    {
        path: '/extra-info',
        exact: true,
        component: AllTagsInfoPage,
    },
    {
        path: '/:type/:itemId/add/extra-info',// :type(device|person|tag)
        exact: true,
        component: LabelItem,
    },
    {
        path: '/:type/:itemId/add/tags',// :type(device|person)
        exact: true,
        component: TagItem,
    },
    {
        path: '/person/:id',
        exact: true,
        component: PersonTab,
    },
    {
        path: '/person/:id/visible-devices',
        exact: true,
        component: PersonCanSee,
    },
    {
        path: '/person/:id/edit',
        exact: true,
        component: PersonEdit,
    },
    {
        path: '/:deviceOrFence(device|fence)/:deviceOrFenceId/notes/:action/:noteId',
        exact: true,
        component: AddNote,
    },
];

export const mobileRoutes: Array<RouteConfig> = Routes;

// export const mobileRoutes: Array<RouteConfig> = [
//     ...sharedRoutes,
//     {
//         path: '/reports',
//         exact: true,
//         component: () => <Redirect to="/reports/travel" />,
//     },
//     {
//         path: '/reports/create-report',
//         exact: true,
//         component: CreateReportMobile,
//     },
//     {
//         path: '/reports/scheduled',
//         exact: true,
//         component: ScheduledReportsMobile,
//     },
//     {
//         path: '/reports/queue',
//         exact: true,
//         component: ReportQueueMobile,
//     },
//     {
//         path: '/reports/:type(travel|static)/filters/:filterItem(device|person|tag|alerts|labels)?',
//         exact: false,
//         component: ReportPageFiltersMobile,
//     },
//     {
//         path: '/reports/:type(travel|static)?',
//         exact: true,
//         component: ReportPageMobile,
//     },
// ];

export const deviceRoutes: Array<RouteConfig> = [
    {
        path: '/device/:id',
        exact: true,
        component: DeviceDetailsPage,
    },
    {
        path: '/device/:deviceId/timeline',
        exact: true,
        component: TimeLine,
    },
    {
        path: '/device/:id/edit',
        exact: true,
        component: DeviceTabEditPage,
    },
    {
        path: '/device/:deviceId/assign',
        exact: true,
        component: DeviceAssignmentPage,
    },
    {
        path: '/:type/:itemId/add/tags',// :type(device|person)
        exact: true,
        component: TagItem,
    },
    {
        path: '/:type(device|person|tag)/:itemId/add/extra-info',// :type(device|person|tag)
        exact: true,
        component: LabelItem,
    },
    {
        path: '/:itemType/:fenceType/:deviceId/:itemId/alerts/:eventKey',
        exact: true,
        component: AlertSettingsPage,
    },
    {
        path: '/:itemType/:itemId/alerts/:eventKey',
        exact: true,
        component: AlertSettingsPage,
    },
    {
        path: '/:deviceOrFence(device|fence)/:deviceOrFenceId/notes/:action/:noteId',
        exact: true,
        component: AddNote,
    },
];
