import React from 'react';
import {connect} from 'react-redux';
import Marker from 'react-google-maps/lib/components/Marker';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import {equals} from 'ramda';

import {iFullStoreState} from '../../../../shared/interfaces';
import {BaseComponent} from '../../../../shared/BaseComponent';
import {Actions} from '../../../../stores/reducers/gmap-reducers';
import {FaVector} from '../../../elements/fa';
import * as color from 'color';
import {IFullInfoMarkerProps, IInfoMarkerProps, IInfoMarkerPropsFromState} from './types';
import {setSelectedPoint} from '../../../../stores/reducers/selectedPoint/AC';

const DeviceMarker = connect((s: iFullStoreState, o: IInfoMarkerProps): IInfoMarkerPropsFromState => ({
    deviceDetails: s.devicesData.devicesDetails.get(o.deviceId),
    deviceLastPing: s.devicesData.devicesLastPing.get(o.deviceId),
    bounds: s.gmap.bounds
}))
(class extends BaseComponent<IFullInfoMarkerProps> {

    shouldComponentUpdate ({deviceDetails, deviceLastPing, deviceId, size, bounds}: Readonly<IFullInfoMarkerProps>, nextState: Readonly<{}>): boolean {
        return (
            !equals(deviceId, this.props.deviceId) ||
            !equals(bounds, this.props.bounds) ||
            !equals(size, this.props.size) ||
            !equals(deviceDetails, this.props.deviceDetails) ||
            !equals(deviceLastPing?.pointId, this.props.deviceLastPing?.pointId) ||
            !equals(nextState, this.state)
        );
    }

    componentDidMount (): void {
        this.recenterToDevice();
    }

    componentDidUpdate (prevProps: Readonly<IFullInfoMarkerProps>): void {
        const prevLastLocation = prevProps.deviceLastPing?.coordinates.location;
        const lastLocation = this.props.deviceLastPing?.coordinates.location;

        if (!prevLastLocation && !!lastLocation) {
            this.recenterToDevice();
        }
    }

    recenterToDevice = (): void => {
        const {dispatch, deviceId, doPan = false} = this.props;

        if (doPan) {
            dispatch(Actions.RECENTER_MAP_TO_DEVICE(deviceId, true));
        }
    };

    debugRender = () => {
        const {deviceDetails, deviceLastPing, size, dispatch, bounds} = this.props;

        if (!deviceLastPing || !deviceDetails) return null;

        const {device: deviceId, tripId, pointId, coordinates: {location}} = deviceLastPing;

        const {fa, url} = deviceDetails.icon || {} as any;

        const renderDevice = (
            location.lat > bounds.latSouth &&
            location.lng > bounds.lngWest &&
            location.lat < bounds.latNorth &&
            location.lng < bounds.lngEast
        );

        if(!renderDevice) return;

        return (
            <>
                <Marker
                    zIndex={8}
                    position={location}
                    onClick={() => dispatch(setSelectedPoint(deviceId, tripId, pointId, location))}
                    icon={{
                        [fa ? 'path' : 'url']: url || (!fa ? '' : FaVector(fa)),

                        size: new google.maps.Size(size, size),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(size / 2, size / 2),
                        scaledSize: new google.maps.Size(size, size),

                        ...(url ? {} : {
                            strokeColor: '#000',
                            fillColor: deviceDetails.color || '#000',
                            fillOpacity: .95,
                            anchor: new google.maps.Point(250, 340),
                            scale: .05,

                        }),
                    } as any}
                />
                <InfoBox
                    options={{
                        disableAutoPan: true,
                        zIndex: 4,
                        boxStyle: {
                            zIndex: 4,
                            width: '1px',
                            overflow: 'visible',
                            padding: '2px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: '.9',
                        },
                        alignBottom: true,
                        closeBoxURL: '',
                        pixelOffset: new google.maps.Size(9, 29),
                    }}
                    position={new google.maps.LatLng(location.lat, location.lng)}>
                    <div style={{
                        textOverflow: 'ellipsis',
                        padding: '0 5px',
                        backgroundColor: deviceDetails.color,
                        color: (color(deviceDetails.color).light() ? '#000' : '#fff'),
                        border: `1px solid ${color(deviceDetails.color).darken(.5)}`,
                        whiteSpace: 'nowrap',

                    }}>{deviceDetails.name}</div>
                </InfoBox>
            </>
        );
    };
});

export default DeviceMarker;