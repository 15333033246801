import React, { useEffect, useState } from 'react'
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from "moment";

import instance from '../../../../api/instance';
import ModalPlans from '../ModalPlans';
import { ReactComponent as On } from "../../../../assets/svg/on.svg";
import { ReactComponent as Edit } from "../../../../assets/svg/edit.svg";

export const ActivatedButton = ({row}) => {
  const [modalActive, setModalActive] = useState(false);
  const {
    cancellationDate,
    // deviceActivationHistory: { action, device_ID, device_activation_log_ID, time, user_ID },
    device_status,
    isActivated,
    subscriptionStatus,
    subscriptions: { canEdit, devicePlans, nonRenewal, subscriptionToUnit },
  } = row;
  const STATUS_CANCELLED = subscriptionStatus === 4;

  return (
    <>
        {modalActive && (
          <ModalPlans active={modalActive} setActive={setModalActive} row={row}/>
        )}

      <div className='row-icon'>
        <ul>
          {devicePlans?.map(({ adminLink, description,renewDate,status, subscriptionID }) => {
            const chargBee = adminLink && adminLink.substr(-4);
            
            return (
                <li key={row.id}>
                  {description && <span>{description} - </span>}
                  {status && <span style={{ fontWeight: "bold", color: status == 'Active' ? 'green' : '#a94442'}}>{status}</span>}
                  {renewDate && <span>{renewDate}</span>}
                  {adminLink && <a target='_blank' href={adminLink} style={{color: 'blue'}}> - {chargBee}</a>}
                  {subscriptionID && - subscriptionID}
                  <br/>
                  {subscriptionToUnit && <span>{subscriptionToUnit}</span>}
                </li>
            );
          })}
        </ul>
        
        {(canEdit && !nonRenewal) && 
          <div className='row-active'>
            {STATUS_CANCELLED && <On />}
            <button onClick={() => setModalActive(true)} className={`${STATUS_CANCELLED ? 'activate-btn' : 'deactivate-btn'}`}>{STATUS_CANCELLED ? 'Reactivate this device' : 'Change'}</button>
          </div>
        }
      </div>
    </>
    )
  }

  export const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
    cell
  }) => {
    const [value, setValue] = useState(initialValue);
    const {original} = cell.row;
  
    const onChange = (e) => {
      setValue(e.target.value);
    };
  
    const onBlur = () => {
      updateMyData(index, id, value);
      instance.post("/api/device/rename", { device_id: original.id, name: value })
        .then(( {data: { message, name } } ) => {
          const responseMessage = message
            ? toast.error(message)
            : toast.success(`NickName was updated to => ${name}`);
          return responseMessage;
        })
        .catch((err) => console.log('ERROR: /api/device/rename =>', err));
    };
  
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return (
      <Tooltip title="Click to Edit" placement="top">
        <span className='edit-row'>
        <input value={value} onChange={onChange} onBlur={onBlur} />
          <Edit />
        </span>
      </Tooltip>
    );
  };

export const COLUMNS = [
  {
    Header: "NickName",
    accessor: "name",
    sticky: "top",
    Cell: EditableCell,
  },
  {
    Header: "Serial",
    accessor: "serial",
    sticky: "left",
  },
  {
    Header: "Subscription",
    accessor: "subscriptionStatus",
    disableFilters: true,
    Cell: ({ row }) => <ActivatedButton row={row.original} />,
  },
];