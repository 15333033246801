import React from 'react';
import { AlignContentProperty, JustifyContentProperty } from 'csstype';


interface iRowProps {
    style?: React.CSSProperties,
    wrap?: boolean,
    justify?: JustifyContentProperty | "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "stretch",
    "full-height"?: boolean;
    align?: AlignContentProperty | "flex-start" | "flex-end" | "center" | "baseline" | "stretch",
    className?: string,
    onClick?: (...any) => any
    disableStyles?: boolean
}

export class Row extends React.Component<iRowProps, any> {
    render() {
        const { disableStyles=false, className='', onClick = false, "full-height": fullHeight= false, wrap = false, align = "center", justify = 'space-between', children, style = {} } = this.props;

        return <div
            className={className}
            {...(onClick ? { onClick } : {} )}
            style={disableStyles? {} : {
                alignItems: align,
                flexWrap: wrap ? 'wrap' : 'initial',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: justify as any,
                height: fullHeight ? '100%' : 'initial',
                ...style
            }}
        >
            {children}
        </div>
    }
}

interface iColProps {
    style?: React.CSSProperties
    justify?: JustifyContentProperty | "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "stretch",
    align?: AlignContentProperty | "flex-start" | "flex-end" | "center" | "baseline" | "stretch",
    onClick?: (...any) => any,
    className?: string,
}

export class Col extends React.Component<iColProps, any> {
    render() {
        const { onClick=false, children, style = {}, className = '', justify = 'initial', align = "initial" } = this.props;

        return <div
            className={className}
            {...(onClick ? { onClick } : {} )}
            style={{
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: justify as any,
                alignItems: align,
                ...style
            }}>
            {children}
        </div>
    }
}
