import React, { CSSProperties as css } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import update from 'immutability-helper';
import { path, pipe, keys, map, T, omit, values } from 'ramda';
import { faBell } from '@fortawesome/fontawesome-free-solid';

import ModalHeader from '../../menus/modal/modal-header';
import DashboardBlock from '../../DashboardBlock';
import * as tagsDb from '../../../shared/db/tags-db';
import { fenceHistory, history } from '../../../stores/store';
import { FenceBodyLayout } from '../../fence/tab-bodies/BodyLayout/FenceBodyLayout/FenceBodyLayout';
import { iFenceDetails, iList, iTag, iFullStoreState, ItemType, UserAuth } from '../../../shared/interfaces';
import { StandardItem } from '../../general';
import SearchGridOld from "../../SearchGridOld/SearchGridOld";
import C from '../../../shared/constants';
import ActionRow from '../../fence/tab-bodies/ActionRow/ActionRow';
import { BaseComponent } from '../../../shared/BaseComponent';
import { Actions } from '../../../stores/reducers/tag-item';
import * as tagSelectors from '../../../shared/db/tags-labels-selectors';
import { Fa } from "../../elements/fa";
import { idValArr, vals } from "../../../shared/helpers";
import { DialogConfigSetter } from '../../Dialog';

type IProps = RouteComponentProps<{
    type: ItemType;
    itemId: string;
    isFence?: string;
}>
type IPropsFromStore = {
    authUser?: UserAuth;
    tagFilter: string;
    tags: iList<iTag>;
    itemTags: iList<iTag>;
    fence?: iFenceDetails;
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;

class TagItem extends BaseComponent<IFullProps, { visTags: iList<true>, showWarn: boolean, alertChanged: boolean, }> {
    state = {
        showWarn: false,
        alertChanged: false,
        visTags: {} as iList<true>,
    }

    private dialog: DialogConfigSetter;
    setupDialog = (callBack: () => DialogConfigSetter): void => {
        this.dialog = callBack();
    }

    componentWillReceiveProps(nextProps: IFullProps) {
        this.updateVisTagsWithUserTags(nextProps);
    }

    componentDidMount() {
        this.updateVisTagsWithUserTags(this.props);
    }

    componentWillUnmount() {
        this.props.dispatch({type: Actions.RESET_PAGE});
    }

    updateVisTagsWithUserTags(props: IFullProps) {
        const { visTags } = this.state || {visTags: {}};
        const { itemTags } = props;

        const toMerge = pipe(
            omit(keys(visTags)),
            map(T)
        )(itemTags);

        this.setState(s => update(s, {visTags: {
            $merge: toMerge
        }}))
    }

    static TagToEl = ({ tag, isSelected }: {tag: iTag, isSelected: boolean}) => {
        const style: css = ({
            color: isSelected ? C.primaryColor : 'inherit',
            fontWeight: isSelected ? 'bold' : 'normal',
            padding: 2,
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'space-around',
            flex: 1,
        });

        return (
            <div style={style}>
                {tag.details.name} {!tag.details.isAlertType ? null : <Fa icon={faBell} style={{position: 'absolute', right: 4}} />}
            </div>
        )
    }

    private alertsMatchUpdate = (state) => {

        const { tags, itemTags } = this.props;
        const pre = values(itemTags).find(t => t.details.isAlertType);
        const post = idValArr(state.visTags).find(({ id }) => tags[id].details.isAlertType);

        const preId = path(['details', 'id'])(pre);
        const postId = path(['id'])(post);

        return update(state, {
            alertChanged: { $set: preId != postId }
        });
    }

    private toggleTag = (key) => {
        const match = this.props.tags[key];
        const visHasAlert = idValArr(this.state.visTags).find(({ id }) => this.props.tags[id].details.isAlertType);

        // bail if this is a second alert
        if (match.details.isAlertType && visHasAlert && !this.state.visTags[key]) return this.showWarn();

        this.setState(s => {
            const exists = s.visTags[key];


            if (exists) return this.alertsMatchUpdate(update(s, {
                visTags: { $unset: [key]},
                showWarn: { $set: false },
            }))

            return this.alertsMatchUpdate(update(s, {
                visTags: {
                    [key]: { $set: true },
                },
                showWarn: { $set: false },
            }))
        })
    }

    private updateTags = async () => {
        const {authUser} = this.props;
        const {isFence, type, itemId} = this.props.match.params;

        try {
            await tagsDb.setItemTags(authUser)(type, itemId, Object.keys(this.state.visTags))
        } catch (e) {
            console.log(e);
        }

        this.setState({visTags: {}});

        isFence ? fenceHistory.goBack() : history.goBack();
    }

    timeOutForHide;
    private showWarn = () => {
        this.setState(s => ({ showWarn: true }))
        this.timeOutForHide = setTimeout(this.hideWarn, 5000);
    }

    private hideWarn = () => {
        clearTimeout(this.timeOutForHide);
        this.setState(s => ({ showWarn: false }))
    }
    filterChange = filter => this.props.dispatch({type: Actions.CHANGE_FILTER, filter});

    debugRender = () => {
        const {tags, tagFilter, fence} = this.props;
        const {type, itemId, isFence} = this.props.match.params;

        if (!tags) return null;

        const { visTags = {} } = this.state;

        const filteredTags = vals(tags)
            // filter on search
            .filter(t => t.details.name?.toLowerCase().indexOf(tagFilter.toLowerCase()) !== -1)

            // filter out alerts if not device
            .filter(t => type === ItemType.device || !t.details.isAlertType);

        const DynamicLayout = isFence ? FenceBodyLayout : DashboardBlock as any;
        const DynamicHeader = isFence ? <ActionRow title={(fence || {} as any).name} canBack actions={[]} /> : <ModalHeader title="Add Tag" />

        return (
            <DynamicLayout mapOnly={!itemId || !isFence}>

                {DynamicHeader}
                <SearchGridOld
                    style={{maxWidth: 300}}
                    list={filteredTags.map(tag => <TagItem.TagToEl key={tag.details.id} tag={tag} isSelected={!!visTags[tag.details.id]} />)}
                    filterStr={tagFilter}
                    placeholder="Search Tags"
                    perPage={9}
                    keyClicked={this.toggleTag}
                    filterChange={this.filterChange}
                />

                {/* List of adding tags */}
                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 15}}>
                    {Object.keys(visTags).map(tagId =>
                        <StandardItem
                            showBell={tags[tagId].details.isAlertType}
                            key={tagId}
                            style={{margin: 4, order: tags[tagId].details.name.length}}
                            remove={this.toggleTag} itemId={tagId} displayName={tags[tagId].details.name}
                        />
                    )}
                </div>

                <div className="alert alert-info" style={{display: this.state.alertChanged ? 'block' : 'none'}}>
                    Saving these changes will cause device <span style={{whiteSpace: 'nowrap'}}>settings / alerts</span> to change.
                </div>

                <div className="alert alert-warning" style={{display: this.state.showWarn ? 'block' : 'none' }}>
                    <span className="close btn-link" onClick={this.hideWarn} aria-label="close">&times;</span>
                    Multiple alert type tags are not permitted.
                </div>

                {/* Update Button */}
                <div className="text-center" style={{marginTop: 10}}>
                    <button onClick={this.updateTags} className="btn btn-primary">Update Tags</button>
                </div>
            </DynamicLayout>
        )
    }
}

const mapStateToProps = (state: iFullStoreState, props: IProps): IPropsFromStore => {
    const {itemId, type} = props.match.params;

    return {
        authUser: state.auth.user!,
        tagFilter: state.tagItem.tagFilter || '',
        tags: state.general.tags,
        itemTags: tagSelectors.getItemTagsSelector(state, {itemId, itemType: type}),
        fence: (state.general.fences[itemId] || {} as any).details,
    };
}

export default connect(mapStateToProps)(TagItem);
