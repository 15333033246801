import React, {CSSProperties as css} from 'react';
import {connect, DispatchProp} from 'react-redux';
import {Link} from 'react-router-dom';
import * as debounce from 'debounce';
import {path, pipe, always} from 'ramda';
import {faCaretRight, faTag} from '@fortawesome/fontawesome-free-solid';

import {Icon} from '../elements/icon';
import UserImage from '../general/user-image';
import {GoogleSearchService} from '../../shared/googleSearchService';
import {iList, iDeviceDetails, iPerson, iTag, iLocation, iFullStoreState} from '../../shared/interfaces';
import {devicesMatchSelector, peopleMatchSelector, tagsMatchSelector} from '../../shared/db/search-selectors';
import {vals, gstractLocation, isMobile} from '../../shared/helpers';
import {Actions as GeneralActions} from '../../stores/reducers/general-reducers';
import {
    Actions as GmapActions,
    getMapCenter as getMapZoomFromStore,
} from '../../stores/reducers/gmap-reducers';
import {BaseComponent} from '../../shared/BaseComponent';
import {Fa} from '../elements/fa';
import {DevicesDetailsContainer, DevicesLastPingContainer} from '../../stores/reducers/devicesData';

const width = 450;

type iPropsFromStore =  {
    // from store
    isVis: boolean;
    searchTerm: string;
    devicesDetails: DevicesDetailsContainer;
    devicesLastPing: DevicesLastPingContainer;

    // calculated
    devices: iList<iDeviceDetails>;
    people: iList<iPerson>;
    tags: iList<iTag>;
    getMapCenter: () => iLocation;
}

type iProps = DispatchProp & iPropsFromStore;
const mapStateToProps = (state: iFullStoreState): iPropsFromStore => {
    const getMapCenter = () => getMapZoomFromStore(state.gmap);

    return {
        isVis: state.general.showSearch,
        devices: devicesMatchSelector(state),
        people: peopleMatchSelector(state),
        tags: tagsMatchSelector(state),
        devicesDetails: state.devicesData.devicesDetails,
        devicesLastPing: state.devicesData.devicesLastPing,
        searchTerm: state.general.searchTerm,
        getMapCenter,
    };
};
export default connect(mapStateToProps)
(class SearchModal extends BaseComponent<iProps, {searching: boolean; gSearchResults: google.maps.places.PlaceResult[]}> {
    state = {
        gSearchResults: [],
        searching: false,
    }

    curSearch;
    _performSearch = debounce(async (keyword) => {
        const {getMapCenter} = this.props;

        const search = this.curSearch = GoogleSearchService.get(keyword, getMapCenter());

        const res = await this.curSearch;

        if (this.curSearch !== search) return; // new search fired

        this.setState({searching: false, gSearchResults: res || []});
    }, 800)

    private mapSearch = (keyword) => {
        this.setState({searching: true});
        this._performSearch(keyword);
    }

    componentWillReceiveProps(props) {
        if (props.searchTerm != this.props.searchTerm) this.mapSearch(props.searchTerm);
    }

    recenterMap = (center: iLocation): void => {
        this.props.dispatch(GmapActions.RECENTER_MAP(center));
    };

    debugRender = () => {
        const {isVis, dispatch, devices, people, tags, devicesDetails, devicesLastPing, searchTerm} = this.props;
        const {gSearchResults} = this.state;

        if (!isVis) return null;

        return (
            <div className="SearchModal" style={modalStyle}>
                <div style={{padding: 8}}>
                    <SearchCat header="Devices" links={
                        vals(devices).map((device) => {
                            return {
                                id: device.id,
                                title: device.name,
                                icon: <Icon size="sm" color="#111" icon={device.icon} />,
                                link: `/device/${device.id}`,
                                subText: devicesLastPing.getIn([device.id, 'address', 'street'], ''),
                            };
                        })
                    }/>

                    <SearchCat header="People" links={
                        vals(people as iList<iPerson>).map((person) => ({
                            id: person.id,
                            title: person.displayName,
                            icon: <UserImage person={person} size={32} />,
                            link: `/person/${person.id}`,
                            subText: devicesDetails.getIn([path(['hasDevice', 'deviceId'])(person), 'address', 'street']) || ''
                        }))
                    }/>

                    <SearchCat header="Tags" links={
                        vals(tags).map((tag) => ({
                            id: tag.details.id,
                            title: tag.details.name,
                            icon: <Icon size="sm" color="#111" icon={{fa: faTag}} />,
                            link: `/tag/${tag.details.id}`,
                            subText: '',
                        }))
                    } />

                    <SearchCat header="Map" links={
                        gSearchResults.map((res) => ({
                            id: res.place_id,
                            title: res.name || searchTerm,
                            icon: <Icon size="xs" icon={{url: 'images/gmap-marker.png'}} style={{border: 'none', paddingTop: 5,}}/>,
                            click: (pipe as any)(
                                always(res),
                                path(['geometry', 'location']),
                                gstractLocation,
                                this.recenterMap,
                                always(res),
                                GmapActions.ADD_SEARCH_MARKER,
                                dispatch
                            ),
                            subText: res.formatted_address,
                        }))
                    } />
                </div>
            </div>
        );
    }
});

const inputStyle: css = {
    borderBottom: '3px solid #fff',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    outline: 'none',
    fontSize: 22,
    width: width - 16,
    color: '#fff',
    backgroundColor: 'transparent',
    fontWeight: 'bold'
};
// window['cordova']
const modalStyle: css = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: isMobile ? 3 : 150,
    width: '100%',
    height: '100%',
};

interface iSearchCatProps {
    header: string;
    dispatch?: any;
    links: {
        id: string; title: string; icon: any; link?: string; click?: any; subText?: string;
    }[];
}

const SearchCat = connect()(({header, links, dispatch}: iSearchCatProps) => (
    <div style={{marginBottom: 15}}>
        <div style={sectionHeader}>
            <span>{header}</span>
            <span style={{backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 15, width: 15, height: 15}}>
                <Fa icon={faCaretRight} style={{color: '#333', fontSize: 18}}/>
            </span>
        </div>
        {links.map((l) => {
            const action = l.link ? {to: l.link} : {to: '#', onClick: (ev) => { ev.preventDefault(); ev.stopPropagation(); l.click();}};

            return (
                <div key={l.id} onClick={(_) => dispatch(GeneralActions.HIDE_SEARCH)} style={{paddingLeft: 10}}>
                    <Link {...action} key={l.id} style={{display: 'flex', borderBottom: '1px dotted #111', textDecoration: 'none', color: '#111'}}>
                        <span style={{marginRight: 10, width: 30, color: 'black'}}> {l.icon} </span>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <span style={{paddingBottom: 10}}>{l.title}</span>
                            <span style={{paddingBottom: 10}}>{l.subText}</span>
                        </div>
                    </Link>
                </div>
            );
        })}
    </div>
));

const sectionHeader: css = {
    paddingBottom: 5,
    alignItems: 'center',
    marginBottom: 8,
    fontSize: 18,
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    borderBottom: '1px solid #fff',

};
