import { friendlyDist, friendlySpeed } from '../../../../shared/helpers';
import { getSafetyPercent, SafetyPercent } from '../report-table/cells/helpers';
import {
	countDriveTime,
	countStoppedTime,
	countTraveledDistance,
	getTimeFormated,
} from '../ReportPage';
import { sum } from 'ramda';

export const mapFromDeviceResultToTableBody = (result, deviceUnits, csv = false) => {
	const { device, averageSpeed, distanceTraveled, driveTime, safetyPercent, stoppedTime } = result;

	const speedCalc = isNaN(averageSpeed) ? 0 : friendlySpeed(averageSpeed, deviceUnits);
	const driveTimeCalc = getTimeFormated(driveTime);
	const stoppedTimeCalc = getTimeFormated(stoppedTime / 60);
	const distanceCalc = distanceTraveled > 0 ? friendlyDist(distanceTraveled, deviceUnits) : 0;

	if (csv) {
		return [
			[device, speedCalc, safetyPercent, driveTimeCalc, stoppedTimeCalc, distanceCalc]
		];
	}
	return [
		['Average Speed', speedCalc],
		['Safety', safetyPercent],
		['Drive Time', driveTimeCalc],
		['Stopped Time', stoppedTimeCalc],
		['Total Distance', distanceCalc],
	];
};

export const checkFileResolution = ({
	typeWithoutDot,
	fileName,
}: {
	typeWithoutDot: string;
	fileName: string;
}): string => {
	return fileName.endsWith(typeWithoutDot) ? fileName : `${fileName}.${typeWithoutDot}`;
};

export const generateSummaryResults = (records, deviceName, reportType) => {
	const recordsValues = Object.values(records);
	const percents = getSafetyPercent(reportType as SafetyPercent);
	const safetyPercent = sum(recordsValues.map(percents)) / recordsValues.length;
	return {
		device: deviceName,
		safetyPercent: safetyPercent ? safetyPercent.toFixed(2) + '%' : safetyPercent,
		driveTime: countDriveTime(records),
		distanceTraveled: countTraveledDistance(records),
		averageSpeed: Math.floor((countTraveledDistance(records) / countDriveTime(records)) * 10) / 10,
		stoppedTime: countStoppedTime(records),
	};
};
