import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addIndex,
  any,
  identity,
  map,
  path,
  pipe,
  values,
  isEmpty,
} from "ramda";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";

import {
  countDateWithUserAndTimezoneOffset,
  friendlyMilesPer,
  friendlySpeed,
  isIncidentSafety,
  isMobile,
  speedWholeNumber,
  userCurrentOffset,
  userUnitSpeedStr,
  userUnitStr,
  utcOffset,
} from "../../../../shared/helpers";
import {
  iDevicePing,
  iFullStoreState,
  iTrip,
  Units,
  AlertTypes as AlertsType,
} from "../../../../shared/interfaces";
import { useRedux } from "../../../../states/redux-state";
import { Row } from "../../../elements/flex";
import { TripCell } from "./cells/trip";
import { TravelCell } from "./cells/travel";
import { ActivityDeetsCol } from "./cells/activity-deets";
import {AlertTypes, getPoints, iGetReportPropsWrapper, tripPointsReal} from "../../../../shared/db/report-db";
import { withPrevPair } from "../../../../shared/ramda";
import { LabelOrPersonRow } from "./cells/person-label-row";
import { useLocalStorage } from "../../../../stores/reducers/general-reducers";
import { iHiddenReportData } from "../vis-report-data";
import {
  setReportTrip,
  toggleShowReportTrip,
} from "../../../../stores/reducers/devicesTripsPoints/AC";
import { IPointsContainer } from "../../../../stores/reducers/devicesTripsPoints";
import "./table-wrapper.scss";
import { ReactComponent as Elipse } from "../../../../assets/svg/elipse.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DashboardSize } from "../../../../stores/reducers/dashboardInfo";
import { ReactComponent as MapPointerSvg } from "../../../../assets/svg/map_pointer.svg";
import { ReactComponent as NotificationSvg } from "../../../../assets/svg/notification.svg";
import { Button, ButtonSize, ButtonStyles } from "../../../Button";
import { string } from "prop-types";
import GeofenceReport from "../GeofenceReport";
import { Actions as GeneralActions } from "../../../../stores/reducers/general-reducers";
import { fullCloseDashboard } from "../../../../stores/reducers/dashboardInfo/AC";
import ReactPaginate from "react-paginate";
import {Actions as ReportActions, Actions} from "../../../../stores/reducers/report-reducers";
import {filterRecords, validate} from "../reportFilter";

const StartStopIco = ({
  type,
}: {
  type: AlertTypes.poweron | AlertTypes.poweroff;
}) => (
  <div>
    <img
      src={
        type === AlertTypes.poweroff
          ? "assets/incidents/ignition-off.png"
          : "assets/incidents/ignition-on.png"
      }
      width={13}
      style={{ marginRight: 2 }}
    />
  </div>
);

const ReportPointRow = ({
  prevCur: { previous, current: row },
  deviceUnits,
  condensed,
  timezone,
}: {
  deviceUnits: Units;
  condensed: boolean;
  prevCur: { current: iDevicePing; previous: null | iDevicePing };
  timezone: string;
}) => {
  const [showCondensed, setShowCondensed] = React.useState(false);

  if (!row.time) console.log("row no time", row);

  const newDate =
    row?.time?.format("MMM-D") !== previous?.time?.format("MMM-D");
  const [hiddenFields] = useLocalStorage<iHiddenReportData>(
    "hidden-report-data:static",
    {}
  );

  const date = countDateWithUserAndTimezoneOffset(row?.time, timezone);

  const setDataStyle = (prop) => {
    return !hiddenFields[prop]
      ? {
          display: "",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }
      : { display: "none" };
  };

  return (
    <>
      {!newDate ? null : (
        <div className="trip-static-header-wrapper">
          <div className="trip-static-date">{date.format("MM/DD/YYYY")}</div>
          <div key={`${row.pointId}-0`} className="trip-static-header">
            <div>Time</div>
            <div style={{ display: !hiddenFields["fuel"] ? "" : "none" }}>
              Fuel
            </div>
            <div style={{ display: !hiddenFields["speed"] ? "" : "none" }}>
              {userUnitSpeedStr(deviceUnits)}
            </div>
            <div style={{ display: !hiddenFields["posted"] ? "" : "none" }}>
              Posted
            </div>
            <div
              style={{ display: !hiddenFields["consumption"] ? "" : "none" }}
            >
              {userUnitStr(deviceUnits)}
            </div>
            <div style={{ display: !hiddenFields["event"] ? "" : "none" }}>
              Event
            </div>
            <div style={setDataStyle("location")}>Location</div>
            <div
              style={{ textAlign: "center", ...setDataStyle("coordinates") }}
            >
              Coordinates
            </div>
            {condensed ? (
              <div>...</div>
            ) : (
              <>
                <div style={{ display: !hiddenFields["label"] ? "" : "none" }}>
                  Label
                </div>
                <div style={{ display: !hiddenFields["person"] ? "" : "none" }}>
                  Person
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div key={`${row.pointId}-1`} className="trip-static-row">
        <div className="trip-static-row-item">{date.format("h:mmA")}</div>
        <div
          className="trip-static-row-item"
          style={{ display: !hiddenFields["fuel"] ? "" : "none" }}
        >
          {row.fuel}
        </div>
        <div
          className="trip-static-row-item"
          style={{ display: !hiddenFields["speed"] ? "" : "none" }}
        >
          {(row.speed as any) === ""
            ? "-"
            : speedWholeNumber(row.speed, deviceUnits)}
        </div>
        <div
          className="trip-static-row-item"
          style={{ display: !hiddenFields["posted"] ? "" : "none" }}
        >
          {row.posted_speed
            ? friendlySpeed(row.posted_speed, deviceUnits)
            : "-"}
        </div>
        <div
          className="trip-static-row-item"
          style={{ display: !hiddenFields["consumption"] ? "" : "none" }}
        >
          {row.mpg ? friendlyMilesPer(row.mpg, deviceUnits) : "-"}
        </div>
        <div
          className="trip-static-row-item"
          style={{ display: !hiddenFields["event"] ? "" : "none" }}
        >
          {row.msg ? row.msg : "-"}
        </div>
        <div
          className="trip-static-row-item"
          style={{ display: !hiddenFields["location"] ? "" : "none" }}
        >
          {row.address.street}
          <br />
          {row.address.city} {row.address.state} {row.address.zip}
        </div>
        <div
          className="trip-static-row-item"
          style={setDataStyle("coordinates")}
        >
          {row.coordinates.location.lat}, {row.coordinates.location.lng}
        </div>
        {condensed ? (
          <div>
            <div onClick={() => setShowCondensed(!showCondensed)}>
              <FontAwesomeIcon icon={showCondensed ? "minus" : "plus"} />
            </div>
          </div>
        ) : (
          <>
            <div
              className="trip-static-row-item"
              style={{ display: !hiddenFields["label"] ? "" : "none" }}
            >
              <LabelOrPersonRow row={row} type="point" field="label" />
            </div>
            <div
              className="trip-static-row-item"
              style={{ display: !hiddenFields["person"] ? "" : "none" }}
            >
              <LabelOrPersonRow row={row} type="point" field="personId" />
            </div>
          </>
        )}
      </div>
      {!showCondensed ? null : (
        <>
          <div key={`${row.pointId}-condensed-1`}>
            <div style={{ paddingLeft: 10 }}>Label:</div>
            <div style={{ display: !hiddenFields["label"] ? "" : "none" }}>
              <LabelOrPersonRow row={row} type="point" field="label" />
            </div>
          </div>
          <div key={`${row.pointId}-condensed-2`}>
            <div style={{ paddingLeft: 10 }}>Person:</div>
            <div style={{ display: !hiddenFields["person"] ? "" : "none" }}>
              <LabelOrPersonRow row={row} type="point" field="personId" />
            </div>
          </div>
        </>
      )}
    </>
  );
};
type IReportTripRowProps = {
  deviceId: string;
  timezone: string;
  units: Units;
  current: iTrip;
  showAllRoute: boolean;
  showTrip: (item: { device: string; tripId: string }) => void;
};

const ReportTripRow = ({
  current: row,
  deviceId,
  units,
  timezone,
  showTrip,
}: IReportTripRowProps) => {
  const dispatch = useDispatch();

  const dashboardInfo = useRedux((s) => s.dashboardInfo);

  const tripPoints = useSelector<iFullStoreState, IPointsContainer | undefined>(
    (s) => s.devicesTripsPoints.getIn([deviceId, "tripsPoints", row.tripId]),
    (l, r) => l?.keySeq?.()?.last?.() === r?.keySeq?.()?.last?.()
  );

  const alertsArray =
    tripPoints?.reduce(
      (result, point) => [
        ...result,
        ...(Object.keys(point.alertActivity) as AlertsType[])
          .filter(isIncidentSafety)
          .filter((k) => {
            return k.indexOf("has") !== -1 && point.alertActivity[k];
          }),
      ],
      [] as Array<{ alert: string }>
    ) ?? [];

  const [showAlerts, setShowAlerts] = React.useState(false);
  // const [showRoute, setShowRoute] = React.useState(false);
  const showRoute = useSelector<iFullStoreState, boolean>(
    (s) =>
      s.devicesTripsPoints.getIn([deviceId, "reportTripsIds", row.tripId]) ||
      false
  );

  // React.useEffect(() => {
  //     if (showRoute) {
  //         if (!tripPoints) {
  //             tripPointsReal(row.tripId)
  //                 .then(values)
  //                 .then((points: any) => {
  //                     dispatch(setReportTrip(deviceId, row.tripId, points));
  //                 });
  //         } else {
  //             dispatch(toggleShowReportTrip(deviceId, row.tripId, true));
  //         }
  //     } else if (!!tripPoints) {
  //         dispatch(toggleShowReportTrip(deviceId, row.tripId, false));
  //     }
  // }, [showRoute]);

  React.useEffect(() => {
    if (showRoute) {
      tripPointsReal(row.tripId)
        .then(values)
        .then((points: any) => {
          dispatch(setReportTrip(deviceId, row.tripId, points));
        });
    } else if (!!tripPoints) {
      dispatch(toggleShowReportTrip(deviceId, row.tripId, false));
    }
  }, [showRoute]);

  // React.useEffect(() => {
  //     setShowRoute(showAllRoute);
  // }, [showAllRoute]);

  const showRouteToggle = () => {
    // setShowRoute(!showRoute);
    console.log("deviceId", deviceId, "trip id", row.tripId);

    if (!showRoute) {
      if (isMobile) {
        dispatch(GeneralActions.SHOW_SEARCH);
        dispatch(fullCloseDashboard());
      }
      showTrip({ device: deviceId, tripId: row.tripId });
    } else {
      dispatch(toggleShowReportTrip(deviceId, row.tripId, false));
    }
  };

  const empty = values(row.alertActivity).filter((a) => a > 0);
  const emptyAlerts = isEmpty(empty);

  // moment().isDST() don't using, cause userCurrent Offset already contain or not DST
  // that's why we pass instead moment().isDST() -> just false, to utcOffset
  const deviceOffset = utcOffset(timezone, false);
  const diff = userCurrentOffset - deviceOffset;

  const start = countDateWithUserAndTimezoneOffset(row.startDate, timezone);
  const end = countDateWithUserAndTimezoneOffset(row.endDate, timezone);
  const sameDay = start.dayOfYear() == end.dayOfYear();

  return (
    <>
      <tr key={`${row.tripId}-1`}>
        <td colSpan={2} style={{ backgroundColor: "#f0f0f0f0" }}>
          <Row style={{ flexDirection: "row" }}>
            <div className={"trip-date-wrap"}>
              <span style={{ whiteSpace: "nowrap", flexGrow: 1 }}>
                <Elipse className="start-date-icon" />{" "}
                {start.format("MM/DD/YYYY")} {start.format("h:mmA")}
              </span>
              <div
                className={`address-actions-wrapper ${
                  dashboardInfo.size === DashboardSize.OPEN ? "open" : ""
                }`}
              >
                <span>{row.startAddress}</span>
                <div
                  style={{ marginLeft: "10px" }}
                  className={isMobile || window["cordova"] ? "d-flex" : ""}
                >
                  <Button
                    styleType={
                      emptyAlerts
                        ? ButtonStyles.GRAY_INACTIVE
                        : ButtonStyles.YELLOW_WHITE
                    }
                    style={
                      isMobile || window["cordova"]
                        ? { height: "max-content" }
                        : {}
                    }
                    size={ButtonSize.XSM}
                    disabled={emptyAlerts}
                    onClick={() =>
                      setShowAlerts(!showRoute ? showAlerts : !showAlerts)
                    }
                  >
                    <Tooltip
                      title={
                        emptyAlerts
                          ? "No Alerts for trip"
                          : "Click to see alerts"
                      }
                    >
                      <NotificationSvg
                        className={
                          isMobile || window["cordova"] ? "icon-smd" : "icon-sm"
                        }
                      />
                    </Tooltip>
                  </Button>
                  {showRoute && !tripPoints ? (
                    <Button
                      styleType={ButtonStyles.NONE}
                      title="Click to show route on map"
                      disabled={showRoute && !tripPoints}
                      onClick={() => showRouteToggle()}
                    >
                      <FontAwesomeIcon icon="spinner" spin />
                    </Button>
                  ) : (
                    <Button
                      styleType={
                        showRoute
                          ? ButtonStyles.BLACK_WHITE
                          : ButtonStyles.GRAY_INACTIVE
                      }
                      style={
                        isMobile || window["cordova"]
                          ? { height: "max-content" }
                          : {}
                      }
                      size={ButtonSize.XSM}
                      disabled={showRoute && !tripPoints}
                      onClick={() => showRouteToggle()}
                    >
                      <Tooltip title="Click to show route on map">
                        <MapPointerSvg
                          className={
                            isMobile || window["cordova"]
                              ? "icon-smd"
                              : "icon-sm"
                          }
                        />
                      </Tooltip>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </td>
      </tr>
      <tr key={`${row.tripId}-3`}>
        <td colSpan={2}>
          {!showAlerts ? (
            <Row
              disableStyles={true}
              className={`trip-wraper ${
                dashboardInfo.size === DashboardSize.OPEN ? "open" : ""
              }`}
              justify="space-evenly"
              style={{ fontSize: 11.5 }}
            >
              <div>
                <TripCell row={row} />
              </div>
              <div>
                <TravelCell row={row} />
              </div>
            </Row>
          ) : (
            <ActivityDeetsCol
              tripId={row.tripId}
              units={units}
              deviceId={deviceId}
              errorCloser={() => setShowAlerts(false)}
              timezone={timezone}
            />
          )}
        </td>
      </tr>
      <tr key={`${row.tripId}-4`}>
        <td
          className="footer"
          colSpan={2}
          style={{
            backgroundColor: "#f0f0f0f0",
            fontSize: isMobile ? 9 : "inherit",
          }}
        >
          <Row
            justify="flex-start"
            style={{ fontSize: isMobile ? 9 : "inherit" }}
          >
            <div className="trip-date-wrap">
              <span>
                <Elipse className="end-date-icon" /> {end.format("h:mmA")}
              </span>
              <span>{row.endAddress}</span>
            </div>
          </Row>
        </td>
      </tr>
    </>
  );
};

type NewDeviceReportSectionsProps = {
  displayName: string;
  records: Array<iDevicePing | iTrip>;
  getReportData: (data: object) => void;
  showAllRoute: boolean;
  showTrip: (item: { device: "string"; tripId: string }) => void;
  showAllTrips: () => void;
  hideAllTrips: () => void;
};

export const NewDeviceReportSections: FC<NewDeviceReportSectionsProps> = ({
  displayName,
  records,
  getReportData,
  showAllRoute,
  showAllTrips,
  showTrip,
  hideAllTrips,
}) => {
  const deviceId = pipe(path([0, "device"]))(records);

  const deviceUnits = useSelector<iFullStoreState, Units>((s) =>
    s.devicesData.devicesDetails.getIn([deviceId, "units"], Units.miles)
  );
  const deviceTimezone = useSelector<iFullStoreState, string>((s) =>
    s.devicesData.devicesDetails.getIn([deviceId, "timezone"], "EST")
  );
  const reportTripsIds = useSelector<iFullStoreState, Array<boolean>>((s) => {
    const ids = s.devicesTripsPoints.getIn([deviceId, "reportTripsIds"]);

    return ids ? [...ids.values()] : [];
  });
  const showAllRoutes = reportTripsIds.length
    ? reportTripsIds.some((item) => item)
    : false;
  const reportType = useRedux((s) => s.report.details.reportType);
  const [isCondensed, setIsCondensed] = React.useState(false);
  const tableRef = React.useRef(null);

  // Effect to check if the table is causing page scroll
  // if so set the condensed flag
  useEffect(() => {
    if (!tableRef.current || !isMobile) return;

    let next = tableRef.current;
    while (next.parentElement) {
      if (next.parentElement.scrollWidth > next.parentElement.clientWidth) {
        setIsCondensed(true);
        break;
      }

      next = next.parentElement;
    }
    }, [records, tableRef]);

  useEffect(() => {
    getReportData({ [deviceId]: { deviceUnits, deviceTimezone } });
  }, [deviceId]);

  const outTable = reportType === "travel" ? addIndex(map) : identity;

  const getRecordsWithPrevPair = withPrevPair(records);

  const getTripOrPointData = outTable((tripOrPoint) => {
    const trip: {
      current: iTrip;
      previous: iTrip;
    } = tripOrPoint;

    const points: Array<{
      current: iDevicePing;
      previous: iDevicePing;
    }> = tripOrPoint;

    return reportType === "travel" ? trip : points;
  });

  const reportData = getTripOrPointData(getRecordsWithPrevPair);

  const RenderStaticReportData = ({ points }) => {
    const [pagination, setPagination] = useState({
      data: points,
      offset: 0,
      numberPerPage: 100,
      pageCount: 0,
      currentData: [],
    });

    useEffect(() => {
      setPagination((prevState) => ({
        ...prevState,
        pageCount: Math.ceil(prevState.data.length / prevState.numberPerPage),
        currentData: prevState.data.slice(
          pagination.offset,
          pagination.offset + pagination.numberPerPage
        ),
      }));
    }, [pagination.numberPerPage, pagination.offset]);

    const tags = useRedux(s => s.general.tags);
    const filters = useRedux(s => s.report.details.filters);
    const dates = useRedux(s => s.report.details.dates);
    const devicesDetails = useRedux(s => s.devicesData.devicesDetails);
    const userToken = useRedux(s => s.auth.user.beToken);
    const dispatch = useDispatch();
    const firstOrLastVisible = useRedux(s => s.report.firstOrLastVisible);
    const filterDisplayRecords = useRedux(s => s.report.filterDisplayRecords);

    const handlePageClick = async (event) => {
      const selected = event.selected;
      const offset = selected * pagination.numberPerPage;
      setPagination({ ...pagination, offset });
      // let query: iGetReportPropsWrapper = {
      //   allTags: tags,
      //   filters,
      //   dates,
      //   devicesDetails,
      // };
      //
      // let points = await getPoints(query, userToken?.devices || false, dispatch, firstOrLastVisible, next);
      // dispatch(ReportActions.SET_DISPLAY_RECORDS(filterDisplayRecords.length > 0 && validate(filterDisplayRecords) ? filterRecords(filterDisplayRecords, points) : points));
    };

    return (
      <>
        <PerfectScrollbar className="report-point-table-wrap">
          <div className={"report-point-table"}>
            {pagination.currentData &&
              pagination.currentData.map((point, index) => {
                return (
                  <ReportPointRow
                    key={`${point.current.uniqId}-${point.current.pointId}`}
                    deviceUnits={deviceUnits}
                    condensed={isCondensed}
                    timezone={deviceTimezone}
                    prevCur={point}
                  />
                );
              })}
          </div>
        </PerfectScrollbar>

        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={pagination.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />

        {/*<button onClick={handlePageClick}>{' < '}</button>*/}
        {/*<button onClick={()=>handlePageClick(true)}>{' > '}</button>*/}
      </>
    );
  };

  return (
    <div className="tables-wraper" ref={tableRef}>
      <div
        className={`d-flex justify-content-between ${
          (isMobile || window["cordova"]) && "align-items-center"
        }`}
      >
        <h3
          style={{
            maxWidth: 450,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {displayName}
        </h3>
        <div className={isMobile || window["cordova"] ? "d-flex" : ""}>
          <Button
            className="but but-toggle but-24px"
            styleType={ButtonStyles.GRAY_INACTIVE}
            style={
              isMobile || window["cordova"] ? { height: "max-content" } : {}
            }
            size={ButtonSize.XSM}
          >
            <NotificationSvg
              className={isMobile || window["cordova"] ? "icon-smd" : "icon-sm"}
            />
          </Button>
          <Button
            className={`but but-toggle but-24px ${
              showAllRoutes ? "active" : ""
            }`}
            styleType={
              showAllRoutes
                ? ButtonStyles.BLACK_WHITE
                : ButtonStyles.GRAY_INACTIVE
            }
            style={
              isMobile || window["cordova"] ? { height: "max-content" } : {}
            }
            size={ButtonSize.XSM}
            onClick={showAllRoutes ? hideAllTrips : showAllTrips}
          >
            <MapPointerSvg
              className={isMobile || window["cordova"] ? "icon-smd" : "icon-sm"}
            />
          </Button>
        </div>
      </div>
      {reportType === "travel" ? (
        reportData.map((trip) => (
          <div className="report-table-wraper" key={trip.current.tripId}>
            <RenderTripReportData
              trip={trip}
              showTrip={showTrip}
              records={records}
            />
          </div>
        ))
      ) : (
        <div className="report-table-wraper">
          {reportData && <RenderStaticReportData points={reportData} />}
        </div>
      )}
    </div>
  );
};

const RenderTripReportData = React.memo(({ trip,showTrip,records }:any) => {
  const deviceId = pipe(path([0, "device"]))(records);

  const deviceUnits = useSelector<iFullStoreState, Units>((s) =>
    s.devicesData.devicesDetails.getIn([deviceId, "units"], Units.miles)
  );

  const deviceTimezone = useSelector<iFullStoreState, string>((s) =>
    s.devicesData.devicesDetails.getIn([deviceId, "timezone"], "EST")
  );

  const reportTripsIds = useSelector<iFullStoreState, Array<boolean>>((s) => {
    const ids = s.devicesTripsPoints.getIn([deviceId, "reportTripsIds"]);

    return ids ? [...ids.values()] : [];
  });

  const showAllRoutes = reportTripsIds.length
    ? reportTripsIds.some((item) => item)
    : false;

    return (
      <>
        <PerfectScrollbar>
          <table
            className={
              "report-table new-table report-table-fix report-table-travel"
            }
          >
            <tbody className="report-table-travel-body">
              <ReportTripRow
                deviceId={deviceId}
                units={deviceUnits}
                timezone={deviceTimezone}
                current={trip.current}
                showAllRoute={showAllRoutes}
                showTrip={showTrip}
              />
            </tbody>
          </table>
        </PerfectScrollbar>
      </>
    );
  } );
