import { FenceGroup, iIcon } from '../../../../shared/interfaces';

import { FileFormat } from '../../../../shared/parsers';
import { IconName } from '@fortawesome/fontawesome-free-regular';
import { iCreateFence } from './../../../../shared/db/fences-db';
import toHex from 'colornames';

const convertType = (value: 'Ellipses' | 'Rectangle') => {
  const fenceTypes = {
    'Ellipses': 1,
    'Rectangle': 0
  };
  return fenceTypes[value];
}

type BoolString = 'TRUE' | 'FALSE';
enum FenceTypeName {
  ELLIPSES = 'Ellipses',
  RECTANGLE = 'Rectangle'
};

const booleanStringToBoolean = (value: BoolString): boolean => {
  switch (value) {
    case 'TRUE': return true;
    case 'FALSE':
    default:
      return false
  }
}

export type ICSVFileFence = {
  'Label': string[],
  'Type': FenceTypeName[],
  'Center Lat': string[],
  'Center Lng': string[],
  'Size': string[],
  'Waypoint': BoolString[],
  'Icon': string[],
  'Color': string[],
  'Description': string[],
  'Enter Alert': BoolString[],
  'Enter Alert Endpoints': string[],
  'Exit Alert': BoolString[],
  'Exit Alert Endpoints': string[],
  'Hide Name': BoolString[],
  'Hide Shadows': BoolString[],
  'Hide Icon': BoolString[]
};

export const transformFence = (obj: ICSVFileFence): iCreateFence => ({
  'label': obj['Label'][0],
  'name': obj['Label'][0],
  'waypoint': booleanStringToBoolean(obj['Waypoint'][0]),
  'region': {
    'center': {
      'lat': Number(obj['Center Lat'][0]),
      'lng': Number(obj['Center Lng'][0]),
    },
    'name': obj['Label'][0],
    'editable': true,
    'width': Number(obj['Size'][0]),
    'height': Number(obj['Size'][0]),
    'shape': convertType(obj['Type'][0] || 'Ellipses'),
    'hideName': booleanStringToBoolean(obj['Hide Name'][0]),
    'hideShadows': booleanStringToBoolean(obj['Hide Shadows'][0]),
    'hideIcon': booleanStringToBoolean(obj['Hide Icon'][0])
  },
  'icon': { fa: {
    iconName: obj['Icon'][0].toLowerCase() as IconName || 'car',
    prefix: 'fas'
  }} as iIcon,
  // TODO: [ DATA CONSISTENCY ] need to look avery iIcon usage;
  // TODO: redo google map marker icon handling
  // for some reasone there is path value in each icon
  // but for representing icon is enought prefix and icon name property
  'color': toHex(obj['Color'][0] || 'Black'),
  'fenceGroup': FenceGroup.FENCE,
  'exitAlertEndpoint': obj['Exit Alert Endpoints']?.[0]? {
    type: 'custom',
    value: obj['Exit Alert Endpoints']
  } : null,
  'description': obj['Description'][0],
  'enterAlertEndpoint': obj['Enter Alert Endpoints']?.[0]? {
    type: 'custom',
    value: obj['Enter Alert Endpoints']
  } : null,
  'exitAlert': booleanStringToBoolean(obj['Exit Alert'][0]),
  'enterAlert': booleanStringToBoolean(obj['Enter Alert'][0])
});



export const createFenceTransformFrom = (type: FileFormat): (obj: any) => iCreateFence => {
  switch (type) {
    case FileFormat.CSV:
      return transformFence
  }
}