import {IFullProps, IProps, IPropsFromStore} from './ReportPageFilterSection.interfaces';

import {BaseComponent} from '../../../../shared/BaseComponent';
import {Collapsible} from "../../../general/Collapsible";
import React from 'react';
import {Actions as ReportActions} from '../../../../stores/reducers/report-reducers';
import {SearchGrid} from '../../../general';
import {SelectionsGrid} from '../../../general';
import { connect } from "react-redux";
import {iFullStoreState} from '../../../../shared/interfaces';
import { lCount } from "../../../../shared/helpers";

const mapStateToProps = (state: iFullStoreState, {filterType}: IProps): IPropsFromStore => {
    return {
        currentFilter: state.report.details.filters[filterType],
        currentFilterObject: state.report.details.filters,
        filterStr: state.report.filterFilters[filterType],
    }
}

export const ReportPageFilterSection = connect(mapStateToProps)(
	class extends BaseComponent<IFullProps> {
		debugRender = () => {
			const { title, visible, filterType, Icon, disabled, currentFilter, currentFilterObject, dispatch, filterStr, RedirectToStatic, hasSelectionsGrid, countOfSelectedFilters } = this.props;

			const selectedCount = lCount(currentFilter);
			const countTitle = selectedCount ? `(${selectedCount})` : (hasSelectionsGrid ? '' : '(all)');

			const someFiltersLessThanTwo = Object.entries(countOfSelectedFilters).every(([key,value]) => value < 2);

			const checkingSelectedFilters = () => {
				return countOfSelectedFilters[filterType] >= 2 || !countOfSelectedFilters[filterType] || countOfSelectedFilters[filterType] == 1;
			}

			const checkingCurrentFiltersMoreThanTwo = checkingSelectedFilters()
			const setFilterByClick = ({filterType, id}) => {
				if(someFiltersLessThanTwo || checkingCurrentFiltersMoreThanTwo) {
					dispatch(ReportActions.TOGGLE_ITEM_FILTER(filterType, id))
				}
			}

			return (
				<div id={filterType} className={`report-filter-section ${disabled? "disabled": ""}`}>
					<Collapsible
						isCollapsed={true}
						className="report-filter"
						headerClassName="report-filter-header"
						bodyClassName="report-filter-body"
						header={
							<div className="report-filter-labels-header" >
								<Icon className="report-filter-icon "/>
								{title}
								<span className="weight-normal">&nbsp;{countTitle}</span>
							</div>
						}
					>
						{
							hasSelectionsGrid ?
								<SelectionsGrid
									filterStr={filterStr || ''}
								/>
								:
								<SearchGrid
									onReset={() => dispatch(ReportActions.RESET_ALL_TYPE_FILTERS(filterType))}
									list={visible}
									filterStr={filterStr || ''}
									placeholder={`Search...`}
									perPage={9}
									RedirectToStatic={RedirectToStatic}
									keyClicked={id => setFilterByClick({filterType, id})}
									filterChange={f => dispatch(ReportActions.SET_FILTER_FILTER(filterType, f))}
									selectedCount={selectedCount}
									countOfSelectedFilters={countOfSelectedFilters}
									currentFilterObject={currentFilterObject} 
									filterType={filterType}
								/>
						}

					</Collapsible>
				</div>
			);
		}
	}
)
