import React from 'react';

import { cnTabs } from '../';

type IProps = {
    label: string;
    onClick: () => void;
    active?: boolean;
}

const Tab = ({label, active, onClick}: IProps) => (
    <li className={cnTabs('Tab', {active})} onClick={onClick}>
        <span>{label}</span>
    </li>
);

export default Tab;
