import React from "react";
import {iFullProps, iProps} from "./LoadFenceAsyncWrapper.interfaces";
import {connect} from "react-redux";
import {iFullStoreState} from "../../../../shared/interfaces";
import {BaseComponent} from "../../../../shared/BaseComponent";
import {fenceHistory} from "../../../../stores/store";
import {Actions as FenceActions} from "../../../../stores/reducers/fences-reducers";
import { pathOr } from 'ramda';

class LoadFenceAsyncWrapper extends BaseComponent<iProps, any, iFullProps> {

    componentDidMount() {
        if (!this.props.tagView && (this.props.itemId == null || this.props.itemType == null)) {
            fenceHistory.push('/');
        }

        if (this.props.setEditMode) {
            this.props.dispatch(FenceActions.SET_EDIT_MODE(true));
        }
    }

    componentWillUnmount() {
        if (this.props.setEditMode) {
            this.props.dispatch(FenceActions.SET_EDIT_MODE(false));
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {itemId, itemType, match: {params: {itemId: realUrlItemId, itemType: realUrlItemType}}} = nextProps;

        // make sure once the url changes we don't show the fence for the wrong path
        if (!this.props.tagView && (itemId !== realUrlItemId || itemType !== realUrlItemType)) fenceHistory.push('/');
    }

    debugRender = () => {
        const {comp: Component, fenceDetails} = this.props;

        return !fenceDetails ? null : <Component {...this.props} initialDetails={fenceDetails} />;
    };
}

export default connect((state: iFullStoreState, ownProps: iProps) => {
    return ({
        fenceDetails: pathOr({}, ['general', 'fences', ownProps.match.params.itemType, ownProps.match.params.itemId, ownProps.match.params.fenceId])(state)
    });
})(LoadFenceAsyncWrapper);