import React, {FC, ComponentType} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import Tooltip from "@material-ui/core/Tooltip";
import './ToggleButton.scss';
import {isMobile} from '../../shared/helpers';

export interface IToggleButtonProps extends IClassNameProps {
    pressed?: boolean;
    icon: ComponentType;
}

export const cnToggleButton = cn( 'ToggleButton');

const ToggleButton: FC<IToggleButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    className,
    pressed = false,
    icon: Icon,
    name='',
    ...props
}) => (

       isMobile ?
           (<div className='icon-wrapper'>
                <button {...props} className={cnToggleButton({pressed}, [className])}>
                    {Icon && (
                        <Icon />
                    )}
                </button>
                <label className='icon-name'>{name}</label>
           </div>
           )
           : (
               <button {...props} className={cnToggleButton({pressed}, [className])}>
                   {Icon && (
                       <Icon />
                   )}
                   {children}
               </button>
           )
);

export default ToggleButton;
