import update from 'immutability-helper';
import { assoc, always } from 'ramda';

class ActionsClass {
    TOGGLE_MODAL_SIZE = (dir: 'shrink'|'grow') => ({type: 'LAYOUT_TOGGLE_MODAL_SIZE', dir})
    EXPAND_IF_CLOSED = () => ({ type: 'LAYOUT_EXPAND_IF_CLOSED' });
}

const nextSize = (current, dir: 'shrink'|'grow') => {
    if (dir == 'shrink') {
        if (current == 'none') return 'none';
        if (current == 'partial') return 'none';
        if (current == 'full') return 'partial';
    } else {
        if (current == 'none') return 'partial';
        if (current == 'partial') return 'full';
        if (current == 'full') return 'full';
    }

}


export const Actions = new ActionsClass();

export const reducers = (state:iState = initialState, action) => {
    if (action.type == 'GMAP_STREET_VIEW_TOGGLE') return update(state as any, {
        modalSize: { $set: 'none' }
    })

    if (action.type == 'LAYOUT_TOGGLE_MODAL_SIZE') return update(state as any, {
        modalSize: { $set: nextSize(state.modalSize, action.dir) }
    });

    if (action.type == 'LAYOUT_EXPAND_IF_CLOSED') {
        if (state.modalSize == modalSize.none) return update(state as any, {
            modalSize: { $set: modalSize.partial }
        })
    }

    return state;
}

export enum modalSize {
    none = 'none',
    partial = 'partial',
    full = 'full',
}
export interface iState {
    modalSize: modalSize
}

const initialState: iState = {
    modalSize: modalSize.partial
}
