import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DayPicker, {RangeModifier} from 'react-day-picker';
import moment from 'moment';

import {Actions as TimelineActions} from '../../../../../stores/reducers/timeline-reducers';
import {iFullStoreState, TimelineRangeType} from '../../../../../shared/interfaces';
import {WeekCustomPickerProps} from "./WeekCustomPicker.interfaces";
import {DayModifiers} from 'react-day-picker/types/Modifiers';
import Weekday from '../../DayPickerCustomWeekday';
import NavBar from '../../DayPickerCustomNavBar';
import {isMobile, WeekOrDayHelper} from '../../../../../shared/helpers';

function WeekCustomPicker ({ weekRange, setActiveTab, setTabsData } : WeekCustomPickerProps ) {
    const {from, to} = weekRange ?? {};
    const modifiers = {start: from, end: to};

    React.useEffect(() => {
        setActiveTab('Week');
    }, []);

    const handleDayClick = (day, modifiers: DayModifiers) => {
        if (modifiers.disabled) return;

        const getRange = WeekOrDayHelper(day, true);

        let range = {
            from: getRange.startDate,
            to: getRange.endDate
        };

        setTabsData(range);
    };

    const selectedDays = from && to ? [from, {from, to}] : undefined;
    const today = new Date();

    return (
        <DayPicker
            disabledDays={{after: today}}
            numberOfMonths={ isMobile ? 1 : 2}
            initialMonth={from ? from : today}
            toMonth={today}
            month={from ? from : today}
            showOutsideDays
            className="Selectable"
            onDayClick={handleDayClick}
            selectedDays={selectedDays}
            modifiers={modifiers}
            navbarElement={NavBar}
            weekdayElement={Weekday}
            captionElement={() => null}
        />
    );
}

export default WeekCustomPicker;

