import React from "react";
import { Fa } from "../../../elements/fa";
import { faSpinner } from "@fortawesome/fontawesome-free-solid";
import "./BillingButton.scss";

interface IButton {
  key?: string;
  text: string;
  loading?: boolean;
  color?: string;
  action?: any;
}

const BillingButton = ({ text, loading, color, action }: IButton) => {
  return (
    <button
      className="form-btn"
      type="submit"
      style={{ backgroundColor: `${color}` }}
      onClick={action}
    >
      {loading ? <Fa icon={faSpinner} spin /> : `${text}`}
    </button>
  );
};

export default BillingButton;
