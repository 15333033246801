import React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {values, pathOr} from 'ramda';
import {get} from 'lodash';
import {faSpinner} from '@fortawesome/fontawesome-free-solid';

import {BaseComponent} from '../../../shared/BaseComponent';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {iFullStoreState, ItemType, UserAuth, iPerson, iTag, iList, iItemsAllowed} from '../../../shared/interfaces';
import { default as C, UserCan} from '../../../shared/constants';
import { toggleUserPermission, userCan } from "../../../shared/db/users-access";
import { StandardItem} from '../../general';
import {TableSimple} from '../../elements/plain-table';
import {setAllowedSee, removeAllowedSee, watchPersonAllowedSee, tagReadOnlyToggle} from '../../../shared/db/general-db';
import {Fa} from '../../elements/fa';
import {Row} from '../../elements/flex';
import {DevicesDetailsContainer} from '../../../stores/reducers/devicesData';
import Checkbox from '../../elements/Checkbox';
import SearchGridOld from "../../../components/SearchGridOld/SearchGridOld";

type IPropsFromStore = {
    person: iPerson;
    tags: iList<iTag>;
    devicesDetails: DevicesDetailsContainer;
    authUser: UserAuth;
}
type IFullProps = IPropsFromStore & DispatchProp;

interface StateInterface {
    tagFilter;
    deviceFilter;
    allowed?: iItemsAllowed;
    readOnlyAll: boolean;
    readOnlyAccess: object;
    canEditTagsAndFences: boolean;
}

const itemStyle = (active, compressed=false) => {
    const style = {
        cursor: 'pointer',
        display: 'inline-block',
        margin: compressed ? 4 : 8,
        fontSize: compressed ? 10 : 'initial',
        color: active ? '#fff' : C.primaryColor,
    } as any;

    if (active) style.backgroundColor = C.primaryColor;

    return style;
};

export default connect((s: iFullStoreState, o: any): IPropsFromStore => ({
    person: s.general.people[o.match.params.id],
    tags: s.general.tags,
    devicesDetails: s.devicesData.devicesDetails,
    authUser: s.auth.user!,
}))
(class PersonCanSee extends BaseComponent<IFullProps, StateInterface> {
    private canceller;
    constructor(props) {
        super(props);
        this.state = {
            tagFilter: '',
            deviceFilter: '',
            readOnlyAll: false,
            readOnlyAccess: {},
            canEditTagsAndFences: false,
        };
    }

    _populateState = (allowed: iItemsAllowed) => {
        this.setState({allowed});
    }

    async componentDidMount() {
        const {person, tags} = this.fullProps;
        this.canceller = watchPersonAllowedSee(person.id, (allowed) => this.setState({allowed: allowed || {}}, () => {
            const devicesAccess = allowed.device ? Object.keys(allowed.device).reduce((acc, rec) => {
                return {...acc, [rec]: allowed.device[rec].readOnly || false};
            }, {}) : {};
            const filteredTags = Object.keys(tags).reduce((acc, rec) => {
                if(get(tags[rec], `instances.allowed-see.person.${person.id}`)) {
                    return {...acc, [rec]: tags[rec]};
                }

                return acc;
            }, {});
            const tagsAccess = Object.keys(filteredTags).reduce((acc, rec) => {
                return {...acc, [rec]: get(tags[rec], `instances.allowed-see.readOnly.${person.id}`, false)};
            }, {});

            this.setState({readOnlyAccess: {...devicesAccess, ...tagsAccess}});
        }));
         const canEditTags = await userCan(person.id, UserCan.TAG_THINGS) && await userCan(person.id, UserCan.CREATE_TAGS);
         const canEditFences = await userCan(person.id, UserCan.EDIT_FENCES);
         this.setState({canEditTagsAndFences: canEditTags && canEditFences });
    }

    requestPermissionToggling = async () => {
        const { person } = this.fullProps;
        const { canEditTagsAndFences } = this.state;

        const tagThings = await toggleUserPermission(person.id, UserCan.TAG_THINGS, !canEditTagsAndFences);
        const canCreateTags = await toggleUserPermission(person.id, UserCan.CREATE_TAGS, !canEditTagsAndFences);
        const canEditFences = await toggleUserPermission(person.id, UserCan.EDIT_FENCES, !canEditTagsAndFences);

        this.setState({canEditTagsAndFences: tagThings && canCreateTags && canEditFences});
    }

    componentWillUnmount() {
        if (this.canceller) this.canceller();
    }

    _toggleTagFlag = async (tagId) => {
        const {tags, person,} = this.fullProps;
        const {readOnlyAccess} = this.state;

        const isRemove = pathOr(false, [tagId, 'instances', 'allowed-see', 'person', person.id])(tags);

        await (isRemove ? removeAllowedSee : setAllowedSee)(this.fullProps.authUser)(
            this.fullProps.person.id,
            ItemType.tag,
            tagId
        );

        if (isRemove) {
            this.setState({readOnlyAccess: {...readOnlyAccess, [tagId]: !readOnlyAccess?.[tagId]}});
        }

        fetch('https://api.mastrack-map.sapient.pro/api-firebase/revoke', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({uid: this.fullProps.person.id})
        });
    }

    _filterchange = (key: keyof StateInterface) => (str) => this.setState({[key]: str} as any)

    static helpMsg = `
        Manage which devices a user can view. Adding a tag will allow this person to see all devices that have the associated tag.
        To prevent a user from seeing a device make sure device overrides and appropriate tags are removed. The complete list
        of seeable devices is at the very bottom.
     `;

    debugRender = () => {
        const {person, tags} = this.props;
        const {tagFilter, allowed = false, readOnlyAccess, canEditTagsAndFences } = this.state;

        const tagItems = values(tags).map((tag) =>
            <StandardItem
                style={itemStyle(pathOr(false, ['instances', 'allowed-see', 'person', person.id])(tag))}
                key={tag.details.id}
                displayName={tag.details.name}
                itemId={tag.details.id}
            />);

        const filteredTags = Object.keys(tags).reduce((acc, rec) => {
            if(get(tags[rec], `instances.allowed-see.person.${person.id}`)) {
                return {...acc, [rec]: tags[rec]};
            }

            return acc;
        }, {});

        return (
            <DashboardBlock>
                <ModalHeader title={person ? person.displayName : ''} help={PersonCanSee.helpMsg} />

                {!allowed ? <Row justify="center"><Fa icon={faSpinner} spin style={{fontSize: 28}} /></Row> : <>
                    <SearchGridOld
                        style={{marginTop: 10, maxWidth: 350}}
                        textual={true}
                        list={tagItems}
                        filterStr={tagFilter}
                        placeholder="Search Tags..."
                        perPage={9}
                        keyClicked={this._toggleTagFlag}
                        filterChange={this._filterchange('tagFilter')}
                    />
                    { <div><label><input type="checkbox" onChange={this.requestPermissionToggling} checked={canEditTagsAndFences}/> Allow to edit tags and fences</label></div>}
                    {/* <div><label><input type="checkbox" onChange={this.toggleUserCanEditFences} checked={canEditFences}/> Allow to edit fences</label></div> */}
                    {(Object.keys(filteredTags).length > 0 || !!allowed.device) && <>
                        <p style={{marginTop: 10}}>Visible list</p>
                        <TableSimple sm dark striped headers={['Label', 'Source', 'Access']}>
                            {Object.keys(filteredTags || {}).map((tag) => (
                                <tr key={tag}>
                                    <td style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: 170
                                    }}>{filteredTags[tag].details.name}</td>
                                    <td>Tag</td>
                                    <td>
                                        <Checkbox
                                            styles={{marginTop: 6}}
                                            labelStyles={{fontSize: 16, color: "white"}}
                                            inputId={tag}
                                            onChange={() => {
                                                this.setState({
                                                    readOnlyAccess: {
                                                        ...readOnlyAccess,
                                                        [tag]: !readOnlyAccess?.[tag]
                                                    }
                                                });
                                                tagReadOnlyToggle(this.fullProps.authUser)(tag, this.fullProps.person.id, !readOnlyAccess?.[tag]);
                                            }}
                                            checked={readOnlyAccess?.[tag]} label='Read only'
                                        />
                                    </td>
                                </tr>))
                            }
                        </TableSimple>
                    </>}

                </>}
            </DashboardBlock>
        );
    }
});