import { iList, iPerson, iTag } from "../../../shared/interfaces";

import { DevicesDetailsContainer } from "../../../stores/reducers/devicesData";
import { DispatchProp } from "react-redux";
import { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { iReportType } from "../../../stores/reducers/report-reducers";

export enum FilterType {
	DEVICES = 'device',
	PEOPLE = 'person',
	TAGS = 'tag',
	ALERTS = 'alerts',
	LABELS = 'labels',
	DATA = 'data',
    VALUES = 'values'
}

export interface IPropsFromStore {
    devicesDetails: DevicesDetailsContainer;
    people: iList<iPerson>,
    tags: iList<iTag>,
    dateError?: string,

    labels: string[],
    reportKey?: string;

    visLabels: ReactElement[],
    visDevices: ReactElement[],
    visPeople: ReactElement[],
    visTags: ReactElement[],
    visAlerts: ReactElement[],

    dates: { startDate: moment.Moment, endDate: moment.Moment }[],
    filterStrs: { [itemType: string]: string },
    currentFilters: {[type: string]: { [id: string]: true }},
}

export interface IRouteParams {
    type: iReportType;
    filterItem?: FilterType;
}

export interface IProps extends IPropsFromStore, DispatchProp, RouteComponentProps<IRouteParams> {}

export interface IState {
    deviceFilter: string;
    multiDate: boolean;
    startKey?: string
}