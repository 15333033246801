import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import {useDispatch} from 'react-redux';
import {useRedux} from "../../../states/redux-state";
import {Actions as ReportActions} from "../../../stores/reducers/report-reducers";
import {
    groupedOptions,
    optionsCompare,
    customSelectStyles,
    address,
    compareOperations,
} from './constants';
import {iProps} from "./types";
import {iDevicePing} from "../../../shared/interfaces";
import {ReactComponent as AddIcon} from "../../../assets/svg/plus.svg";
import {ReactComponent as RemoveIcon} from "../../../assets/svg/minus.svg";
import {ReactComponent as ChevronDownIcon} from "../../../assets/svg/chevron_down_black.svg";
import { Button, ButtonSize, ButtonStyles } from '../../Button';
import './index.scss'

const SelectionsGrid = (props: iProps) => {
    const initialSelections = { option: '', compare: '',  value: '' };
    const [selectionsList, setSelectionsList] = useState([initialSelections]);
    const [showValidateMessage, setShowValidateMessage] = useState(false);
    const dispatch = useDispatch();
    const displayRecordsRaw = useRedux((s) => s.report.displayRecords) || {};

    const handleChangeSelection = (e, index) => {
        const { name, value } = e.target ? e.target : e;
        const list = [...selectionsList];
        // dispatch(ReportActions.SET_FILTER_DISPLAY_RECORDS(list));
        list[index][name] = value;
        setSelectionsList(list);
        setShowValidateMessage(false);
    };

    const handleRemoveClick = index => {
        const list = [...selectionsList];
        list.splice(index, 1);
        setSelectionsList(list);
    };

    const handleAddClick = () => {
        setSelectionsList([...selectionsList, initialSelections]);
    };

    const handleOnResetFilter = () => {
        dispatch(ReportActions.RESET_DATES());
        // dispatch(ReportActions.SET_FILTER_DISPLAY_RECORDS([]));
        dispatch(ReportActions.SET_SELECTIONS_LIST([]));
        setSelectionsList([initialSelections]);
    }

    const records = Object.values(displayRecordsRaw);
    const checkAddressChose = (address, option) => address.some(item => item === option);

    const validate = () => {
        let isInvalid = false;

        selectionsList.forEach((filter) => {
            const {option, compare, value} = filter;

            if (!option || !compare || !value) {
                isInvalid = true;
            }
        })

        return !isInvalid;
    }

    const handleOnApplyFilter = () => {
        dispatch({type: 'REPORT_SET_DISPLAY_RECORDS', displayRecords: {}});
        dispatch(ReportActions.SET_SELECTIONS_LIST(selectionsList))
        let updates = {};
        let filtered;
        const isValid = validate();
        setShowValidateMessage(false);

        if (!isValid) {
            setShowValidateMessage(!isValid);
            return false;
        }

        selectionsList.forEach((filter) => {
            const {option, compare, value} = filter;
            const isAddress = checkAddressChose(address, option);

            filtered = records
                .filter((record: iDevicePing) => {
                    if (isAddress && record.hasOwnProperty('address')) {
                        return compare === 'equal'
                            ? record.address[option] === value.trim()
                            : record.address[option] !== value.trim();
                    } else {
                        return compareOperations?.[compare](parseInt(record?.[option]), parseInt(value));
                    }
                })
        })

        filtered.forEach((record: iDevicePing) => updates[record.pointId] = record);
        dispatch(ReportActions.SET_VALUES_FILTER(updates));
    }

    const DropdownIndicator = (
        props: DropdownIndicatorProps<true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <ChevronDownIcon/>
            </components.DropdownIndicator>
        );
    };


    return (
        <>
            <div className="selections-grid">
                {showValidateMessage && <div className="selections-grid-message">Complete all filter fields</div>}
                {selectionsList.map((selection, index) => {
                    return (
                        <div className="selections-grid-row" key={index}>
                            <div className="selections-group">

                                <Select options={groupedOptions}
                                        styles={customSelectStyles}
                                        components={{ DropdownIndicator }}
                                        className="selections-group-select"
                                        onChange={(e) => handleChangeSelection(e, index)}
                                        name="option"
                                />
                                <Select options={optionsCompare}
                                        styles={customSelectStyles}
                                        components={{ DropdownIndicator }}
                                        className="selections-group-select"
                                        onChange={(e) => handleChangeSelection(e, index)}
                                        name="compare"
                                />
                                <input type="text"
                                       placeholder="Enter value..."
                                       className="form-control selections-group-input"
                                       onChange={(e) => handleChangeSelection(e, index)}
                                       name="value"
                                       value={selection.value}
                                />
                            </div>
                            <div className="selections-controls">
                                {selectionsList.length !== 1 &&
                                    <button type="button"
                                            className="selections-btn"
                                            onClick={() => handleRemoveClick(index)}>
                                            <RemoveIcon className="selections-btn-icon"/>
                                    </button>}
                                {selectionsList.length - 1 === index &&
                                    <button type="button"
                                            className="selections-btn"
                                            onClick={handleAddClick}>
                                            <AddIcon className="selections-btn-icon"/>
                                    </button>}
                            </div>
                        </div>
                    );
                })}
                <div className="selections-grid-controls">
                    <Button
                        styleType={ButtonStyles.BLACK_WHITE}
                        size={ButtonSize.MD}
                        onClick={() => handleOnResetFilter()}
                    >
                        Reset to all
                    </Button>
                    <Link
                        className="selections-apply-btn"
                        to={'static'}
                        onClick={() => handleOnApplyFilter()}
                    >
                        Apply
                    </Link>
                </div>
            </div>
        </>
    )
}

export default SelectionsGrid;
