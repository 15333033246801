import React from "react";
import styles from './ServiceCancelationModal.module.scss';
const ServiceCancelationInfoModal = ({ row, activationDevice, onClose }) => {
    return (
        <div className={styles.modalContainer}>
            <h2>Service Cancellation Confirmation</h2>
            <div className={styles.cancelInfo}>
                <h4>Device Serial#</h4>
                <span>{row?.serial || activationDevice?.serial}</span>
                <h4>Status</h4>
                <span>CANCELLED</span>
                <h4>Request date</h4>
                <span>08-05-2022</span>
                <h4>Last Day of Service</h4>
                <span>04-18-2019</span>
                <p>No further service charges will be made for Serial # N4143901088 Service for device Serial # N4143901088
                    will cease on 04-18-2019. You may re-activate for free within 30 days.
                    After 30 days a reactivation fee applies.
                </p>
                <p>No further charges will be made to your account for the suspended unit(s) unless you reactivate service.</p>
            </div>
        </div>
    )
}

export default ServiceCancelationInfoModal;
