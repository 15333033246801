import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {compose} from '@bem-react/core';
import moment from 'moment';

import {iFullStoreState} from '../../../../shared/interfaces';
import {TimelineDialogProps} from "./TimelineDialog.interfaces";
import DayRangeCustomPicker from './DayRangeCustomPicker';
import MonthCustomPicker from './MonthCustomPicker';
import WeekCustomPicker from "./WeekCustomPicker";
import DayCustomPicker from "./DayCustomPicker";
import TimelineDialogFooter from "./TimeLineDialogFooter/TimeLineDialogFooter";
import {Actions as GeneralActions} from '../../../../stores/reducers/general-reducers';
import {Actions as GmapActions} from '../../../../stores/reducers/gmap-reducers';
import {Actions as TimelineActions} from '../../../../stores/reducers/timeline-reducers';

import BEMTabs from '../../../../BemComponents/Tabs';
import {withTabsThemeDark} from '../../../../BemComponents/Tabs/_theme/Tabs_theme_dark';
import {withTabsStyledUnderlined} from '../../../../BemComponents/Tabs/_styled/Tabs_styled_underlined';

import './styles.scss';
import {isMobile} from '../../../../shared/helpers';
import {RangeModifier} from "react-day-picker";
import {clearDeviceRoute} from "../../../../stores/reducers/devicesTripsPoints/AC";
import {ref} from "yup";

const Tabs = compose(
    withTabsThemeDark,
    withTabsStyledUnderlined,
)(BEMTabs);

const TimelineDialog = (props: TimelineDialogProps ) => {
    // const rangeType = useSelector<iFullStoreState, TimelineRangeType>(s => s.timeline.rangeType)
    const [showTrail, setShowTrail] = React.useState(true);
    const [pause, setPause] = React.useState(true);

    const [activeTab, setActiveTab] = React.useState<string>('Custom');
    const [tabsData, setTabsData] = React.useState(null);

    const dispatch = useDispatch();

    const trailToggle = () => {
        setShowTrail(!showTrail);
        dispatch(GeneralActions.TOGGLE_LIVE_TRAIL());
    };
    const pauseToggle = () => {
        setPause(!pause);
        dispatch(GmapActions.TOGGLE_RECENTER_MAP());
    };

    const applyRange = () => {
        const drawer = props.drawerRef.current;
        const dateRange = tabsData[activeTab];
        drawer?.({close: true});
        props.setOpenedSettings(false);
        dispatch(clearDeviceRoute(props.deviceId));
        dispatch(TimelineActions.APPLY_RANGE( moment(dateRange.from), moment(dateRange.to)));
    };

    const handleTabData = (tabData: RangeModifier) => {
        setTabsData(prevSate => ({
            ...prevSate,
            [activeTab]: tabData
        }));
    };

    const clearRange = () => {
        setTabsData(prevSate => ({
            ...prevSate,
            [activeTab]: null
        }));
    };

    const dateRange = tabsData && tabsData[activeTab] ? tabsData[activeTab] : null;

    return (
        <div className="TimelineDialog">
            {/* <div className={`TimelineDialog-Body${rangeType === TimelineRangeType.DAY || rangeType === TimelineRangeType.WEEK ? ' compact' : ''}`}>*/}
            {props.live ? (
                <div className="TimelineDialog-LiveBody">
                    <button onClick={trailToggle}> { showTrail ? 'Hide trail' : 'Show trail'}</button>
                    <button onClick={pauseToggle}>{ pause ? 'Pause' : 'Go'}</button>
                </div>
            ) : (
                <>
                    <div className="TimelineDialog-Body">
                        <Tabs styled="underlined" theme="dark" activeTab={3}>
                            <div data-label="Custom">
                                <DayRangeCustomPicker setActiveTab={setActiveTab}
                                                      customDayRange={dateRange}
                                                      setTabsData={handleTabData} />
                            </div>
                            <div data-label="Month">
                                <MonthCustomPicker monthYearRange={dateRange} setActiveTab={setActiveTab} setTabsData={handleTabData} />
                            </div>
                            <div data-label="Week">
                                <WeekCustomPicker setTabsData={handleTabData} weekRange={dateRange} setActiveTab={setActiveTab} />
                            </div>
                            <div data-label="Day">
                                <DayCustomPicker setTabsData={handleTabData} dayRange={dateRange} setActiveTab={setActiveTab} />
                            </div>
                        </Tabs>
                    </div>
                    <TimelineDialogFooter tempRange={dateRange}
                                          clearRangeCallback={clearRange}
                                          updateRangeCallback={applyRange}
                                          cancelButtonCallback={props.hideCalendarCallback} />
                </>
            )}
        </div>
    );
};

export default TimelineDialog;
