import React from "react";
import {RouteComponentProps} from "react-router-dom";
import AlertSettingsPage from "../../../pages/alerts/alert-settings";
import {withDispatchActions} from "../../../../shared/hoc/withDispatchActions";
import {toggleFenceEditModeActions} from "../FencesContainer.helpers";
import {IAlertSettingsPageWithToggleActionsProps} from "./AlertSettingsPageWithToggleActions.intrefaces";

type IAlertSettingsFullProps = IAlertSettingsPageWithToggleActionsProps & RouteComponentProps;

const AlertSettingsPageWithToggleActions = (props: IAlertSettingsFullProps) => {
    return (
        <AlertSettingsPage {...props} />
    );
}

export default withDispatchActions(AlertSettingsPageWithToggleActions, toggleFenceEditModeActions);