import React from "react";

interface CustomInputT {
  name: string;
  placeholder?: string;
  value: string;
  classname?: string;
  onChange: (e: any) => void;
  type?: string;
  label?: string;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  errors?: string;
  touched?: boolean;
  className?: string;
}

const CustomBillingInput: React.FC<CustomInputT> = ({
  label,
  name,
  type = "text",
  errors,
  touched,
  className,
  ...rest
}) => {
  return (
    <label htmlFor={name}>
      {label}
      <input
        {...rest}
        name={name}
        id={name}
        type={type}
        className={className}
        style={{
          borderColor: errors && "#fa5732",
          boxShadow: errors && "inset 0 1px 1px #00000014, 0 0 8px #f8000099",
        }}
      />
      {touched && errors ? <div className="error-message">{errors}</div> : null}
    </label>
  );
};

export default CustomBillingInput;
