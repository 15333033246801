import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Loader from "../../Other/Loader";
import { iReducersState } from "../../../../../stores/reducers";
import { getUnactivatedDevices } from "../../BillingApi/billingApiRequests";
import "./UnactivatedDevices.scss";

const UnactivatedDevices = ({ chooseUnactivatedDevice }) => {
  const [loading, setLoading] = useState(false);
  const [unactivatedDevice, setUnactivatedDevice] = useState([]);
  const { token } = useSelector((state: iReducersState) => state.billing);

  useEffect(() => {
    setLoading(true);
    getUnactivatedDevices({ setUnactivatedDevice, token })
    .finally(() => setLoading(false));
  }, []);

  return (
    <div className="activation">
      <p className="activation__title">
        {`WE FOUND ${
          unactivatedDevice && unactivatedDevice?.length
        } UNACTIVATED DEVICES ON YOUR ACCOUNT, WOULD YOU LIKE TO
          ACTIVATE ONE OF THESE?`}
      </p>
      <hr />
      <div className="activation__devices">
        <div className="activation__devices-header">
          <div>Name</div>
          <div>Serial</div>
          <div>Status</div>
        </div>
        <div className="activation__devices-content">
          {loading ? (
            <Loader />
          ) : (
            <>
              {unactivatedDevice.length ? (
                unactivatedDevice?.map((i) => {
                  return (
                    <div className="activation__devices-line" key={i.device_ID}>
                      <div>{i.name}</div>
                      <div>{i.serial}</div>
                      <div>
                        <button
                          onClick={() =>
                            chooseUnactivatedDevice(i.device_ID, i.serial)
                          }
                        >
                          ACTIVATE THIS DEVICE
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="empty-list">
                  There is no unactivated devices
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default UnactivatedDevices;
