import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { iReducersState } from "../../../../../stores/reducers";
import { billingLoading } from "../../../../../stores/reducers/billing";

import { IUndoCancellation } from "./interfaces";

import {
  changePendingInfo,
  changesPending,
  contractCancel,
  contractCancelInfo,
} from "../../BillingApi/billingApiRequests";
import Loader from "../../Other/Loader";
import BillingButton from "../../Other/BillingButton";
import "./ModalCancellation.scss";

function ModalCancellation({ row, closeWindow }) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [cancel, setCancel] = useState({ plan_id: "" });
  const [pending, setPending] = useState([]);
  const [pendingDescription, setPendingDescription] = useState<IUndoCancellation>({
    message: "",
    status: "",
    value: null,
  });
  const { loading } = useSelector(
    (state: iReducersState) => state.billing
  );
  const { plan_id } = cancel;
  const { value, status } = pendingDescription || {};
  const dispatch = useDispatch();

  useEffect(() => {
    // contract cancel info GET
    setLoader(true);
    dispatch(billingLoading(true));
    if (row?.changeSubscription === "contract-cancel") {
      contractCancelInfo({ id: row?.id, setCancel }).finally(() => {
        setLoader(false);
        dispatch(billingLoading(false));
      });
    }

    // /api/plan/change-pending-info, change pending info GET
    if (row?.changeSubscription === "changes-pending") {
      changePendingInfo({
        id: row?.id,
        setPendingDescription,
        setPending
      }).finally(() => {
        setLoader(false);
        dispatch(billingLoading(false));
      });
    }
  }, [row?.changeSubscription, dispatch, row?.id]);

  const cancellationRequest = () => {
    dispatch(billingLoading(true));
    //// post contract cancel, don't work at this moment
    if (row?.changeSubscription === "contract-cancel") {
      contractCancel({
        plan_id,
        device_id: row?.id
      }).finally(() => {
        dispatch(billingLoading(false));
        closeWindow(true);
        history.push('/billing')
      });
    }

    //// post change pending, don't work at this moment
    if (row.changeSubscription === "changes-pending") {
      changesPending({ value, status, device_id: row.id})
      .finally(() => {
          dispatch(billingLoading(false));
          closeWindow(true);
          history.push('/billing')
        });
    }
  };
  return (
    <div className="modal__cancellation">
      <div className="modal__cancellation-description">
        {loader ? (
          <Loader size={20} />
        ) : (
          <>
            {row?.changeSubscription === "changes-pending" && (
              <>
                <h4>{pendingDescription.message}</h4>
                <div className="modal__cancellation-btn">
                  {pending?.map((message) => {
                    return (
                      <BillingButton
                        key={message.status}
                        text={message.status}
                        loading={loading}
                        color="#8f8fc2"
                        action={cancellationRequest}
                      />
                    );
                  })}
                </div>
              </>
            )}

            {row?.changeSubscription === "contract-cancel" && (
              <>
                <div>
                  <h6>
                    Thank you for being a valued customer. By clicking below you
                    agree to cancel your commitment for this device and are
                    subject to a $75.00 early terminatin fee. This fee is
                    collected immediately upon processing your cancellation.
                  </h6>
                  <p>
                    Once service is cancelled, no further charges will be made
                    to your account for this device unless you reactivate
                    service. Service will automatically expire at the end of the
                    current billing cycle and you will no longer be able to
                    track or access information
                  </p>
                  <p>
                    All account changes are subject to the MasTrack.com terms of
                    service
                  </p>
                </div>
                <div className="modal__cancellation-btn">
                  <BillingButton
                    text="Cancel service on this device"
                    loading={loading}
                    color="red"
                    action={cancellationRequest}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ModalCancellation;
