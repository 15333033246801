import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { equals } from 'ramda';

import { fenceHistory, history } from '../../../stores/store';
import ModalActions from '../../menus/modal/modal-actions';
import C from '../../../shared/constants';
import * as notesdb from '../../../shared/db/notes-db';
import ModalHeader from '../../menus/modal/modal-header';
import { iFullStoreState, UserAuth } from '../../../shared/interfaces';
import { FenceBodyLayout } from '../../fence/tab-bodies/BodyLayout/FenceBodyLayout/FenceBodyLayout';
import DashboardBlock from '../../DashboardBlock';
import ActionRow from '../../fence/tab-bodies/ActionRow/ActionRow';
import { withSentry } from '../../../shared/hoc/withSentry';
import Dialog, {DialogConfigSetter} from "../../Dialog";

type IRouteParams = {
    deviceOrFenceId: string;
    deviceOrFence: 'device' | 'fence';
    action: 'add' | 'edit'
    noteId: string;
};

const AddNote = () => {
    const {deviceOrFenceId, deviceOrFence, noteId, action} = useParams<IRouteParams>();

    const location = useLocation<{mainRouterProps?: { itemType: string; itemId: string; }, ownerTitle?: string}>();
    const ownerTitle = location.state?.ownerTitle;

    const authUser = useSelector<iFullStoreState, UserAuth | undefined>(
        state => state.auth?.user ?? undefined,
        (l,r) => equals(l, r)
    );

    const [note, updateNote] = React.useState('');

    const loadExistingBody = async () => {

        if (action === 'edit') {
            const noteData = await notesdb.getNote(deviceOrFenceId, deviceOrFence, noteId);

            if (noteData?.note) {
                updateNote(noteData.note);
            }
        }
    }
    React.useEffect(() => {
        loadExistingBody();
    }, [])

    const back = isFence => isFence ? fenceHistory.goBack() : history.goBack();

    const update = async () => {
        if (authUser) {
            await notesdb.updateNote(authUser)(deviceOrFenceId, noteId, deviceOrFence, note);
        }
        back(deviceOrFence === 'fence');
    }
    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    const remove = async () => {
        const dialog = dialogRef.current;

        dialog?.().then(confirmed => {
            if (authUser) notesdb.deleteNote(authUser)(deviceOrFenceId, noteId, deviceOrFence);
            back(deviceOrFence === 'fence');
        });
    }

    const title = action === 'add' ? 'Add New Note' : 'Update Note';

    const DynamicLayout: any = deviceOrFence === 'fence' ? FenceBodyLayout : DashboardBlock;
    const DynamicHeader = deviceOrFence === 'fence'
                          ? <div>
                              <ActionRow title={ownerTitle ?? 'no title'} canBack actions={[]} />
                              <h4 style={{marginLeft: 8}}>{title}</h4>
                          </div>
                          : <ModalHeader title={title} />

    return (
        <DynamicLayout mapOnly={!deviceOrFenceId || !deviceOrFence}>
            {DynamicHeader}

            <textarea autoFocus className="mas-input" style={{...C.inputCss, width: '100%', height: 123, marginBottom: 80}} placeholder="Type new note here..." value={note} onChange={({ target }) => updateNote(target.value)} />

            <ModalActions
                isFence={deviceOrFence === 'fence'}
                buttons={ action === 'add' ? [
                    {click: () => back(deviceOrFence === 'fence'), title: 'Cancel'},
                    {click: update, title: 'Create', primary: true},
                ] : [
                    {click: () => back(deviceOrFence === 'fence'), title: 'Cancel'},
                    {click: remove, title: 'Delete', primary: true},
                    {click: update, title: 'Update', primary: true},
                ]} />
            <Dialog setupConfig={setupDialog} title="Confirm Note Delete">
                Are you sure you want to delete? This action cannot be undone.
            </Dialog>
        </DynamicLayout>
    );
}

export default withSentry(AddNote);
