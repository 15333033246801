import React from 'react';
import moment from 'moment';

import './styles.scss';
import YearPicker from "./YearPicker/YearPicker";
import {MonthCustomPickerProps} from "./MonthCustomPicker.interfaces";

const months = [...Array(12).keys()].map((i) => moment().month(i).format('MMMM'));
const startYear = 2015;
const currentYear = +moment().format('Y');

const years = [...Array(currentYear - startYear + 1).keys()].map((i) => startYear + i);

function MonthCustomPicker (props: MonthCustomPickerProps) {

    const startDate = props.monthYearRange ? props.monthYearRange.from : undefined;

    const activeMonthIndex = +(moment(startDate).format('M')) - 1;
    const activeYear = +(moment(startDate).format('Y'));

    React.useEffect(() => {
        props.setActiveTab('Month');
    }, []);

    const changeMonth = (monthIndex: number) => () => {
        const month = moment().month(monthIndex).year(activeYear).startOf('month');
        let endDate = moment(month).endOf('month');

        if (endDate.isAfter(moment())) {
            endDate = moment().endOf('day');
        }

        const from = month.toDate();
        const to = endDate.toDate();

        props.setTabsData({
            from,
            to
        });
    };

    const changeYear = (year: number) => {
        const month = moment().month(activeMonthIndex).year(year).startOf('month');
        const startDate = month;

        if (startDate.isAfter(moment())) {
            if (props.monthYearRange && +(moment(props.monthYearRange.from).format('Y')) < year) {
                const from = moment().startOf('month').toDate();
                const to = moment().endOf('day').toDate();
                return props.setTabsData({
                    from,
                    to
                });
            }
            return;
        }

        let endDate = moment(month).endOf('month');

        if (endDate.isAfter(moment())) {
            endDate = moment().endOf('day');
        }

        const from = month.toDate();
        const to = endDate.toDate();

        props.setTabsData({
            from,
            to
        });
    };

    return <div className="YearMonthPickerWrapper">
        <div className="YearPicker">
            <YearPicker changeYear={changeYear} years={years} year={activeYear} />
        </div>
        {<div className="MonthGrid">
            {months.map((month, monthIndex) => {
                const disable = moment().isBefore(moment().year(activeYear).month(monthIndex));

                return (
                    <div key={month}
                         className={`MonthGrid-Month${monthIndex === activeMonthIndex ? ' active' : ''}${disable ? ' disable' : ''}`}
                         onClick={changeMonth(monthIndex)}
                    >
                        {month}
                    </div>
                );
            })}
        </div>}
    </div>;
}

export default MonthCustomPicker;