export const csvExporter = (filename: string) => (rows: any[][]) => {
    const blob = new Blob([rows.map(processRow).join('\n')], { type: 'text/csv;charset=utf-8;' });

    if(navigator.msSaveBlob)
        navigator.msSaveBlob(
            blob,
            filename
        );
    else {
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

const toStr = (v: any) => {
    if (v === null || v === undefined) {
        return '';
    }

    if (v instanceof Date) {
        return v.toLocaleString();
    }

    return `${v}`;
}

const strToCsvStr = v => {
    let s = v.replace(/"/g, '""');
    return s.search(/("|,|\n)/g) >= 0
        ? `"${s}"`
        : s;
}

const processRow = row => row
    .map(toStr)
    .map(strToCsvStr)
    .join(',')