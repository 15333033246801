import React, {CSSProperties as css, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {addIndex, equals, map, pipe, splitEvery, toPairs} from 'ramda';

import C, {ACL, UserCan} from '../../../shared/constants';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {FormatAddress} from '../devices/device-list';
import * as favoritesdb from '../../../shared/db/favorites-db';
import {iDeviceDetails, iDevicePing, iFullStoreState, iList, iPerson, ItemType} from '../../../shared/interfaces';
import UserImage from '../../../components/general/user-image';
import {FaDevice, FaFav} from '../../elements/fa';
import {extractDevice, isMobile} from '../../../shared/helpers';
import {formatWhenLast} from '../devices/device-page';
import {fitText} from '../../../shared/fit-text';
import {history} from '../../../stores/store';

import styles from './people-page.module.scss';

export default function PeoplePage () {
    const people = useSelector<iFullStoreState, iList<iPerson>>(
        (s) => s.general.people,
        (l, r) => equals(l, r),
    );
    const userCanDo = useSelector<iFullStoreState, Array<string>>(
        (s) => s.auth.user?.acl?.can
    );
    const userId = useSelector<iFullStoreState, string>(
        (s) => s.auth.user.uid
    );

    useEffect(() => {
        if(!ACL.check(UserCan.DO_ANYTHING, userCanDo) || Object.keys(people).length === 1) {
            history.push(`person/${userId}`);
        }
    }, []);

    return (
        <DashboardBlock>
            <ModalHeader title="People" />

            <div className={styles.peopleListWrapper} style={{display: 'flex', flexWrap: 'wrap', alignContent: 'space-between', flexDirection: 'row'}}>
                {pipe(
                    toPairs,
                    map(([id, person]) => <PersonTile key={id} person={person} />),
                    splitEvery(isMobile ? Infinity : 2) as any,
                    addIndex(map)((g, idx) => <div className={styles.peopleListDirection} key={idx} style={{display: 'flex', flex: 'wrap'}}>{g}</div>),
                )(people)}
            </div>

        </DashboardBlock>
    );
}

interface ITileProps {
    person: iPerson;
    style?: React.CSSProperties;
}

const PersonTile = (({person, style={}}: ITileProps) => {
    const isFavorite = useSelector<iFullStoreState, boolean>(
        (state) => !!(state.general.favorites?.[ItemType.person]?.[person.id]),
    );
    const assignedDevice = useSelector<iFullStoreState, iDeviceDetails | undefined>(
        (state) => extractDevice(state.devicesData.devicesDetails, person.hasDevice?.deviceId),
    );
    const assignedDeviceLastPing = useSelector<iFullStoreState, iDevicePing | undefined>(
        (state) => state.devicesData.devicesLastPing.get(person.hasDevice?.deviceId),
    );
    const uid = useSelector<iFullStoreState, string>(
        (state) => state.auth.user?.uid ?? '',
    );
    const userName = React.createRef();

    React.useEffect(() => {
        fitText([
            [userName.current, `person-tile-${uid}`]
        ]);
    }, []);

    const ago = formatWhenLast(assignedDevice ? assignedDeviceLastPing?.time : undefined);

    const toggleFav = (e) => {
        e.preventDefault();
        e.stopPropagation();
        favoritesdb.toggleFavorite(ItemType.person, uid, person.id, !isFavorite);
    };

    return (
        <Link key={person.id} to={`/person/${person.id}`} style={{...tileCss, ...style}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "flex-start", borderBottom: `1px solid ${C.darkGray}`, paddingBottom: 8, marginBottom: 3, width: '100%'}}>
            <FaDevice active={!!person.hasDevice} />
            <UserImage key={person.id} person={person} size={50}/>
            <FaFav isFav={isFavorite} style={{color: C.yellow}} onClick={toggleFav}/>
            </div>

            <div ref={userName as any} style={{width: '100%', textAlign: 'center', marginBottom: 8, overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: 55}}>{person.displayName}</div>

            {/* Last Active */}
            {ago && <span
                style={{fontSize: 12, whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
                title={ago.toString()}
            >{ago}</span>
            }

            {/* Status */}
            <div style={{borderRadius: 3, backgroundColor: C.lightGray, fontSize: 12, fontWeight: 'bold', textAlign: 'center', padding: 3}}>
                Status: {assignedDeviceLastPing?.msg ?? 'N/A'}
            </div>

            {/* Location */}
            {assignedDevice && <>
                <FormatAddress style={{alignSelf: 'stretch', fontSize: 11, paddingTop: 5, textAlign: 'center'}} address={assignedDeviceLastPing?.address} />
                <div style={{fontSize: 11, paddingTop: 10}}>
                    {assignedDevice.name}
                </div>
            </>}
        </Link>
    );
});

const tileCss: css = {
    margin: 5,
    textDecoration: 'none',
    color: C.primaryText,
    border: `1px solid ${C.mediumGray}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    borderRadius: 3,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: 125,
    minHeight: 200,
};
