import {Map, Set} from 'immutable';
import {AnyAction} from 'redux';

import {iList, iTag} from '../../../shared/interfaces';
import {
    DEVICE_SHOW_TOGGLE, deviceShowToggle,
    HIDE_ALL_TAGS,
    HIDE_ALL_DEVICES,
    SHOW_TAG_DEVICES, showTagDevices,
    SHOW_ALL_DEVICES, showAllDevices,
    TOGGLE_SHOW_SELECTED_TAG_DEVICE, toggleShowSelectedTagDevice,
    TAG_SHOW_TOGGLE, tagShowToggle,
} from './AC';
import {ALL_DEVICES_TAG} from '../../../shared/helpers';

export type TagsDevicesMapReducer = {
    //               tagId    devicesIds
    tagsDevices: Map<string, Set<string>>;
    showedTagsIds: Set<string>;
    //                deviceId
    showedDevices: Map<string, { selected: boolean; tagsIds: Set<string> }>;
};

const initialSate: TagsDevicesMapReducer = {
    tagsDevices: Map(),
    showedTagsIds: Set(),
    showedDevices: Map(),
};

export default (state = initialSate, action: AnyAction) => {
    switch (action.type) {
    case 'SET_ALL_TAGS': {
        const tags: iList<iTag> = action.tags;

        const tagsDevices = Object.entries(tags)
            .filter(([_tagId, tag]) => !!tag.instances?.device)
            .reduce((result, [tagId, tag]) => {
                const devicesIds = Object.keys(tag.instances!.device!);

                return result.merge(Map({[tagId]: Set(devicesIds)}));
            }, Map<string, Set<string>>());

        return {
            ...state,
            tagsDevices,
        };
    }

    case SHOW_ALL_DEVICES: {
        const {devicesIds} = (action as ReturnType<typeof showAllDevices>).payload;
        const data = devicesIds.reduce((acc, rec) => {
            return [...acc, [rec, {selected: true, tagsIds: Set([])}]];
        }, []);
        const showedDevices = Map([...data]);

        return {
            ...state,
            showedDevices,
        };
    }

    case HIDE_ALL_DEVICES: {
        const showedDevices = Map([]);

        return {
            ...state,
            showedDevices,
        };
    }

    case TAG_SHOW_TOGGLE: {
        const {tagId} = (action as ReturnType<typeof tagShowToggle>).payload;
        const {devicesIds} = (action as ReturnType<typeof tagShowToggle>).payload;
        const {filter} = (action as ReturnType<typeof tagShowToggle>).payload;
        const tagDevicesIds = state.tagsDevices.get(tagId, Set<string>());

        const data = devicesIds?.reduce((acc, rec) => {
            return [...acc, [rec, {selected: true, tagsIds: Set([ALL_DEVICES_TAG])}]];
        }, []);

        let showedDevices = (tagId === ALL_DEVICES_TAG) ? Map([...data]) : state.showedDevices;

        if (filter) {
            const devices = [];
            for (const tagDeviceId of tagDevicesIds) {
                devices.push(tagDeviceId);
            }

            const getDevices = (tagId === ALL_DEVICES_TAG) ?
                devicesIds :
                devices.filter((item) => devicesIds.indexOf(item) !== -1);

            const collection = getDevices.reduce((acc, rec) => {
                return [...acc, [rec, {selected: true, tagsIds: Set([tagId])}]];
            }, []);

            return {
                ...state,
                showedTagsIds: state.showedTagsIds.add(tagId),
                showedDevices: Map([...collection]),
            };
        }

        if (state.showedTagsIds.has(tagId)) {
            // Removing
            for (const tagDeviceId of tagDevicesIds) {
                showedDevices = showedDevices.update(tagDeviceId, ({selected, tagsIds}) => ({
                    selected,
                    tagsIds: tagsIds.remove(tagId),
                }));
            }

            showedDevices = showedDevices.filter(({tagsIds}) => tagsIds.size > 0);

            return {
                ...state,
                showedTagsIds: state.showedTagsIds.remove(tagId),
                showedDevices,
            };
        }

        for (const tagDeviceId of tagDevicesIds) {
            if (showedDevices.has(tagDeviceId)) {
                showedDevices = showedDevices.update(tagDeviceId, ({selected, tagsIds}) => ({
                    selected,
                    tagsIds: tagsIds.add instanceof Function? tagsIds.add(tagId) : Set(tagId),
                }));
            } else {
                showedDevices = showedDevices.set(tagDeviceId, {selected: true, tagsIds: Set([tagId])});
            }
        }

        return {
            ...state,
            showedTagsIds: state.showedTagsIds.add(tagId),
            showedDevices,
        };
    }
    case HIDE_ALL_TAGS: {
        return {
            ...state,
            showedTagsIds: Set(),
            showedDevices: Map(),
        };
    }
    case TOGGLE_SHOW_SELECTED_TAG_DEVICE: {
        const {tagId, deviceId} = (action as ReturnType<typeof toggleShowSelectedTagDevice>).payload;

        let showedDevices = state.showedDevices;

        if (showedDevices.has(deviceId)) {
            showedDevices = showedDevices.update(deviceId, ({selected, tagsIds}) => ({
                selected: !selected,
                tagsIds,
            }));
        } else {
            showedDevices = showedDevices.set(deviceId, {selected: true, tagsIds: Set([tagId])});
        }

        return {
            ...state,
            showedTagsIds: tagId,
            showedDevices,
        };
    }
    case SHOW_TAG_DEVICES: {
        const {tagId} = (action as ReturnType<typeof showTagDevices>).payload;

        const tagDevicesIds = state.tagsDevices.get(tagId, Set<string>());

        if (!tagDevicesIds.size) return {...state};

        let showedDevices = state.showedDevices;

        tagDevicesIds.map((devicesId) => {
            showedDevices = showedDevices.set(devicesId, {selected: true, tagsIds: Set([tagId])});
        });

        return {
            ...state,
            showedDevices,
            showedTagsIds: state.showedTagsIds.add(tagId),
        };
    }
    case DEVICE_SHOW_TOGGLE: {
        const {deviceId} = (action as ReturnType<typeof deviceShowToggle>).payload;

        return {
            ...state,
            showedDevices: state.showedDevices.update(deviceId, ({selected, tagsIds}) => ({
                selected: !selected,
                tagsIds,
            })),
        };
    }
    default:
        return state;
    }
};
