import {FenceGroup, ItemType, iFenceDetails, iFullStoreState, iList} from '../../../../../../shared/interfaces';
import React, {useCallback, useEffect, useState} from 'react';
import {equals, values} from 'ramda';

import Chevron from '../../../../../SVG-components/chevron_down';
import FenceLine from '../../FenceLine';
import {Link} from 'react-router-dom';
import {keyGen} from '../../../../../../shared/firebase';
import styles from './FenceAccordion.module.scss';
import {useSelector} from 'react-redux';
import {useVisRegions} from '../../../../../../stores/reducers/map-region-reducers';
import {debounce} from 'lodash'

const FenceAccordion = ({tagDetails}) => {
    const [regions, {toggleRegion}] = useVisRegions();

    const tagFences = useSelector<iFullStoreState, iList<iFenceDetails>>(
        (state) => state.general.fences?.[ItemType.tag] ?? {},
        (l, r) => equals(l, r)
    );

    const [filter, setFilter] = useState<string>('');
    const [collapsed, setCollapsed] = useState(false);
    const fences = values(tagFences[tagDetails.id] || {}) as iFenceDetails[];
    const [filteredFences, setFilteredFences] = useState<iFenceDetails[]>(fences);

    const filterDebounce = useCallback(debounce(filter => {
        // sometimes name can be undefined despire the fact that iFenceDetails name property is required
        // issue related to fix MAS-793
        const newFilteredFences = fences.filter(fence => fence.name?.includes(filter));
        setFilteredFences(newFilteredFences);
    }, 300), []);

    useEffect(() => {
        filterDebounce(filter)
    }, [filter]);

    return (

        <div key={tagDetails.id} className={styles.fenceAccordion}>

            <div className={styles.tagDetails}>
                #{tagDetails.name}
                <Chevron onClick={() => setCollapsed(!collapsed)}/>
            </div>

            {!collapsed && <div className={styles.tagsList}>
                {fences.length > 1 && (
                    <input
                        className='SearchInput'
                        value={filter}
                        placeholder="Search fences..."
                        onChange={({target: {value}}) => setFilter(value)}
                    />
                )}
                {filteredFences
                    .map((fence: iFenceDetails) =>
                        <FenceLine
                            key={fence.id}
                            isVis={!!regions[fence.id]}
                            fenceGroup={FenceGroup.FENCE}
                            fence={fence}
                            itemId={tagDetails.id}
                            itemType={ItemType.tag}
                            toggler={() => toggleRegion(fence)}
                        />
                    )}
                <Link
                    title={'Create New Fence'}
                    to={`/${ItemType.tag}/${tagDetails.id}/${FenceGroup.FENCE}/${keyGen()}/edit`}
                    className={styles.tagsListLink}>
                    + Add new
                </Link>
            </div>}

        </div>
    );
};

export default FenceAccordion;