import React from 'react';
import { keys, merge } from 'ramda';

import { useLocalStorage } from '../../../stores/reducers/general-reducers';
import StandardItem from '../../../components/general/standard-item'
import { Row } from '../../elements/flex';

export type iHiddenReportData = {
    [k: string]: boolean
}

export const VisReportData = ({type, showVisibleReportData}: {type: 'static'|'travel', showVisibleReportData: false | true}) => {
    const [hiddenFields, setData] = useLocalStorage<iHiddenReportData>(`hidden-report-data:${type}`, {});

    return <>
        {/* TODO: make these on off buttons so we can flex wrap them */}
        {!showVisibleReportData ? null : <Row style={{border: `1px solid gray`, padding: 8, maxWidth: 400}} justify="flex-start" wrap>
            {keys(typeColumns[type]).map(field => <StandardItem
                key={field}
                displayName={field}
                itemId={field}
                style={{marginBottom: 8, marginRight: 8, cursor: 'pointer'}}
                view={() => setData(merge(hiddenFields, {[field]: !hiddenFields[field]}))}
                isViewed={!hiddenFields[field]}
            />)}
        </Row>}
    </>
}

export const typeColumns = {
    static: {
        fuel: 'Fuel',
        consumption: 'Consumption',
        posted: 'Posted Speed',
        speed: 'Speed',
        event: 'Event',
        location: 'Location',
        label: 'Label',
        person: 'Person',
    },
    travel: {
        duration: 'Duration',
        idle: 'Idle',
        stopped: 'Stopped',
        safety: 'Safety',
        person: 'Person',
        maxSpeed: 'Max Speed',
        avgSpeed: 'Avg. Speed',
        distance: 'Distance',
        consumption: 'Consumption',
        label: 'Label'
    }
}
