import {history} from './stores/store';
import {fbapp} from './shared/firebase';

export const authenticateWithCustomToken = async () => {
    try {
        const search = history.location.search;
        const params = new URLSearchParams(search);
        const authToken = params.get('authToken');

        if (authToken) {
            await fbapp.auth().signInWithCustomToken(authToken);
            history.replace(history.location.pathname);
        } else {
            history.replace(history.location.pathname);
        }
    } catch (e) {
        console.log("Authentication with custom token error", e);
        history.replace(history.location.pathname);
    }
}