import {AnyAction} from 'redux';
import {Map, OrderedMap, OrderedSet, Record} from 'immutable';

import {iDevicePing} from '../../../shared/interfaces';
import {UPDATE_DEVICE_LOCATION, updateDeviceLocation} from '../devicesData/AC';
import {
    TOGGLE_SHOW_REPORT_TRIP, toggleShowReportTrip,
    HIDE_REPORT_TRIPS,
    SET_DEVICE_ROUTE, setDeviceRoute,
    SET_REPORT_TRIP, setReportTrip,
    REMOVE_REPORT_TRIPS,
    HIDE_POINT, hidePoint,
    SHOW_POINT, showPoint,
    CLEAR_DEVICE_ROUTE, clearDeviceRoute
} from './AC';

//                                       pointId
export type IPointsContainer = OrderedMap<string, Readonly<iDevicePing>>;
//                                       tripId
export type ITripsContainer = OrderedMap<string, IPointsContainer>;

const defaultDeviceTrips = {
    //                     tripId
    tripsPoints: OrderedMap<string, IPointsContainer>(),
    liveTripsIds: OrderedSet<string>(),
    actualTrips: Map<string, boolean>(),
    //                         showHide  highlightPointId
    reportTripsIds: Map<string, boolean | string>(),
};
const DeviceTripsRecordFactory = Record<typeof defaultDeviceTrips>(defaultDeviceTrips, 'DeviceTripsRecord');
const DeviceTripsRecord = DeviceTripsRecordFactory();
export type IDeviceTripsRecord = typeof DeviceTripsRecord;

//                      deviceId
const initialState = Map<string, IDeviceTripsRecord>();

//                                          deviceId
export type IReducerDevicesTripsPoints = Map<string, IDeviceTripsRecord>;

export default (state: IReducerDevicesTripsPoints = initialState, action: AnyAction): IReducerDevicesTripsPoints => {
    type TripTuple = [string, IPointsContainer];
    type PointTuple = [string, iDevicePing];

    switch (action.type) {
    case UPDATE_DEVICE_LOCATION: {
        const {deviceId, ping} = (action as ReturnType<typeof updateDeviceLocation>).payload;

        if (!ping?.tripId) return state;

        if (!state.has(deviceId)) {
            const pointTuple: PointTuple = [ping.pointId, ping];
            const tripTuple: TripTuple = [ping?.tripId, OrderedMap<string, Readonly<iDevicePing>>([pointTuple])];

            return state.set(deviceId,
                DeviceTripsRecordFactory({
                    tripsPoints: OrderedMap<string, OrderedMap<string, Readonly<iDevicePing>>>([tripTuple]),
                    liveTripsIds: OrderedSet<string>([ping.tripId]),
                }));
        }

        if (!state.hasIn([deviceId, 'tripsPoints', ping.tripId])) {
            return state.update(deviceId,
                (tripsPointsRecord) =>
                    DeviceTripsRecordFactory({
                        ...tripsPointsRecord,
                        tripsPoints: tripsPointsRecord.tripsPoints.set(
                                ping.tripId!, OrderedMap<string, Readonly<iDevicePing>>([[ping.pointId, ping] as PointTuple]),
                        ),
                        liveTripsIds: tripsPointsRecord.liveTripsIds.add(ping.tripId!),
                    }),
            );
        }

        return state.setIn([deviceId, 'tripsPoints', ping.tripId, ping.pointId], ping);
    }

    case SET_DEVICE_ROUTE: {
        const {deviceId, points} = (action as ReturnType<typeof setDeviceRoute>).payload;

        const checkedPoints = points.map((point) => {
            return point.tripId ? point : {...point, tripId: 'pointsWithoutTripId'}
        })

        const tripsPoints = checkedPoints.reduce((result, point) => {
            if (!result.has(point.tripId)) {
                return result.set(point.tripId, OrderedMap([[point.pointId, point]]));
            }

            return result.setIn([point.tripId, point.pointId], point);
        }, OrderedMap<string, IPointsContainer>());

        return removeNotLiveTripsForDevice(state, deviceId).update(deviceId, DeviceTripsRecordFactory({tripsPoints}),
            (deviceTripRecord) =>
                deviceTripRecord.update('tripsPoints', (existsTripsPoints) => tripsPoints.mergeDeep(existsTripsPoints)),
        );
    }

    case CLEAR_DEVICE_ROUTE: {
        const {deviceId} = (action as ReturnType<typeof clearDeviceRoute>).payload;
        const tripsPoints = OrderedMap<string, IPointsContainer>();

        return removeNotLiveTripsForDevice(state, deviceId).update(deviceId, DeviceTripsRecordFactory({tripsPoints}),
            (deviceTripRecord) =>
                deviceTripRecord.update('tripsPoints', (existsTripsPoints) => tripsPoints.mergeDeep(existsTripsPoints)),
        );
    }

    case SET_REPORT_TRIP: {
        const {deviceId, tripId, points, show} = (action as ReturnType<typeof setReportTrip>).payload;

        // if (state.hasIn([deviceId, 'tripsPoints', tripId])) {
        //     // This condition checks before dispatching
        //     return state;
        // }

        const pointsTuples: Array<PointTuple> = points.reduce((result, point) => [
            ...result,
            [point.pointId, point],
        ], [] as Array<PointTuple>);

        if (!state.has(deviceId)) {
            const tripTuple: TripTuple = [tripId, OrderedMap<string, Readonly<iDevicePing>>(pointsTuples)];

            return state.set(deviceId,
                DeviceTripsRecordFactory({
                    tripsPoints: OrderedMap<string, OrderedMap<string, Readonly<iDevicePing>>>([tripTuple]),
                    reportTripsIds: Map<string, boolean>([[tripId, show]]),
                }),
            );
        }

        return state.setIn([deviceId, 'tripsPoints', tripId], OrderedMap<string, Readonly<iDevicePing>>(pointsTuples))
            .setIn([deviceId, 'reportTripsIds', tripId], show);
    }


    case TOGGLE_SHOW_REPORT_TRIP: {
        const {deviceId, tripId, show = false} = (action as ReturnType<typeof toggleShowReportTrip>).payload;

        if (!show && !state.hasIn([deviceId, 'reportTripsIds', tripId])) {
            return state;
        }

        return state.setIn([deviceId, 'reportTripsIds', tripId], show);
    }

    case HIDE_POINT: {
        const {deviceId, tripId, pointId} = (action as ReturnType<typeof hidePoint>).payload;

        return state.setIn([deviceId, 'tripsPoints', tripId, pointId], {...state.getIn([deviceId, 'tripsPoints', tripId, pointId]), 'hidden': true});
    }

    case SHOW_POINT: {
        const {deviceId, tripId, pointId} = (action as ReturnType<typeof showPoint>).payload;

        return state.setIn([deviceId, 'tripsPoints', tripId, pointId], {...state.getIn([deviceId, 'tripsPoints', tripId, pointId]), 'hidden': false});
    }

    case HIDE_REPORT_TRIPS:
        return state.map((deviceTripsRecord) =>
            deviceTripsRecord.update('reportTripsIds', (reportTripsIds) => reportTripsIds.map(() => false)),
        );

    case REMOVE_REPORT_TRIPS:
        let rState = state;

        let isRemoveSomeTrips = false;

        const devicesIds = state.keySeq();
        // TODO use immutable.Map.map()
        for (const deviceId of devicesIds) {
            const deviceTripsRecord: IDeviceTripsRecord = state.get(deviceId)!;

            const reportTripsIdsForRemove = deviceTripsRecord.reportTripsIds.keySeq().toSet()
                .filter((tripId) => !deviceTripsRecord.liveTripsIds.includes(tripId));

            if (!reportTripsIdsForRemove.size) continue;

            isRemoveSomeTrips = true;

            rState = rState.updateIn([deviceId, 'tripsPoints'],
                (tripsPoints: OrderedMap<string, IPointsContainer>) => tripsPoints.removeAll(reportTripsIdsForRemove),
            )
                .setIn([deviceId, 'reportTripsIds'], Map<string, boolean>());
        }

        return isRemoveSomeTrips ? rState : state;

    case 'TIMELINE_GO_LIVE':
        return removeNotLiveTrips(state);

    default:
        return state;
    }
};
const removeNotLiveTripsForDevice = (state: IReducerDevicesTripsPoints, deviceId: string): IReducerDevicesTripsPoints => {
    const deviceTripsRecord: IDeviceTripsRecord = state.get(deviceId);
    if(!deviceTripsRecord) return state;
    const tripsIdsForRemoving = deviceTripsRecord.tripsPoints.keySeq()
        .filter((tripId) => !deviceTripsRecord.liveTripsIds.includes(tripId));

    return state.setIn([deviceId, 'tripsPoints'],
        deviceTripsRecord.tripsPoints.removeAll(tripsIdsForRemoving),
    );
};

const removeNotLiveTrips = (state: IReducerDevicesTripsPoints): IReducerDevicesTripsPoints => {
    let rState = state;

    const devicesIds = state.keySeq();
    for (const deviceId of devicesIds) {
        rState = removeNotLiveTripsForDevice(rState, deviceId);
    }

    return rState;
};
