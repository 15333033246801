import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DayPicker, {RangeModifier, DateUtils} from 'react-day-picker';
import {DayModifiers} from 'react-day-picker/types/Modifiers';
import moment from 'moment';

import {Actions as TimelineActions} from '../../../../../stores/reducers/timeline-reducers';
import {iFullStoreState, TimelineRangeType} from '../../../../../shared/interfaces';
import {DayRangeCustomPickerProps} from "./DayRangeCustomPicker.interfaces";
import NavBar from '../../DayPickerCustomNavBar';
import Weekday from '../../DayPickerCustomWeekday';
import {isMobile} from '../../../../../shared/helpers';

function DayRangeCustomPicker (props: DayRangeCustomPickerProps ) {
    let {from, to} = props.customDayRange ?? {};

    let modifiers = {
        start: from&&moment(from).clone().toDate(),
        end: to&&moment(to).clone().toDate()
    };

    React.useEffect(() => {
        props.setActiveTab('Custom');
    }, []);

    const handleDayClick = (day, dayModifiers: DayModifiers) => {
        if (dayModifiers.disabled) return;

        // @ts-ignore

        const newRange = DateUtils.addDayToRange(day, {
            from: modifiers.start,
            to: modifiers.end
        });
        
        newRange.from = moment(newRange.from).clone().startOf('day').toDate()
        newRange.to = moment(newRange.to).clone().endOf('day').toDate()
        
        props.setTabsData(newRange);
    };
    const selectedDays = from && to ? [from, {from, to}] : undefined;
    const today = new Date();
    
    return (
        <DayPicker
            disabledDays={{after: today}}
            initialMonth={from ? from : today}
            toMonth={today}
            month={from ? from : today}
            showOutsideDays
            className="Selectable"
            onDayClick={handleDayClick}
            selectedDays={selectedDays}
            modifiers={modifiers}
            navbarElement={NavBar}
            weekdayElement={Weekday}
            numberOfMonths={isMobile ? 1 : 2}
            captionElement={() => null}
        />
    );
}

export default DayRangeCustomPicker;