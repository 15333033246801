import update from 'immutability-helper';
import moment from 'moment';
import { path } from 'ramda';
import { dev, idValArr } from '../../shared/helpers';
import { iPerson, ItemType } from '../../shared/interfaces';
import { iList, iTrip, iDevicePing } from '../../shared/interfaces';
import {compareOperations} from "../../components/general/selections-grid/constants";

class ActionsClass {
    public SET_TYPE = reportType => ({ type: 'REPORT_SET_TYPE', reportType })
    public SET_DEVICE_PERSON = (devicePerson: 'device' | 'person') => ({ type: 'REPORT_SET_DEVICE_PERSON', devicePerson })
    public SET_START_DATE = (date: moment.Moment) => ({ type: 'REPORT_SET_DATE_RANGE', date, slot: 'startDate' });
    public SET_END_DATE = (date: moment.Moment) => ({ type: 'REPORT_SET_DATE_RANGE', date, slot: 'endDate' })
    public SET_FILTER_FILTER = (itemType: ItemType|string, filter) => ({ type: 'REPORT_SET_FILTER_FILTER', itemType, filter });
    public RESET_ALL_FILTERS = () => ({ type: 'REPORT_RESET_ALL_FILTERS' });
    public RESET_ALL_TYPE_FILTERS = (itemType: ItemType|string) => ({type: 'REPORT_RESET_ALL_TYPE_FILTERS', itemType})
    public TOGGLE_ITEM_FILTER = (itemType: ItemType|string, id) => ({ type: 'REPORT_TOGGLE_ITEM_FILTER', itemType, id });
    public ITEM_FILTER_EXPLICIT_VALUE = (itemType: ItemType|string, id, value) => ({ type: 'REPORT_ITEM_FILTER_EXPLICIT_VALUE', itemType, id, value });

    public SET_VALUES_FILTER = (values: {}) => ({ type: 'SET_VALUES_FILTER', values});
    public SET_SELECTIONS_LIST = (selectionsList) => ({type: 'SET_SELECTIONS_LIST', selectionsList})

    public UPSERT_DATE_GROUP_IN_DATES = (group: { startDate: moment.Moment, endDate: moment.Moment }) => ({type: 'REPORT_UPSERT_DATE_IN_DATES', group })
    public REMOVE_DATE_GROUP_IN_DATES = (date: moment.Moment) => ({type: 'REPORT_REMOVE_DATE_IN_DATES', date});
    public RESET_DATES = () => ({type: 'REPORT_RESET_DATES'})

    public SET_LAST_VISIBLE_RECORDS = (firstOrLastVisible) => ({type: 'REPORT_LAST_VISIBLE_RECORD', firstOrLastVisible})
    public SET_DISPLAY_RECORDS = (records: iList<iTrip>|iList<iDevicePing>) => ({ type: 'REPORT_SET_DISPLAY_RECORDS', records })
    public SET_FILTER_DISPLAY_RECORDS = (filter => ({type: 'SET_FILTER_DISPLAY_RECORDS', filter}))
    public UPDATE_DISPLAY_RECORDS_MATCHING = (matching: {[col: string]: any}, updateCol: string, newVal) => ({type: 'REPORT_UPDATE_RECORDS_MATCHING', matching, updateCol, newVal})

    public PICK_NEW_CELLS_VALUE = (deets: iChoosingDetails) => ({ type: 'REPORT_PICK_NEW_CELLS_VALUE', deets });
    public CANCEL_CELL_PICKER = () => ({type: 'REPORT_CANCEL_CELL_PICKER' });

    public SET_COL_SORT_ORDER = (colSort: string[]) => ({ type: 'REPORT_SET_COL_SORT', colSort });

    public TOGGLE_PEOPLE_SEND_TO_REPORT = (person: iPerson) => ({ type: "TOGGLE_PEOPLE_SEND_TO_REPORT", person});
    public ADD_SEND_TO_REPROT_EMAIL= (email: string) => ({ type: "ADD_EMAILS_SEND_TO_REPORT", email});
    public REMOVE_SEND_TO_REPORT_EMAIL = (email: string) => ({ type: "REMOVE_EMAIL_SEND_TO_REPROT", email});
    public RESET_ALL_SEND_INFO = () => ({ type: "REPORT_RESET_ALL_SEND_INFO"});
}

export const Actions = new ActionsClass;

const datesChecker = (state: iState, checkState: iState) => {
    const foundIdx = checkState.details.dates.findIndex(({ startDate, endDate })=> startDate.isAfter(endDate));

    if (foundIdx != -1) return update(state as any, {
        dateError: { $set: 'Start date must be before end date.' }
    })

    if (checkState.details.dates.findIndex(x => !x) != -1) {
        checkState = update(checkState, {
            details: {
                dates: { $set: checkState.details.dates.filter(d => !d) }
            }
        })
    }
    return update(checkState, {
        $unset: ['dateError']
    });
}

export const recordMatches = (matching: {[col: string]: any}) => ({ id, val: row }) =>
    idValArr(matching).every(({id: col, val: matchVal}) => row[col] == matchVal)

const reducersInit = (state: iState = initialState, action) => {

    if (action.type == 'SET_SELECTIONS_LIST') {
        return {...state, selectionsList: action.selectionsList};
    }

    if (action.type == 'REPORT_LAST_VISIBLE_RECORD') {
        return {...state, firstOrLastVisible: action.firstOrLastVisible};
    }

    if (action.type == 'SET_FILTER_DISPLAY_RECORDS'){
        return {...state, filterDisplayRecords: action.filter}
    }

    if (action.type == 'SET_VALUES_FILTER') {
        return update(state as any, { displayRecords: {$set: action.values}});
    }

    if (action.type == 'REPORT_SET_COL_SORT') return update(state as any, {
        colSort: { $set: action.colSort }
    });

    if (action.type == 'REPORT_SET_DISPLAY_RECORDS') {
        return update(state as any, {
            displayRecords: { $set: action.records },
        })
    }

    if (action.type == 'REPORT_CANCEL_CELL_PICKER') return update(state as any, {
        $unset: ['choosing']
    })

    if (action.type == 'REPORT_PICK_NEW_CELLS_VALUE') return update(state as any, {
        choosing: {$set: action.deets }
    })

    if (action.type == 'REPORT_UPDATE_RECORDS_MATCHING') {
        const updates = {};

        idValArr(state.displayRecords as any)
            .filter(recordMatches(action.matching))
            .forEach(({id, val: row}) => {
                updates[id] = { [action.updateCol]: { $set: action.newVal } }
            })

        return update(state as any, { displayRecords: updates });
    }

    if (action.type == 'REPORT_SET_FILTER_FILTER') return update(state as any, {
        filterFilters: {
            [action.itemType]: { $set: action.filter }
        }
    })

    if (action.type == 'REPORT_RESET_DATES') {
        // dates should never be empty so just pick the first one
        const range = state.details.dates[0];

        return update(state as any, {
            details: { dates: {$set: [] } }
        })
    }

    if (action.type == 'REPORT_REMOVE_DATE_IN_DATES') {
        const idx = state.details.dates.findIndex(d => d.startDate.format('YYYYMMDD') == action.date.format('YYYYMMDD'));

        return update(state as any, {
            details: { dates: {$splice: [[idx, 1]]}}
        })
    }

    if (action.type == 'REPORT_UPSERT_DATE_IN_DATES') {
        let incoming = action.group as { startDate: moment.Moment, endDate: moment.Moment }

        let cloned = ({startDate: incoming.startDate.clone(), endDate: incoming.endDate.clone()});

        // keep in mind that start and end date only differ by hours/minutes
        let idx = (state.details.dates).findIndex(date => date.startDate.format('YYYYMMDD') == cloned.startDate.format('YYYYMMDD'));

        if (idx !== -1) {
            return update(state as any, {
                details: {
                    dates: {
                        [idx] : { $set: cloned }
                    }
                }
            })
        }
        else {
            return update(state as any, {
                details: {
                    dates: {
                        $push: [cloned]
                    }
                }
            })
        }
    }

    if (action.type == 'REPORT_RESET_ALL_FILTERS') return update(state as any, {
        details: { filters: { $set: {} } }
    })

    if (action.type == 'REPORT_RESET_ALL_TYPE_FILTERS') return update(state as any, {
        details: { filters: {$unset: [action.itemType ] } }
    })

    if (action.type == 'REPORT_ITEM_FILTER_EXPLICIT_VALUE') return update(state as any, {
        details : { filters: { [action.itemType ]: { [action.id]: { $set: action.value } } } }
    })

    if (action.type == 'REPORT_TOGGLE_ITEM_FILTER') {
        if (path(['details', 'filters', action.itemType, action.id])(state)) {
            // removing
            const {details: { filters: { [action.itemType]: currentFilter, ...otherFilters}}} = state;
            const {[action.id]: currentFilterAction, ...otherFilterActions} = currentFilter;
            const hasOtherActions = !!Object.keys(otherFilterActions).length;
            const newFilters = { ...otherFilters };
            hasOtherActions && (newFilters[action.itemType] = otherFilterActions);
            
            return update(state as any, { details: { filters: {
                $set: newFilters
            }}})
        } else {
            let newState = !state.details.filters[action.itemType]
                ? update(state as any, { details: { filters: { [action.itemType]: { $set: {} } } } } )
                : state;

            return update(newState as any, {
                details: { filters: { [action.itemType] : {[action.id]: { $set: true } } } }
            });
        }
    }

    if (action.type == 'REPORT_SET_TYPE') return update(state as any, {
        details: {
            reportType: { $set: action.reportType },
        },
        $unset: ['displayPoints', 'displayTrips']
    })


    if (action.type == 'REPORT_SET_DEVICE_PERSON') return update(state as any, {
        details: {
            devicePerson: { $set: action.devicePerson }
        }
    })

    if (action.type == 'REPORT_SET_DATE_RANGE') {
        // make sure only the first date of dates are save
        // this means we changed type from specific to range
        let newState = state;

        if (state.details.dates.length > 1) newState = update(newState as any, {
            details: { dates: {
                $splice: state.details.dates.filter((i, idx) => idx > 0).map((i, idx) => [idx, 1])
            }}
        })

        if (!newState.details.dates.length) newState = update(newState as any, {
            details: { dates: {0: {$set: {}}}}
        })

        newState = update(newState as any, {
            details: { dates: {0: {
                [action.slot]: { $set: action.date.clone() }
            }}}
        })

        return datesChecker(state, newState);
    }

    if (action.type === "TOGGLE_PEOPLE_SEND_TO_REPORT") {
        if (state.details.peopleToSendReport[action.person.id]) {
            const emailIndex = state.details.sendToEmails.indexOf(action.person.email)
            return update(state as iState, {
                details: {
                    peopleToSendReport: {
                        $unset: [action.person.id]
                    },
                    sendToEmails: {
                        $splice: [[emailIndex, 1]]
                    }
                }
            })
        } else {
            console.log(state)
            return update(state as iState, {
                details: {
                    peopleToSendReport: {
                        [action.person.id]: { $set: true }
                    },
                    sendToEmails: {
                        $push: [action.person.email]
                    }
                }
            })
        }
    }

    if (action.type === "ADD_EMAILS_SEND_TO_REPORT") {
        return update(state as iState, {
            details: {
                customEmailsToSendReport: { $push: [action.email]},
                sendToEmails: { $push: [action.email]}
            }
        })
    }

    if (action.type === "REMOVE_EMAIL_SEND_TO_REPROT") {
        const index = state.details.customEmailsToSendReport.indexOf(action.email)
        return update(state as iState, {
            details: {
                customEmailsToSendReport: { $splice: [[index, 1]]},
                sendToEmails: { $splice: [[index, 1]]}
            }
        })
    }

    if (action.type === "REPORT_RESET_ALL_SEND_INFO") {
        return update(state as iState, {
            details: {
                customEmailsToSendReport: { $set: []},
                sendToEmails: { $set: []},
                peopleToSendReport: {
                    $set: {}
                }
            }
        })
    }

    return state;
}

const makeKey = (details: iReportDetails) => {
    // return a string representation of the search so we can know if we need to hit db

    // all dates must have pairs
    if (details.dates.findIndex(x => !x || !x.startDate || !x.endDate) != -1) {
        return null;
    }

    if (!details.dates.length) return null;

    // unique string representation of filterskey
    const filtersKey = [ItemType.device, ItemType.person, ItemType.tag, 'alerts', 'labels'].map(type =>
        Object.keys(details.filters[type] || {}).sort()
    ).join('%%');

    const datesKey = details.dates.map(({ startDate, endDate }) => `${startDate.format('YYYY-MM-DD:HH:mm')}_${endDate.format('YYYY-MM-DD:HH:mm')}`).join('||');

    return `${datesKey}-${details.reportType}-${filtersKey}`
}

export const reducers = (state: iState = initialState, action) => {
    const newState = reducersInit(state, action) as iState;

    return update(newState as any, {
        details: { searchKey: { $set: makeKey(newState.details) }}
    })
}
const initialState: iState = {
    colSort: [],
    filterFilters: {},
    tripMeta: {id: false, dom: null},
    details: {
        reportType: undefined,
        dates: [],
        devicePerson: ItemType.device,
        filters: {},
        searchKey: undefined,
        peopleToSendReport: {},
        customEmailsToSendReport: [],
        sendToEmails: [],
    },
    firstOrLastVisible: {
        first: null,
        last: null,
    },
    filterDisplayRecords: [],
    selectionsList: []
}

if (dev) {
    initialState.details.dates.push({
        startDate: moment('2018-05-28 06:00', 'YYYY-MM-DD HH:mm'),
        endDate: moment('2018-05-30 15:00', 'YYYY-MM-DD HH:mm'),
    })

    // initialState.details.dates.push({
    //     startDate: moment('2017-10-19', 'YYYY-MM-DD').startOf('day'),
    //     endDate: moment('2017-10-19', 'YYYY-MM-DD').endOf('day'),
    // })
}

export interface IFilterValue {
    option: string,
    compare: string,
    value: string | number,
}

export interface iState {
    dateError?: string,
    details: iReportDetails,
    tripMeta: {id: string|false, dom: any},
    filterFilters: {
        [type: string]: string
    },
    colSort: string[],
    choosing?: iChoosingDetails;
    displayRecords?: iList<iDevicePing | iTrip>
    firstOrLastVisible?: any
    filterDisplayRecords?: Array<IFilterValue>,
    selectionsList: []
}

export type iReportType = 'static'|'travel'|'geofence'|'summary';
export interface iReportDetails {
    // !!!! any changes here need to be reflected in functions
    id?: string,
    reportType?: iReportType,
    devicePerson: ItemType.device | ItemType.person,
    dates: {
        startDate: moment.Moment,
        endDate: moment.Moment,
    }[]
    searchKey?: string,
    filters: {
        [type: string]: { [id: string]: true }
    },
    peopleToSendReport: iList<boolean>,
    customEmailsToSendReport: string[],
    sendToEmails: string[],
}

export interface iChoosingDetails {
    pickType: 'personId' | 'label',
    updateType: 'trip'|'point',
    id: string,
    oldVal: string,
    affectedRowCriteria: {[col: string]: any},
    updater: (newVal) => any
}
