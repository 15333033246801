import React, {ButtonHTMLAttributes, FC} from "react";
import "./index.scss";

export enum ButtonStyles {
  BLACK_WHITE = "but-black-white",
  ORANGE_WHITE = "but-orange-white",
  RED_WHITE = "but-red-white",
  WHITE_GRAY = "but-white-gray",
  GRAY_INACTIVE = "but-gray-inactive",
  PLAIN_INACTIVE = "but-plain-inactive",
  YELLOW_WHITE= "but-yellow-white",
  NONE = ""
}

export enum ButtonSize {
  XSM = "but-xsm",
  SM = "but-sm",
  MD = "but-md",
  LG = "but-lg",
  NONE = ""
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
  styleType: ButtonStyles,
  size?: ButtonSize
}

export const Button: FC<ButtonProps> = (
  {
    styleType,
    size = ButtonSize.NONE,
    className = "",
    children,
    ...props
  }
) => {
  return (
    <button {...props} className={`but ${className} ${styleType} ${size}`}>{children}</button>
  )
}