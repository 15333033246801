import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ACL, UserCan } from "../shared/constants";
import { iFullStoreState, UserAuth } from "../shared/interfaces";

export const useAuthUserHasPermission = (permission: UserCan) => {
    const user = useSelector<iFullStoreState, UserAuth>(s => s.auth.user);
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
        const hasPermission = ACL.check(permission, user.acl.can);
        setHasPermission(hasPermission);
    }, [user])

    return hasPermission;
}