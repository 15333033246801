import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";

export const ReportSubNavbar: FC = ({children}) => {
    return (
        <div className="report-sub-navbar">
            {children}
        </div>
    )
}

export const ReportSubNav: FC<{
    to: string,
    onClick?: any
}> = ({to, children, onClick, ...props}) => {
    return (
        <NavLink activeClassName="active" className="report-sub-nav" to={to} {...props}>
            <span onClick={onClick}>{children}</span>
        </NavLink>
    )
}