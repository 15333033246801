import {keys, pipe, filter, isNil, unless} from 'ramda';

import {clientDb, clientStorage, globaldb, keyGen } from '../firebase';
import {makeAudit, storePathAwaiterMaker} from '../helpers';
import {iConfig, iDefaultSettings, ItemType, UserAuth} from '../interfaces';
import {localStorage} from '../storage';

export const userTokenRefreshHandler = async (theStore) => {
    const awaiter = storePathAwaiterMaker(theStore);

    const user = await awaiter<UserAuth>(['auth', 'user']);
    const config = await awaiter<iConfig>(['general', 'config']);

    if (!user || !user.beToken) return;

    // refresh when someone changes site config (eg, strict mode)

    console.log('active-client', localStorage.get('active-client'));

    clientDb().child('config').child('version').on('value', (res) => {
        if (res.val() !== config.version) {
            // clear out old cached config
            localStorage.forget('db-config');

            window.location.reload();
        }
    });

    clientDb().child('people').child('people-details').child(user.uid).child('force-refresh').on('value', async (res) => {
        if (res.val()) {
            // set it to false before we reload else we end up in loop
            await clientDb().child('people').child('people-details').child(user.uid).child('force-refresh').remove();

            user.beToken.role !== 'superAdmin' && window.location.reload();
        }
    });
};

export const getDefaultSettings = async () => (await clientDb().child('default-settings').once('value')).val() || {} as iDefaultSettings

export const getDbConfig = async () => (await clientDb().child('config').once('value')).val() || {} as iConfig;

export const watchLabels = (callback) => clientDb().child('labels').on('value', (res) => {
    callback(res.val());
}, (err) => console.log(err));

export const addTripLabel = (user: UserAuth) => async (newLabel) => clientDb().update(makeAudit(user, {
    [`labels/trip-labels/${newLabel}`]: newLabel
}));

export const addScheduleLabel = (user: UserAuth) => async (newLabel) => clientDb().update(makeAudit(user, {
    [`labels/schedule-labels/${newLabel}`]: newLabel
}));

export const incidentIcons = async () => (await globaldb.child('incident-icons').once('value')).val();

export const saveIcon = async (file: File) => {
    const key = keyGen();

    await clientStorage().child(`icons/${key}/file`).put(file);

    return await clientStorage().child(`icons/${key}/file`).getDownloadURL();
};

export const getPersonAllowedSee = async (personId: string) => {
    return (await clientDb().child('acl').child('items-allowed').child(personId).once('value')).val() || {};
};

export const watchPersonAllowedSee = (personId: string, callback) => {
    const wrapper = (x) => callback(x.val() || {});

    return clientDb().child('acl').child('items-allowed').child(personId).on('value', unless(isNil, wrapper), (err) => console.log(err));
};

export const getDeviceAllowedSee = async (deviceId: string, deviceTags: string[]) => {
    const items = (await clientDb().child('acl').child('items-allowed').once('value')).val() || {};

    return pipe(
        filter(({device = {}, tags = {}}) => device[deviceId] || deviceTags.some((t) => tags[t])),
        keys,
    )(items);
};

export const getEventTypes = async () => (await globaldb.child('events').once('value')).val();

export const setAllowedSee = (user: UserAuth) => async (personId: string, type: ItemType, itemId: string) => {

    if (type === ItemType.device) {
        return clientDb().update(makeAudit(user, {
            [`/acl/items-allowed/${personId}/device/${itemId}/${itemId}`]:'device',
            [`/acl/can/see-fences/${personId}`]: true
        }));
    }

    const taggedDevices = (await clientDb().child('tags').child(itemId).child('instances').child('device').once('value')).val() || {};

    const update = {};

    update[`/tags/${itemId}/instances/allowed-see/person/${personId}`] = true;
    update[`/acl/can/see-fences/${personId}`] = true;
    console.log('taggedDevices', taggedDevices);
    Object.keys(taggedDevices).forEach((deviceId) =>
        update[`/acl/items-allowed/${personId}/device/${deviceId}/${itemId}`] = 'tag'
    );

    await clientDb().update(makeAudit(user, update));

};
export const readOnlyAccessToggle= (user: UserAuth) => async (personId: string,  itemId: string, value: boolean ) => {
    await clientDb().update(makeAudit(user, {
        [`/acl/items-allowed/${personId}/device/${itemId}/readOnly`]: value
    }));

    const isReadOnly = (await (clientDb().child(`/acl/items-allowed/${personId}/device/${itemId}/readOnly`).once('value'))).val();

    if (isReadOnly) {
        await clientDb().update(makeAudit(user, {
            [`/acl/can/see-fences/${personId}`]: false
        }));

        await clientDb().update(makeAudit(user, {
            [`/acl/can/edit-fences/${personId}`]: false
        }));
    }
    else {
        await clientDb().update(makeAudit(user, {
            [`/acl/can/see-fences/${personId}`]: true
        }));
        await clientDb().update(makeAudit(user, {
            [`/acl/can/edit-fences/${personId}`]: true
        }));
    }
};
export const tagReadOnlyToggle= (user: UserAuth) => async (tagId: string,  personId: string, value: boolean ) => {
    console.log("p id", personId, "tag id", tagId);
    await clientDb().update(makeAudit(user, {
        [`/tags/${tagId}/instances/allowed-see/readOnly/${personId}`]: value
    }));
};

export const getReadOnlyStatus = async (personId: string, itemId: string) => {
    const responce = (await (clientDb().child(`/acl/items-allowed/${personId}/device/${itemId}/readOnly`).once('value'))).val()
    return !!responce;
};

// returnі true if status is read only
export const getTagReadOnlyStatus = async (personId: string, tagId: string) => {
    return (await (clientDb().child(`/tags/${tagId}/instances/allowed-see/readOnly/${personId}`).once('value'))).val() || false;
};

export const removeAllowedSee = (user: UserAuth) => async (personId: string, type: ItemType, itemId: string) => {
    if (type == ItemType.device) return clientDb().update(makeAudit(user, {
        [`acl/items-allowed/${personId}/device/${itemId}/${itemId}`]: null,
        [`/acl/can/see-fences/${personId}`]: false
    }));

    const taggedDevices = (await clientDb().child('tags').child(itemId).child('instances').child('device').once('value')).val() || {};

    const update = {};

    update[`/tags/${itemId}/instances/allowed-see/person/${personId}`] = null;
    update[`/tags/${itemId}/instances/allowed-see/readOnly/${personId}`] = null;
    update[`/acl/can/see-fences/${personId}`] = false;

    Object.keys(taggedDevices).forEach((deviceId) =>
        update[`/acl/items-allowed/${personId}/device/${deviceId}/${itemId}`] = null
    );

    await clientDb().update(makeAudit(user, update))
}

export const watchPersonCan = (uid, callback) => {
    const userCanRef = clientDb().child('acl').child('can');
    userCanRef.on("value", (res) => {
        const val = Object.entries(res.val()).filter(([permission, x]) => x[uid]).map(([permission]) => permission);
        callback(val);
    }, err => console.log(err))
    return () => userCanRef.off("value");
}
