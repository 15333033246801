import moment from 'moment';
import {map, evolve, forEachObjIndexed, pathOr} from 'ramda';

import {clientDb, clientStore, keyGen} from '../firebase';
import {iDeviceDetails, iDeviceEngineCode, iList, UserAuth} from '../interfaces';
import {dev, makeAudit} from '../helpers';
import {useFirebase} from '../../states/firebase-state';

const deviceDb = () => clientDb().child('devices');

const fixTimezone = (timezone?: string): string => {
    if (!timezone) {
        return 'EST';
    }

    if (timezone === 'PST') {
        return 'US/Alaska';
    }

    if (timezone === 'CST') {
        return 'US/Mountain';
    }

    return timezone;
};

export const getDevices = async () => {
    const devices = (await (deviceDb().child('device-details').once('value'))).val();

    return  Object.keys(devices);
};

export const cleanDevice = (dbVal: iDeviceDetails, id: string): iDeviceDetails => {
    const ret = dbVal;

    ret.id = id;

    // make the assigned time a moment
    if (dbVal.assignedTo) ret.assignedTo.time = moment(dbVal.assignedTo.time);

    dbVal.timezone = fixTimezone(dbVal.timezone);

    // ensure alerts are array
    ret.possibleAlerts = ret.possibleAlerts || [];

    if (dbVal.earliestTime) dbVal.earliestTime = moment.utc(dbVal.earliestTime as any);

    if (dbVal.premiumUntil) {
        dbVal.premiumUntil = moment.utc(dbVal.premiumUntil as any);
    }

    // need the device to have an icon
    if (!ret['icon']) {
        ret.icon = {fa: 'truck'} as any;
    }

    // missing name?
    ret['name'] = ret['name'] || 'N/A';

    ret['deviceEvents'] = ret['deviceEvents'] ? ret['deviceEvents'] : [];

    return ret;
};

export const populateDeviceEarliestPing = (user: UserAuth) => async (deviceId: string) => {
    const earliest = (await clientStore().collection('points').where('device', '==', deviceId).orderBy('time', 'asc').limit(1).get()).docs;

    if (!earliest.length) return;

    const time = earliest[0].data().time;

    updateDeviceDetails(user)(deviceId, {earliestTime: time});
};

export const unAssignDevice = (user: UserAuth) => async (deviceId, personId) => {
    const tapHistoryKey = keyGen();

    const tap = {
        personId,
        deviceId,
        time: moment().date(),
        event:'OUT',
        audit: {
            uid: user.uid,
            realId: pathOr(false, ['beToken', 'realId'], user)
        }
    };

    const updates = {
        [`devices/device-details/${deviceId}/assignedTo`]: null,    // remove the assignment from device
        [`people/people-details/${personId}/hasDevice`]: null,     // remove the device from the user

        // keep a running list of taps
        [`devices/tap-history/${tapHistoryKey}`]: tap,
    };

    await clientDb().update(updates);
};
export const dismissBatteryWarning = async (deviceId) => {
    await deviceDb().child('device-details').child(deviceId).child('batteryWarning').set(false);
};

export const dismissCheckEngine = async (deviceId) => {
    await deviceDb().child('device-details').child(deviceId).child('hasCheckEngine').set(false);
};

export const fetchCodes = async (deviceId): Promise<iList<iDeviceEngineCode>> => {
    const res = (await deviceDb().child('engine-codes').child(deviceId).once('value')).val() || {};

    return map(evolve({date: moment}))(res) as any;
};

export const assignDevice = (user: UserAuth) => async (deviceId, personId) => {
    const tapHistoryKey = keyGen();

    const tap = {
        personId,
        deviceId,
        time: new Date(),
        event:'IN',
        audit: {
            uid: user.uid,
            realId: pathOr(false, ['user', 'beToken', 'realId'], user)
        }
    };

    const updates = {
        [`devices/device-details/${deviceId}/assignedTo`]: tap,         // store the tap on the device
        [`people/people-details/${personId}/hasDevice`]: tap,          // store the tap on person

        [`devices/tap-history/${tapHistoryKey}`]: tap,
    };

    await clientDb().update(updates);
};

export const updateDeviceDetails = (user: UserAuth) => async (deviceId: string, updates: {[field: string]: any}) => {
    const update = {};

    forEachObjIndexed((val, field) => {
        update[`devices/device-details/${deviceId}/${field}`] = val;
    })(updates);

    await clientDb().update(makeAudit(user, update));
};

export const presetDefaultDeviceSettings = async () => {
    const updates = {};

    const deviceIds = (await deviceDb().child('device-details').once('value')).val() || {};

    const defaultSettings = (await clientDb().child('default-settings').once('value')).val() || {};

    Object.entries(deviceIds).forEach(([deviceId, deviceValue]) => {
        Object.entries(defaultSettings).forEach(([settingKey, settingValue]) => {
            if (!(deviceValue.hasOwnProperty(settingKey))) {
                updates[`devices/device-details/${deviceId}/${settingKey}`] = settingValue;
            }
        })
    })

    await clientDb().update(updates);
}

interface iCountObj {
    max?: number;
    remaining?: number;
    lastUpdated?: string;
}
export const useMonthlyCounter = (type: 'location'|'premium', deviceId: string) => {
    const path = `/devices/${type}-count/${deviceId}`;

    const obj = <iCountObj> Object.values(useFirebase(path, (x) => x || {} )[0])[0]||{};

    const lastUpdated: moment.Moment = obj.lastUpdated ?
        moment(obj.lastUpdated) :
        moment();

    const startOfMonth = moment().startOf('month');

    const remaining = lastUpdated.isBefore(startOfMonth) ?
        Number(obj.max) :
        'remaining' in obj ?
            Number(obj.remaining) :
            Number(obj.max);

    return [remaining, () => {}];
};

export const createNewDeviceFromFileConfig = (device) => {
    return deviceDb().child('device-details').push(device);
}