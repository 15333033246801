import React from "react";
import {overlayContainerCss} from "../FenceBodyLayout.helpers";

const DesktopFenceLayout = ({overlay, children, header, modalActions}): JSX.Element => {
    return (
        <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            {!overlay ? null : <div style={overlayContainerCss}>{overlay}</div>}
            {!header ? null : <div style={{flexShrink: 0, backgroundColor: 'white', position: "sticky", top: 0, zIndex: 10}}>{header}</div>}
            <div className="fence-container-elements" style={{flexShrink: 1}}>
                {children}
            </div>
            {!modalActions ? null : <div className="modal-actions-container">{modalActions}</div>}
        </div>
    );
};

export default DesktopFenceLayout;