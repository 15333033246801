import React, { useEffect, useState } from 'react';
import { iDefaultSettings } from '../../../shared/interfaces';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import { MinimalTile } from '../../general';
import { Col } from '../../elements/flex';
import { useFirebase } from '../../../states/firebase-state';
import { SettingRow } from '../../DefaultSetingsModal/DefaultSettingsModal';
import {presetDefaultDeviceSettings} from "../../../shared/db/devices-db";

const DefaultSettings = () => {

    const[initialSettings, setInitialSettings] = useFirebase<iDefaultSettings>(`default-settings`)
    const [defaultSettings, setDefaultSettings] = useState(initialSettings)
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        if(initialSettings){
            setDefaultSettings(initialSettings)
        }
    }, [initialSettings])

    const onFieldChange = (name) => (field) =>{

        setIsChanged(false)

        if(initialSettings[field.name] !== field.value){
            setIsChanged(true)
        }

        setDefaultSettings({
            ...defaultSettings, 
            [`${name}`] : field.value
        })
    }

    const saveSettings = async () =>{
        setInitialSettings(defaultSettings)
        await presetDefaultDeviceSettings();
        setIsChanged(false)
    }

    return(
        <DashboardBlock>
        <ModalHeader title="Default Settings" />
        <MinimalTile>
            <Col>
            {Object.entries(defaultSettings || {}).map(
                (setting) => 
                    <SettingRow 
                        key={setting[0]} 
                        name={setting[0]} 
                        value={setting[1].toString()}
                        onChange={onFieldChange}
                    />
                )
            }
            </Col>
            <button 
                className="btn btn-primary btn-xs"
                disabled={!isChanged}
                onClick={saveSettings}
            > Save </button>
        </MinimalTile>
   </DashboardBlock>
    ) 
}

export default DefaultSettings