import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { withSentry } from "../../../shared/hoc/withSentry";
import BillingNavBar from "./Other/BillingNavBar";
import BillingUnits from "./BillingUnits";
import BillingInvoices from "./BillingInvoices";
import BillingOrders from "./BillingOrders";
import DashboardBlock from "../../DashboardBlock";
import "./table.scss";

const Billing = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (pathname === "/billing") {
      history.replace("/billing/units");
    }
  }, [pathname]);

  return (
    <DashboardBlock>
      <BillingNavBar />
      <PerfectScrollbar>
        <Switch>
          <Route exact path="/billing/units" component={BillingUnits} />
          <Route exact path="/billing/invoices" component={BillingInvoices} />
          <Route exact path="/billing/orders" component={BillingOrders} />
        </Switch>
      </PerfectScrollbar>
    </DashboardBlock>
  );
};

export default withSentry(Billing);
