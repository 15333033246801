import React, { CSSProperties as css } from 'react';
import { faUser, IconDefinition } from '@fortawesome/fontawesome-free-regular';


import { iPerson } from '../../shared/interfaces';
import C from '../../shared/constants';
import { Fa } from '../elements/fa';
import { fitText } from '../../shared/fit-text';

interface iProps2 {
    personId?: string,
    person: iPerson,
    active: boolean,
    compressed?: boolean,
    tiny?: boolean,
    style?: React.CSSProperties
}
export const PersonBox = ({tiny, person, active, compressed, style={}}: iProps2) => {
    const subtractor = tiny ? 12 : 0;
    const size = (compressed ? 35 : 42) - subtractor;

    let displayNameEl = React.createRef();

    React.useEffect(() => {
        fitText([
            [displayNameEl.current,`person-box-${person.id}`]
        ]);
    }, [compressed]);

    return (
        <div ref={displayNameEl as any} style={{display: 'flex', maxWidth: '100%', lineHeight: '15px', flexDirection: compressed? 'row' : 'column', alignItems:'center', textAlign: 'center', justifyContent: compressed ? 'center' : 'space-between', margin: 5, ...style}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <span
                    style={{fontSize: 16, width: size, height: size, borderRadius: size, backgroundImage: `url(${person.photoURL})`, backgroundSize: 'cover', border: `2px solid ${C.mediumGray}`, position: 'relative', borderColor: active ? C.primaryColor : C.lightGray}}
                >{person.photoURL ? null : <Fa icon={faUser} />}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', color: active ? C.primaryColor : C.darkGray, marginLeft: compressed ? 10 : 0, textOverflow: 'ellipsis' }}>
                <span>{person.displayName}</span>
            </div>
        </div>
    )
}

interface iProps {
    title: string,
    itemId: string,
    sm?: boolean,
    active: boolean,
    fa?: IconDefinition,
    url?: string,
}
export const IconBox = ({itemId, url, fa, title, active, sm=false}: iProps) => {

    const size = sm ? 20 : 35;
    const imgSize = sm ? 25 : 42;

    return (
        <div key={itemId} title={title} style={{display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'space-between', margin: `${sm ? 0 : 5}px 5px`, cursor: sm ? 'default' : 'pointer'}}>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                {fa
                    ? <Fa className={`font-size-${size}`} icon={fa} style={{width: size, height: size, fontSize: size, marginLeft: 'auto', marginRight: 'auto', color: active ? C.primaryColor : C.primaryText }} />
                    : <div style={{width: imgSize, height: imgSize, borderRadius: imgSize, backgroundImage: `url(${url})`, backgroundSize: 'cover', border: `2px solid ${C.mediumGray}`, position: 'relative', borderColor: active ? C.primaryColor : C.lightGray}} /> }
            </div>
            <div style={{display: 'flex', justifyContent: 'center', color: active ? C.primaryColor : C.mediumGray, fontSize: 10 }}>
                <span style={sm ? smTitleCss : {}}>{title}</span>
            </div>
        </div>
    )
}

const smTitleCss: css = {
    display: 'inline-block',
    width: 43,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#000',
}