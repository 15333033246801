import React, {useEffect, useRef} from "react";
import { useDispatch } from "react-redux";
import classNames from 'classnames';
import { isEmpty } from 'ramda'
import Player from "./Player";
import { closeHistorySession } from "../../../../../api/openApi";
import { openModal } from "../../../../../stores/reducers/videoCameras";
import styles from "../deviceStatuses.module.scss";

const RenderPlayers = (
  {
    playedSecondsRef,
    channels,
    reminderIntervalTime,
    isPlaying,
    setIsPlaying,
    setCurrentTime,
    updateTime,
    isControls,
    isLiveView,
    resetPlayer,
    setAvailableTime,
    intervalContinueStreaming,
    historyVideo,
    setSelectedHistoryChannel,
    totalSeconds = 0,
    isLoadedVideo = false,
    setIsLoaded = {}
  }
): JSX.Element => {

  const dispatch = useDispatch();

  const showContinueStreamingModal = async (): Promise<void> => {
    clearInterval(intervalContinueStreaming.current);
    setIsPlaying(false);
    await updateTime().then((time)=>{
      setAvailableTime(time);
      dispatch(openModal({key: "openContinueLiveStreamingModal", value: true}))
      resetPlayer()
    })
  }

  useEffect(() => {
    const int = setInterval(showContinueStreamingModal, reminderIntervalTime);
    intervalContinueStreaming.current = int;
    return () => {
      clearInterval(int);
      if (!isEmpty(historyVideo)) closeHistorySession({ session: historyVideo[0]?.session })
    }

    return () => {
      updateTime();
    }
  }, [reminderIntervalTime, historyVideo]);

  const historyOrLiveView = !isEmpty(channels) ? channels : historyVideo;
  return historyOrLiveView.map(c => {
    return (
      <div key={+c?.channel}>
        <h3>{c?.name}</h3>
        <div className={classNames(styles.player, {
          [styles.player__isLiveView]: isLiveView ,
          [styles.player__isHistoryView]: !isLiveView 
        })}>
          <Player
            url={c?.url}
            isPlaying={isPlaying}
            setCurrentTime={setCurrentTime}
            setPlayedSeconds={(seconds) => {playedSecondsRef.current = seconds}}
            updateTime={updateTime}
            isControls={isControls}
          />
        </div>
      </div>
    )
  })
};

export default RenderPlayers;
