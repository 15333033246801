import React, { useMemo, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { useTable, useSortBy, usePagination } from "react-table";

import Loader from "../Other/Loader";
import ModalInvoice from "./ModaInvoice";
import { COLUMNS } from "./invoivesData";
import BillingPagination from "../Other/BillingPagination";
import { TableApi } from "../interfaces";
import { ReactComponent as Up } from "../../../../assets/svg/sort_up.svg";
import { ReactComponent as Down } from "../../../../assets/svg/sort_down.svg";
import { getInvoicePdf } from "../BillingApi/billingApiRequests";

const InvoicesTable = ({ data, pageCount, fetchData, loading }: TableApi) => {
  const [rowValue, setRowValue] = useState(null);
  const [blob, setBlob] = useState(null);
  const [active, setActive] = useState(false);

  const columns = COLUMNS;
  const invoices = data;

  const takeValue = (value) => {
    getInvoicePdf({ id: value.id, setBlob })
    setRowValue(value);
    setActive(true);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state: tableDetails,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns,
      data: invoices,
      manualPagination: true,
      autoResetPage: false,
      pageCount,
      initialState: {
        pageIndex: 0,
      },
    },
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = tableDetails;

  React.useEffect(() => {
    fetchData &&
      fetchData({
        pageIndex,
        pageSize,
        url: "/api/invoice/get-invoices",
      });
  }, [fetchData, pageIndex, pageSize]);

  return (
    <>
      {active && (
        <ModalInvoice pdf={blob} rowValue={rowValue} setActive={setActive} />
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          <table
            style={{ minWidth: "250px" }}
            className="billing-tables"
            {...getTableProps()}
          >
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers?.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="sort-icon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Down />
                          ) : (
                            <Up />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page?.map((row) => {
                prepareRow(row);
                return (
                  <Tooltip key={row.id} title="Click to Open" placement="top">
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => takeValue(row.original)}
                      {...row.getRowProps()}
                    >
                      {row.cells?.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Tooltip>
                );
              })}
            </tbody>
          </table>
          <BillingPagination
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
          />
        </>
      )}
    </>
  );
};

export default InvoicesTable;
