import { CSVArray, CSVObject } from "../interfaces";

export function convertCSVToJSON(csvArray: CSVArray): CSVObject {
  // considers that first row contains titles and reste contains data
  const titles = csvArray[0];
  const fields = csvArray.filter((row, i) => i !== 0 );
  const json = [];

  for(let i = 0; i < fields.length; i++) {
    const obj = {};
    const row = fields[i];
    for(let j = 0; j < titles.length; ++j) {
      const key = titles[j][0];
      const value = row[j];
      obj[key] = value;
    }
    json.push(obj);
  }
  return json;
}