import React from 'react';
import { mergeRight } from 'ramda';

interface iProps { children: any, style?: React.CSSProperties }

export const MapModal = ({ children, style = {} }: iProps) => (
    <div className="map-modal" style={mergeRight(mapModalCss, style)}>
        {children}
    </div>
)

const mapModalCss: React.CSSProperties = {
    border: '1px solid rgba(0,0,0,.2)',
    borderRadius: '.3rem',
    background: 'white',
    height: 250,
    padding: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    marginTop: 135,
}
