import React, { FC } from 'react';
import style from './MouseTooltip.module.scss';
import classNames from 'classnames';
import MouseStickyTolltip from 'react-sticky-mouse-tooltip';
import {IProps} from './MouseTooltip.interfaces';

export const MouseTooltip: FC<IProps> = ({
    title, 
    isVisible, 
    offsetX = 15, 
    offsetY = 10
}) => {

    return (
        <MouseStickyTolltip
            visible={isVisible}
            offsetX={offsetX}
            offsetY={offsetY}
        >
            <span
                className={classNames(style['mouse-tooltip'])}
            >
                {title}
            </span>
        </MouseStickyTolltip>
    )
}
