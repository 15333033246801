import { FenceGroup, ItemType, iFenceDetails, iFullStoreState, iList, iTag } from '../../../../../shared/interfaces';
import React, { useEffect, useState } from 'react';
import { Set, is } from 'immutable';
import { equals, values } from 'ramda';
import { getAllDevicesTag, isMobile } from '../../../../../shared/helpers';

import FenceAccordion from './FenceAccordion';
import { IButtonsProps } from './ShowVisTagsItems.interfaces';
import { Link } from 'react-router-dom';
import {history} from '../../../../../stores/store';
import { keyGen } from '../../../../../shared/firebase';
import { selectDevices } from '../../../../../stores/reducers/devicesData/selector';
import styles from './ShowVisTagsItems.module.scss';
import { useSelector } from 'react-redux';
import { useVisRegions } from '../../../../../stores/reducers/map-region-reducers';

const ShowVisTagsItems = (props: IButtonsProps): JSX.Element => {
    const { itemId } = props;
    const [regions, { toggleRegion, add, remove }] = useVisRegions();
    const devices = useSelector(selectDevices);

    enum ActionSelectAllType {
        _,
        SELECT,
        UNSELECT,
    }
    const [selectAllButtonType, setSelectAllButtonType] = useState<ActionSelectAllType>(ActionSelectAllType.SELECT);

    const tags = useSelector<iFullStoreState, iList<iTag>>((s) => s.general.tags, (l, r) => equals(l, r));

    const newTags = { ...tags, ...getAllDevicesTag(devices) };

    const showedTagsIds = useSelector<iFullStoreState, Set<string>>(
        (state) => state.tagsDevicesMap.showedTagsIds,
        (left, right) => is(left, right),
    );

    const tagFences = useSelector<iFullStoreState, iList<iFenceDetails>>(
        (state) => state.general.fences?.[ItemType.tag] ?? {},
        (l, r) => equals(l, r)
    );

    const showedTagsDetails = showedTagsIds.map((tagId) => newTags[tagId].details);

    const allToggle = () => {
        /*const showedTags = [];

        for(const item of showedTagsIds){
            showedTags.push(item);
        }

        showedTags.forEach((it) => {
            values(tagFences[it] || {}).forEach( (item) => toggleRegion(item));
        });*/

        const select = selectAllButtonType === ActionSelectAllType.SELECT;
        [...showedTagsIds].forEach((it) => {
            values(tagFences[it] || {}).forEach((item) => {
                select
                    ? add(item)
                    : remove(item.id);
            });
        });

        // useless code, while changeSelectAllButtonType effect is active
        /*const newValue = select ? ActionSelectAllType.UNSELECT : ActionSelectAllType.SELECT;
        setSelectAllButtonType(newValue);*/
    };

    const changeSelectAllButtonType = () => {
        let allFencesItemsCount = 0;
        [...showedTagsIds].forEach((id) => allFencesItemsCount += values(tagFences[id] || {}).length);

        const isAllSelected = (Object.keys(regions).length === allFencesItemsCount) && (allFencesItemsCount > 0);
        const newValue = isAllSelected ? ActionSelectAllType.UNSELECT : ActionSelectAllType.SELECT;
        setSelectAllButtonType(newValue);
    };

    // showedTagsDetails changes on each showedTagsIds values (link not change) change
    useEffect(changeSelectAllButtonType, [showedTagsDetails]);

    // dont show "Add fence" button on tags page becounse it is not clear for which tag it should be assigned
    const showAddFenceBtn = !history.location.pathname.includes('/tags');

    return <div className={`TaggedItems ${isMobile ? styles.taggedItemsMobile : ''}`}>
        {showedTagsDetails.map((tagDetails) =>
            <FenceAccordion key={tagDetails.id} tagDetails={tagDetails} />
        )}
        <div className={styles.buttonsBlock}>
            <button
                onClick={() => allToggle()}
                className={styles.buttonsBlockWhiteButton}>
                {selectAllButtonType === ActionSelectAllType.SELECT ? "Select All" : "Clear All"}
            </button>
            {
                showAddFenceBtn && (
                    <button className={styles.buttonsBlockBlackButton}>
                        <Link
                            title={'Create New Fence'}
                            to={`/${ItemType.tag}/${itemId}/${FenceGroup.FENCE}/${keyGen()}/edit`}>
                            Add Fence
                        </Link>
                    </button>
                )
            }
        </div>

    </div>;
};

export default ShowVisTagsItems;