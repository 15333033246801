import React from 'react';
import PathPolygon from './gmap/path';
import {keys, pickBy, propEq} from 'ramda';
import {connect, DispatchProp} from 'react-redux';
import {OvalPolygon} from './gmap/oval-polygon';
import Rectangle from './gmap/rectangle-polygon';
import {useRedux} from '../states/redux-state';
import InfoMarkers from './GoogleMap/InfoMarkers';
import DevicesRoutes from './GoogleMap/DevicesRoutes';
import SearchMarkers from './GoogleMap/SearchMarkers';
import {localStorage} from '../shared/storage';
import StreetView from './GoogleMap/StreetView';
import {FenceType, iFullStoreState, iLocation} from '../shared/interfaces';
import withGoogleMap, {WithGoogleMapProps} from 'react-google-maps/lib/withGoogleMap';
import {BaseComponent} from '../shared/BaseComponent';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import {Actions as MapActions} from '../stores/reducers/gmap-reducers';
import {getPathIdsSelector} from '../stores/reducers/gmap-selectors';
import {throttle} from 'throttle-debounce';

import './gmap-styles.scss';
import {isMobile} from '../shared/helpers';

type IPropsFromStore = {
    mapType: google.maps.MapTypeId;
    streetViewLocation: boolean | iLocation;
    defaultCenter: iLocation;
    defaultZoom: number;
}
type IFullProps = DispatchProp & IPropsFromStore;

const mapGmapStateToProps = (state: iFullStoreState): IPropsFromStore => ({
    mapType: state.gmap.mapType,
    streetViewLocation: state.gmap.streetViewLocation,
    defaultCenter: state.gmap.defaultCenter,
    defaultZoom: state.gmap.defaultZoom,
});

const GettingStartedGoogleMap1 = connect(mapGmapStateToProps)
(class extends BaseComponent<IFullProps & WithGoogleMapProps> {
    private mapRef = React.createRef<GoogleMap>();

    getMapCenter = (): iLocation => {
        const center = this.mapRef && this.mapRef.current && this.mapRef.current.getCenter();

        return center ?
            {lat: center.lat(), lng: center.lng()} :
            this.props.defaultCenter;
    };

    getMapZoom = (): number => {
        const zoom = this.mapRef && this.mapRef.current && this.mapRef.current.getZoom();

        return zoom || this.props.defaultZoom;
    };

     getMyBounds = () => {
         const latNorth = this.mapRef?.current?.getBounds()?.getNorthEast().lat();
         const lngEast = this.mapRef?.current?.getBounds()?.getNorthEast().lng();
         const latSouth = this.mapRef?.current?.getBounds()?.getSouthWest().lat();
         const lngWest = this.mapRef?.current?.getBounds()?.getSouthWest().lng();

         return {latNorth, lngEast, latSouth, lngWest} || {};
     };

     componentDidMount () {
         const {dispatch} = this.props;

         dispatch(MapActions.SET_MAP(this.mapRef));

         // new google.maps.places.PlacesService(this.map);
     }

     shouldComponentUpdate (nextProps: Readonly<IFullProps & WithGoogleMapProps>): boolean {
         return nextProps.mapType !== this.props.mapType || nextProps.streetViewLocation != this.props.streetViewLocation;
     }

    debugRender = () => {
        const {
            streetViewLocation,
            mapType,
            defaultCenter,
            dispatch,
        } = this.props;

        const center = this.getMapCenter();

        const zoom = this.getMapZoom();

        const getBounds = () => this.getMyBounds();

        const refreshBounds = throttle(1000, ()=>{
            dispatch(MapActions.SET_BOUNDS(getBounds()));
        });

        return <>
            <GoogleMap
                ref={this.mapRef}
                defaultZoom={zoom}
                defaultCenter={defaultCenter}
                options={{
                    streetViewControl: !!streetViewLocation,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    disableDefaultUI: true,
                }}

                mapTypeId={mapType}
                onZoomChanged={function (this: google.maps.Map) {
                    localStorage.set('map-default-zoom', this.getZoom());
                    localStorage.set('map-default-bounds', getBounds());
                    refreshBounds();
                }}
                onDragEnd={function (this: google.maps.Map) {
                    localStorage.set('map-default-center', {lat: this.getCenter().lat(), lng: this.getCenter().lng()});
                    localStorage.set('map-default-bounds', getBounds());
                    // dispatch(MapActions.SET_CENTER({lat: this.center.lat(), lng: this.center.lng()}))
                    refreshBounds();
                }}
            >
                <MapStuff />
            </GoogleMap>
            {
                streetViewLocation && (
                    <>
                        <StreetView mapRef={this.mapRef}
                            defaultCenter={center}
                            center={typeof streetViewLocation === 'object' ? streetViewLocation : undefined}/>
                    </>
                )
            }
        </>;
    };
});

const GettingStartedGoogleMap = withGoogleMap(GettingStartedGoogleMap1);

const MapStuff = () => {
    const pathIds = useRedux(getPathIdsSelector);

    const bounds = useRedux((s) => s.gmap.bounds) || {};

    const regions = useRedux((s) => s.regions.vis) || {};

    const newRegions = Object.keys(regions).reduce((acc,rec) => {
        if (regions[rec].center.lat > bounds.latSouth &&
            regions[rec].center.lng > bounds.lngWest &&
            regions[rec].center.lat < bounds.latNorth &&
            regions[rec].center.lng < bounds.lngEast) {
            return{...acc, [rec]: {...regions[rec]}};
        }

        return acc;
    }, {});
    const ovals = keys(pickBy(propEq('shape', FenceType.OVAL), newRegions));
    const rectangles = keys(pickBy(propEq('shape', FenceType.RECTANGLE), newRegions));

    return (<>
        <SearchMarkers />

        <InfoMarkers />

        <DevicesRoutes />

        {ovals.map((id) => <OvalPolygon key={id} id={id} />)}

        {pathIds.map((id) => <PathPolygon key={id} id={id} />)}

        {rectangles.map((id) => <Rectangle key={id} id={id} />)}
    </>);

};

export default class GmapComponent extends BaseComponent {
    shouldComponentUpdate() {
        return false;
    }

    debugRender = () => {

        return (
            <div id="gmap-wrapper" style={GmapComponent.style}>
                <GettingStartedGoogleMap
                    containerElement={
                        <div id="google-map" style={{flex: 1}} />
                    }
                    mapElement={
                        <div style={{height: '100%'}} />
                    }
                />
                {/* This will be rendered in streetView mode*/}
                {/* <div id="pano" style={{flex: 1}} />*/}
            </div>
        );
    }

    private static style: React.CSSProperties = {
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
    };
}
