import { readTextFile } from "./read-text-file";

export enum FileReaders {
  TEXT
}

export const createFileReader = (type: FileReaders) => {
  switch (type) {
    case FileReaders.TEXT:
      return readTextFile;
  }

}