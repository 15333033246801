import update from 'immutability-helper';
import {assoc, values} from 'ramda';

import {
    iFlashMessage,
    iIcon,
    iList,
    iDeviceEvent,
    iTag,
    iPerson,
    iExtraInfo,
    iConfig,
    iFenceDetails,
} from '../../shared/interfaces';

export enum MOBILE_VIEW {
    MENU = 'MENU',
    SETTINGS = 'SETTINGS',
    FENCE = 'FENCE',
    TIMELINE = 'TIMELINE',
    MAP = 'MAP',
}

export interface iState {
    misc: {[key: string]: any};
    flashMessage?: iFlashMessage;
    invisCaptchPassed?: boolean;
    incidentIcons?: {[incidentname: string]: iIcon};
    initComplete: boolean;
    showSideBars: boolean;
    range: 'LIVE' | {start: Date; end: Date};
    deviceEvents?: iList<iDeviceEvent>;
    tags: iList<iTag>;
    people: iList<iPerson>;
    favorites: {[type: string]: {[key: string]: boolean}};
    extraInfo: iList<iExtraInfo>;
    fences: { [type: string]: iList<iFenceDetails> };
    showSearch: boolean;
    devSlot?: any;
    searchTerm: string;
    tripLabels: string[];
    tagsInitialized: boolean;
    config?: iConfig;
    scheduleLabels: string[];
    currentMobileView: MOBILE_VIEW;
    showLiveTrial: boolean;
    filters: object;
}

const initialState: iState = {
    tripLabels: [],
    misc: {},
    scheduleLabels: [],
    initComplete: false,
    showSideBars: false,
    people: {},
    showSearch: false,
    searchTerm: '',
    range: 'LIVE',
    tags: {},
    tagsInitialized: false as boolean,
    favorites: {} as {[type: string]: {[key: string]: boolean}},
    extraInfo: false as any,
    fences: {},
    filters: {},
    currentMobileView: MOBILE_VIEW.MENU,
    showLiveTrial: true
};

export const reducers = (state: iState = initialState, action) => {
    if (action.type === 'DEV_SLOT') return update(state as any, {
        devSlot: {$set: action.thing}
    });

    if (action.type === 'SET_CLIENT_CONFIG') return assoc('config', action.config, state);

    if (action.type === 'SET_DEVICE_EVENTS') {
        return update(state as any, {
            deviceEvents: {$set: action.events}
        });
    }

    if (action.type === 'SET_INVIS_CAPTCHA') return update(state as any, {
        invisCaptchPassed: {$set: action.pass}
    });

    if (action.type === 'GENERAL_SET_INCIDENT_ICONS') {
        return update(state as any, {
            'incidentIcons': {$set: action.incidentIcons}
        });
    }

    if (action.type === 'TOGGLE_NAV_MENU') {
        return update(state as any, {
            showSideBars: {
                $set: !state.showSideBars
            }
        });
    }

    if (action.type === 'TIME_RANGE') return update(state as any, {
        range: {$set: action.range}
    });

    if (action.type === 'SET_LABELS') {
        return update(state as any, {
            tripLabels: {$set: values((action.labels || {} as any)['trip-labels'] || [])},
            scheduleLabels: {$set: values((action.labels || {} as any)['schedule-labels'] || [])}
        });
    }

    if (action.type === 'SET_ALL_PEOPLE') return update(state as any, {
        people: {$set: action.people}
    });

    if (action.type === 'SET_ALL_TAGS') return update(state as any, {
        tags: {$set: action.tags}
    });

    if (action.type === 'SET_TAG_EXTRA_INFO') return update(state as any, {
        tags: {
            [action.tagId]: {
                details: {
                    'extra-info': {$set: action.extraInfoIdVals}
                }
            }
        }
    });

    if (action.type === 'SET_ALL_FENCES') return update(state as any, {
        fences: {$set: action.fences}
    });

    if (action.type === 'SET_ALL_FILTERS') return update(state as any, {
        filters: {$set: action.filters}
    });

    if (action.type === 'SET_ALL_FAVORITES') return update(state as any, {
        favorites: {$set: action.favorites}
    });

    if (action.type === 'SET_ALL_EXTRA_INFO') return update(state as any, {
        extraInfo: {$set: action.extraInfo}
    });

    if (action.type === 'SET_MISC_VALUE') return update(state as any, {
        misc: {[action.key]: {$set: JSON.parse(JSON.stringify(action.value))}}
    });

    if (action.type === 'SEARCH_SHOW_SEARCH') return update(state as any, {
        showSearch: {$set: true}, searchTerm: {$set: ''}
    });

    if (action.type === 'SEARCH_HIDE_SEARCH') return update(state as any, {
        showSearch: {$set: false}
    });

    if (action.type === 'CHANGE_TERM') return update(state as any, {
        searchTerm: {$set: action.newTerm}
    });

    if (action.type === 'SET_TAGS_INITIALIZED') return update(state as any, {
        tagsInitialized: {$set: true}
    });

    if (action.type === 'INITALIZE_COMPLETE') return update(state as any, {
        initComplete: {$set: true}
    });

    if (action.type === 'TOGGLE_LIVE_TRAIL') {
        return update(state as any, {
            showLiveTrial: {$set: !state.showLiveTrial}
        });
    }

    return state;
};
