import React, {FC, useEffect} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';
import {is} from 'immutable';
import {groupBy, keys, pipe, sum, values} from 'ramda';
import {faSpinner} from '@fortawesome/fontawesome-free-solid';
import moment from 'moment';

import DashboardBlock from '../../DashboardBlock';
import {Fa} from '../../elements/fa';
import {Overlay} from '../../menus/modal/modal';
import {ACL, UserCan} from '../../../shared/constants';
import {
    beToken,
    DevicePingContainer,
    iDevicePing,
    iDevicePingWithGeofences,
    iFullStoreState,
    iList,
    iPerson,
    iTag,
    ItemType,
    iTrip,
    ReportContainer,
    Units
} from '../../../shared/interfaces';
import {
    friendlyDiff,
    friendlyDist,
    friendlyMilesPer,
    friendlySpeed,
    getDiff,
    idValArr,
    isMobile,
    lCount,
    minutesToFriendly,
    userCurrentOffset,
    utcOffset,
    vals
} from '../../../shared/helpers';
import {
    Actions as ReportActions,
    iChoosingDetails, IFilterValue,
    iReportDetails,
    iReportType,
    iState as iReportState,
    recordMatches
} from '../../../stores/reducers/report-reducers';
import {MinimalTile} from '../../general';
import {PersonBox} from '../../general/person-box';
import {getGeonfeces, getPoints, getTrips, iGetReportPropsWrapper, tripPointsReal} from '../../../shared/db/report-db';
import {LabelChooser} from '../../general/trip-list-chooser';
import {BaseComponent} from '../../../shared/BaseComponent';
import {NewDeviceReportSections} from './report-table/table-wrapper';
import {countSafety, getSafetyPercent} from './report-table/cells/helpers';
import Dialog, {DialogConfigSetter} from '../../Dialog';
import {localStorage} from '../../../shared/storage';
import {useRedux} from '../../../states/redux-state';
import {DevicesDetailsContainer} from '../../../stores/reducers/devicesData';
import {
    hideReportTrips,
    removeReportTrips,
    setReportTrip,
    toggleShowReportTrip
} from '../../../stores/reducers/devicesTripsPoints/AC';
import {usePrevious} from '../../../shared/hooks/usePrevius';

import './ReportPage.scss';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import {ReportSubNav, ReportSubNavbar} from "./ReportSubNavbar";
import {ReportNavbar} from "./ReportNavbar"
import {VR} from "../../general/VR";
import "../../../assets/styles/classes.scss";
import {ReactComponent as MapPointer} from "../../../assets/svg/map_pointer.svg";
import {ReactComponent as Notification} from "../../../assets/svg/notification.svg";
import {ReactComponent as FilterListSvg} from "../../../assets/svg/filter_list.svg";
import "./styles.scss";
import {ReactComponent as VisibilitySvg} from "../../../assets/svg/visibility.svg";
import {ReactComponent as DeviceSvg} from "../../../assets/svg/device.svg";
import {ReactComponent as PeopleSvg} from "../../../assets/svg/people.svg";
import Report from '../../../components/SVG-components/report';
import {BottomShadowModal} from "./BottomShadowModal";
import "./shared.scss";
import {iGMapModal} from '../../../stores/reducers/gMapModal';
import {GMapModalAC, GMapModalContent} from '../../../stores/reducers/gMapModal/AC';
import {DashboardSize} from '../../../stores/reducers/dashboardInfo';
import {Button, ButtonSize, ButtonStyles} from "../../Button";
import GeofenceReport from "./GeofenceReport";
import SummaryReport from "./SummaryReport";
import {Actions as GeneralActions} from '../../../stores/reducers/general-reducers';
import { fullCloseDashboard } from '../../../stores/reducers/dashboardInfo/AC';
import {VisReportData} from "./vis-report-data";
import {filterRecords, validate} from './reportFilter';
type IProps = RouteComponentProps<{ type?: 'static' | 'travel' }>;


type IPropsFromStore = {
    report: iReportDetails,
    devicesDetails: DevicesDetailsContainer;
    people: iList<iPerson>,
    userToken?: beToken;
    tags: iList<iTag>,
    choosing?: iChoosingDetails;
    labels: string[],
    displayRecords: iList<iTrip|iDevicePing>,
    reportType: iReportType,
    userCanDo: UserCan[],
    filterDisplayRecords: Array<IFilterValue>
    selectionsList: []
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;
const mapStateToProps = (state: iFullStoreState, ownProps): IPropsFromStore => {
    return ({
        userToken: state.auth.user?.beToken,
        displayRecords: state.report.displayRecords ?? {},
        choosing: state.report.choosing,
        devicesDetails: state.devicesData.devicesDetails,
        people: state.general.people,
        report: state.report.details,
        labels: state.general.tripLabels,
        tags: state.general.tags,
        reportType: ownProps.match.params.type ?? 'travel',
        userCanDo: state.auth.user?.acl?.can ?? [],
        filterDisplayRecords: state.report.filterDisplayRecords,
        selectionsList: state.report.selectionsList
    })
}

type IState = {
    loading: boolean;
    showNewReport: boolean;
    records: object;
    reportData: object;
    showAllRoute: boolean;
    showVisibleReportData: boolean;
    showVisibleFencesData: boolean;
}

export const getTitles = (records, deviceOrPerson, devicesDetails, people, setTitles) => {
    keys(records).forEach(userId => {
        const displayName =
            deviceOrPerson === ItemType.device
                ? devicesDetails.getIn([userId, 'name']) || 'Unknown Device'
                : (people[userId] || {}).displayName || 'Not Assigned';
        setTitles(prevState => ({ ...prevState, [userId]: displayName }));
    });
};

export const TripTravelTable = ({showAllRoute, setShowAllRoute, showReportTrip, hideReportTrip, ...props} ) => {
    const dispatch = useDispatch();
    const {showVisibleReportData} = props;
    const reportType = useRedux(s => s.report.details.reportType);
    const displayRecordsRaw = useRedux(s => s.report.displayRecords) || {};
    const deviceOrPerson = useRedux(s => s.report.details.devicePerson);
    const tripPoints = useRedux(s => s.devicesTripsPoints);
    const devicesDetails = useSelector<iFullStoreState, DevicesDetailsContainer>(
        s => s.devicesData.devicesDetails,
        ((left, right) => is(left, right))
    );
    const people = useRedux(s => s.general.people);
    const records = groupBy(x => deviceOrPerson === ItemType.device ? x.device : x.personId)(vals(displayRecordsRaw));
    const [ titles, setTitles] = React.useState({});

    const showAllTrips = (records, k) => {
        records[k].forEach((item) => {
            setShowAllRoute(!showAllRoute);
            showReportTrip(item, tripPoints)
        })
    }

    React.useEffect(() => () => {
        dispatch(removeReportTrips());
        setShowAllRoute(false);
    }, []);

    const prevReportType = usePrevious(reportType);
    React.useEffect(() => {
        if (reportType !== 'travel' && prevReportType === 'travel') {
            dispatch(hideReportTrips());
        }
    }, [reportType]);

    React.useEffect(() => {
        getTitles(records, deviceOrPerson, devicesDetails, people, setTitles)
        // dispatch(ReportActions.SET_TRIP_RECORDS(records));
        props.getRecords(records)
    }, [deviceOrPerson]);

    React.useEffect(() => {
        props.getNames(titles)
    }, [titles]);

    const hideAllReportTripsFor = (records, k) => {
        records[k].forEach(item => hideReportTrip(item));
    }

    return <>
        <VisReportData showVisibleReportData={showVisibleReportData} type={reportType as any} />
        {keys(records)
            .map(k => {
                const displayName = deviceOrPerson === ItemType.device
                    ? devicesDetails.getIn([k, 'name']) || 'Unknown Device'
                    : (people[k] || {}).displayName || 'Not Assigned';
                return (
                    <div className="report-device-section" key={k} style={{marginBottom: 10}}>
                        <NewDeviceReportSections
                            displayName={displayName}
                            records={records[k] as any}
                            getReportData={props.getReportData}
                            showAllRoute={showAllRoute}
                            showAllTrips={() => showAllTrips(records, k)}
                            showTrip={showReportTrip}
                            hideAllTrips={() => hideAllReportTripsFor(records, k)}
                        />
                    </div>
                )}
            )
        }
    </>
}


class ReportPage extends BaseComponent<IFullProps, IState> {
    constructor(props) {
        super(props);

        this.handleVisReportClick = this.handleVisReportClick.bind(this);
    }

    private dialog: DialogConfigSetter;

    state: IState = {
        loading: false,
        showNewReport: true,
        records: {},
        reportData: {},
        showAllRoute: false,
        showVisibleReportData: false,
        showVisibleFencesData: false
    };
    setShowAllRoute = (bool: boolean) => this.setState({showAllRoute: bool});

    getRecords = (data) => {
        this.setState({records: {...data}});
    }

    getNames = (data) => {
        const users = Object.keys(data)
        users.map( user => {
            this.setState(prevState => {
                return {
                    reportData: {...prevState.reportData, [user]: {...prevState.reportData[user], name: data[user]}},
                }
            })
        })
    }

    showReportTrip = (item, tripPoints) => {
        const {dispatch} = this.props;

        if (!tripPoints?.getIn([item.device, 'tripsPoints', item.tripId])) {
            tripPointsReal(item.tripId)
                .then(values)
                .then((points: any) => {
                    dispatch(setReportTrip(item.device, item.tripId, points));
                }).then(res => {
                dispatch(toggleShowReportTrip(item.device, item.tripId, true));
            })
        } else {
            dispatch(toggleShowReportTrip(item.device, item.tripId, true));
        }
    }

    hideReportTrip = (item) => {
        const {dispatch} = this.props;
        dispatch(toggleShowReportTrip(item.device, item.tripId, false));
    }

    getReportData = (data) => {
        const { dispatch } = this.props;
        this.setState(prevState => {
            return {
                reportData: {...prevState.reportData, ...data},
            }
        })
    }

    private toggleDevicePerson = (type: 'device' | 'person') => () => pipe(ReportActions.SET_DEVICE_PERSON, this.props.dispatch)(type)

    handleVisReportClick = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                showVisibleReportData: !prevState.showVisibleReportData
            }
        })
    }

    handleVisFencesClick = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                showVisibleFencesData: !prevState.showVisibleFencesData
            }
        })
    }

    populateData = async (props: IFullProps) => {
        const {devicesDetails, report, tags, dispatch, userToken} = props;

        if (!report.reportType && !report.searchKey) return;
        if (report.dates.length === 0) return;

        this.setState({loading: true})

        let query: iGetReportPropsWrapper = {
            allTags: tags,
            filters: report.filters,
            dates: report.dates,
            devicesDetails,
        };

        // don't forget that trips that have start dates before the search start date
        // can happend since the trip end date may be within range.
        if (report.reportType === 'travel' || report.reportType === 'summary') {
            const trips = await getTrips(query, userToken?.devices || false);
            dispatch(ReportActions.SET_DISPLAY_RECORDS(trips));
            this.setState({ loading: false })
        } else if (report.reportType === 'static') {
            let points = await getPoints(query, userToken?.devices || false, dispatch);
            dispatch(ReportActions.SET_DISPLAY_RECORDS(props.selectionsList.length > 0 && validate(props.selectionsList) ? filterRecords(props.selectionsList, points) : points));
            this.setState({ loading: false })
        } else if (report.reportType === "geofence") {
            let pointsWithGeofences = await getGeonfeces(query, userToken?.devices || false);
            dispatch(ReportActions.SET_DISPLAY_RECORDS(pointsWithGeofences));
            this.setState({ loading: false })
        } else if (report.reportType === "summary") {
            this.setState({ loading: false })
        }
    }

    async componentDidMount () {
        const {dispatch} = this.props;

        dispatch(ReportActions.CANCEL_CELL_PICKER());

        if (this.props.report.reportType !== this.props.reportType) {
            dispatch(ReportActions.SET_TYPE(this.props.reportType));
        }

        if (this.props.report.searchKey) {
            this.populateData(this.props)
        };

        if (localStorage.get(`matt-report-dev-${this.props.reportType}`)) {
            const report = localStorage.get<iReportState>(`matt-report-dev-${this.props.reportType}`);

            report.details.dates = report.details.dates
                .map(({startDate, endDate}) => ({
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                }));

            setTimeout(() => dispatch({type: 'GEN_SET', val: report, path: ['report']}), 100)
        }
    }

    componentWillUnmount(): void {
        this.props.dispatch(ReportActions.SET_TYPE('travel'));
        this.props.dispatch({
          type: "REPORT_SET_DISPLAY_RECORDS",
          displayRecords: {},
        });
    }
    componentDidUpdate(prevProps, prevState) {
        const { report, dispatch } = this.props;
        const { records, reportData } = this.state;

        if (prevProps.report !== report || prevState.records !== records || prevState.reportData !== reportData) {
            dispatch(GMapModalAC.setParams({params: {records, reportData, report}}))
        }
    }

    private choiceUpdate = async (setVal) => {
        const { dispatch, displayRecords, choosing } = this.props;

        if (!choosing) return;

        const oldRecord = idValArr(displayRecords).filter(recordMatches(choosing.affectedRowCriteria))[0].val;
        const oldVal = choosing.pickType === 'personId' ? oldRecord.personId : oldRecord.label;

        // optimistic update
        dispatch(ReportActions.UPDATE_DISPLAY_RECORDS_MATCHING(choosing.affectedRowCriteria, choosing.pickType, setVal));
        dispatch(ReportActions.CANCEL_CELL_PICKER());

        try {
            await choosing.updater(setVal);
        } catch (e) {
            console.log(e);
            dispatch(ReportActions.UPDATE_DISPLAY_RECORDS_MATCHING(choosing.affectedRowCriteria, choosing.pickType, oldVal));

            this.dialog?.({
                title: 'Problem encountered',
                body: 'An unexpected problem was encountered while changing the assigment. We had to change it back. If you continue to encounter this problem please notify us.',
                type: 'NOTIFICATION',
            });
        }
    }

    setupDialog = (callBack: () => DialogConfigSetter): void => {
        this.dialog = callBack();
    }

    async componentWillReceiveProps(props) {
        if (this.props.report.searchKey !== props.report.searchKey) {
            this.populateData(props);
        }

        if (props.report.reportType !== props.reportType) {
            this.props.dispatch(ReportActions.SET_TYPE(props.reportType));
        }
    }

    _clearRecords = () => {
        this.props.dispatch({type: 'REPORT_SET_DISPLAY_RECORDS', displayRecords: {}});
    }

    saveReport = () => {
        const { records, reportData } = this.state;
        const {report} = this.props

        const pdf = new jsPDF();
        const devices = Object.keys(records)

        devices.map((item, index) => {
            if(report.reportType === 'travel'){
                records[item].map(( row ) => {
                    const deviceOffset = utcOffset(reportData[item].deviceTimezone, moment().isDST());
                    const diff = userCurrentOffset - deviceOffset;
                    const start = moment(row.startDate).subtract(diff, 'hours');
                    const end = moment(row.endDate).subtract(diff, 'hours');

                    autoTable(pdf, {
                        head: [[reportData[item].name,  start.format('M/D/YY'), '', `${start.format('h:mmA')} - ${row.startAddress}` ]],
                        body: [
                            ['', 'Duration:', friendlyDiff(moment(row.endDate), moment(row.startDate))],
                            ['', 'Idle:', minutesToFriendly(row.idleMinutes)],
                            ['', 'Stopped:', minutesToFriendly(row.stopMinutes)],
                            ['', 'Safety:', row.safetyPercent ? row.safetyPercent + '%' : 'NA'],
                            ['', 'Avg. Speed:', `${row.averageSpeed ? friendlySpeed(row.averageSpeed, reportData[item].deviceUnits) : 'NA'}`],
                            ['', 'Consumption:', friendlyMilesPer(row.mpg, reportData[item].deviceUnits)],
                        ],
                        foot: [['','','',`${end.format('h:mmA')} - ${row.endAddress}`]]
                    })
                })
            }
            if(report.reportType === 'static'){
                const data = records[item].map(( row ) => {
                    const deviceOffset = utcOffset(reportData[item].deviceTimezone, moment().isDST());
                    const diff = userCurrentOffset - deviceOffset;
                    const dateWithCorrection = moment(row.time).subtract(diff, 'hours');

                    return [dateWithCorrection.format('MMM-D'), dateWithCorrection.format('h:mmA'), row.fuel, row.speed, row.posted_speed, row.mpg,
                        `${row.address.street} ${row.address.city} ${row.address.state} ${row.address.zip}`,
                        `${row.coordinates.location['lat']}, ${row.coordinates.location['lng']}`,
                    ]
                })

                autoTable(pdf, {
                    theme: 'grid',
                    headStyles: {fillColor: '#2E80BA'},
                    head: [[reportData[item].name, 'Data', 'Fuel', 'mph', 'Posted', 'mpg', 'Location', 'Coordinates']],
                    body: data,
                })
            }
        })

        pdf.save( 'Report.pdf')
    }

    debugRender = () => {
        const { report, dispatch, labels, people, devicesDetails, displayRecords, choosing, reportType, userCanDo } = this.props;
        const { loading, records, reportData, showVisibleReportData, showVisibleFencesData } = this.state;
        const activeIdx = ['static', 'travel', 'scheduled', 'queue'].indexOf(report.reportType as any);

        let overlay;
        if (choosing && choosing.pickType === 'label')
            overlay =
                <Overlay header="Select label" close={pipe(ReportActions.CANCEL_CELL_PICKER, dispatch) as any}>
                    <LabelChooser type="trip" canAdd choose={this.choiceUpdate} items={labels.map(l => ({id: l, content: l}))} />
                </Overlay>
        if (choosing && choosing.pickType === 'personId')
            overlay =
                <Overlay close={pipe(ReportActions.CANCEL_CELL_PICKER, dispatch) as any}>
                    <LabelChooser
                        type="person"
                        items={vals(people).map(p => ({id: p.id, content: <div style={{color: '#000'}}>
                                <PersonBox compressed personId={p.id} person={p} active={false} />
                            </div>}))}
                        choose={this.choiceUpdate} />
                </Overlay>

        const seeAllReports = ACL.check(UserCan.SEE_ALL_REPORTS, userCanDo);
        const showSummaryModal = !isMobile && seeAllReports && report.searchKey
        const startDate = moment.min(report.dates.map(d => d.startDate));
        const endDate = moment.max(report.dates.map(d => d.endDate));

        const openBottomShadowModal = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.AVERAGE_VALUES}));

        const handleExport = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.EXPORT_REPORT, params: {report, records, reportData}}));
        const handleFilters = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.REPORT_PAGE_FILTERS}));
        // console.log("records", records, "reportData", reportData, "report", report);
        return <>
            <DashboardBlock title="Reports" overlay={
                <>
                    {overlay}
                    {showSummaryModal && (
                        <LocalModalActions
                            userCanDo={userCanDo}
                            seeAllReports={seeAllReports}
                            records={records}
                            reportData={reportData}
                            report={report}
                        />
                    )}
                </>
            }>
                <ReportNavbar />
                <div className="report-page-container">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="report-header-wrapper" style={{display: 'flex', flexWrap: 'wrap', flexDirection: "column"}}>
                            {/* Item Type Filters */}
                            {!report.searchKey ? null : (
                                <>
                                    <div className="report-page-report-filters">
                                        <div className="filter-indicators" >
                                            {[ItemType.device, ItemType.person, ItemType.tag, 'alerts', 'labels'].map(type => <FilterIndicator key={type} type={type} />)}
                                            <FilterIndicatorItem className="dates">
                                                <span>
                                                    <span>{startDate.format("MM/DD/YY")} </span>
                                                    <span className="time">{startDate.format("hh:mmA")}</span>
                                                    <span>  -  </span>
                                                </span>
                                                <span>
                                                    <span>{endDate.format("MM/DD/YY")} </span>
                                                    <span className="time">{endDate.format("hh:mmA")}</span>
                                                </span>
                                            </FilterIndicatorItem>
                                        </div>


                                        <div className="report-filters-actions" style={{display: 'flex', justifyContent: 'space-between', fontSize: 10}}>
                                            <button className="but but-28px but-link" onClick={() => { dispatch(ReportActions.RESET_DATES()); dispatch(ReportActions.RESET_ALL_FILTERS())}}>Clear all</button>
                                        </div>
                                    </div>

                                    <div className="report-page-subnav-section">
                                        <ReportSubNavbar>
                                            <ReportSubNav to="/reports/new/static" onClick={this._clearRecords}>Static</ReportSubNav>
                                            <VR className="h-8 mv-8" />
                                            <ReportSubNav to="/reports/new/travel" onClick={this._clearRecords}>Travel</ReportSubNav>
                                            <VR className="h-8 mv-8" />
                                            <ReportSubNav to="/reports/new/geofence" onClick={this._clearRecords}>Geofence</ReportSubNav>
                                            <VR className="h-8 mv-8" />
                                            <ReportSubNav to="/reports/new/summary" onClick={this._clearRecords}>Summary</ReportSubNav>
                                        </ReportSubNavbar>

                                        {/* Device Person Toggle */}
                                            <div className="filter-btn-container">
                                                <div className="restrictive-container">
                                                    <div className="visible-report-data-container">
                                                        <Button
                                                            styleType={ButtonStyles.PLAIN_INACTIVE}
                                                            size={ButtonSize.XSM}
                                                            onClick={this.handleVisReportClick}
                                                        >
                                                            <VisibilitySvg className="icon-sm"/> Visible Report Data
                                                        </Button>
                                                    </div>
                                                    {report.reportType === 'geofence' &&
                                                        (<div className="visible-report-data-container">
                                                            <Button
                                                                styleType={ButtonStyles.PLAIN_INACTIVE}
                                                                size={ButtonSize.XSM}
                                                                onClick={this.handleVisFencesClick}
                                                            >
                                                                <VisibilitySvg className="icon-sm"/> Visible Fences
                                                            </Button>
                                                        </div>)
                                                    }
                                                    </div>

                                            </div>

                                        <div className="device-person-toggle">
                                            <Button
                                                styleType={
                                                    report.devicePerson === "device"
                                                        ? ButtonStyles.BLACK_WHITE
                                                        : ButtonStyles.GRAY_INACTIVE
                                                }
                                                size={ButtonSize.XSM}
                                                onClick={this.toggleDevicePerson('device')}
                                            >
                                                <DeviceSvg
                                                    className="icon-sm"
                                                />
                                            </Button>
                                            <Button
                                                styleType={
                                                    report.devicePerson === "device"
                                                        ? ButtonStyles.GRAY_INACTIVE
                                                        : ButtonStyles.BLACK_WHITE
                                                }
                                                size={ButtonSize.XSM}
                                                onClick={this.toggleDevicePerson('person')}
                                            >
                                                <PeopleSvg
                                                    className="icon-sm"
                                                />
                                            </Button>

                                        </div>
                                        <ShowOnMap showAllRoute={this.state.showAllRoute} setShowAllRoute={this.setShowAllRoute}/>
                                        <div className="for-print" style={{whiteSpace: "nowrap"}}>
                                            Grouped by: {report.devicePerson === 'device' ? 'Device' : 'Person'}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* !isFullModal ? null :
                        <Row justify="center">
                            <div className="inline-gmap-wrapper" style={{height: 250, width: 250, position: 'relative', marginTop: 20}}>
                                <GMaps />
                            </div>
                        </Row>
                    */}
                    {/* Table */}
                    { (!report.searchKey) ? <FilterPromptBlock /> :
                        <div className="report-tables"  >
                            {loading
                                ? <div style={{width: '100%', fontSize: 25, textAlign: 'center'}}><Fa icon={faSpinner} spin /></div>
                                : this.props.reportType === "static" || this.props.reportType === "travel"? (
                                    <TripTravelTable
                                        showAllRoute={this.state.showAllRoute}
                                        setShowAllRoute={this.setShowAllRoute}
                                        getRecords={this.getRecords}
                                        getNames={this.getNames}
                                        getReportData={this.getReportData}
                                        showReportTrip={this.showReportTrip}
                                        hideReportTrip={this.hideReportTrip}
                                        showVisibleReportData={showVisibleReportData}
                                    />
                                ) : (this.props.reportType === 'geofence' ? <GeofenceReport showVisibleFencesData={showVisibleFencesData} /> : <SummaryReport showReportTrip={this.showReportTrip} hideReportTrip={this.hideReportTrip} />)
                            }
                        </div>
                    }

                    {/* No data indicator */}
                    {!report.searchKey || this.state.loading || lCount(displayRecords) > 0 ? null :
                        <MinimalTile className="minimal-tile" style={{marginTop: 10, textAlign: 'center'}}>
                            <p>No data was found for you filter criteria.</p>
                            <FilterPrompt />
                        </MinimalTile>
                    }
                </div>
            </DashboardBlock>
            <Dialog setupConfig={this.setupDialog} />
        </>
    }
}

export default connect(mapStateToProps)(ReportPage);

const ShowOnMap = ({showAllRoute, setShowAllRoute}) => {
    const dispatch = useDispatch();
    const displayRecordsRaw = useRedux(s => s.report.displayRecords) || {};
    const deviceOrPerson = useRedux(s => s.report.details.devicePerson);
    const tripPoints = useRedux(s => s.devicesTripsPoints);

    const records = groupBy(x => deviceOrPerson === ItemType.device ? x.device : x.personId)(vals(displayRecordsRaw));


    const showReportTrip = (item) => {
        if (!tripPoints.getIn([item.device, 'tripsPoints', item.tripId])) {
            tripPointsReal(item.tripId)
                .then(values)
                .then((points: any) => {
                    dispatch(setReportTrip(item.device, item.tripId, points));
                }).then(res => {
                dispatch(toggleShowReportTrip(item.device, item.tripId, true));
            })
        } else {
            dispatch(toggleShowReportTrip(item.device, item.tripId, !showAllRoute));
        }
    }

    const showAllTrips = (records, k) => {
        records[k].forEach((item) => {
            setShowAllRoute(!showAllRoute);
            showReportTrip(item)
        })
    }

    const handleAllOnMapClick = () => {
        if (isMobile) {
            dispatch(GeneralActions.SHOW_SEARCH)
            dispatch(fullCloseDashboard())
        }
        keys(records).map(k => showAllTrips(records, k))
    }

    return (
        <Button
            styleType={
                showAllRoute
                    ? ButtonStyles.BLACK_WHITE
                    : ButtonStyles.GRAY_INACTIVE
            }
            size={(isMobile || window['cordova']) ? ButtonSize.MD : ButtonSize.XSM}
            onClick={handleAllOnMapClick}
        >
            <MapPointer className={(isMobile || window['cordova']) ? 'icon-smd' : 'icon-sm'} /> All On Map
        </Button>
    )
}

export const LocalModalActions = (props) => {
    const report = useSelector<iFullStoreState, iReportDetails>(s => s.report.details);
    const dashboardInfo = useRedux(s => s.dashboardInfo);
    const reportData = useSelector<iFullStoreState, DevicesDetailsContainer>(s => s.devicesData.devicesDetails);
    const reportType = useSelector<iFullStoreState, string>(s => s.report.details.reportType);
    const displayRecordsRaw = useRedux(s => s.report.displayRecords) || {};
    const deviceOrPerson = useRedux(s => s.report.details.devicePerson);
    const records = groupBy(x => deviceOrPerson === ItemType.device ? x.device : x.personId)(vals(displayRecordsRaw));

    const dispatch = useDispatch();
    const handleExport = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.EXPORT_REPORT, params: {report, records, reportData}}))
    };
    const handleFilters = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.REPORT_PAGE_FILTERS}));
    const handleCreateReport = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.GENERATE_REPORT}));
    const {userCanDo} = props;
    const seeAllReports = ACL.check(UserCan.SEE_ALL_REPORTS, userCanDo, true);
    return (
        <BottomShadowModal>
            <div className={`report-summary ${dashboardInfo.size === DashboardSize.OPEN? "dashboard-open": ""} ${reportType === "geofence"? "report-summary--end": ""}`}>
                {
                    reportType === "static"
                        ? <StaticTotalTable />
                        : reportType === "travel" || reportType === 'summary'
                            ? <TravelTotalTable />
                            : null
                }
                <div className="report-summary-actions" >
                    {seeAllReports && (<Link className={"generate-report-btn link"} to={(isMobile || window['cordova']) ? "/reports" : "/reports/scheduled?generateReport=true"}>
                        <Button
                            styleType={ButtonStyles.ORANGE_WHITE}
                            size={(isMobile || window['cordova']) && ButtonSize.LG}
                            onClick={(isMobile || window['cordova']) && handleCreateReport}
                        >
                            Generate Report
                        </Button>
                    </Link>)}
                    {(!isMobile) &&
                    <Button
                        className={"filters-btn"}
                        styleType={ButtonStyles.BLACK_WHITE}
                        onClick={handleFilters}
                    >
                        <FilterListSvg className="icon-16px"/>
                    </Button>
                    }
                    <Button
                        className={"export-btn"}
                        styleType={ButtonStyles.WHITE_GRAY}
                        onClick={handleExport}
                        size={(isMobile || window['cordova']) && ButtonSize.LG}
                    >
                        Export
                    </Button>
                </div>
            </div>
        </BottomShadowModal>
    )
}

export const getTimeFormated = ( time: number) => {
    const num: number = Number((time).toFixed(1));
    return num === 0 ? 0 : `${num} h`
}

export const countDriveTime = (displayRecords: ReportContainer | (iDevicePing | iTrip)[]) => {
    return Object.keys(displayRecords).reduce((acc, record) => {
        return acc + getDiff(moment(displayRecords[record].endDate), moment(displayRecords[record].startDate))
    }, 0);
}

export const countTraveledDistance = (displayRecords: ReportContainer | (iDevicePing | iTrip)[]) => {
    return Object.keys(displayRecords).reduce((acc, record) => {
        return acc + displayRecords[record].miles
    }, 0);
}

export const countStoppedTime = (displayRecords: ReportContainer | (iDevicePing | iTrip)[]) => {
    return Object.keys(displayRecords).reduce((acc, record) => {
        const stoppedTime = displayRecords[record].stopMinutes
        return stoppedTime > 0 ? acc + stoppedTime : acc
    }, 0);
}

export const useOneDeviceReportUnits = () => {
    const displayRecords = useSelector<iFullStoreState, ReportContainer>(s => s.report.displayRecords || {});
    const deviceIds = Object.keys(displayRecords).reduce((acc, record) => {
        if(acc.indexOf(displayRecords[record].device) === -1) {
            return [...acc, displayRecords[record].device]
        }
        return acc
    }, []);

    const oneDeviceReport = deviceIds.length === 1;
    let deviceUnits = useSelector<iFullStoreState, Units>(
        s => s.devicesData.devicesDetails.getIn([deviceIds[0], 'units'], Units.miles),
    )
    deviceUnits = oneDeviceReport ? (
        deviceUnits
    ) : Units.miles ;

    return deviceUnits;
}

const TravelTotalTable = () => {
    const reportType = useSelector<iFullStoreState, iReportType>(s => s.report.details.reportType);
    const displayRecords = useSelector<iFullStoreState, ReportContainer>(s => s.report.displayRecords || {});
    const safetyPercent = (sum(vals(displayRecords).map(getSafetyPercent(reportType as any))) / vals(displayRecords).length);
    const displaySafetyPercent = safetyPercent ? safetyPercent.toFixed(2) + "%" : '';

    const driveTime = countDriveTime(displayRecords);
    const distanceTraveled = countTraveledDistance(displayRecords);
    const averageSpeed = Math.floor((distanceTraveled / driveTime) * 10 ) / 10;
    const stoppedTime = countStoppedTime(displayRecords);
    const deviceUnits = useOneDeviceReportUnits();
    return (
        <div className="report-summary-table">
            <div className="report-summary-col">
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Average Speed:</span>
                    <span className="report-summary-data-value">{isNaN(averageSpeed) ? 0 : friendlySpeed(averageSpeed, deviceUnits)}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Safety:</span>
                    <span className="report-summary-data-value">{displaySafetyPercent}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Drive Time:</span>
                    <span className="report-summary-data-value">{getTimeFormated(driveTime)}</span>
                </div>
            </div>
            <div className="report-summary-col">
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Stopped Time:</span>
                    <span className="report-summary-data-value">{getTimeFormated(stoppedTime / 60)}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name"> Total Distance:</span>
                    <span className="report-summary-data-value">
                        {distanceTraveled > 0 ? friendlyDist(distanceTraveled, deviceUnits) : 0}
                    </span>
                </div>
            </div>
        </div>
    )
}

const countAverageFuel = (displayRecords: Array<iDevicePing>): number => {
    return sum(displayRecords.map(record => record.fuel ?? 0)) / displayRecords.length || 0;
}

const countAverageSpeed = (displayRecords: Array<iDevicePing>): number => {
    return sum(displayRecords.map(record => record.speed ?? 0)) / displayRecords.length || 0;
}

const StaticTotalTable = () => {
    const displayRecords = useSelector<iFullStoreState, ReportContainer>(s => s.report.displayRecords || {});
    const displayValues = Object.values(displayRecords);

    const averageFuel = countAverageFuel(displayValues as Array<iDevicePing>).toFixed(2);
    const safetIncidents = sum(vals(displayRecords).map(countSafety));
    const averageSpeed = countAverageSpeed(displayValues as Array<iDevicePing>).toFixed(2);
    const deviceUnits = useOneDeviceReportUnits();
    return (
        <div className="report-summary-table report-summary-table--one-col">
            <div className="report-summary-col">
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Average fuel:</span>
                    <span className="report-summary-data-value">{averageFuel}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Average speed:</span>
                    <span className="report-summary-data-value">{friendlySpeed(averageSpeed, deviceUnits)}</span>
                </div>
                <div className="report-summary-data">
                    <span className="report-summary-data-name">Safety Incidents</span>
                    <span className="report-summary-data-value">{safetIncidents}</span>
                </div>
            </div>
        </div>
    )
}

const FilterPrompt = connect(
    (s: iFullStoreState): {details: iReportDetails, gMapModal: iGMapModal} => ({details: s.report.details, gMapModal: s.gMapModal})
)(({details}: {details: iReportDetails, gMapModal}) => {
    const dispatch = useDispatch();
    const showReportFiltersModal = () => {
        dispatch(GMapModalAC.showModal({contentType: GMapModalContent.REPORT_PAGE_FILTERS, params: {fullHeight: true}}));
    }

    return (
            <Button
                styleType={ButtonStyles.BLACK_WHITE}
                size={ButtonSize.MD}
                onClick={showReportFiltersModal}
            >
                <span className={"align-center"}><FilterListSvg className="icon-md"  style={{marginRight: "5px"}}/>Report Filters</span>
            </Button>
    )
});

const FilterPromptBlock = () => {
    return (
        <div className="report-filter-begin">
            <p>Select report filters to begin.</p>
            <FilterPrompt />
        </div>
    )
}

type IFilterIndicatorProps = {
    type: string;
    reportType: string;
}
type IFilterIndicatorPropsFromStore = {
    report: iReportDetails;
}
type IFullFilterIndicatorProps = IFilterIndicatorProps & IFilterIndicatorPropsFromStore & DispatchProp;


const FilterIndicator = connect((state: iFullStoreState): IFilterIndicatorPropsFromStore => ({
    report: state.report.details,
}))
((props: IFullFilterIndicatorProps) => {
    const { type, report } = props as IFullFilterIndicatorProps;

    const typeSlice = (report.filters || {})[type];

    const itemCount = lCount(typeSlice);

    return (
        itemCount === 0
            ? <FilterIndicatorItem>All <span className="type">{type}</span></FilterIndicatorItem>
            : <FilterIndicatorItem>
                <span className="capitalize">{type}: </span>
                <b>{itemCount}</b>
            </FilterIndicatorItem>
    )
});

const FilterIndicatorItem: FC<{className?: string}> = ({children, className = ""}) => {
    const dispatch = useDispatch();
    const handleClick = () => dispatch(GMapModalAC.showModal({contentType: GMapModalContent.REPORT_PAGE_FILTERS}));
    return (
        <Button
            className={`filter-indicator-item ${className}`}
            styleType={ButtonStyles.WHITE_GRAY}
            size={ButtonSize.SM}
            onClick={handleClick}
        >
            {children}
        </Button>
    )
}
