import { select, takeEvery, put } from 'redux-saga/effects';

import { Actions as GmapActions } from '../reducers/gmap-reducers';
import { iFullStoreState, iLocation } from '../../shared/interfaces';


function* worker (action: ReturnType<typeof GmapActions.RECENTER_MAP_TO_DEVICE>) {
    const {deviceId, recenterIfOutOfBounds} = action.payload;
    const deviceLocation: iLocation | undefined = yield select(
        (state: iFullStoreState) => state.devicesData.devicesLastPing.getIn([deviceId, 'coordinates', 'location']),
    );

    if (!!deviceLocation) {
        yield put(GmapActions.RECENTER_MAP(deviceLocation, recenterIfOutOfBounds));
    }
}

export default function* () {
    yield takeEvery('RECENTER_MAP_TO_DEVICE', worker);
}
