import React from "react";
import { NavLink } from "react-router-dom"
import "./index.scss";

export const ClipedNavbar = ({children}) => {
    return (
        <div className="cliped-navbar">
            {children}                   
        </div>
    )
}

export const ClipedNav = ({children, to, ...props}) => {
    return (
        <NavLink activeClassName="active" className="cliped-nav-wraper" to={to}>
            <div className="cliped-nav">
                {children}
            </div>
        </NavLink>
    )
}