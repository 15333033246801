import React from 'react';
import {omit} from 'ramda';

import C from '../../shared/constants';
import {iIcon} from '../../shared/interfaces';
import {BaseComponent} from '../../shared/BaseComponent';
import {Fa} from './fa';

interface Props {
    icon: iIcon;
    color?: string;
    noBorder?: true;
    [key: string]: any;
}

export class IconNew extends BaseComponent<Props, any> {
    debugRender = (): JSX.Element => {
        const {icon, color = C.primaryText} = this.props;

        if (!icon) return null;

        const props = {...this.props};
        delete props.icon;

        const {style = {}} = this.props;

        return !!icon.fa ?
            <Fa className="testing" { ...omit(['size'], props) } icon={icon.fa} style={{color: `${color}`, ...style}}/> :
            <img alt='icon' src={icon.url} {...props} />;
    }
}
