import React from 'react';
import { omit } from 'ramda';

import C from '../../shared/constants';
import { iIcon } from '../../shared/interfaces';
import { BaseComponent } from '../../shared/BaseComponent';
import { Fa } from './fa';


interface iProps {
    icon: iIcon,
    size?: 'xs'|'sm'|'md'|'lg',
    color?: string,
    noBorder?: true,
    [key: string]: any
}

export class Icon extends BaseComponent<iProps, any> {
    debugRender = () => {
        const { icon, size='md', color = C.primaryText } = this.props;

        if (!icon) return null;

        let props = {...this.props};
        delete props.icon;

        let em = 1.2;
        if (size == 'sm') em = 2;
        if (size == 'md') em = 3;
        if (size == 'lg') em = 4.5;

        let { style = {} } = this.props;

        return !!icon.fa
            ? <Fa className="testing" { ...omit(['size'], props) } icon={icon.fa} style={{color: `${color}`, fontSize: `${em}em`, ...style}}/>
            : <img src={icon.url} {...props} style={{width: `${em}em`, border: `1px solid ${color}`, ...style}}/>
    }
}