import React from 'react';
import {connect, useSelector} from 'react-redux';
import {ACL, UserCan} from '../../../../shared/constants';
import {iFullStoreState} from '../../../../shared/interfaces';
import {ClipedNavbar, ClipedNav} from '../../../general/ClipedNavbar';

type IPropsFromStore = {
    reportType;
};

const mapStateToProps = (state: iFullStoreState): IPropsFromStore => {
    return {
        reportType: state.report.details.reportType
    };
};

export const ReportNavbar = connect(mapStateToProps)(
    ({reportType}) => {
        const type = reportType ? `/${reportType}` : '';
        const userCanDo = useSelector<iFullStoreState, UserCan[]>((s) => s.auth.user?.acl?.can ?? []);
        const seeAllReports = ACL.check(UserCan.SEE_ALL_REPORTS, userCanDo, true);

        return (
            <ClipedNavbar>
                <ClipedNav to={`/reports/new${type}`}>New</ClipedNav>
                { seeAllReports && (
                    <ClipedNav to="/reports/scheduled">Scheduled</ClipedNav>
                )}
            </ClipedNavbar>
        );
    }
);
