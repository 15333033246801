import { ItemType, UserAuth, iList, iTag } from '../interfaces';
import { __, contains, equals, filter, ifElse, isEmpty, keys, map, not, path, pipe } from 'ramda';
import { idValArr, makeAudit, vals } from '../helpers';

import { clientDb } from '../firebase';
import {store} from '../../stores/store';

const tagsDb = () => clientDb().child('tags');

export const all = async (): Promise<iList<iTag>> => (await tagsDb().once("value")).val();


const tagsWatching = {} as iList<true>
const subscribeTagInfo = (tags, extraInfoCallback, finishedCallback) =>  (id: string) => clientDb().child('tags-extra-info-values').child(id).on('value',
    res => {
        if (res.val) extraInfoCallback(id, res.val() || {})

        registerTagInfoWatch(finishedCallback, tags)(id)
    },
    err => console.log('subsceribe-err', err) as any || registerTagInfoWatch(finishedCallback, tags)(id) // can't read but still should register we tried
)

const registerTagInfoWatch = (finishedCallback, tags) => id => {
    tagsWatching[id] = true

    try {
        if (equals(keys(tagsWatching), keys(tags))) finishedCallback()
    } catch (e) {
        console.log('estuff', e);
    }
}

export const allWatch = (callback, extraInfoCallback: (tagId, extraInfoIdVals: iList<string>) => any, finishedCallback) => tagsDb().on("value", res => {
    const tags: iList<iTag> = res.val() || {} as iList<iTag>

    callback(tags);

    // watch the tag extra info
    pipe(
        keys,
        filter((id: string) => !(id in tagsWatching)),
        ifElse(isEmpty,
            () => finishedCallback(true),
            map(subscribeTagInfo(tags, extraInfoCallback, finishedCallback))
        )
    )(tags as any)
}, err => console.log(err));

export const update = (user: UserAuth) => async (tagId, tag: { [key: string]: any }) => {
    const updatedTagFields = {id: tagId, ...tag};

    const update = Object.entries(updatedTagFields).reduce((r, [field, value]) => ({
        ...r,
        [`tags/${tagId}/details/${field}`]: value,
    }), {});

    await clientDb().update(makeAudit(user, update));
};

const isAssigned = (itemType: ItemType, itemId: string) => path(['instances', itemType, itemId]);

export const setItemTags = (user: UserAuth) => async (itemType: ItemType, itemId: string, tagIds: string[], tagId?: string) => {
    let update = {};

    const allTags = await all();
    const deviceTags = vals(allTags).filter(isAssigned(itemType, itemId));

    // ones to remove
    const getId = path(['details', 'id']);
    const isRequested = pipe(getId, contains(__, tagIds));

    deviceTags
        .filter(pipe(isRequested, not))
        // remove inst from tag instance list
        .map(tag => {
            if (tagId === tag.details.id) {
                update[`tags/${tag.details.id}/instances/${itemType}/${itemId}`] = null;
            }

            return tag;
        })

        // update the device to remove proper notification settings
        .filter(t => t.details.isAlertType && t.details.eventValues)
        // reverse the ones that are "on" for tag
        .forEach(tag => idValArr(tag.details.eventValues)
            .filter(({ val }) => val.on)
            .map(({ id: alertType, val}) => {
                update[`devices/device-details/${itemId}/eventValues/${alertType}`] = null;
            })
        );

    // add instances not present
    vals(allTags)
        .filter(isRequested)
        .map(tag => {
            update[`tags/${tag.details.id}/instances/${itemType}/${itemId}`] = true;
            return tag;
        })

        .forEach(tag => idValArr(tag.details.eventValues || {})
            .filter(({ val }) => val.on)
            .map(({ id: alertType, val}) => {
                // need to update individually rather than replace because we don't want to turn existing off
                update[`devices/device-details/${itemId}/eventValues/${alertType}/on`] = true;
                idValArr(val.people || {}).forEach(({id: personId}) => {
                    update[`devices/device-details/${itemId}/eventValues/${alertType}/people/${personId}`] = true;
                })
            }))

    await clientDb().update(makeAudit(user, update))
}

// neet to make it more generic in future if there be a need in that
export const toggleDevicesForTag = async (devicesId: string[], tagId: string, value: boolean) => {
    const update = {};
    const tag = store.getState().general.tags[tagId];
    const user = store.getState().auth.user;
    const eventValues = tag.details.eventValues || {};
    const eventOnKeys = Object.keys(eventValues).filter(eventKey => eventValues[eventKey].on);
    const valueToUpdateTo = value? true : null;

    devicesId.forEach(deviceId => {
        // add device to tag instances
        update[`tags/${tagId}/instances/${ItemType.device}/${deviceId}`] = valueToUpdateTo;
        eventOnKeys.forEach(eventType => {
            // turn on all alerts from tag
            update[`devices/device-details/${deviceId}/eventValues/${eventType}/on`] = valueToUpdateTo;
            const personsId = Object.keys(eventValues[eventType].people || {});
            personsId.forEach(personId => {
                // not shore what is it
                update[`devices/device-details/${deviceId}/eventValues/${eventType}/people/${personId}`] = valueToUpdateTo;
            })
        })
    })

    await clientDb().update(makeAudit(user, update));
}

export const deleteTags = (user: UserAuth) => async (tag: iTag) => {
    const update = {
        [`tags/${tag.details.id}`]: null
    };

    const peopleCanSee = keys(path(['instances', 'allowed-see', 'person'], tag, []));
    const devices = keys(path(['instances', 'device'], tag, []))

    peopleCanSee.forEach(personId => devices.forEach(deviceId => {
        update[`acl/items-allowed/${personId}/device/${deviceId}/${tag.details.id}`] = null
    }))

    await clientDb().update(makeAudit(user, update))
}