import React, { HTMLProps, useEffect, useState } from "react";
import "./index.scss";
import Input from "../Input";
import { Button, ButtonSize, ButtonStyles } from "../Button";

interface SearchGridProps<T> extends HTMLProps<HTMLDivElement> {
    items: T[];
    searchFunction: ({item, searchString}: {item: T, searchString: string}) => boolean;
    render: ({item}: {item: T}) => JSX.Element;
    onResetAll?: () => void;
}

function SearchGrid<T>({items: propItem, searchFunction, render, onResetAll}: SearchGridProps<T>): React.ReactElement | null {
    const [searchString, setSearchString] = useState("");
    const [items, setItems] = useState(propItem);

    const handleResetAllClick = () => {
        setSearchString("");
        onResetAll?.();
    }
    const handleSearchChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(value);
    }

    const handleSearch = () => searchString? items.filter(item => searchFunction({item, searchString})) : propItem;

    useEffect(() => {
        const searched = handleSearch();
        setItems(searched);
    }, [searchString])


    return (
        <div className="search-grid">
            <div className="search-grid__actions">
                <Input className="search-grid__input" type="search" placeholder="Search..." value={searchString} onChange={handleSearchChange}/>
                <Button styleType={ButtonStyles.BLACK_WHITE} size={ButtonSize.MD} className="search-grid__reset-all-btn" onClick={handleResetAllClick}>Reset all</Button>
            </div>
            <div className="search-grid__body">
                {items.map((item) => render({item}))}
            </div>
        </div>
    )
}

export default SearchGrid;