import React, {useEffect, useRef, useState} from "react";
import Modal from "../../../Modal";
import {Fa} from "../../../elements/fa";
import { faTimes, faSpinner } from "@fortawesome/fontawesome-free-solid";
import RenderChannels from "./RenderChannels/RenderChannels";
import RenderPlayers from "./RenderPlayers";
import { getChannels, getHistoryVideoApi, updateAvailableTime} from "../../../../api/openApi";
import ContinueStreamModal from "./ContinueStreamModal";
import AvailableTime from "./AvailableTime";
import {useDispatch, useSelector} from "react-redux";
import {iReducersState} from "../../../../stores/reducers";
import BuyMoreModal from "./BuyMoreModal";
import {
  IAvailableTimeProps,
  IBuyMoreModalProps,
  IChannelsProps,
  ISelectedHistoryVideo,
  IRenderPlayersProps,
  ISelectChannel,
  ITariffs,
  IHistoryVideo,
} from "./interfaces";
import History from "./History";
import styles from "./deviceStatuses.module.scss";
import moment from "moment";
import { openModal } from "../../../../stores/reducers/videoCameras";

const REMINDER_INTERVAL: number = 60000;


const DeviceStatuses = (props): JSX.Element => {
  const {device: {isCamera, 'extra-info': serial}} = props;
  const dispatch = useDispatch();
  const { modals, cameraOnline, availableTime: reviewTime} = useSelector((state: iReducersState) => state.videoCameras);
  const {
    openHistoryModal, 
    openHistoryPlayer, 
    openLiveViewModal, 
    openLiveViewPlayer, 
    openBuyMoreModal, 
    openContinueLiveStreamingModal
  } = modals;


  const playedSecondsRef = useRef<number>(0);
  const [selectedChannels, setSelectedChannels] = useState<Array<ISelectChannel>>([]);
  const [reminderIntervalTime, setReminderIntervalTime] = useState<number>(REMINDER_INTERVAL);
  const [prevPlayedSeconds, setPrevPlayedSeconds] = useState<number>(0);
  const [channels, setChannels] = useState<Array<ISelectChannel>>([]);
  const [availableTime, setAvailableTime] = useState<number>(null || reviewTime);
  const [streamingTariffs, setStreamingTariffs] = useState<ITariffs | null>(null);
  const [isControls, setIsControls] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currency, setCurrency] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [prevIdChannels, setPrevIdChannels] = useState(null);
  const [prevHistoryTime,setPrevHistoryTime] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [historyVideo, setHistoryVideo] = useState<Array<IHistoryVideo>>([]);
  const [selectedHistoryChannel, setSelectedHistoryChannel] = useState<ISelectedHistoryVideo>(null);
  const intervalContinueStreaming = useRef<ReturnType<typeof setInterval>>(null);
  const [isLoadedVideo, setIsLoaded] = useState(false);
  const [alertTimestamp, setAlertTimestamp] = useState(0);


  useEffect(() => {
    (cameraOnline) && getVideos();
  }, [cameraOnline])

  const updateTime = async (): Promise<number> => {
    setPrevPlayedSeconds(playedSecondsRef.current);
    setPrevHistoryTime((prev) => prev + Math.round(playedSecondsRef.current));
    setAlertTimestamp((prev) => new Date(prev).setSeconds(new Date(prev).getSeconds() + Math.round(playedSecondsRef.current)));
    const {available_time} = await updateAvailableTime(serial, playedSecondsRef.current - prevPlayedSeconds);
    return available_time;
  };

  const getVideos = async (): Promise<Array<ISelectChannel>> => {
    setIsLoading(true);
    if (cameraOnline) {
      const {
        channels,
        time_info: {
          available_time,
          tariffs: {streaming_tariffs, currency}
        }
      } = await getChannels({serial: serial[0]}).finally(() => setIsLoading(false));
      setAvailableTime(available_time);
      setChannels(channels);
      setStreamingTariffs(streaming_tariffs);
      setCurrency(currency);
      return channels;
    }
  }

  const resetPlayer = (): void => {
    setIsPlaying(false);
    setCurrentTime(0);
    playedSecondsRef.current = 0;
    setPrevPlayedSeconds(0);
    setSelectedChannels([]);
    setCount(0)
  }

  const closeModal = async (): Promise<void> => {
    const time = await updateTime();
    setAvailableTime(time);
    for (let key in modals) modals[key] && dispatch(openModal({key, value: false}))
    setHistoryVideo([])
    resetPlayer();
  };

  const clickView = async (): Promise<void> => {
    setLoadingButton(true);
    await getVideos().then((channels) => {
      setSelectedChannels([channels.find(item => item.channel_id === selectedChannels[0].channel_id)]);
      dispatch(openModal({key: "openLiveViewPlayer", value: true}))
      setLoadingButton(false);
      setIsPlaying(true);
    })
  }

  const getHistoryVideo = async () => {
    setLoadingButton(true);
    if(cameraOnline) {
      const history= await getHistoryVideoApi({
        selectedHistoryChannel,
        uniqueId: serial,
        playedSeconds: prevHistoryTime,
        alertTimestamp
      }).finally(() => setLoadingButton(false));

      if(history?.channels){
        const {channels, time_info: { available_time, tariffs: { streaming_tariffs, currency } }} = history;
        setHistoryVideo(channels);
        setCurrency(currency);
        setAvailableTime(available_time);
        setStreamingTariffs(streaming_tariffs);
        setIsPlaying(true)
        return channels
      }else {
        closeModal();
      }
    }
  }

  const viewHistoryVideo = async (): Promise<void> => {
    const { beginTime, endTime } = selectedHistoryChannel;
    const startSecond = moment(beginTime).toDate().getSeconds();
    const lastSecond = moment(endTime).toDate().getSeconds();
    const total = lastSecond - startSecond;
    setTotalSeconds(Math.abs(total));
    await getHistoryVideo()
    dispatch(openModal({key: "openHistoryPlayer", value: true}))
    setIsPlaying(true);
  }

  const ChannelsProps: IChannelsProps = {
    setSelectedChannels,
    setChannels,
    serial,
    channels,
    setPrevIdChannels
  }

  const AvailableTimeProps: IAvailableTimeProps = {
    setIsPlaying,
    setAvailableTime,
    setIsControls,
    currentTime,
    availableTime,
    isPlaying,
    intervalContinueStreaming,
  }


  const RenderPlayersProps: IRenderPlayersProps = {
    channels: selectedChannels,
    reminderIntervalTime,
    isPlaying,
    setIsPlaying,
    setCurrentTime,
    updateTime,
    isControls,
    getVideos,
    isLiveView: true,
    playedSecondsRef,
    resetPlayer,
    setAvailableTime,
    intervalContinueStreaming,
    historyVideo,
    setSelectedHistoryChannel
  };

  const BuyMoreModalProps: IBuyMoreModalProps = {
    serial: serial[0],
    closeShowBuyMoreModal: () => dispatch(openModal({key: "openBuyMoreModal", value: false})),
    currency,
    streamingTariffs,
    reset: closeModal,
    setIsControls,
    setIsPlaying,
  }

  return (
    <>

      {(cameraOnline && openHistoryModal) && (
        <Modal className={`${styles.modal} ${!openHistoryPlayer && styles.historyWidth}`}>
          <div className={styles.modalChannels}>
            <div className={styles.buttonClose}>
              <div className={styles.modal_header}>
                <h6 className={styles.modal_header__title}>History</h6>
                {(count > 0 && !openHistoryPlayer) && <span className={styles.modal_header__count}>{count}</span>}
              </div>
              <button className={styles.modal_header__close} onClick={closeModal}>X</button>
            </div>
            {!openHistoryPlayer ? (
              <History
                serial={serial}
                setCount={setCount}
                loadingButton={loadingButton}
                viewHistoryVideo={viewHistoryVideo}
                selectedHistoryChannel={selectedHistoryChannel}
                setSelectedHistoryChannel={setSelectedHistoryChannel}
                setPrevHistoryTime={setPrevHistoryTime}
                prevHistoryTime={prevHistoryTime}
                setAlertTimestamp={setAlertTimestamp}
              />
            ) : (
              <RenderPlayers {...RenderPlayersProps} totalSeconds={totalSeconds} isLoadedVideo={isLoadedVideo} setIsLoaded={setIsLoaded} isLiveView={false}/>
            )
            }
            <AvailableTime {...AvailableTimeProps}/>
          </div>
        </Modal>
      )}

      {(cameraOnline && openLiveViewModal) && (
        <Modal className={styles.modal}>
          <div className={styles.modalChannels}>

            <div className={styles.buttonClose}>
              <button className={styles.modal_header__close_ml} onClick={closeModal}>
                <Fa icon={faTimes} className={styles.modalCamera__icon}/>
              </button>
            </div>

            {openLiveViewPlayer ? (
              <>
                <div className={styles.view}>
                  {selectedChannels.length > 0 && <RenderPlayers {...RenderPlayersProps}/>}
                  <AvailableTime {...AvailableTimeProps}/>
                </div>
              </>
            ) : (
              <>
                {
                  availableTime > 0 && (
                    <>
                      <h3>Select the channels to live stream</h3>

                      <RenderChannels {...ChannelsProps}/>

                      <button
                        className={styles.viewButton}
                        onClick={clickView}
                        disabled={!selectedChannels.length}
                      >
                        {loadingButton ? <Fa icon={faSpinner} spin /> :"View"}
                      </button>
                      <AvailableTime {...AvailableTimeProps}/>
                    </>)
                }
              </>
            )}
          </div>
        </Modal>
      )}

      {/* {
        (cameraOnline && openBuyMoreModal) &&
          <BuyMoreModal {...BuyMoreModalProps} />
      } */}

      {(cameraOnline && openContinueLiveStreamingModal) && (
        <ContinueStreamModal
          closeContinueLiveStreamingModal={() => dispatch(openModal({key: "openContinueLiveStreamingModal", value: false}))}
          setReminderIntervalTime={setReminderIntervalTime}
          setIsPlaying={setIsPlaying}
          getVideos={getVideos}
          setSelectedChannels={setSelectedChannels}
          prevIdChannels={prevIdChannels}
          historyVideo={historyVideo}
          getHistoryVideo={getHistoryVideo}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default DeviceStatuses;
