import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAP } from 'react-google-maps/lib/constants';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';

import { iFullStoreState } from '../../../shared/interfaces';
import { Actions as GmapActions } from '../../../stores/reducers/gmap-reducers';
import { ReactComponent as SettingsIcon } from '../../../assets/svg/settings.svg';
import { ReactComponent as MinusIcon } from '../../../assets/svg/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as MyLocationIcon } from '../../../assets/svg/my_location.svg';
import { ReactComponent as StreetViewIcon } from '../../../assets/svg/street-view.svg';

import { Tooltip } from '@material-ui/core';
import './styles.scss';


const FabMenu = ({controlId = 'menu-open'}: { controlId?: string }) => {
    const handlerChange = (e) => {
        if(e.target.checked === true) {
            (window as any).Beacon('destroy')
        } else {
            (window as any).Beacon('init', '7cba4a7d-b53f-4b4f-a23b-d8e2d6d77035')
        }
    }

    return (
        <nav className="menu">
            <input onChange={handlerChange} type="checkbox" className="menu-open" name="menu-open" id={controlId}/>
            <div className="menu-open-button">
                <label htmlFor={controlId} style={{marginBottom: 0}}>
                <Tooltip title="Settings" placement='top'>
                    <SettingsIcon />
                </Tooltip>
                </label>
            </div>

            {/*<span className="menu-item"><MinusIcon width={16} /></span>*/}
            {/*<span className="menu-item"><PlusIcon width={16} /></span>*/}
            <ZoomButtons />
            {/*<span className="menu-item"><StreetViewIcon width={16} /></span>*/}
            <ButtonToggleMapType />
            {/*<span className="menu-item"><MyLocationIcon width={16} /></span>*/}
            {/*<span className="menu-item"><MyLocationIcon width={16} /></span>*/}
            <ButtonToggleStreetView />
        </nav>
    );
}

export default FabMenu;

const ZoomButtons = () => {
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>(state => state.gmap.mapRef());

    const changeZoom = (step: number) => {
        if (mapRef?.current) {
            mapRef.current.context[MAP].setZoom(mapRef.current.getZoom() + step);
        }
    };
    const incrementZoom = () => changeZoom(1);
    const decrementZoom = () => changeZoom(-1);

    return <>
        <Tooltip title="Zoom Out" placement='top'>
            <span className="menu-item" onClick={decrementZoom}>
                <MinusIcon />
            </span>
        </Tooltip>
        <Tooltip title="Zoom in" placement='top'>
            <span className="menu-item" onClick={incrementZoom}>
                <PlusIcon />
            </span>
        </Tooltip>
    </>;
};

const ButtonToggleMapType = () => {
    const dispatch = useDispatch();
    const isHybrid = useSelector<iFullStoreState, boolean>(s => s.gmap.mapType === google.maps.MapTypeId.HYBRID);
    const title = isHybrid ? 'Map view': 'Satellite view';

    const toggleMapType = () => dispatch(GmapActions.TOGGLE_MAP_TYPE());

    return (
        <Tooltip title={title} placement='top'>
            <span className={`menu-item${isHybrid ? ' pressed' : ''}`} onClick={toggleMapType}>
                <StreetViewIcon />
            </span>
        </Tooltip>
    );
};


const ButtonToggleStreetView = () => {
    const dispatch = useDispatch();
    const isStreetView = useSelector<iFullStoreState, boolean>(s => !!s.gmap.streetViewLocation);

    const toggleStreetView = () => dispatch(GmapActions.TOGGLE_STREET_VIEW());

    return (
        <Tooltip title='Street view' placement='top'>
            <span className={`menu-item${isStreetView ? ' pressed' : ''}`} onClick={toggleStreetView}>
                <MyLocationIcon />
            </span>
        </Tooltip>
    );
};
