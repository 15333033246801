import { iDeviceDetails, iDevicePing, iList } from '../../../shared/interfaces';

export const SET_ALL_DEVICES_INFO = 'SET_ALL_DEVICES_INFO';
export const SET_DEVICE_INFO = 'SET_DEVICE_INFO';
export const UPDATE_DEVICE_LOCATION = 'UPDATE_DEVICE_LOCATION';


export const setAllDevicesInfo = (devicesDetails: iList<iDeviceDetails>) => ({
    type: SET_ALL_DEVICES_INFO,
    payload: {devicesDetails},
});

export const setDeviceInfo = (deviceDetails: iDeviceDetails) => ({
    type: SET_DEVICE_INFO,
    payload: {deviceDetails},
});

export const updateDeviceLocation = (deviceId: string, ping: iDevicePing | null) => ({
    type: UPDATE_DEVICE_LOCATION,
    payload: {deviceId, ping},
});
