import React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {values, pipe, pathOr, uniq, join, filter} from 'ramda';
import {faSpinner} from '@fortawesome/fontawesome-free-solid';

import {BaseComponent} from '../../../shared/BaseComponent';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {iFullStoreState, ItemType, UserAuth, iPerson, iTag, iList, iDeviceDetails, iItemsAllowed, tagObj} from '../../../shared/interfaces';
import {default as C, UserCan} from '../../../shared/constants';
import {SearchGrid, StandardItem} from '../../general';
import {TableSimple} from '../../elements/plain-table';
import {setAllowedSee, removeAllowedSee, watchPersonAllowedSee, readOnlyAccessToggle} from '../../../shared/db/general-db';
import {Fa} from '../../elements/fa';
import {Row} from '../../elements/flex';
import {DevicesDetailsContainer} from '../../../stores/reducers/devicesData';
import Checkbox from '../../elements/Checkbox';
import { toggleUserPermission, userCan } from '../../../shared/db/users-access';
import SearchGridOld from '../../SearchGridOld/SearchGridOld';
import styles from './people-page.module.scss'

type IPropsFromStore = {
    person: iPerson;
    tags: iList<iTag>;
    devicesDetails: DevicesDetailsContainer;
    authUser: UserAuth;
}
type IFullProps = IPropsFromStore & DispatchProp;

interface StateInterface {
    tagFilter;
    deviceFilter;
    allowed?: iItemsAllowed;
    readOnlyAll: boolean;
    readOnlyAccess: object;
    canEditDevicesAndFences: boolean;
}

const itemStyle = (active, compressed=false) => {
    const style = {
        cursor: 'pointer',
        display: 'inline-block',
        margin: compressed ? 4 : 8,
        fontSize: compressed ? 10 : 'initial',
        color: active ? '#fff' : C.primaryColor,
    } as any;

    if (active) style.backgroundColor = C.primaryColor;

    return style;
};

export default connect((s: iFullStoreState, o: any): IPropsFromStore => ({
    person: s.general.people[o.match.params.id],
    tags: s.general.tags,
    devicesDetails: s.devicesData.devicesDetails,
    authUser: s.auth.user!,
}))
(class PersonCanSee extends BaseComponent<IFullProps, StateInterface> {
    private canceller;
    constructor(props) {
        super(props);
        this.state = {
            tagFilter: '',
            deviceFilter: '',
            readOnlyAll: false,
            readOnlyAccess: {},
            canEditDevicesAndFences: false,
        };
    }

    _populateState = (allowed: iItemsAllowed) => {
        this.setState({allowed});
    }

    async componentDidMount() {
        const {person} = this.fullProps;
        this.canceller = watchPersonAllowedSee(person.id, (allowed) => this.setState({allowed: allowed || {}}, () => {
            const devicesAccess = allowed.device ? Object.keys(allowed.device).reduce((acc, rec) => {
                return {...acc, [rec]: allowed.device[rec].readOnly || false};
            }, {}) : {};

            this.setState({readOnlyAccess: {...devicesAccess}});
        }));
        // const canEditDevices = await userCan(person.id, UserCan.EDIT_DEVICE);
        // const canEditFences = await userCan(person.id, UserCan.EDIT_FENCES);
        // this.setState({ canEditDevicesAndFences: canEditDevices && canEditFences });
    }

    componentWillUnmount() {
        if (this.canceller) this.canceller();
    }

    _toggleTagFlag = async tagId => {
        const { tags, person } = this.fullProps;

        const isRemove = pathOr(false, [tagId, 'instances', 'allowed-see', 'person', person.id])(tags)

        await (isRemove ? removeAllowedSee : setAllowedSee)(this.fullProps.authUser)(
            this.fullProps.person.id,
            ItemType.tag,
            tagId
        )
        fetch('https://api.mastrack-map.sapient.pro/api-firebase/revoke', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({uid: this.fullProps.person.id})
        });
    }

    _toggleDeviceFlag = async (deviceId, tagId = null) => {
        const {allowed} = this.state;

        const isRemove = pathOr(false, ['device', deviceId, deviceId])(allowed);

        await (isRemove ? removeAllowedSee : setAllowedSee)(this.fullProps.authUser)(
            this.fullProps.person.id,
            ItemType.device,
            deviceId,
        );
        fetch('https://api.mastrack-map.sapient.pro/api-firebase/revoke', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({uid: this.fullProps.person.id})
        });
    }

    _filterchange = (key: keyof StateInterface) => (str) => this.setState({[key]: str} as any)

    static helpMsg = `
        Manage which devices a user can view. Adding a tag will allow this person to see all devices that have the associated tag.
        To prevent a user from seeing a device make sure device overrides and appropriate tags are removed. The complete list
        of seeable devices is at the very bottom.
     `;

    _source = (deviceId) => pipe(
        pathOr({}, ['device', deviceId]),
        values,
        uniq,
        filter((item) => typeof item !== 'boolean'),
        join(' & ')
    )(this.state.allowed)

    // toggleCanEditDevicesAndFences = async () => {
    //     const { person } = this.fullProps;
    //     const { canEditDevicesAndFences } = this.state;
    //     const canEditDevicesRes = await toggleUserPermission(person.id, UserCan.EDIT_DEVICE, !canEditDevicesAndFences);
    //     const canEditFencesRes = await toggleUserPermission(person.id, UserCan.EDIT_FENCES, !canEditDevicesAndFences);
    //     this.setState({ canEditDevicesAndFences: canEditDevicesRes && canEditFencesRes });
    // }


    debugRender = () => {
        const {person, devicesDetails, tags} = this.props;
        const {deviceFilter, allowed = false, readOnlyAccess, tagFilter} = this.state;
        const devicesIds = devicesDetails.keySeq();

        const deviceItems = devicesDetails
            .map(
                (device, deviceId) =>
                    <StandardItem
                        key={deviceId}
                        style={itemStyle(pathOr(false, ['device', deviceId, deviceId])(allowed))}
                        displayName={device.name}
                        itemId={deviceId}
                    />,
            ).valueSeq().toArray();

        const tagItems = values(tags).map(tag =>
            <StandardItem 
                style={itemStyle(pathOr(false, ['instances', 'allowed-see', 'person', person.id])(tag))} 
                key={tag.details.id} 
                displayName={tag.details.name} 
                itemId={tag.details.id}
        />)

        return (
            <DashboardBlock>
                <ModalHeader title={person ? person.displayName : ''} help={PersonCanSee.helpMsg} />

                {!allowed ? <Row justify="center"><Fa icon={faSpinner} spin style={{fontSize: 28}} /></Row> : <>
                    <SearchGridOld
                        style={{marginTop: 10, maxWidth: 350}}
                        textual={true}
                        list={tagItems}
                        filterStr={tagFilter}
                        placeholder="Search Tags..."
                        perPage={9}
                        keyClicked={this._toggleTagFlag}
                        filterChange={this._filterchange('tagFilter')}
                    />
                    <SearchGridOld
                        style={{marginTop: 10, maxWidth: 350}}
                        textual={true}
                        list={deviceItems}
                        filterStr={deviceFilter}
                        placeholder="Search Devices..."
                        perPage={9}
                        keyClicked={this._toggleDeviceFlag}
                        filterChange={this._filterchange('deviceFilter')}
                    />
                    {/* <div><label><input type="checkbox" onChange={this.toggleCanEditDevicesAndFences} checked={canEditDevicesAndFences}/> Allow to edit devices and fences</label></div> */}
                    {!!allowed.device && <>
                        <div className={styles.VisibleDevicesTableHead}>
                            <p style={{marginTop: 10}}>Visible list</p>
                        </div>
                        <TableSimple sm dark striped headers={['Label', 'Source', 'Access']}>
                            {Object.keys(allowed.device || {})
                                .filter((deviceId) => devicesIds.includes(deviceId))
                                .map((deviceId) => devicesDetails.get(deviceId)!)
                                .map((device: iDeviceDetails) => (
                                    <tr key={device.id}>
                                        <td style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: 170
                                        }}>{device.name}</td>
                                        <td>{this._source(device.id)}</td>
                                        <td>
                                            <Checkbox
                                                styles={{marginTop: 6}}
                                                labelStyles={{fontSize: 16, color: "white"}}
                                                inputId={device.id}
                                                onChange={() => readOnlyAccessToggle(this.fullProps.authUser)(this.fullProps.person.id, device.id, !readOnlyAccess?.[device.id])}
                                                checked={readOnlyAccess?.[device.id]} label='Read only'/>
                                        </td>
                                    </tr>))
                            }
                        </TableSimple>
                    </>}

                </>}
            </DashboardBlock>
        );
    }
});
