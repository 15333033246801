import React from 'react';
import { withBemMod } from '@bem-react/core';

import { cnTabs } from '../index';
import './Tabs_styled_underlined.scss';

export interface ITabsStyledUnderlinedProps {
    styled?: 'underlined';
}

export const withTabsStyledUnderlined = withBemMod<ITabsStyledUnderlinedProps>(
    cnTabs(),
    {styled: 'underlined'},
    (Component) => ({styled, ...props}) => <Component {...props} />,
);
