import React from 'react';
import { withBemMod } from '@bem-react/core';

import { IButtonProps, cnButton } from '../index';
import './Button_variant_contained.scss';

export interface IButtonVariantContainedProps {
    variant?: 'contained';
}

export const withButtonVariantContained = withBemMod<IButtonVariantContainedProps, IButtonProps>(
    cnButton(),
    {variant: 'contained'},
    (Button) => ({variant, ...props}) => <Button {...props} />,
);
