import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {always, assoc, assocPath, dissocPath, equals, isEmpty, keys, map, omit, pathOr, pipe, prop, toPairs, values, when} from 'ramda';
import {faPlusCircle} from '@fortawesome/fontawesome-free-solid';
import {faTimesCircle} from '@fortawesome/fontawesome-free-regular';

import {history} from '../../../stores/store';
import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {targetVal} from '../../../shared/helpers';
import {MinimalTile} from '../../general';
import C, {ACL, UserCan} from '../../../shared/constants';
import ModalActions from '../../menus/modal/modal-actions';
import {FenceBodyLayout} from '../../fence/tab-bodies/BodyLayout/FenceBodyLayout/FenceBodyLayout';
import ActionRow from '../../fence/tab-bodies/ActionRow/ActionRow';
import {iExtraInfo, iFenceDetails, iFullStoreState, iList, ItemType, UserAuth} from '../../../shared/interfaces';
import * as selectors from '../../../shared/db/tags-labels-selectors';
import * as labelsDb from '../../../shared/db/extra-info-db';
import {BaseComponent} from '../../../shared/BaseComponent';
import {Fa} from '../../elements/fa';
import {Col, Row} from '../../elements/flex';
import {Overlay} from '../../menus/modal/modal';

// todo. sort out the type differences a bit.
// the type is the thing we are tagging.
// the itemtype is optionally added for fences as the fence type -> fences/{type}/ so type will be fence but itemType might be tag or device

type ILabelRowProps = {
    name: string;
    value: string;
    readOnly?: boolean;
    remove: () => void;
    onChange: (...any) => void;
}
const LabelRow = ({name, value, onChange, remove, readOnly}: ILabelRowProps) => (
    <Row style={{borderBottom: `1px solid ${C.mediumGray}`}}>
        <span style={{width: '50%'}}>{name}</span>
        <input type="text" disabled={readOnly} className="mas-input"
            style={{...C.inputCss, margin: '5px 8px', width: '50%'}}
            onChange={pipe(targetVal, onChange)}
            value={value}
        />
        {readOnly ? null : <Fa icon={faTimesCircle} onClick={remove} />}
    </Row>
);

type IProps = {itemType?: ItemType} & RouteComponentProps<{ itemId: string; isFence: string; type: ItemType}>;

type IPropsFromStore = {
    item: {'extra-info': iList<string>};
    userCanDo?: Array<string>;
    fence: iFenceDetails | false;
    fenceId?: string|false;
    allLabels: iList<iExtraInfo>;
    authUser: UserAuth;
};

type IFullProps = IProps & IPropsFromStore
interface iState {
    toSave: iList<string>;
    newOverlay?: boolean;
}

const mapStateToProps = (state: iFullStoreState, ownProps): IPropsFromStore => ({
    item: selectors.getItemSelector(state, ownProps.match.params),
    fenceId: !!ownProps.match.params.isFence ? ownProps.match.params.itemId : false,
    fence: pathOr(false,['general', 'fences', ownProps.itemType, ownProps.match.params.itemId, 'details'], state),
    userCanDo: state.auth.user!.acl?.can,
    allLabels: state.general.extraInfo,
    authUser: state.auth.user!,
});

class LabelItemComponent extends BaseComponent<IFullProps, iState> {
    constructor(props) {
        super(props);

        this.state = {
            toSave: this.props.item['extra-info'] || {},
        };
    }

    _saveChanges = async () => {
        const {item, itemType, fenceId} = this.props;
        const {isFence, type, itemId} = this.props.match.params;
        console.log('this.state.toSave', type, this.state.toSave);

        // todo: this his hacky - look into replacing this somehow!!!!!!!!!!!!!!!
        if (!!isFence) {
            await labelsDb.updateItemInfoValue(this.props.authUser)(type, `info/${fenceId}`, this.state.toSave);
        } else {
            await labelsDb.updateItemInfoValue(this.props.authUser)(type, itemId, this.state.toSave);
        }

    }

    _newRow = () => this.setState(assoc('newOverlay', true))

    _setLabel = (idx, value: string) => this.setState(assocPath(['toSave', idx], value))

    debugRender = () => {
        const {fence, allLabels, userCanDo, item} = this.props;
        const {type, itemId, isFence} = this.props.match.params;
        const {newOverlay = false, toSave} = this.state;

        const DynamicLayout: any = !!isFence ? FenceBodyLayout : DashboardBlock;
        const DynamicHeader = !!isFence ? <div><ActionRow title={(fence || {} as any).name} canBack actions={[]} /></div> : <ModalHeader title="Add Extra Info" />;

        const needsSave = !equals(this.state.toSave, item['extra-info'] || {});

        const overlay = !newOverlay ?
            null :
            <LabelSelectorOverlay
                onSelected={(key) => this.setState(pipe(assocPath(['toSave', key], ''), assoc('newOverlay', false)))}
                possible={omit(keys(toSave), allLabels)}
                close={this.stateSetter({newOverlay: false})}
            />;

        return (
            <DynamicLayout
                mapOnly={!itemId || !type}
                overlay={overlay}
                modalActions={!needsSave ? false : <ModalActions isFence hidden={!needsSave} buttons={[
                    {click: history.goBack, title: 'Cancel'},
                    {click: this._saveChanges, title: 'Update', primary: true},
                ]}/>}
            >
                {DynamicHeader}

                <div style={!!isFence ? {padding: 8} : { } }>
                    {!isFence ? null :
                        <MinimalTile>
                            Change Labels
                        </MinimalTile>
                    }

                    <MinimalTile>
                        {toPairs(toSave).filter(([idx]) => idx in allLabels).map(([idx, value]) =>
                            <LabelRow
                                key={idx}
                                readOnly={allLabels[idx]?.details?.readOnly}
                                name={allLabels[idx].details.name}
                                value={value}
                                remove={() => this.setState(dissocPath(['toSave', idx]))}
                                onChange={(v) => {
                                    this.setState(assocPath(['toSave', idx], v), this._saveChanges.bind(this));
                                }}
                            />
                        )}
                        <Row style={{paddingTop: 8}}>
                            {!ACL.check(UserCan.LABEL_THINGS, userCanDo) ? null :
                                <Fa icon={faPlusCircle} style={{color: C.primaryColor, fontSize: 17}} onClick={this._newRow} />
                            }
                            {!ACL.check(UserCan.CREATE_LABEL, userCanDo) ? null :
                                needsSave ?
                                    <button disabled style={{cursor: 'not-allowed'}} title="Save changes before adding new label" className="btn btn-primary btn-xs">Manage Labels</button> :
                                    <Link className="btn btn-primary btn-xs" to="/extra-info" >New Label</Link>
                            }
                        </Row>
                    </MinimalTile>
                </div>

                <div style={{paddingBottom: needsSave ? 35 : 0}}>&nbsp;</div>

            </DynamicLayout>
        );
    }
}

export default connect(mapStateToProps)(LabelItemComponent);

interface iLabelSelectorOverlayProps {
    possible: iList<iExtraInfo>;
    onSelected: (id: string) => any;
    close: () => any;
}

const LabelSelectorOverlay = ({possible, onSelected, close}: iLabelSelectorOverlayProps) => (<Overlay close={close} header="Choose a label">
    <Col>
        {pipe(
            (x) => x,
            map(prop('details')),
            map((l: {id; name}) => (
                <button
                    key={l.id}
                    className="btn btn-outline-primary"
                    style={{marginBottom: 8}}
                    onClick={pipe(always(l.id), onSelected)
                    }>{l.name}
                </button>)
            ) as any,
            values,
            when(isEmpty, always(<p>No more labels found</p>)) as any
        )(possible)}
    </Col>
</Overlay>);
