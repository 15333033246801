import React from "react";
import {BaseComponent} from "../../../../../shared/BaseComponent";
import {containerCss} from "./FenceBodyContainer.helpers";

class FenceBodyContainer extends BaseComponent<any, any> {
    debugRender = (): JSX.Element => {
        return <div style={containerCss}>{this.props.children}</div>;
    }
}

export default FenceBodyContainer;