import { createSelector } from "reselect";
import { localStorage } from "../../../shared/storage";
import { ACTIONS } from "./action.billing";

const initialState = {
  loading: false,
  orders: [],
  invoices: [],
  units: [],
  brainTreeToken: "",
  plaidToken: "",
  token: localStorage.get("billing-auth", ''),
  loadingCards: false,
  creditCard: [],
  bankAccount: [],
  wallet: {}
};

export const billing = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DELETE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: state.bankAccount.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case ACTIONS.UPDATE_UNITS_NAME:
      const index = state.units.findIndex((i) => i.id === action.payload.id);
      const newUnits = [...state.units];
      newUnits[index].name = action.payload.value;
      return {
        ...state,
        units: newUnits,
      };
    case ACTIONS.USER_BILLING:
      return {
        ...state,
        token: action.payload.token,
      };
    case ACTIONS.ADD_CREDIT_CARD:
      return {
        ...state,
        // loadingCard: false,
        creditCard: action.payload.newCard,
      };
    case ACTIONS.ADD_BANK_ACCOUNT:
      return {
        ...state,
        // loadingBankAcc: false,
        bankAccount: action.payload.paymentMethod.filter(card => card.processor === 'Stripe')
      };
    case ACTIONS.GET_CARDS:
      return {
        ...state,
        creditCard: action.payload.cards.filter((el) => el.processor === "Braintree"),
        bankAccount: action.payload.cards.filter((el) => el.processor === "Stripe"),
        loadingCards: false,
      };
    case ACTIONS.BRAINTREE_TOKEN:
      return {
        ...state,
        brainTreeToken: action.payload.brainTreeToken.message,
      };
    case ACTIONS.PLAID_TOKEN:
      return {
        ...state,
        plaidToken: action.payload.plaidToken.message,
      };
    case ACTIONS.SET_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
      };
    case ACTIONS.SET_INVOICES:
      return {
        ...state,
        invoices: action.payload.invoices,
      };
    case ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ACTIONS.LOADING_CARDS:
      return {
        ...state,
        loadingCards: action.payload,
      };
    case ACTIONS.SET_UNITS:
      return {
        ...state,
        units: action.payload.units,
      };
    default:
      return state;
  }
};

// const billingState = state => state.billing

// export const billingUnitsSelector = createSelector(billingState, (billing) =>
//   billing.units,
// );

export type BillingState = ReturnType<typeof billing>;
