import React from 'react';
import { pipe, path, replace, sum } from 'ramda';

import { iTrip, iDevicePing, iTripAlertActivity, iAlertActivity } from '../../../../../shared/interfaces';
import { isIncidentSafety } from '../../../../../shared/helpers';

export const getSpeed = (row: iDevicePing|iTrip) => (row as iTrip).maxSpeed || (row as iDevicePing).speed || 0;

export type SafetyPercent =  'static'|'travel'|'summary';

export const getSafetyPercent = (type: SafetyPercent) => (row: iDevicePing|iTrip) => {
    return ((type == 'travel' || type == 'summary')
        ? (row as iTrip).safetyPercent
        : countSafety(row)  // 0 or 1
    ) || 0;
}

export const countSafety = pipe(
    // grap the activity obj
    path(['alertActivity']),

    // make them filtered and countable
    (x:iTripAlertActivity|iAlertActivity) => {
        return Object.keys(x)
            .map(replace(/^has/, ''))                       // hasharsh into harsh
            .filter(isIncidentSafety)                       // is a safety type
            .filter(i => x[`has${i}`])                      // is set to true
            .map((i): number => ((x as any)[`${i}Count`] || 1))      // make them just the count
    },

    // sum the array of incident counts
    sum
)
