import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {iDeviceDetails, iDevicePing, iFullStoreState, iLocation, Units} from "../../shared/interfaces";
import Marker from 'react-google-maps/lib/components/Marker';
import {useDispatch, useSelector} from "react-redux";
import {FaVector} from "../elements/fa";
import GoogleMap from "react-google-maps/lib/components/GoogleMap";
import {icoSize} from "../../shared/helpers";
import Polyline from "react-google-maps/lib/components/Polyline";
import {Actions as MapActions} from "../../stores/reducers/gmap-reducers";
import ReplyComponent from "../elements/ReplyComponent";
import {array} from "yup";

type IReplyProps = {
    location: Array<iLocation>;
    deviceId: string;
    polylineOptions: Object;
    routeDetails: object[];
}

export const Reply = (props: IReplyProps) => {
    const dispatch = useDispatch();
    const pathInterval = useRef<NodeJS.Timeout>();
    const {location, deviceId, polylineOptions,routeDetails} = props;
    const [currentPosition, setCurrentPosition] = useState(location[0]);
    const [locations, setLocations] = useState([]);
    const [startPlayBtnAttr, setStartPlayBtnAttr] = useState(false);
    const [resetPathBtnAttr, setResetPathBtnAttr] = useState(true);
    const [currentPositionCounter, setCurrentPositionCounter] = useState(0);
    const [currentRouteNumber, setCurrentRouteNumber] = useState(1);
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>((state) => state.gmap.mapRef());
    const size = icoSize(mapRef.current.getZoom());
    const replayPath = useSelector<iFullStoreState, boolean>((s) => s.gmap.replayPath);
    const [shouldMoveDevice, setShouldMoveDevice] = useState(true);
    const [currentDeviceColor, setCurrentDeviceColor] = useState('');
    const currentDevice = useSelector<iFullStoreState, iDeviceDetails>(
        (s) => s.devicesData.devicesDetails.getIn([`${deviceId}`])
    );
    const [pointsArrayMultipleState, setPointsArrayMultipleState] = useState([]);

    const [activeRoute, updateActiveRoute] = useState(routeDetails[0]['points']);
    const activeRouteRef = useRef(activeRoute);

    const setActiveRoute = state => {
        updateActiveRoute(state);
        activeRouteRef.current = state;
    }

    const activeCountNumber = useRef(0);

    const setActiveCountNumber = state => {
        setCurrentPositionCounter(currentPositionCounter);
        activeCountNumber.current = state;
    }

    const currentRouteIndexRef = useRef(currentRouteNumber);

    const setCurrentRouteIndexRef = state => {
        setCurrentRouteNumber(state);
        currentRouteIndexRef.current = state;
    }

    const currentDeviceColorRef = useRef(currentDeviceColor);

    const setCurrentDeviceColorRef = state => {
        setCurrentDeviceColor(state);
        currentDeviceColorRef.current = state;
    }

    const {fa, url} = currentDevice.icon || {} as any;

    const toggleReplayPath = () => dispatch(MapActions.TOGGLE_REPLAY_PATH());

    const resetToStart = () => {
        setActiveRoute(routeDetails[0]['points'])
        setPointsArrayMultipleState([]);
        setActiveCountNumber(0);
        setCurrentPosition(routeDetails[0]['points'][0]);
        setLocations([]);
    }

    let changeDevicePosition = (i) => {
            let finishedArrayIndex = 0;
            pathInterval.current = setInterval(() => {
                const isLastPoint = activeRouteRef.current[activeCountNumber.current]['isLast'];
                const shouldPlayNextRoute = isLastPoint && routeDetails.length > 1 && currentRouteIndexRef.current < routeDetails.length;
                const shouldTakeNextPoint = i < activeRouteRef.current.length;
                if (shouldPlayNextRoute) {
                    setActiveRoute(routeDetails[`${currentRouteIndexRef.current}`]['points']);
                    setCurrentRouteIndexRef(currentRouteIndexRef.current + 1);
                    setPointsArrayMultipleState(prevState => [...prevState, routeDetails[finishedArrayIndex++]['points']])
                    setActiveCountNumber(0);

                    i = 0;
                }
                else if (shouldTakeNextPoint) {
                    if (i === 0) setCurrentDeviceColorRef(routeDetails[finishedArrayIndex]['color']);
                    setActiveCountNumber(i);
                    setCurrentPosition(activeRouteRef.current[i]);
                    i++;
                }
                else {
                    clearInterval(pathInterval.current);
                    setResetPathBtnAttr(false);
                    setActiveCountNumber(0);
                    toggleReplayPath();
                }

            }, 300);
    }

    const pausePlay = () => {
        setShouldMoveDevice(!shouldMoveDevice);
        clearInterval(pathInterval.current);
        const isNotLastPoint = currentPositionCounter !== activeRouteRef.current.length-1;
        isNotLastPoint && setStartPlayBtnAttr(false);
    }

    const startPlay = () => {
        const isFirstPoint = activeCountNumber.current === 0;
        isFirstPoint ? toggleReplayPath() : setCurrentPositionCounter(activeCountNumber.current);
        changeDevicePosition(activeCountNumber.current);
        setStartPlayBtnAttr(true);
        setResetPathBtnAttr(true);
    }

    const resetPath = () => {
        resetToStart();
        setCurrentRouteIndexRef(1);
        setResetPathBtnAttr(true);
        setStartPlayBtnAttr(false);
    }

    useEffect(() => {
        const isNotLastPoint = currentPositionCounter < activeRoute.length;
        if (isNotLastPoint) {
            setLocations([...activeRoute.slice(0, activeCountNumber.current), currentPosition])
        }
    }, [activeCountNumber.current])

    useEffect(() => {
        return () => activeCountNumber.current > 0 && toggleReplayPath()
    }, [])

    const mutatedPolylineOptions = {...polylineOptions, strokeColor: currentDeviceColor }

    return <>
        <ReplyComponent
            startPlayHandler={startPlay}
            resetPathHandler={resetPath}
            isDisabledResetBtn={resetPathBtnAttr}
            isDisabledStartBtn={startPlayBtnAttr}
            pausePlayHandler={pausePlay}
            shouldMoveDevice={shouldMoveDevice}
        />
        <Marker
            position={currentPosition}
            title={'Car'}
            icon={{
                [fa ? 'path' : 'url']: url || (!fa ? '' : FaVector(fa)),

                size: new google.maps.Size(size, size),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(size / 2, size / 2),
                scaledSize: new google.maps.Size(size, size),

                ...(url ? {} : {
                strokeColor: '#000',
                fillColor: currentDevice.color || '#000',
                fillOpacity: .95,
                anchor: new google.maps.Point(250, 340),
                scale: .05,

            }),
            } as any}
        />
        {replayPath && <Polyline path={locations} options={mutatedPolylineOptions}/>}
        {
            pointsArrayMultipleState.map(item => <Polyline path={item} options={polylineOptions}/>)
        }
    </>;
}
