import { createSelectorCreator, defaultMemoize } from 'reselect';
import { is, isImmutable } from 'immutable';
import { equals } from 'ramda';

import { idValArr, extractDevice } from '../helpers';
import { iDevicePing, iFullStoreState, iIcon, iList, iPerson, iTag, ItemType } from '../interfaces';
import { DevicesDetailsContainer, DevicesLastPingContainer } from '../../stores/reducers/devicesData';

function isEquals (first: any, second: any): boolean {
    if (isImmutable(first) && isImmutable(second)) {
        return is(first, second);
    } else if (!isImmutable(first) && !isImmutable(second)) {
        return equals(first, second);
    }
    return false;
}

const createImmutableEqualSelector = createSelectorCreator(defaultMemoize, isEquals);

const getFavorites = (state: iFullStoreState) => state.general.favorites;

const getDevices = (state: iFullStoreState) => state.devicesData.devicesDetails;
const getDevicesLastPing = (state: iFullStoreState) => state.devicesData.devicesLastPing;

const getPeople = (state: iFullStoreState) => state.general.people;

export interface iFav {name: string, photo?: string, color?: string, icon?: iIcon, link: string, isRounded: boolean, type: string, itemId: string, lastPing?: iDevicePing, visible?: boolean}

const favoriteExists = (type: ItemType, itemId: string, devicesDetails: DevicesDetailsContainer, people: iList<iPerson>, tags: iList<iTag>): boolean => {
    switch(type) {
        case ItemType.device : return devicesDetails.has(itemId);
        case ItemType.person : return itemId in people;
        case ItemType.tag : return itemId in tags;
        default : return false;
    }
}
const extractTypeMeta = (type: ItemType, itemId: string, devicesDetails: DevicesDetailsContainer, people: iList<iPerson>, tags: iList<iTag>, devicesLastPing?: DevicesLastPingContainer): iFav => {
    const device = extractDevice(devicesDetails, itemId);

    switch (type) {
        case ItemType.device : return {
            itemId, type: type.toString(),
            name: device.name,
            icon: device.icon,
            color: device.color,
            link: `/device/${device.id}`,
            isRounded: false,
            ...(devicesLastPing && device.externalData ? {lastPing: devicesLastPing.get(itemId)} : {}),
            visible: device.visible
        };

        case ItemType.person : return {
            itemId, type: type.toString(),
            name: people[itemId].displayName,
            photo: people[itemId].photoURL,
            link: `/person/${people[itemId].id}`,
            isRounded: true,
            ...(devicesLastPing && people[itemId].hasDevice ? {lastPing: devicesLastPing.get(people[itemId].hasDevice.deviceId)} : {} ),
        };

        case ItemType.tag : return {
            itemId, type: type.toString(),
            name: tags[itemId].details.name,
            icon: tags[itemId].details.icon,
            color: tags[itemId].details.color,
            link: `/tag/${tags[itemId].details.id}`,
            isRounded: true,
        }

        default : throw new Error('Unrecognized type for favorite');
    }
}

export const getTags = (s: iFullStoreState) => s.general.tags;

export const getFavoriteLinksSelector = (needLastPing = false) => createImmutableEqualSelector(
    getFavorites, getDevices, getPeople, getTags, needLastPing ? getDevicesLastPing : () => undefined,
    (fav, devicesDetails, people, tags, devicesLastPing) => {
        // first lest flatten them into single array
        let favs = [];

        idValArr(fav as any).forEach(({id: type, val}) => {
            const items = Object.keys(val);
            items.forEach(itemId => favs.push({
                type, itemId, sort: parseInt(val[itemId])
            }))
        })

        const sorter = (a, b) => a - b;

        // now tack on the meta data for dipslay and links
        return favs
            .filter(f => favoriteExists(f.type, f.itemId, devicesDetails, people, tags))
            .map(f => ({sort: f.sort, ...extractTypeMeta(f.type, f.itemId, devicesDetails, people, tags, devicesLastPing)}))
            .sort((a, b) => sorter(a.sort, b.sort));
    })
