import {DevicesDetailsContainer} from "../../stores/reducers/devicesData";
import {iReportDetails} from "../../stores/reducers/report-reducers";
import {iList, iPerson, iTag} from "../../shared/interfaces";

export enum IReportFiltersLabelsType {
    ALERTS,
    LABELS,
    TAGS,
    DEVICES,
    PERSONS,
}

export type IReportFiltersLabelsProps = {
    type: IReportFiltersLabelsType
    render: ({item}: { item: string }) => {},
    defaultLabel: string
}
export type IReportFiltersLabelsPropsFromStore = {
    devicesDetails: DevicesDetailsContainer;
    reportDetails: iReportDetails,
    people: iList<iPerson>;
    tags: iList<iTag>;
}