import React, { CSSProperties as css } from 'react';
import { faPlus } from '@fortawesome/fontawesome-free-solid';

import C from '../../shared/constants';
import { addTripLabel, addScheduleLabel } from '../../shared/db/general-db';
import { BaseComponent } from '../../shared/BaseComponent';
import { Fa } from '../elements/fa';
import { UserAuth } from '../../shared/interfaces';
import { connect } from '../../shared/helpers';


interface iLabelProps {
    type: 'trip'|'person'|'schedule',
    items: {id: string, content: any}[],
    canAdd?: boolean,
    style?: css,
    choose: (id) => any
}

@connect((s, o: object) => ({user: s.auth.user}))
export class LabelChooser extends BaseComponent<iLabelProps, {adding: boolean}, {user: UserAuth}> {
    state = { adding: false }

    private addLabel = async newLabel => {
        if (!newLabel) return;

        console.log('here', this.props);
        switch (this.props.type) {
            case 'trip' : await addTripLabel(this.fullProps.user)(newLabel); break;
            case 'schedule': await addScheduleLabel(this.fullProps.user)(newLabel); break;
        }

        this.setState({adding: false})
    }

    __title = {
        trip: 'Trip',
        person: 'Person',
        schedule: 'Schedule'
    }[this.props.type]

    input;
    debugRender = () => {
        const { items, style={}, choose, canAdd } = this.props;
        const { adding } = this.state;

        return (
            <div style={{...boxStyle, ...style}}>
                {adding
                    ? <div style={{padding: 10}}>
                        <strong>Enter New {this.__title} Label</strong>
                        <div>
                            <input type="text" className="mas-input" style={C.inputCss} ref={x => this.input = x} />
                            <button onClick={_ => this.addLabel(this.input.value)} className="btn btn-sm btn-primary">Add</button>
                        </div>
                     </div>
                    : <div>
                        {items.map(({ id, content }) => <div key={id} onClick={_ => choose(id)} className="mas-row-hover" style={rowStyle}>{content}</div>)}
                        {!canAdd ? null :
                            <div onClick={_ => this.setState({ adding: true })} className="mas-row-hover" style={rowStyle}>Add New <Fa icon={faPlus} /></div>
                        }
                     </div>
                }
            </div>
        )
    }
}

const rowStyle = {
    cursor: 'pointer',
    padding: 5,
} as any

const boxStyle: css = {
    border: `1px solid ${C.primaryColor}`,
    backgroundColor: '#fff'
}