import React, {useState} from 'react';
import {useForm} from 'react-hook-form';

import classNames from 'classnames';
import InputBox from '../../elements/InputBox';
import EmailIcon from '../../SVG-components/email_logo';
import BackIcon from '../../SVG-components/left_chevron';

type SignFields = { username: string; password: string };

const RequestPasswordReset = ({goBack, setResponse}): JSX.Element => {

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const {register, handleSubmit, errors} = useForm<SignFields>( );

    const onSubmit = ()=> {
        setLoading(true);
        setResponse(null);
        fetch(`${process.env.REACT_APP_API_URL}/site/app-request-password-reset`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({PasswordResetRequestForm: {email: inputValue}}),
        })
            .then(async(response) => {
                const data = await response.json();
                setResponse(data);
                setShowButton(false);
            })
            .catch((res) => console.log('ERROR:',res));

    };

    const handleChange = (event): void => {
        setInputValue(event.target.value);
    };

    const btnClass = classNames({
        btn: true,
    });

    return <form onSubmit={handleSubmit(onSubmit)}>
        <InputBox
            label="Email"
            name="email"
            register={register}
            error={!!errors.username}
            icon={EmailIcon}
            handleChange={handleChange}
        />
        <div
            style={{fontSize: '11px', cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            onClick={() => goBack()}
        >
            <BackIcon/>
            Go back
        </div>

        {showButton && <div className="loginBtns">
            <button className={btnClass} type="submit">{loading ? 'Wait please...' : 'Submit'}</button>
        </div>}

    </form>;
};

export default RequestPasswordReset;
