import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ItemType, iFullStoreState } from './interfaces';
import * as favoritesdb from './db/favorites-db';
import { default as C } from './constants';
import { BaseComponent } from './BaseComponent';
import { FaFav } from '../components/elements/fa';


interface iProps {
    type: ItemType,
    id: string,
    link: string,
    children: any,

    uid?: string,
    style?: React.CSSProperties,
    typeFavs?: {[id: string]: true},
    strikeBottom?: boolean,
    isFav?: boolean
}

const mapDeviceTileProps = (state: iFullStoreState, ownprops: iProps) => {
    const favorites = (state.general.favorites || {} as any);

    let typeFavs = favorites[ownprops.type] || {};

    return {
        ...ownprops,
        isFav: typeFavs[ownprops.id],
        typeFavs,
        uid: state.auth.user?.uid,
    }
}

@connect(mapDeviceTileProps)
export default class ItemLaunchComponent extends BaseComponent<iProps, any> {

    toggleFav = async (id: string, on: boolean) => {
        await favoritesdb.toggleFavorite(this.props.type, this.props.uid, id, on);
    }

    debugRender = () => {
        const { type, id, uid, link, style={}, strikeBottom = true, isFav, children } = this.props;

        const bottomLine = strikeBottom
            ? {borderBottom: `1px solid ${C.lightGray}`}
            : {};

        return (
            <div style={{display: 'flex', paddingTop: 10, paddingBottom: 10, ...bottomLine, ...style}}>
                <div style={{padding: '0 8px', color: C.yellow, cursor: 'pointer'}}>
                    <FaFav isFav={isFav} onClick={_ => favoritesdb.toggleFavorite(type, uid, id, !isFav)} />
                </div>
                <Link to={link} style={{textDecoration: 'none', color: C.primaryText}}>
                    <div style={{display: 'flex', flexDirection: 'column', borderLeft: `1px solid ${C.mediumGray}`, paddingLeft: 8}}>
                        {children}
                    </div>
                </Link>
            </div>
        )

    }

}
