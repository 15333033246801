import React, {useEffect, useState} from 'react';

import {
    alertDotIcon,
    friendlySpeed,
    isDev,
    countDateWithUserAndTimezoneOffset,
    clearTimezone,
} from '../../shared/helpers';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import {iDeviceDetails, iDevicePing, iFullStoreState, Units} from '../../shared/interfaces';
import { Point } from './oval-polygon';
import { Col } from '../elements/flex';
import { Fa } from '../elements/fa';
import { faSpinner } from "@fortawesome/fontawesome-free-solid";
import { useRedux } from '../../states/redux-state';
import { UserCan } from "../../shared/constants";
import { clientStore, FieldValue } from "../../shared/firebase";
import { useDispatch, useSelector } from "react-redux";
import { checkStatusCamera, dayChooseByTimestamp } from '../../api/openApi';
import { IReducerSelectedPoint } from "../../stores/reducers/selectedPoint";
import { hidePoint, showPoint } from "../../stores/reducers/devicesTripsPoints/AC";
import styles from './PointDetails.module.scss';
import {getTimezoneByValue} from "../pages/devices/DeviceTabPageEdit/Timezones/Timezones.helper";
import { cameraAvailableTime, openModal, statusCameraOnline, takeDeviceInfo } from '../../stores/reducers/videoCameras';
import { iReducersState } from '../../stores/reducers';


export const shouldShowPoint = ({showPOI, showDots}) => (point: iDevicePing) => {
    const ico = showPOI ? alertDotIcon(point.alertActivity) : false;
    // @ts-ignore
    const isSpeed = point.alertActivity.hasspeedingcapped || point.alertActivity.hasspeedingposted;
    const isHidden = point.hidden;

    if (!showPOI && isHidden && (ico || isSpeed)) return false;
    return !(!showDots && !ico && !isSpeed);
}

type IPoisProps = {
  lastPointId?: string;
  tripIdx: number;
  points: Array<iDevicePing>;
  color: string;
  units: Units;
  tripId: string;
};

export const Pois = (props: IPoisProps) => {
    const { lastPointId, points, color, units, tripIdx, tripId } = props;
    const user = useRedux(s => s.auth.user.acl);
    const showPOI = useRedux(s => s.gmap.showPOI);
    const showDots = useRedux(s => s.gmap.showDots);

    let filteredPois = points;
    if (!showDots || !showPOI) {
        filteredPois = points.filter(shouldShowPoint({showDots, showPOI}));
    }
    if (showDots && !user.can.includes(UserCan.HIDE_POINTS)) {
        filteredPois = points.filter(point => {
            return !point.hidden;

        })
    }

    const checkedPoints = tripId === "pointsWithoutTripId" ? points : filteredPois;

    return <>{checkedPoints.map(point => {
        return <Point
                key={point.pointId}
                point={point}
                tripIdx={tripIdx}
                color={color}
                isLastPoint={lastPointId === point.pointId}
                units={units}
                icoSizeDiff={22} // sos slightly bigger cause of text
            />
        }
    )}</>;
}

type iPointDetails = {gotoStreet: () => any, point: iDevicePing, units: Units, timezone?: string};

export const PointDetails = ({gotoStreet, point, units, timezone = 'EST'}: iPointDetails) => {
  const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    const selectedPoint = useSelector<iFullStoreState, IReducerSelectedPoint>(state => state.selectedPoint);
    const [isButtonHidden, setIsButtonHidden] = useState(point.hidden)
    const { address = false, posted_speed, time, msg, alertActivity, device } = point;
    const deviceData = useSelector<iFullStoreState, iDeviceDetails>(
        (state) => state.devicesData.devicesDetails.get(device)
    );
    const { isCamera, id: deviceId, 'extra-info': serial }:any = deviceData;

    const auth = useRedux(s => s.auth);
    const { user: { acl: user }, isDriver } = auth;

    const checkingStatus = async () => {
        setLoading(true)
        const {onlineStatus, channels, cameraTime} = await checkStatusCamera({serial: serial[0]});
        dispatch(statusCameraOnline(onlineStatus));
        dispatch(cameraAvailableTime(cameraTime?.available_time));
        setLoading(false);
    }

    const { cameraOnline, deviceInfo, availableTime } = useSelector((state: iReducersState) => state.videoCameras);
    const cameraStatus = cameraOnline ? 'Camera is online' : 'Camera is offline';

    useEffect(() => {
        setIsButtonHidden(point.hidden)
    }, [point])

    const hidePointHandle = async () => {
        setIsButtonHidden(true)
        const points = clientStore().collection('points').doc(point.pointId);
        await points.update({'hidden': true});

        await dispatch(hidePoint(selectedPoint.deviceId, point.tripId, point.pointId));
    }

    const showPointHandle = async () => {
        setIsButtonHidden(false)
        const points = clientStore().collection('points').doc(point.pointId);
        await points.update({'hidden': FieldValue.delete()});

        await dispatch(showPoint(selectedPoint.deviceId, point.tripId, point.pointId));
    }
    const showAlertTitle = Object.keys(alertActivity).length > 0

    const timezoneData = getTimezoneByValue(timezone);
    const clearedTimezone = clearTimezone(timezone);
    const [timezoneTime, ...countries] = timezoneData.split(' ');
    const offSetTime = countDateWithUserAndTimezoneOffset(time, clearedTimezone)
    const alertTime = offSetTime.format("HH:mm:ss");
    const alertDate = offSetTime.format('YYYY-MM-DD');


    const checkingDateAndSetAvailableVideo = async () => {
        setLoading(true);
        const data = await dayChooseByTimestamp({ serial, convertDate: alertDate, alertTime });
        if(data?.length) {
            dispatch(takeDeviceInfo({id: deviceId, alertDate, alertTime}));
            dispatch(openModal({key: "openHistoryModal", value: true}));
        }
        setLoading(false);
        return data
    }

    const check30DaysLeft  = isMoreThan30DaysAgo(new Date(offSetTime.format('YYYY-MM-DD')));

    useEffect(() => {
        if(isCamera && !check30DaysLeft) {
            checkingStatus();
        }
    }, [alertDate, point])

    function isMoreThan30DaysAgo(date) {
        //                   days  hours min  sec  ms
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;
        
        return timestampThirtyDaysAgo > date ? true : false;
    }

    const showAlertHistoryVideo = () => {
        if (cameraOnline && availableTime > 0) {
            checkingDateAndSetAvailableVideo();
        } 
        if(cameraOnline && availableTime === 0) {
            dispatch(openModal({key: "openBuyMoreModal", value: true}));
        }
    }

    return (
        <div className={styles.pointDetails}>
            {showAlertTitle && <div className={styles.alertTitle}>{msg}</div>}
            <div>
                <p className={styles.deviceTime}>{offSetTime.format('MM/DD/YY h:mm A')}</p>
                <div className={styles.timezoneTime}>
                    <span className={styles.timezoneTimeTitle}>{timezoneTime}</span>
                    <span className={styles.timezoneTimeInfo}>{countries}</span></div>
            </div>

            {/*{!isDev ? null : <div className={styles.devInfo}>*/}
            {/*    {(point as iDevicePing).pointId && isDev ? <p className={styles.devInfoKey}>key: {(point as iDevicePing).pointId}</p> : null}*/}
            {/*    {(point as iDevicePing).tripId && isDev ? <p>trip: {(point as iDevicePing).tripId}</p> : null}*/}
            {/*    <div>{point.coordinates.location.lat}, {point.coordinates.location.lng}</div>*/}
            {/*</div>}*/}

            <div className={styles.speedInfo}>
                <p>
                    <span className={styles.speed}>Speed:</span>
                    {friendlySpeed(point.speed, units)}
                </p>
            </div>

            {posted_speed ? <div>Posted Speed: {posted_speed}</div> : null}

            <div className={styles.locationInfo}>
                <span className={styles.location}>Current location:</span>
                <div>
                    {!address ? null : <span>{address.street}</span>}
                    {!address ? null :<p>{address.city}, {address.state} {address.zip}</p>}
                </div>
            </div>

            {!isDriver && (
                    <Col className={styles.driverInfo} /* todo: Use default flex classes in refactor version */ >
                        {/* <img src={`https://maps.googleapis.com/maps/api/streetview?size=325x175&location=${lat},${lng}&fov=90&heading=235&pitch=10&key=AIzaSyBsNvkRiUmkDgv71-TaWfubQnf5E1niYXY`} /> */}
                        <span className="btn-link" onClick={gotoStreet}>Go to Street</span>
                        {(isCamera && !check30DaysLeft) && 
                            <Tooltip title={cameraStatus} placement='top'>
                                    <button className={styles.playVideo} onClick={() => showAlertHistoryVideo()}>
                                        {loading ? <Fa icon={faSpinner} spin /> : 'Play Video'}
                                    </button>
                            </Tooltip>
                        }
                        {(user.can.includes(UserCan.HIDE_POINTS) && !isButtonHidden)
                            ? <span className="btn-link text-danger" onClick={hidePointHandle}>Hide</span>
                            : null}
                        {(user.can.includes(UserCan.HIDE_POINTS) && isButtonHidden)
                            ? <span className="btn-link" onClick={showPointHandle}>Show</span>
                            : null}
                    </Col>
                )
            }
        </div>
    )
}