import React, { FC, HTMLProps } from "react";
import { Icon } from "../../../general/Icon";
import "./styles.scss";

interface iProps extends HTMLProps<HTMLDivElement> {
    itemId?: string,
    url?: any,
    fa?: any,
    title?: any,
    active?: any
}

export const ReportSelectorLabel: FC<iProps> = ({itemId, url, fa, title, active, className="", ...props}) => {
return (
    <div key={itemId} className={`report-selector  ${active? "active" : ""} ${className}`} {...props}>
        <div className={`report-image`}><Icon fa={fa} url={url} /></div>
        <div className="report-label"><span>{title}</span></div>
    </div>
)
}