import React, { createContext, ReactNode, useCallback } from "react";

export const ScrollContext = createContext<{
    scrollRef: React.MutableRefObject<any>;
    scrollToBottom: Function
}>(null);

const ScrollProvider = ({
    children,
    scrollRef
}: {
    children: ReactNode;
    scrollRef: React.MutableRefObject<any>;
}) => {

    const scrollToBottom = useCallback(() => {
        scrollRef.current.scrollTop = scrollRef.current.clientHeight
    }, [scrollRef]);

    return (
        <ScrollContext.Provider value={{scrollRef, scrollToBottom}}>
            {children}
        </ScrollContext.Provider>
    )
}

export default ScrollProvider;