import React from "react";
import "./ActivationInfo.scss";

const ActivationInfo = () => {
  return (
    <div className="activation-info">
      <hr />
      <h5>FIND YOUR SERIAL NUMBER</h5>
      <div className="activation-info__block">
        <img
          height="250px"
          width="250px"
          src="https://cdn.shopify.com/s/files/1/2689/9952/products/OBD_Tracker_1_800x.jpg?v=1565784528"
          alt="tracker"
        />
        <div className="activation-info__description">
          <h5>MT-OBD and Hard wired devices:</h5>
          <p>
            The serial number is printed on the label located on the front of
            the device.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActivationInfo;
