import {put, select, take, takeEvery} from 'redux-saga/effects';
import {channel} from 'redux-saga';

import {iFullStoreState} from '../../shared/interfaces';
import {
    SET_ALL_DEVICES_INFO,
    SET_DEVICE_INFO,
    setAllDevicesInfo, setDeviceInfo,
    updateDeviceLocation,
} from '../reducers/devicesData/AC';
import {watchDeviceLive} from '../../shared/db/report-db';

const watchDevicesLocationsChannel = channel();

function* worker (action: ReturnType<typeof setAllDevicesInfo> | ReturnType<typeof setDeviceInfo>) {
    const devicesIdsFromStore: Array<string> = yield select(
        (state: iFullStoreState) => state.devicesData.devicesLastPing.keySeq().toArray(),
    );

    switch (action.type) {
    case SET_DEVICE_INFO: {

        const {deviceDetails} = (action as ReturnType<typeof setDeviceInfo>).payload;

        if (!devicesIdsFromStore.includes(deviceDetails.id)) {
            watchDeviceLive(deviceDetails.id, (ping) => watchDevicesLocationsChannel.put(updateDeviceLocation(deviceDetails.id, ping)));
        }

        break;
    }
    case SET_ALL_DEVICES_INFO: {
        const {devicesDetails} = (action as ReturnType<typeof setAllDevicesInfo>).payload;

        for (const deviceId of Object.keys(devicesDetails)) {
            if (!devicesIdsFromStore.includes(deviceId)) {
                watchDeviceLive(deviceId, (ping) => watchDevicesLocationsChannel.put(updateDeviceLocation(deviceId, ping)));
                // TODO: next function before refactoring never calls. Maybe can it remove.
                // populateDeviceEarliestPing(user)(deviceId);
            }
        }

        break;
    }
    }
}

export default function* () {
    yield takeEvery([SET_ALL_DEVICES_INFO, SET_DEVICE_INFO], worker);

    while (true) {
        const action = yield take(watchDevicesLocationsChannel);

        yield put(action);
    }
}
