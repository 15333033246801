import React, { useEffect, useState } from 'react';
import {Fa, FaLink} from './fa';
import {
    faChevronDown,
    faChevronRight,
    faPencilAlt,
} from '@fortawesome/fontawesome-free-solid';
import C, { ACL, UserCan } from '../../shared/constants';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { iFullStoreState, ItemType, UserAuth } from '../../shared/interfaces';
import { useIsReadOnly } from '../../hooks/useIsReadOnly';

// #region styles
const styleExtraInfo: React.CSSProperties = {
    width: '100%',
    margin: '3px 3px 6px',
};
const head: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 8px',
    backgroundColor: C.darkGray,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
};
const body: React.CSSProperties = {
    width: '100%',
    padding: '3px 8px',
    border: `1px solid ${C.lightGray}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
};
const toggle: React.CSSProperties = {
    color: '#fff',
    marginRight: 10,
    cursor: 'pointer',
};
const title: React.CSSProperties = {
    flex: '1 1 0%',
    color: '#fff',
    fontWeight: 'bold',
};
const edit: React.CSSProperties = {
    color: '#fff',
    cursor: 'pointer',
};
const item: React.CSSProperties = {
    marginBottom: 3,
};
const key: React.CSSProperties = {
    fontWeight: 'bold',
};
// #endregion

interface iProps {
    editUrl: string;
    labels: [string, string][];
    role?: boolean;
    readOnly?: boolean;
    itemId?: string;
    itemType?: ItemType;
}

export const ExtraInfo = ({editUrl, labels, role, itemId, itemType}: iProps) => {
    const [collapsed, setCollapsed] = React.useState(true);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const readOnly = useIsReadOnly(itemId, itemType);

    return (
        <div style={{...styleExtraInfo}}>
            <div style={{...head}}>
                <Fa icon={ collapsed ? faChevronRight : faChevronDown } onClick={toggleCollapsed} style={{...toggle}} />
                <span style={{...title}}>Extra Info</span>
                {
                    !role && !readOnly && (
                        <FaLink icon={faPencilAlt} to={editUrl} style={{...edit}} />)
                }
            </div>
            <div style={{...body}}>
                {!collapsed ?
                    !labels.length ? 'None Yet' : labels.map(([name, value]) => (
                        <div key={name} style={{...item}}>
                            <span style={{...key}}>{name}</span>
                            <span> : </span>
                            <span>{value}</span>
                        </div>
                    )) :
                    ''
                }
            </div>
        </div>
    );
};
