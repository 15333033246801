import React, { useEffect, useRef, useState } from 'react';
import {FenceBodyLayoutProps} from "./FenceBodyLayout.interfaces";
import {useDispatch, useSelector} from 'react-redux';
import {iFullStoreState} from '../../../../../shared/interfaces';
import {Actions} from '../../../../../stores/reducers/map-region-reducers';
import {Actions as PathActions} from '../../../../../stores/reducers/map-path-reducers';
import {isMobile} from '../../../../../shared/helpers';
import Portal from "../../../../Portal";
import DesktopFenceLayout from "./DesktopFenceLayout";
import MobileFenceLayout from "./MobileFenceLayout";
import MobileTopArrow from "../../../../elements/MobileTopArrow/MobileTopArrow";
import ScrollProvider from "../../../../../contexts/ScrollProvider";
import PerfectScrollbar from 'react-perfect-scrollbar';
import LayersTabs from '../../../LayersTabs/LayersTabs';
import './FenceBodyLayout.scss';

export const FenceBodyLayout: React.FunctionComponent<FenceBodyLayoutProps> = (props) => {
    const {overlay = false, isVisible, toggleVisible, modalActions = false, style = {}, header, showTabs = false, activeTab = 0} = props;
    const hasShowedTags = useSelector<iFullStoreState, boolean>((state) => state.tagsDevicesMap.showedTagsIds.size > 0);
    const ref = useRef(null);
    const [activeTabs, setActiveTabs] = useState(false);
    const [animationGoes, setAnimationGoes] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {

        if(isVisible) ref.current.scrollTop = 0;

        if(showTabs) setActiveTabs(true);

        return () => {
            dispatch(PathActions.STASH_PATHS());
            dispatch(Actions.STASH_REGIONS());
        }
    }, []);


    useEffect(() => {
        setAnimationGoes(false);
    }, [isVisible]);

    const hideFenceModal = () => {
        setAnimationGoes(true);
        setTimeout(() => {
            toggleVisible(false);
        },1000);
    };

    const portalContent =  (
        isVisible &&
            <Portal>
                    <div className={`fence-mobile-modal ${animationGoes ? 'fence-mobile-modal-hide': '' }`}>
                            <MobileTopArrow onClick={() => hideFenceModal()} />
                            <ScrollProvider scrollRef={ref}>
                                <PerfectScrollbar className="scrollable" style={{paddingRight: 16, zIndex: 1}} options={{suppressScrollX:true}} containerRef={(el) => ref.current = el}>
                                        {activeTabs && <LayersTabs activeTab={activeTab}/>}
                                        {!!props.children &&
                                        <div className='fence-mobile-modal-content'>
                                            <MobileFenceLayout {...{overlay, header, modalActions}}>
                                                {props.children}
                                            </MobileFenceLayout>
                                        </div>
                                        }
                                </PerfectScrollbar>
                            </ScrollProvider>

                    </div>
            </Portal>
    );

    const simpleContent = (
            isVisible &&
                    <>
                        <ScrollProvider scrollRef={ref}>
                            <PerfectScrollbar className="scrollable" style={{paddingRight: 16, zIndex: 1}} options={{suppressScrollX:true}} containerRef={(el) => ref.current = el}>
                                {activeTabs && <LayersTabs activeTab={activeTab}/>}
                                {!!props.children &&
                                <div className='fence-modal-content'>
                                    <DesktopFenceLayout {...{overlay, header, modalActions}}>
                                        {props.children}
                                    </DesktopFenceLayout>
                                </div>
                                }
                            </PerfectScrollbar>
                        </ScrollProvider>
                    </>
    );

    return (
            <div className={`fence-modal ${isMobile ? `fence-modal-fullWidth fence-modal-transparent` : 'fence-modal-white'} ${ activeTabs || props.children ? `fence-modal-white` : 'fence-modal-transparent'}`} style={{...style}}>
                {isMobile? portalContent: simpleContent}
            </div>
    );
};

export default FenceBodyLayout;