import firebase from 'firebase/app';

import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';


import { localStorage } from './storage';
import { AppConfig } from './interfaces';

const appConfig: AppConfig = window['fbConfig'];


// const { clientId } = JSON.parse(base64.decode(localStorage.get('login-init-be-token').split('.')[1]))
// localStorage.set('active-client', clientId);

export const fbConfig = appConfig['firebase-app'];
export const captchaKeyV3 = appConfig['captcha-v3-key'];
export const captchaKeyV2 = appConfig['captcha-v2-key'];
export const fbapp = firebase.initializeApp(fbConfig);

window['react-fb'] = firebase;

// export const fbapp = firebase.initializeApp(fbConfig);

export const theClient = () => localStorage.get('active-client', 'non-empty-placeholder');

export const globaldb = firebase.database(fbapp).ref();

export const clientDb = () => firebase.database().ref(`clients/${theClient()}`);
export const fbConnection = firebase.database().ref(".info/connected");

export const firestore = firebase.firestore()

export const clientStore = () => firestore.collection('clients').doc(theClient());

export const clientStorage = () => firebase.storage().ref(`clients/${theClient()}`);
export const globalStorage = firebase.storage().ref();

export const keyGen = () => clientDb().push().key
export const auditKeyGen = () => `-audit-key-${clientDb().push().key}`

export const FieldValue = firebase.firestore.FieldValue

export const getDeviceItemsAllowedIds = async (sub_id) => {
    const devices = (await (clientDb().child('acl').child('items-allowed').child(sub_id ?? '').child('device').once('value'))).val();

    return Object.keys(devices ?? {});
}

