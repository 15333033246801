import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { values } from 'ramda';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import {
    AlertTypeTitle,
    iDevicePing,
    Units,
    iFullStoreState,
    AlertTypes,
} from '../../../../../shared/interfaces';
import { tripPointsReal } from '../../../../../shared/db/report-db';
import { isIncidentSafety, utcOffset, userCurrentOffset, friendlySpeed } from '../../../../../shared/helpers';
import C from '../../../../../shared/constants';
import { BaseComponent } from '../../../../../shared/BaseComponent';
import { Fa } from '../../../../elements/fa';
import { Row } from '../../../../elements/flex';
import { IPointsContainer } from '../../../../../stores/reducers/devicesTripsPoints';
import { setReportTrip, toggleShowReportTrip } from '../../../../../stores/reducers/devicesTripsPoints/AC';

type IProps = {
    deviceId: string;
    tripId: string;
    timezone: string;
    units: Units;
    errorCloser: () => void;
}

type IPropsFromStore = {
    points: IPointsContainer | undefined;
}

type IFullProps = IProps & IPropsFromStore & DispatchProp;

export const ActivityDeetsCol = connect((s: iFullStoreState, props: IProps): IPropsFromStore => ({
    points: s.devicesTripsPoints.getIn([props.deviceId, 'tripsPoints', props.tripId])
}), null, null, {
    areStatePropsEqual: (l, r) => l.points?.keySeq?.()?.last?.() === r.points?.keySeq?.()?.last?.(),
})
(class extends BaseComponent<IFullProps> {
    componentDidMount () {
        const {deviceId, tripId, points, dispatch} = this.props;
        if (!!points) return;

        tripPointsReal(tripId)
            .then(values)
            .then((points: any) => {
                dispatch(setReportTrip(deviceId, tripId, points, false));
            });
    }

    private showPoint(pointId: string) {
        const {dispatch, deviceId, tripId} = this.props;

        dispatch(toggleShowReportTrip(deviceId, tripId, pointId));
    }

    debugRender = () => {
        const {points, errorCloser, timezone, units} = this.props;

        if (!points) return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}> <Fa icon={faSpinner} spin  /></div>
            </div>
        )

        const alertPoints = points.reduce((result, point) => [
            ...result,
            ...(Object.keys(point.alertActivity) as AlertTypes[])
                .filter(isIncidentSafety)
                .filter(k => k.indexOf('has') !== -1 && point.alertActivity[k])
                .reduce((r, alert) => [...r, {alert, point}],
                    [] as Array<{ alert: string; point: iDevicePing; }>,
                ),
        ], [] as Array<{ alert: string; point: iDevicePing; }>);

        const deviceOffset = utcOffset(timezone, false);
        const diff = userCurrentOffset - deviceOffset;

        const thStyle: React.CSSProperties = {
            width: '100%',
            backgroundColor: '#fff',
            borderBottom: `2px solid ${C.primaryColor}`,
            borderWidth: 2,
            padding: 5,
        }
        return (
            <div style={{maxHeight: 175, overflow: 'auto' }}>
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th style={{padding: 0, position: 'sticky', top: -1}}><div style={thStyle}>Alert</div></th>
                            <th style={{padding: 0, position: 'sticky', top: -1}}><div style={thStyle}>Speed</div></th>
                            <th style={{padding: 0, position: 'sticky', top: -1}}><div style={thStyle}>Location</div></th>
                            <th style={{padding: 0, position: 'sticky', top: -1}}><div style={thStyle}>Time</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        {alertPoints.length ? null : <tr><td colSpan={4}>
                            <Row align="center" style={{position: 'relative'}}>
                                {!errorCloser ? null
                                    :<button onClick={errorCloser} style={errorCloserStyle}><FontAwesomeIcon icon="times" /></button>
                                }
                                <div style={{padding: 8}}><FontAwesomeIcon icon="exclamation-circle"/></div>
                                <div style={{flex: 1}}>
                                    <div>Oops something when wrong.</div>
                                    <div>The point alerts didn't match the trip alerts</div>
                                </div>
                            </Row>

                        </td></tr>}

                        {alertPoints.map(({alert, point}) =>
                            <tr key={`${point.pointId}-${alert}`} style={{cursor: 'pointer'}} onClick={() => this.showPoint(point.pointId)}>
                                <td>{AlertTypeTitle(alert.replace('has', ''))}</td>
                                <td>{friendlySpeed(point.speed, units)}</td>
                                <td>{point.address.street} {point.address.city}, {point.address.state}</td>
                                <td>{moment(point.time).subtract(diff, 'hours').format('lll')}  {(timezone)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
});

const errorCloserStyle = {
    color: 'darkred',
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 18,
    border: 'none',
    background: 'transparent',

} as React.CSSProperties
