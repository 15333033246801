import React from 'react';
import PullUpChevron from '../../../components/SVG-components/pull-up-chevron';

import "./MobileTopArrow.scss";

const MobileTopArrow = ({onClick}) => {
	return (
		<div className="mobile-top-arrow" onClick={onClick}>
			<PullUpChevron/>
		</div>
	)
}

export default MobileTopArrow;