import React, { useEffect, useState } from 'react'
import StatusOnPNG from "../../../../../assets/png/accept.png";
import StatusOffPNG from "../../../../../assets/png/exclamation-circle.png";
import PlayIconPNG from "../../../../../assets/png/play-button.png";
import CalendarPNG from "../../../../../assets/png/calendar.png";
import {IDevicesStatusNavigationProps} from '../interfaces';
import styles from "./DevicesStatusNavigation.module.scss";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { cameraAvailableTime, openModal, statusCameraOnline } from '../../../../../stores/reducers/videoCameras';
import { checkStatusCamera } from '../../../../../api/openApi';
import { iReducersState } from '../../../../../stores/reducers';

const INTERVAL_TIME: number = 30000;

const DevicesStatusNavigation = ({ device }: IDevicesStatusNavigationProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availableTime, setAvailableTime] = useState<number>(null);
  const {openLiveViewPlayer, openHistoryPlayer}:any = useSelector((state: iReducersState) => state.videoCameras.modals);
  const {isCamera, 'extra-info': serial} = device || {};
  
  const dispatch = useDispatch();
  const {cameraOnline} = useSelector((state: iReducersState) => state.videoCameras);

const getStatus = async (): Promise<void> => {
  setIsLoading(true);
  if(isCamera && serial[0]) {
    const {onlineStatus, channels, cameraTime} = await checkStatusCamera({serial: serial[0]});
    dispatch(statusCameraOnline(onlineStatus))
    dispatch(cameraAvailableTime(cameraTime?.available_time))
    setAvailableTime(cameraTime?.available_time)
    setIsLoading(false);
  }
};

useEffect(() => {
  getStatus();
  let interval 
  if(openHistoryPlayer || openLiveViewPlayer) {
      clearInterval(interval);
  }else{
      interval = setInterval(getStatus, INTERVAL_TIME);
  }

  return () => clearInterval(interval);
}, [openHistoryPlayer, openLiveViewPlayer]);


  const liveViewClick = (): void => {
      if(cameraOnline) dispatch(openModal({key: "openLiveViewModal", value: true}));
      if(cameraOnline && availableTime === 0) dispatch(openModal({key: "openBuyMoreModal", value: true}));
  }

  const history = () => {
      if(cameraOnline) dispatch(openModal({key: "openHistoryModal", value: true}));
      if(cameraOnline && availableTime === 0) dispatch(openModal({key: "openBuyMoreModal", value: true}));
  }

  return (
    <div className={styles.cameraIcons}>
      <div
        className={styles.deviceIcon}
        title={cameraOnline ? "Unit online" : "Unit is not online"}
      >
          {
              isLoading ? <CircularProgress size={25} color={'inherit'}/> :
              <img src={cameraOnline ? StatusOnPNG : StatusOffPNG} alt="status"/>
          }
        <p>Status</p>
      </div>

      <div
        className={styles.deviceIcon}
        title={"Live View"}
        onClick={liveViewClick}
      >
        {isLoading ? <CircularProgress size={25} color={'inherit'}/> : <img src={PlayIconPNG} alt="live" />}
        <p>View</p>
      </div>

      <div
        className={styles.deviceIcon}
        title={"History"}
        onClick={history}
      >
        {isLoading ? <CircularProgress size={25} color={'inherit'}/> : <img src={CalendarPNG} alt="history" />}
        <p>History</p>
      </div>
    </div>
  );
};

export default DevicesStatusNavigation
