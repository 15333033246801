import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {compose} from '@bem-react/core';
import Filters from './Filters';
import VisTagsDevices from '../tab-bodies/Tags-Devices';
import BEMTabs from '../../../BemComponents/Tabs';
import FenceBody from '../tab-bodies/FenceBody/FenceBody';
import {iFullStoreState, ItemType} from '../../../shared/interfaces';
import {withTabsThemeDarkNew} from '../../../BemComponents/Tabs/_theme/Tabs_theme_dark_new';
import {withTabsStyledUnderlinedNew} from '../../../BemComponents/Tabs/_styled/Tabs_styled_underlined_new';

const LayersTabs = ({activeTab}): JSX.Element => {
    const hasShowedTags = useSelector<iFullStoreState, boolean>((state) => {
        return state.tagsDevicesMap.showedTagsIds.size > 0;
    });
    const {id, itemType} = useParams<{ itemType: string; id: string }>();

    const item = {itemType: null, itemId: null};

    const Tabs = compose(withTabsThemeDarkNew, withTabsStyledUnderlinedNew)(BEMTabs);

    const hash = window.location.hash.split('/').filter((item) => item !== '#');
    const itemData = hash.reduce((acc, rec) => {
        if (rec === 'device' || rec === 'tag' || rec === 'person') {
            return {...acc, itemType: rec};
        }

        return {...acc, itemId: rec};
    }, {...item});

    return (
        <>
            {itemData.itemType === 'device' ? (
                <Tabs styled="underlined_new" theme="dark_new" activeTab={activeTab}>
                    <div data-label="Fences">
                        <FenceBody  itemId={itemData.itemId } itemType={itemData.itemType  as ItemType}/>
                    </div>
                    <span data-label=""/>
                </Tabs>
            ) : (hasShowedTags && <Tabs styled="underlined_new" theme="dark_new" activeTab={activeTab}>
                <div data-label="Fences">
                    <FenceBody  itemId={itemData.itemId } itemType={itemData.itemType  as ItemType}/>
                </div>
                <div data-label="Filters">
                    <Filters/>
                </div>
                <div data-label="Devices">
                    <VisTagsDevices/>
                </div>
            </Tabs>)}
        </>
    );
};

export default LayersTabs;
