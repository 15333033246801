import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subject, Subscription } from 'rxjs';
import { compose } from '@bem-react/core';

import Portal from '../Portal';
import './Dialog.scss';

import { Button as ButtonPresenter } from '../../BemComponents/NewButton/Button';
import { withButtonThemeOutline } from '../../BemComponents/NewButton/_theme/Button_theme_outline';
import { withButtonVariantContained } from '../../BemComponents/NewButton/_variant/Button_variant_contained';
import { withButtonTypeLink } from '../../BemComponents/NewButton/_type/Button_type_link';


const Button = compose(
    // composeU(withButtonThemeHighlighted,withButtonThemeOutline),
    withButtonThemeOutline,
    withButtonVariantContained,
    withButtonTypeLink,
)(ButtonPresenter);

export type DialogConfig = {
    title?: string;
    body?: React.ReactNode;
    type?: 'CONFIRM' | 'NOTIFICATION';
    afterClose?: Function;
}

export type DialogConfigSetter = (config?: DialogConfig & { close?: boolean }) => Promise<boolean>;

type ModalProps = {
    setupConfig: (callBack: () => DialogConfigSetter) => void;
    title?: string;
    type?: 'CONFIRM' | 'NOTIFICATION',
    afterClose?: Function
}

type AnimationState = 'CLOSED' | 'OPENING' | 'OPENED' | 'CLOSING';

const Dialog: React.FunctionComponent<ModalProps> = ({setupConfig, title, children, type = 'CONFIRM', afterClose}) => {
    const startEl = React.useRef<HTMLSpanElement>(null);
    const confirmSubject = React.useRef(new Subject<boolean>());

    const [animationState, setAnimationState] = React.useState<AnimationState>('CLOSED');

    const [config, setConfig] = React.useState<DialogConfig>({title, body: children, type, afterClose});

    React.useEffect(() => {
        let subscription: Subscription | undefined;
        let promise: Promise<boolean> | undefined;

        setupConfig(() => ({close, ...newConfig} = {}) => {
            if (close && promise) {
                setAnimationState('CLOSING');

                return promise;
            }
            setAnimationState('OPENING');

            if (typeof newConfig === 'object' && newConfig) {
                setConfig({...config, ...newConfig});
            }

            promise = new Promise(resolve => {
                subscription = confirmSubject.current.asObservable().subscribe(type => {
                    setAnimationState('CLOSING');
                    resolve(type);
                });
            });

            return promise;
        });

        return () => subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (animationState === "CLOSED") {
            config?.afterClose?.();
        }
    }, [animationState])

    const isOpen = animationState !== 'CLOSED';

    React.useEffect(() => {
        switch (animationState) {
            case 'CLOSING':
                setTimeout(() => setAnimationState('CLOSED'), 300);
                break;
            case 'OPENING':
                setAnimationState('OPENED');
                break;
            default:
                break;
        }
    }, [animationState]);

    React.useEffect(() => {
        if (isOpen) {
            startEl.current?.focus();
        }
    }, [isOpen]);
    const close = () => confirmSubject.current.next(false);

    const confirm = () => confirmSubject.current.next(true);
    const stopPropagation = event => event.stopPropagation();

    return <>{
        isOpen &&
        <Portal>
            <div className={`modal-overlay${animationState === 'OPENED' ? ' open' : ''}`} onClick={close}>
                <div className="modal-window" tabIndex={-1} onClick={stopPropagation}>
                    <span tabIndex={1} ref={startEl} />
                    <div className="modal-header">
                        <div className="modal-title">{config.title}</div>
                        <span onClick={close}><FontAwesomeIcon icon="times" /></span>
                    </div>
                    <div className="modal-body">{config.body}</div>
                    <div className="modal-footer">
                        <Button tabIndex={2} theme="outline" onClick={close}>
                            {config.type === 'NOTIFICATION' ? 'CLOSE' : 'CANCEL'}
                        </Button>
                        {config.type === 'CONFIRM' && <Button tabIndex={3} theme="outline" variant="contained" onClick={confirm}>OK</Button>}
                    </div>
                    <span tabIndex={4} onFocus={() => startEl.current?.focus()} />
                </div>
            </div>
        </Portal>
    }</>;
};

export default Dialog;
