import React, { useState } from "react";
import classNames from "classnames";

import Modal from "../../../Modal";
import Loader from "../Other/Loader";
import "../modalOrdersInvoice.scss";

const ModaInvoice = ({ setActive, rowValue, pdf }) => {
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoice] = useState(null);
  const { id, status, date, total } = invoiceData ?? {};
  const file = pdf && window.URL.createObjectURL(pdf);

  // useEffect(() => {
  //   getInvoiceOrOrderById({
  //     endPoint: "/api/invoice/get-invoice?invoice_ID",
  //     id: rowValue.id,
  //     setState: setInvoice,
  //     token,
  //   }).finally(() => setLoading(false));
  // }, []);

  return (
    <Modal>
      <div
        className="modal_ivoices_orders fade-in"
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="modal_ivoices_orders-row">
              <span className="modal_ivoices_orders-title">
                {`Invoice №${rowValue.id}`}
                <span className={classNames({
                    "paid-status": rowValue.state === "paid" && true,
                  })}>
                  {` - ${rowValue.state}`}
                </span>
              </span>
              <button onClick={() => setActive(false)}>X</button>
            </div>
            <hr />
            <iframe className="invoice-pdf" src={file} />

            {/* ///////// */}

            {/* <div className="modal_ivoices_orders-row">
              <span className="modal_ivoices_orders-title">
                {`Invoice №${id}`}
                <span style={{ color: status === "paid" && "#48d02a" }}>
                  {status === "paid" && " - Paid"}
                </span>
              </span>
              <button onClick={() => setActive(false)}>X</button>
            </div>

            <table className="modal_ivoices_orders-table">
              <tbody>
                <tr>
                  <th>Invoice ID</th>
                  <td>{id}</td>
                </tr>
                <tr>
                  <th>Invoice Date</th>
                  <td>{moment(date).format("DD/MM/YYYY")}</td>
                </tr>
                <tr>
                  <th>Amount</th>
                  <td>${total}</td>
                </tr>
                <tr>
                  <th>Tracking Number</th>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div className="summary">
              <div className="summary_block">
                <span>SUMMARY</span>
                <div>
                  <p>
                    Payment for activation device for user Umberto Silvia $
                    {total}
                  </p>
                  <p>Amount charged ${total}</p>
                </div>
              </div>
              <div className="summary_support">
                <p>
                  If you have any questions, contact us at
                  <a className="link" href={"mailto:" + "maail"}>
                    {" "}
                    mastrack.crm2.0@gmail.com{" "}
                  </a>{" "}
                  or call at
                  <a className="link" href={"tel:" + "phone"}>
                    +1 800 444 4444
                  </a>
                </p>
                <button onClick={window.print} className="print-button">
                  PRINT
                </button>
              </div>
            </div> */}
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModaInvoice;
