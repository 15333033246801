import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { is, OrderedMap, Seq } from 'immutable';
import InfoWindow from 'react-google-maps/lib/components/InfoWindow';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/fontawesome-free-solid';

import { Fa } from '../../elements/fa';
import { iDevicePing, iFullStoreState, Units } from '../../../shared/interfaces';
import { IReducerSelectedPoint } from '../../../stores/reducers/selectedPoint';
import { Actions } from '../../../stores/reducers/gmap-reducers';
import { PointDetails, shouldShowPoint } from '../../gmap/pois';
import { IPointsContainer } from '../../../stores/reducers/devicesTripsPoints';
import { removeSelectedPoint, setSelectedPoint } from '../../../stores/reducers/selectedPoint/AC';
import { selectLastPointInPrevTrip } from '../../../stores/sagas/selectLastPointInPrevTrip';
import { selectFirstPointInNextTrip } from '../../../stores/sagas/selectFirstPointInNextTrip';

type IProps = {
    firstTripId?: string;
    lastTripId?: string;
}

const InfoSelectedPoint = ({firstTripId, lastTripId}: IProps) => {
    const dispatch = useDispatch();

    const selectedPoint = useSelector<iFullStoreState, IReducerSelectedPoint>(state => state.selectedPoint);

    const showPOI = useSelector<iFullStoreState, boolean>(s => s.gmap.showPOI);
    const showDots = useSelector<iFullStoreState, boolean>(s => s.gmap.showDots);

    const point = useSelector<iFullStoreState, iDevicePing | undefined>(
        state => selectedPoint
                 ? state.devicesTripsPoints.getIn([selectedPoint.deviceId, 'tripsPoints', selectedPoint.tripId, selectedPoint.pointId])
                 : undefined,
        (left, right) => left?.pointId === right?.pointId,
    );

    const deviceLastPing = useSelector<iFullStoreState, iDevicePing | undefined | null>(
        (state) => state.devicesData.devicesLastPing.get(selectedPoint?.deviceId),
        (l, r) => typeof l === typeof r && l?.pointId === r?.pointId,
    );

    const tripPoints = useSelector<iFullStoreState, IPointsContainer | undefined>(
        state => {
            if (!selectedPoint) return undefined;

            let points: IPointsContainer = state.devicesTripsPoints.getIn(
                [selectedPoint.deviceId, 'tripsPoints', selectedPoint.tripId],
                OrderedMap(),
            );

            if (!showDots || !showPOI) {
                points = points.filter(shouldShowPoint({showDots, showPOI}));
            }
            return points;
        },
        (left, right) => {
            if (typeof left === 'undefined' || typeof right === 'undefined') {
                return !!left === !!right;
            }
            return is(left.keySeq(), right.keySeq());
        },
    );

    const timezone = useSelector<iFullStoreState, string>(
        state => state.devicesData.devicesDetails.getIn([selectedPoint?.deviceId, 'timezone'], 'EST')
    );

    const units = useSelector<iFullStoreState, Units>(
        state => state.devicesData.devicesDetails.getIn(
            [selectedPoint?.deviceId, 'units'],
            Units.miles,
        ));

    if (!selectedPoint) return null;

    let prevPointId: string | undefined;
    let nextPointId: string | undefined;
    if (tripPoints) {
        const prevLastTuple = findPrevNextElements(tripPoints.keySeq(), selectedPoint.pointId);
        if (prevLastTuple) {
            [prevPointId, nextPointId] = prevLastTuple;
        }
    }

    const existsPrevPoint = !!(prevPointId ?? (!!firstTripId ? firstTripId !== selectedPoint?.tripId : false));
    const existsNextPoint = !!(nextPointId ?? (!!lastTripId ? lastTripId !== selectedPoint?.tripId : false));

    const selectPrevPoint = () => {
        if (prevPointId) {
            dispatch(setSelectedPoint(selectedPoint.deviceId, selectedPoint.tripId, prevPointId, tripPoints?.getIn([prevPointId, 'coordinates', 'location'])));
        } else {
            dispatch(selectLastPointInPrevTrip(selectedPoint.deviceId, selectedPoint.tripId));
        }
    };
    const selectNextPoint = () => {
        if (nextPointId) {
            dispatch(setSelectedPoint(selectedPoint.deviceId, selectedPoint.tripId, nextPointId, tripPoints?.getIn([nextPointId, 'coordinates', 'location'])));
        } else {
            dispatch(selectFirstPointInNextTrip(selectedPoint.deviceId, selectedPoint.tripId));
        }
    };

    const hasExtraInfo = point||deviceLastPing

    return (
        <InfoWindow position={selectedPoint!.position}
                    onCloseClick={() => dispatch(removeSelectedPoint())}
                    zIndex={5}
                    options={{disableAutoPan: true}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {
                    existsPrevPoint &&
                    <div style={{cursor: 'pointer', paddingRight: 5}} onClick={selectPrevPoint}>
                        <Fa icon={faArrowCircleLeft} />
                    </div>
                }
                {hasExtraInfo ? <PointDetails 
                        gotoStreet={() => dispatch(Actions.TOGGLE_STREET_VIEW(selectedPoint!.position))}
                        point={point ?? deviceLastPing}
                        units={units}
                        timezone={timezone}
                     />
                    : <p>There is no extra info about this device</p>}
                {
                    existsNextPoint &&
                    <div style={{cursor: 'pointer', paddingLeft: 5}} onClick={selectNextPoint}>
                        <Fa icon={faArrowCircleRight} />
                    </div>
                }
            </div>
        </InfoWindow>
    );
}

const findPrevNextElements = (collection: Seq.Indexed<string>, element: string | undefined): undefined | [string | undefined, string | undefined] => {
    if (!collection.size || !element) return;

    let nextElement;
    let prevElement;

    const currentElementIndex = collection.findIndex(el => element === el);
    if (currentElementIndex === -1) return;

    if (currentElementIndex < collection.size - 1) {
        nextElement = collection.get(currentElementIndex + 1);
    }
    if (currentElementIndex > 0) {
        prevElement = collection.get(currentElementIndex - 1);
    }

    return [prevElement, nextElement];
};

export default InfoSelectedPoint