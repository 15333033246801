import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { iReducersState } from "./../../../../stores/reducers";
import { billingLoading } from "./../../../../stores/reducers/billing";
import { isEmpty } from 'ramda'
import Modal from "../../../Modal";
import ModalHeader from "./ModalHeader";
import ServicePlans from "./ServicePlans";
import ModalCancellation from "./Cancellation/ModalCancellation";
import PlansForm from "./PlansForm";
import { changePlan as changePlanApi } from "./../BillingApi/billingApiRequests";
import {
  activateDevice,
  devicePlanById,
} from "./../BillingApi/billingApiRequests";
import ActivationDeviceForm from "../ActivationDevice/ActivationDeviceForm";
import UnactivatedDevices from "../ActivationDevice/UnactivatedDevices";
import ActivationInfo from "../ActivationDevice/ActivationInfo";

import { IAllPlans, Iinputs } from "../interfaces";
import { IModalPlans } from "./interfaces";

import "./ModalPlans.scss";

const ModalPlans = ({ active, setActive, row, step, setStep }: IModalPlans) => {
  const [loader, setLoader] = useState(false);
  const [cancel, setCancel] = useState([]);
  const [inputs, setInputs] = useState<Iinputs>({
    name: "",
    surname: "",
    plan_id: "",
    period: row?.plan_description && row?.plan_description.split("-")[1],
    card_id: "",
  });
  const [selectedPlan, setSelectedPlan] = useState({
    planType: row?.plan_description && row?.plan_description.split("-")[0],
  });
  const [allPeriods, setAllPeriods] = useState();
  const [allPlans, setAllPlans] = useState<Array<IAllPlans>>([]);
  const [activationDevice, setActivationDevice] = useState({
    device_ID: "",
    serial: "",
  });
  const { device_ID, serial } = activationDevice;
  const { plan_id } = inputs;
  const { token, loading } = useSelector(
    (state: iReducersState) => state.billing
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (row) {
      dispatch(billingLoading(true));
      devicePlanById({
        deviceId: row?.id,
      })
        .then((res) => {
          const { plan, devicePlans } = res.data;
          setAllPlans(plan);
        })
        .catch((err) => console.log("err", err))
        .finally(() => dispatch(billingLoading(false)));
    }
  }, [dispatch, row?.id, token.access_token]);

  useEffect(() => {
    if (activationDevice.device_ID) {
      dispatch(billingLoading(true));
      devicePlanById({
        deviceId: device_ID,
      })
        .then((res) => {
          const { plan, devicePlans } = res.data;
          setAllPlans(plan);
        })
        .catch((err) => console.log("devicePlanById ===>", err))
        .finally(() => dispatch(billingLoading(false)));
    }
  }, [activationDevice.device_ID]);

  const closeWindow = () => {
    setActive(false);
  };

  const changePlan = () => {
    setLoader(true);

    if (row && row?.subscriptionStatus !== 4) {
      changePlanApi({ device_id: row?.id, plan_id }).finally(() => {
        setLoader(false);
        setActive(false);
        history.push('/billing');
      });
    }

    if ((row && row?.subscriptionStatus == 4) || activationDevice.device_ID) {
      activateDevice({ device_id: device_ID || row.id, plan_id }).finally(() => {
        setLoader(false);
        setActive(false);
        if(row && row?.subscriptionStatus == 4) history.push('/billing');
      });
    }
  };

  const chooseUnactivatedDevice = (id, serial) => {
    setActivationDevice({
      device_ID: id,
      serial: serial,
    });
    setStep(2);
  };

  const choosePlan = (periodsData, planType) => {
    setAllPeriods(periodsData);
    setSelectedPlan({
      planType: planType,
    });
  };

  const handleBillingDetails = (e) => {
    const { name, value } = e.target;

    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal onBackdropClick={()=>closeWindow()}>
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
        <ModalHeader
          row={row}
          cancel={cancel}
          closeWindow={closeWindow}
          activationDeviceSerial={serial}
        />

        {row?.changeSubscription === "changes-pending" ||
        row?.changeSubscription === "contract-cancel" ? (
          <ModalCancellation row={row} closeWindow={closeWindow} />
        ) : null}

        {(isEmpty(row) || step === 1) && (
          <>
            <UnactivatedDevices
              chooseUnactivatedDevice={chooseUnactivatedDevice}
            />
            <ActivationDeviceForm
              setActivationDevice={setActivationDevice}
              setStep={setStep}
            />
            <ActivationInfo />
          </>
        )}

        {(row?.changeSubscription === "service-plans" || step === 2) && (
          <ServicePlans
            row={row}
            choose={choosePlan}
            allPlans={allPlans}
            loading={loading}
            activationDevice={activationDevice}
            selectedPlan={selectedPlan}
            activationDeviceId={device_ID}
            setCancel={setCancel}
            closeWindow={closeWindow}
          />
        )}

        {selectedPlan.planType && (
          <PlansForm
            inputs={inputs}
            setInputs={setInputs}
            plan={selectedPlan}
            allPeriods={allPeriods}
            row={row}
            changePlan={changePlan}
            loader={loader}
            activationDevice={activationDevice}
            handleBillingDetails={handleBillingDetails}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalPlans;
