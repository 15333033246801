import React, {useEffect} from 'react';
import {OrderedMap, OrderedSet} from 'immutable';
import {useDispatch, useSelector} from 'react-redux';
import {renderRoutes} from 'react-router-config';
import {path} from 'ramda';
import moment from 'moment';

import * as reportdb from '../../../shared/db/report-db';
import {Actions as TimelineActions} from '../../../stores/reducers/timeline-reducers';
import {useRedux} from '../../../states/redux-state';
import {deviceRoutes} from '../../../routes';
import {iDevicePing, iFullStoreState} from '../../../shared/interfaces';
import {setDeviceRoute} from '../../../stores/reducers/devicesTripsPoints/AC';
import {removeSelectedPoint} from '../../../stores/reducers/selectedPoint/AC';
import {Actions as MapActions, defaultBounds} from '../../../stores/reducers/gmap-reducers';

const getRangePoints = async ({rangeStart, rangeEnd, deviceId, timezone}) => new Promise<iDevicePing[]>((res, rej) => {
    getRangePoints.searchInc += 1;
    const mySearch = getRangePoints.searchInc;

    setTimeout(async () => {
        if (mySearch != getRangePoints.searchInc) return rej(); // user moved on from this search

        const points = await reportdb.getDevicePoints(deviceId, rangeStart, rangeEnd, 10000, 'asc', timezone);

        if (mySearch != getRangePoints.searchInc) return rej(); // user moved on from this search

        res(points);
    }, 1000);
});
getRangePoints.searchInc = 1;

export default ({match: {params: {itemId, id}}}) => {
    const deviceId = itemId || id;

    const dispatch = useDispatch();
    const rangeStart = useSelector<iFullStoreState, moment.Moment | undefined>(
        path(['timeline', 'range', 'startDate']),
        (l, r) => l?.unix() === r?.unix(),
    );
    const rangeEnd = useSelector<iFullStoreState, moment.Moment | undefined>(
        path(['timeline', 'range', 'endDate']),
        (l, r) => l?.unix() === r?.unix(),
    );
    const timezone = useSelector<iFullStoreState, string>(
        state => state.devicesData.devicesDetails.getIn([deviceId, 'timezone'], 'EST')
    );

    const loadedData = useRedux((s) => {
        const liveTripsIds = s.devicesTripsPoints.getIn([deviceId, 'liveTripsIds'], OrderedSet());
        const allTripsIds = s.devicesTripsPoints.getIn([deviceId, 'tripsPoints'], OrderedMap()).keySeq();

        return allTripsIds.size > liveTripsIds.size;
    });

    const loadData = () => {
        dispatch(TimelineActions.TIMELINE_START_SEARCHING());

        getRangePoints({rangeStart, rangeEnd, deviceId, timezone})
            // catch generally means it was an old search
            .catch((e) => console.log('point search failure', e) as any || [] as iDevicePing[])
            .then((points) => {
                dispatch(setDeviceRoute(deviceId, points));
            });
    };
    useEffect(() => {
        dispatch(MapActions.SET_BOUNDS(defaultBounds));
    }, []);

    useEffect(() => {
        if (!rangeStart || !rangeEnd) return;

        loadData();
    }, [rangeStart, rangeEnd]);

    useEffect(() => {
        if (!rangeStart || !rangeEnd) {
            dispatch(removeSelectedPoint());

            return;
        }

        if (!loadedData) loadData();

    }, [deviceId]);

    return renderRoutes(deviceRoutes);
};
