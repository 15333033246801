import React from 'react';
import ChevronRightIcon from '../../../SVG-components/chevron_right';
import {isMobile} from '../../../../shared/helpers';
import {NavbarElementProps} from "react-day-picker";

export default function NavBar ({onPreviousClick, onNextClick, className, localeUtils, month, showNextButton, showPreviousButton}: NavbarElementProps) {
    const months = localeUtils.getMonths();
    const currentMonthIndex = month.getMonth();
    const nextMonthIndex = currentMonthIndex < 11 ? currentMonthIndex + 1 : 0;

    const year = month.getFullYear();

    return (
        <div className={className}>
            <div
                className={`DayPicker-CustomNavButton${showPreviousButton ? '' : ' disabled'}`}
                onClick={() => onPreviousClick()}
            >
                <ChevronRightIcon className="turn-left" />
            </div>
            <div>{months[currentMonthIndex]} {year}</div>
            {!isMobile && <>
                <div/>
                <div/>
                <div>{months[nextMonthIndex]} {nextMonthIndex === 0 ? year + 1 : year}</div>

            </>}
            <div
                className={`DayPicker-CustomNavButton${showNextButton ? '' : ' disabled'}`}
                onClick={() => onNextClick()}
            >
                <ChevronRightIcon/>
            </div>

        </div>
    );

    /*
    return (
        <div className={className}>
            <div className={`DayPicker-CustomNavButton${showPreviousButton ? '' : ' disabled'}`}
                 onClick={() => onPreviousClick()}
            >
                <ChevronRightIcon className="turn-left" />
            </div>
            <div>{months[currentMonthIndex]} {year}</div>
            <div className={`DayPicker-CustomNavButton${showNextButton ? '' : ' disabled'}`}
                 onClick={() => onNextClick()}
            >
                <ChevronRightIcon />
            </div>
        </div>
    );
     */
}
