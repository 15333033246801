import { assoc, assocPath, dissocPath, always } from 'ramda';


class ActionsClass {
    CHANGE_FILTER = 'TAG_ITEM_PAGE_CHANGE_TAG_FILTER';
    ADD_TAG = 'TAG_ITEM_PAGE_ADD_TAG';
    REMOVE_TAG = 'TAG_ITEM_PAGE_REMOVE_TAG';
    RESET_PAGE = 'TAG_ITEM_PAGE_RESET';
}

export const Actions = new ActionsClass();

export const reducers = (state = initialState, action) => {
    if (action.type === Actions.CHANGE_FILTER) return assoc('tagFilter', action.filter)(state);
    if (action.type === Actions.ADD_TAG) return assocPath(['selectedTags', action.tagId], true)(state);
    if (action.type === Actions.REMOVE_TAG) return dissocPath(['selectedTags', action.tagId])(state);
    if (action.type === Actions.RESET_PAGE) return always(initialState);

    return state;
}

export interface iState {
    tagFilter: string,
    selectedTags: {[tagId: string]: true},
}

const initialState: iState = {
    tagFilter: '',
    selectedTags: {},
}


