import React from 'react';

import './styles.scss';

type IProps = {
    disabled?: boolean;
    checked?: boolean;
    label?: string
    onChange?: () => void;
    inputId?: string;
    labelStyles?: object;
    styles?: object;
}

const Checkbox = ({onChange, checked, label, inputId = 'styled-checkbox', labelStyles, disabled = false}: IProps) => {
    return (
        <div className="check-box">
            <input
            onChange={onChange}
            className="styled-checkbox"
            id={inputId}
            type="checkbox"
            checked={checked}
            disabled={disabled}
        />
        <label htmlFor={inputId} style={labelStyles}>{label}</label>
    </div>
    )
};

export default Checkbox;
