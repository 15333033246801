import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Map, OrderedMap, OrderedSet, Seq } from 'immutable';

import { iFullStoreState, Units } from '../../../shared/interfaces';
import DeviceTrip from './DeviceTrip';
import InfoSelectedPoint from '../InfoSelectedPoint';

type DeviceTripsProps = {
    deviceId: string;
    isReportTrips?: boolean;
}

const DeviceTrips = ({deviceId, isReportTrips = false}: DeviceTripsProps) => {
    const tripsArr = [];
    let filteredTripsArray = [];
    const deviceColor = useSelector<iFullStoreState, string>(
        state => state.devicesData.devicesDetails.getIn([deviceId, 'color'], '#000'),
    );
    const deviceUnits = useSelector<iFullStoreState, Units>(
        state => state.devicesData.devicesDetails.getIn([deviceId, 'units'], Units.miles),
    );
    const deviceLastTripId = useSelector<iFullStoreState, string | undefined>(
        state => state.devicesData.devicesLastPing.getIn([deviceId, 'tripId']),
    );

    const tripsIds = useSelector<iFullStoreState, Seq.Indexed<string>>(
        s => {
            if (!isReportTrips) {
                return s.devicesTripsPoints.getIn([deviceId, 'tripsPoints'], OrderedMap()).keySeq();
            }
            const reportTripsIds: Map<string, boolean | string> = s.devicesTripsPoints.getIn([deviceId, 'reportTripsIds']);
            return reportTripsIds.filter((show) => !!show).keySeq();
        },
        (left, right) => left.size === right.size,//is(left, right))
    );
    // filtered trip ids of device where value is true

    const liveTripsIds = useSelector<iFullStoreState, OrderedSet<string>>(
        s => s.devicesTripsPoints.getIn([deviceId, 'liveTripsIds']),
        (left, right) => left.size === right.size,//is(left, right))
    );

    const highlightTripPointIds = useSelector<iFullStoreState, Map<string, string>>(
        s => s.devicesTripsPoints.getIn([deviceId, 'reportTripsIds'], Map())
            .filter((highlightPointId) => typeof highlightPointId === 'string'),
    );
    const deviceDetails = useSelector<iFullStoreState, Map<string, object>>(
        s => s.devicesData.devicesDetails
    );

    useSelector<iFullStoreState>(
        s => {
           s.devicesTripsPoints.entrySeq().forEach(item => {
              const reportTripsIds: Map<string, boolean | string> = s.devicesTripsPoints.getIn([item[0], 'reportTripsIds']);
              reportTripsIds.toArray().forEach((deviceTripEntry) => {
                      const found = tripsArr.some(el => el.tripId === deviceTripEntry[0])
                      if(!found && deviceTripEntry[1]) {
                          let deviceColor = deviceDetails.getIn([item[0], 'color']);
                          tripsArr.push({color: deviceColor, deviceId: item[0],tripId:deviceTripEntry[0]})
                          filteredTripsArray = tripsArr;
                      }
                     else {
                          const index = tripsArr.findIndex(obj => obj.tripId === deviceTripEntry[0])
                          if (index !== -1 && !deviceTripEntry[1]) filteredTripsArray = tripsArr.splice(index, 1);
                     }
              })
           });
        },
    );


    const firstTripId = tripsIds.first<undefined>();
    const lastTripId = tripsIds.last<undefined>();

    return <>
        {tripsIds.toArray().map((id, index) =>
            <DeviceTrip key={id}
                        tripsDetails={filteredTripsArray}
                        last={deviceLastTripId === id}
                        tripIdx={index}
                        tripId={id}
                        highlightPointId={isReportTrips ? highlightTripPointIds.get(id) : undefined}
                        deviceId={deviceId}
                        color={deviceColor}
                        units={deviceUnits}
                        live={liveTripsIds.includes(id)}
            />,
        )}
        {(tripsIds.toArray().length > 0) && <InfoSelectedPoint firstTripId={firstTripId} lastTripId={lastTripId} />}
    </>;
};

export default React.memo(DeviceTrips);

