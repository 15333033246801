export class localStorage {
    private static get store() {
        return window.localStorage;
    }

    private static isQuotaExceeded(e) {
        let quotaExceeded = false;
        if (e) {
            switch (e.code) {
                case 22:
                    quotaExceeded = true;
                    break;
                case 1014:
                    if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                        quotaExceeded = true;
                    }
                    break;
            }
        }

        return quotaExceeded;
    }

    static get = <T=any>(key, defaultVal = undefined): T => {
        if (!localStorage.store.key(key) || localStorage.store.getItem(key) === undefined) return defaultVal;

        let parsed = defaultVal;
        try {
            parsed = JSON.parse(localStorage.store.getItem(key));

            if (parsed === null || parsed === undefined) parsed = defaultVal;
        } catch {}

        return localStorage.store.key(key) ? parsed : defaultVal;
    }

    static set = (key, value) => {
        try {
            return localStorage.store.setItem(key, JSON.stringify(value));
        } catch (e) {
            if (localStorage.isQuotaExceeded(e)) {
                console.log('Local Storage is full, Please empty site data!')
                return false
            }
        }
    }

    static forget = key => localStorage.store.removeItem(key);

    static has = (key) => !!localStorage.store.key(key);

    static clear = () => localStorage.store.clear();
}