import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {Actions as GeneralActions} from '../../stores/reducers/general-reducers';

import SearchModal from '../menus/search';
import {iFullStoreState} from '../../shared/interfaces';
import {closeDashboard, openDashboard} from '../../stores/reducers/dashboardInfo/AC';
import {DashboardSize} from '../../stores/reducers/dashboardInfo';
import ChevronRightIcon from '../SVG-components/chevron_right';
import SearchIcon from '../SVG-components/search';
import {ReactComponent as PlusIcon} from '../../assets/svg/plus.svg';
import './styles.scss';
import {isMobile} from '../../shared/helpers';
import { useLocation } from 'react-router-dom';

export const SearchInput = ({visibleInput}: {visibleInput: boolean}) => {
    const dispatch = useDispatch();
    const searchTerm = useSelector<iFullStoreState, string>((state) => state.general.searchTerm);
    const isSearchVis = useSelector<iFullStoreState, boolean>((s) => s.general.showSearch);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(GeneralActions.CHANGE_TERM(e.target.value));
    };

    return (
        <div className="search">
            <div className={`search-input${visibleInput ? ' open': ''}`}>
                <input type="text" id="search-input" value={searchTerm} onChange={handleChange} />
                <label htmlFor="search-input"><SearchIcon onClick={() => dispatch(GeneralActions.SHOW_SEARCH)} /></label>
                {isSearchVis && <label className="close-icon"><PlusIcon onClick={() => dispatch(GeneralActions.HIDE_SEARCH)} /></label>}
            </div>
        </div>
    );
};

type IProps = {
    overlay?: JSX.Element;
	title?: string
}
const DashboardBlock: React.FunctionComponent<IProps> = ({children, overlay, title=''}) => {
    const size = useSelector<iFullStoreState, DashboardSize>((s) => s.dashboardInfo.size);
    let open = size !== DashboardSize.CLOSED ? ' open' : '';

    const isSearchVis = useSelector<iFullStoreState, boolean>((s) => s.general.showSearch);
    const params = useLocation();

    if (size === DashboardSize.OPEN_FULL) {
        open += ' full-size';
    }

    const ref = useRef(null);

    const [showScroll, setShowScroll] = useState(false);
    const route = ["/billing/units", "/billing/invoices", "/billing/orders"];
    const billingRoute = route.some((item) => item === params.pathname)

    useEffect(() => {
        ref.current.scrollTop = 0;
        setShowScroll(false);
    }, []);

    // !window['cordova']
    return (
        <div className={`dashboard-block${open}`} onClick={() => setShowScroll(true)}>
			{((isMobile || window['cordova']) && title!='') && <h1>{title}</h1>}
            {(!billingRoute && !(isMobile || window['cordova'])) && <SearchInput visibleInput={isSearchVis}/>}
            <PerfectScrollbar className="scrollable" containerRef={(el) => ref.current = el}>
                {isSearchVis ? <SearchModal /> : children}
            </PerfectScrollbar>
            {overlay}
            {!(isMobile || window['cordova']) && <DashboardExpanderButtons/>}
        </div>
    );
};

export default DashboardBlock;

const DashboardExpanderButtons = () => {
    const dispatch = useDispatch();

    return (
        <div className="expander-buttons">
            <div className="increase-button" onClick={() => dispatch(openDashboard())}>
                <ChevronRightIcon />
            </div>
            <span />
            <div className="decrease-button" onClick={() => {dispatch(closeDashboard()); dispatch(GeneralActions.HIDE_SEARCH); }}>
                <ChevronRightIcon />
            </div>
        </div>
    );
};
