import React from 'react';
import { withBemMod } from '@bem-react/core';

import { cnButton } from '../index';
import './Button_theme_outline.scss';

export interface IButtonThemeActionProps {
    theme?: 'outline';
}

export const withButtonThemeOutline = withBemMod<IButtonThemeActionProps>(
    cnButton(),
    {theme: 'outline'},
    (Button) => ({theme, ...props}) => <Button {...props} />,
);
