import { createSelector } from 'reselect';
import { path, pickBy } from 'ramda';

import { vals, idValArr } from '../helpers';
import { iList, ItemType, FenceGroup, iFenceDetails, iFence, iFullStoreState } from '../interfaces';
import { getItemTagsSelector } from './tags-labels-selectors';


const getFences = (state: iFullStoreState) => state.general.fences;

export const fenceOrPathSelector = (itemType: ItemType, itemId: string, fenceGroup: FenceGroup) => createSelector(
    getFences,
    getItemTagsSelector,
    (fences, tags) => {
        // fences are both directly applied and through tags so search the tag fences too.
        if (!itemType) return [] as Array<iFenceDetails>;

        const direct = path([itemType, itemId])(fences) || {} as iList<iFenceDetails>;

        const tagFences = {};
        Object.keys(tags).forEach(async tagId =>
            idValArr((path([ItemType.tag.toString(), tagId])(fences) || {}) as iList<iFenceDetails>).forEach(({id, val}) => {
                tagFences[id] = val
            })
        );

        // merge them together prefering direct to tagged
        const all = { ...tagFences, ...direct } as iList<iFenceDetails>;

        return vals(pickBy((f: iFenceDetails) => f.fenceGroup == fenceGroup)(all)) as Array<iFenceDetails>;
    }
)
